import clsx from 'clsx';
import { SortIcon } from 'icons';
import { PropsWithChildren } from 'react';
import { SortOptionEnum } from 'types';
import styles from './SortTableHeader.module.scss';

type SortTableHeaderProps = { sortOption: string | null; onClick: () => void };

export const SortTableHeader = ({ sortOption, onClick, children }: PropsWithChildren<SortTableHeaderProps>) => {
  return (
    <span onClick={onClick}>
      <SortIcon
        className={clsx(
          'mr-4',
          styles.sortOption,
          sortOption && styles.activeSortOption,
          sortOption === SortOptionEnum.ASC && styles.activeSortOptionRotate,
        )}
      />
      {children}
    </span>
  );
};
