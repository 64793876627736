import React, { FC, useCallback, useMemo, useState } from 'react';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import Tooltip from '@material-ui/core/Tooltip';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import clsx from 'clsx';

import { LoadingButton, PassVisibilityBtn } from 'components';
import { ResetFormValues } from 'types';
import { PASSWORD } from 'consts';

import styles from './ResetPassword.module.scss';

interface ResetPasswordInterface {
  onSubmit: (values: ResetFormValues, formikHelpers: FormikHelpers<ResetFormValues>) => void | Promise<void>;
  password?: string;
  passwordRepeat?: string;
}

export const ResetPassword: FC<ResetPasswordInterface> = ({ password, passwordRepeat, onSubmit }) => {
  const [isPassVisible, setIsPassVisible] = useState(false);

  const togglePasswordVisibility = useCallback(() => setIsPassVisible((prev) => !prev), []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape(
        {
          password: Yup.string().matches(PASSWORD, 'Use 8 or more characters with a mix of letters, numbers & symbols'),
          passwordRepeat: Yup.string()
            .when(['password'], {
              is: (newPassword: string) => {
                return !!newPassword;
              },
              then: Yup.string().required('Password confirmation should be equal to new password'),
            })
            .oneOf([Yup.ref('password')], 'Password should be matched')
            .matches(PASSWORD, 'Use 8 or more characters with a mix of letters, numbers & symbols'),
        },
        [['password', 'passwordRepeat']],
      ),
    [],
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        password: password ?? '',
        passwordRepeat: passwordRepeat ?? '',
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <div className="flex flex-column justify-content-between">
          <div className="flex flex-column juctify-content-between align-items-center padding-15">
            <div>
              <p className={clsx('weight-normal', styles.titleFontSize)}>Reset password</p>
              <p className={clsx('weight-normal text-10 color-smallTextGrey pb-48', styles.textStyle)}>
                Use 8 or more characters with a mix of letters, numbers & symbols
              </p>
              <Form>
                <Field
                  component={TextField}
                  type={isPassVisible ? 'text' : 'password'}
                  name="password"
                  variant="outlined"
                  label="New password"
                  className="mb-24 mb-md-36"
                  InputProps={{
                    endAdornment: (
                      <Tooltip
                        aria-label={'Show password'}
                        title={isPassVisible ? 'Hide password' : 'Show password'}
                        placement="right"
                      >
                        <span className="ml-6">
                          <PassVisibilityBtn
                            isPassVisible={isPassVisible}
                            togglePasswordVisibility={togglePasswordVisibility}
                          />
                        </span>
                      </Tooltip>
                    ),
                  }}
                />

                <Field
                  component={TextField}
                  type={isPassVisible ? 'text' : 'password'}
                  name="passwordRepeat"
                  variant="outlined"
                  label="Confirm new password"
                  className="mb-24 mb-md-36"
                  InputProps={{
                    endAdornment: (
                      <Tooltip
                        aria-label={'Show password'}
                        title={isPassVisible ? 'Hide password' : 'Show password'}
                        placement="right"
                      >
                        <span className="ml-6">
                          <PassVisibilityBtn
                            isPassVisible={isPassVisible}
                            togglePasswordVisibility={togglePasswordVisibility}
                          />
                        </span>
                      </Tooltip>
                    ),
                  }}
                />
                <div className="flex align-items-center justify-content-center">
                  <LoadingButton type="submit" className="mb-24 mb-md-0" loading={isSubmitting}>
                    RESET PASSWORD
                  </LoadingButton>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
