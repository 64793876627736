import { IconProps } from 'types';

export const ActiveStarIcon = ({ className, onClick }: IconProps) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99934 0.666504L4.96167 4.82584L0.333008 5.4965L3.68601 8.7745L2.88434 13.3332L6.99967 11.1398L11.1147 13.3332L10.3197 8.77484L13.6663 5.49684L9.06334 4.82584L6.99967 0.666504H6.99934Z"
        fill="#212B83"
      />
    </svg>
  );
};
