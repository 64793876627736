import { IconProps } from 'types';

export const DocImg = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.75 25.6666C24.683 25.6666 26.25 24.0996 26.25 22.1666V5.83325C26.25 3.90026 24.683 2.33325 22.75 2.33325H5.25C3.317 2.33325 1.75 3.90025 1.75 5.83325V22.1666C1.75 24.0996 3.317 25.6666 5.25 25.6666H22.75ZM23.9167 16.8215V5.83325C23.9167 5.18892 23.3943 4.66659 22.75 4.66659H5.25C4.60567 4.66659 4.08333 5.18892 4.08333 5.83325V22.1666C4.08333 22.8109 4.60567 23.3333 5.25 23.3333H17.7996L23.9167 16.8215ZM21.001 23.3333H22.75C23.3943 23.3333 23.9167 22.8109 23.9167 22.1666V20.2294L21.001 23.3333Z"
        fill="#565EA1"
      />
      <path
        d="M5.83301 11.1698H8.27246C8.75334 11.1698 9.14119 11.2321 9.436 11.3567C9.73323 11.4812 9.9785 11.66 10.1718 11.8931C10.3651 12.1261 10.5053 12.3972 10.5923 12.7064C10.6793 13.0155 10.7228 13.3431 10.7228 13.6892C10.7228 14.2314 10.6575 14.6525 10.527 14.9524C10.399 15.25 10.2202 15.5004 9.99059 15.7034C9.76102 15.9041 9.51454 16.0379 9.25114 16.1048C8.89108 16.1971 8.56485 16.2433 8.27246 16.2433H5.83301V11.1698ZM7.47502 12.3187V15.0908H7.87736C8.2205 15.0908 8.46457 15.0551 8.60956 14.9835C8.75455 14.9097 8.86812 14.7828 8.95029 14.6029C9.03245 14.4206 9.07353 14.1264 9.07353 13.7204C9.07353 13.1828 8.9817 12.8148 8.79805 12.6164C8.61439 12.418 8.30991 12.3187 7.88461 12.3187H7.47502Z"
        fill="#565EA1"
      />
      <path
        d="M11.3825 13.71C11.3825 12.8817 11.6241 12.2368 12.1074 11.7754C12.5907 11.314 13.2637 11.0833 14.1264 11.0833C15.0109 11.0833 15.6923 11.3105 16.1708 11.765C16.6492 12.2172 16.8885 12.8517 16.8885 13.6685C16.8885 14.2614 16.7834 14.7482 16.5731 15.1289C16.3653 15.5073 16.0632 15.8026 15.6669 16.0149C15.273 16.2248 14.7813 16.3298 14.1917 16.3298C13.5924 16.3298 13.0958 16.2387 12.7019 16.0564C12.3104 15.8741 11.9927 15.5857 11.7486 15.1912C11.5045 14.7967 11.3825 14.3029 11.3825 13.71ZM13.0245 13.7169C13.0245 14.2291 13.1236 14.5971 13.3217 14.8209C13.5223 15.0447 13.7941 15.1566 14.1373 15.1566C14.4901 15.1566 14.7632 15.047 14.9565 14.8278C15.1498 14.6086 15.2465 14.2153 15.2465 13.6477C15.2465 13.1701 15.145 12.8217 14.942 12.6025C14.7414 12.381 14.4683 12.2703 14.1228 12.2703C13.7917 12.2703 13.5259 12.3822 13.3253 12.606C13.1248 12.8298 13.0245 13.2001 13.0245 13.7169Z"
        fill="#565EA1"
      />
      <path
        d="M21.3107 14.1703L22.7497 14.5856C22.653 14.9709 22.5008 15.2927 22.293 15.5511C22.0851 15.8095 21.8266 16.0045 21.5173 16.136C21.2104 16.2675 20.8189 16.3333 20.3428 16.3333C19.7653 16.3333 19.2929 16.2537 18.9256 16.0945C18.5607 15.933 18.2453 15.6503 17.9795 15.2466C17.7137 14.8428 17.5808 14.326 17.5808 13.6961C17.5808 12.8563 17.814 12.2115 18.2804 11.7616C18.7492 11.3094 19.4113 11.0833 20.2667 11.0833C20.9361 11.0833 21.4617 11.2125 21.8435 11.4709C22.2277 11.7293 22.5129 12.1261 22.6989 12.6614L21.249 12.9694C21.1983 12.8148 21.1451 12.7017 21.0895 12.6302C20.9977 12.5102 20.8853 12.418 20.7524 12.3534C20.6195 12.2888 20.4709 12.2565 20.3066 12.2565C19.9345 12.2565 19.6493 12.3995 19.4512 12.6856C19.3013 12.8979 19.2264 13.2312 19.2264 13.6858C19.2264 14.2487 19.3158 14.6352 19.4947 14.8451C19.6735 15.0528 19.9248 15.1566 20.2486 15.1566C20.5627 15.1566 20.7996 15.0724 20.9591 14.9039C21.121 14.7355 21.2382 14.491 21.3107 14.1703Z"
        fill="#565EA1"
      />
    </svg>
  );
};
