import { Fragment, useMemo } from 'react';
import { links } from 'App';
import { useHistory } from 'react-router';
import { CellProps } from 'react-table';
import { WarningReportIcon } from 'icons';
import Tooltip from '@material-ui/core/Tooltip';
import { TableActions } from 'components/common/Table/parts/Actions';
import { Proposal, WorkOrderTypeEnum } from 'types';
import { formatDateToMMDDYYYY, getFullName } from 'utils';
import formatNumberWithCommas from 'utils/formatNumberWithCommas';
import commonStyles from 'styles/common.module.scss';
import { ReactComponent as LinkIcon } from 'icons/link.svg';
import { SortTableHeader } from 'components';

import { SortingOptions } from './types';

export const useProposalRequestsTableColumns = (
  handleDelete: (id: number) => void,
  handleEdit: (id: number) => void,
  sortOption: { [x: string]: string | null },
  setSortingOptions: (option: SortingOptions, table: string) => void,
  group: string,
) => {
  const {
    location: { search },
    push,
  } = useHistory();
  const columns = useMemo(
    () => [
      {
        id: `${group}_name`,
        Header: () => (
          <SortTableHeader
            onClick={() => setSortingOptions(SortingOptions.Name, group)}
            sortOption={sortOption[SortingOptions.Name]}
          >
            <span>Name</span>
          </SortTableHeader>
        ),
        minWidth: 230,
        maxWidth: 230,
        Cell: ({
          row: {
            original: { name, id, project, state, expectedDateOfFirstInspection, expectedDateOfLastInspection },
          },
        }: CellProps<Proposal>) => {
          const isMissingData = state && expectedDateOfFirstInspection && expectedDateOfLastInspection;
          return (
            <div
              onClick={() => push(links.ProposalRequestOverview({ id }), { search })}
              className="flex gap-10 align-center align-items-center p-relative"
            >
              <div className="flex flex-column p-absolute" style={{ left: -22, top: 2 }}>
                {!isMissingData && (
                  <Tooltip title="Missing data: state, anticipated first inspection date or anticipated last inspection date">
                    <div>
                      <WarningReportIcon color="#212b83" />
                    </div>
                  </Tooltip>
                )}
                {!project && (
                  <Tooltip title="RFP is not linked to a project">
                    <div>
                      <LinkIcon width={18} height={18} fill="#ff9900" />
                    </div>
                  </Tooltip>
                )}
              </div>
              <p>{name}</p>
            </div>
          );
        },
      },
      {
        id: `${group}_client`,
        Header: () => (
          <SortTableHeader
            onClick={() => setSortingOptions(SortingOptions.Bank, group)}
            sortOption={sortOption[SortingOptions.Bank]}
          >
            <span>Client(Bank)</span>
          </SortTableHeader>
        ),
        minWidth: 160,
        maxWidth: 160,
        Cell: ({
          row: {
            original: { bank },
          },
        }: CellProps<Proposal>) => <>{bank?.name}</>,
      },
      {
        id: `${group}_bank_contacts`,
        Header: () => (
          <SortTableHeader
            onClick={() => setSortingOptions(SortingOptions.BankContacts, group)}
            sortOption={sortOption[SortingOptions.BankContacts]}
          >
            <span>Bank Contacts</span>
          </SortTableHeader>
        ),
        minWidth: 160,
        maxWidth: 160,
        Cell: ({
          row: {
            original: { bankContacts },
          },
        }: CellProps<Proposal>) => (
          <>
            {bankContacts?.map((contact) => (
              <Fragment key={contact?.id}>
                <p>{getFullName(contact)}</p>
                <br />
              </Fragment>
            ))}
          </>
        ),
      },
      {
        id: `${group}_pre_price`,
        minWidth: 100,
        Header: () => (
          <SortTableHeader
            onClick={() => setSortingOptions(SortingOptions.PrePrice, group)}
            sortOption={sortOption[SortingOptions.PrePrice]}
          >
            <span className={commonStyles.tableItemEndAlign}>PRE Price</span>
          </SortTableHeader>
        ),
        Cell: ({
          row: {
            original: { services },
          },
        }: CellProps<Proposal>) => {
          const price = services?.find(({ name }) => name === WorkOrderTypeEnum.PreConstructionReview)?.price;
          return <span className={commonStyles.tableItemEndAlign}>{price && `$${formatNumberWithCommas(price)}`}</span>;
        },
      },
      {
        id: `${group}_inspection_price`,
        minWidth: 100,
        Header: () => (
          <SortTableHeader
            onClick={() => setSortingOptions(SortingOptions.InspectionPrice, group)}
            sortOption={sortOption[SortingOptions.InspectionPrice]}
          >
            <span className={commonStyles.tableItemEndAlign}>Inspection</span>
          </SortTableHeader>
        ),
        Cell: ({
          row: {
            original: { services },
          },
        }: CellProps<Proposal>) => {
          const price = services?.find(({ name }) => name === WorkOrderTypeEnum.InspectionReport)?.price;
          return <span className={commonStyles.tableItemEndAlign}>{price && `$${formatNumberWithCommas(price)}`}</span>;
        },
      },
      {
        id: `${group}_ltv`,
        minWidth: 100,
        Header: () => (
          <SortTableHeader
            onClick={() => setSortingOptions(SortingOptions.Ltv, group)}
            sortOption={sortOption[SortingOptions.Ltv]}
          >
            <span className={commonStyles.tableItemEndAlign}>LTV</span>
          </SortTableHeader>
        ),
        Cell: ({
          row: {
            original: { ltv },
          },
        }: CellProps<Proposal>) => (
          <span className={commonStyles.tableItemEndAlign}>{ltv && `$${formatNumberWithCommas(ltv)}`}</span>
        ),
      },
      {
        id: `${group}_rfp_received`,
        minWidth: 140,
        Header: () => (
          <SortTableHeader
            onClick={() => setSortingOptions(SortingOptions.RfpRecieved, group)}
            sortOption={sortOption[SortingOptions.RfpRecieved]}
          >
            <span>RFP RECEIVED</span>
          </SortTableHeader>
        ),
        Cell: ({
          row: {
            original: { receivedDate },
          },
        }: CellProps<Proposal>) => <>{formatDateToMMDDYYYY(receivedDate)}</>,
      },
      {
        id: `${group}_proposal_sent`,
        minWidth: 150,
        Header: () => <span>Proposal Sent</span>,
        Cell: ({
          row: {
            original: { sentDate },
          },
        }: CellProps<Proposal>) => <>{formatDateToMMDDYYYY(sentDate)}</>,
      },
      {
        id: `${group}_proposal_accepted`,
        minWidth: 170,
        Header: () => <span>Awarded</span>,
        Cell: ({
          row: {
            original: { awardDate },
          },
        }: CellProps<Proposal>) => <>{formatDateToMMDDYYYY(awardDate)}</>,
      },
      {
        id: `${group}_estimated_date_start`,
        minWidth: 140,
        Header: () => (
          <SortTableHeader
            onClick={() => setSortingOptions(SortingOptions.ExpectedDateOfFirstInspection, group)}
            sortOption={sortOption[SortingOptions.ExpectedDateOfFirstInspection]}
          >
            <span>First Inspection</span>
          </SortTableHeader>
        ),
        Cell: ({
          row: {
            original: { expectedDateOfFirstInspection },
          },
        }: CellProps<Proposal>) => <>{formatDateToMMDDYYYY(expectedDateOfFirstInspection)}</>,
      },
      {
        id: `${group}_actions`,
        Header: '',
        accessor: 'edit',
        minWidth: 30,
        width: 30,
        maxWidth: 42,
        Cell: ({
          row: {
            original: { id },
          },
        }: CellProps<Proposal>) => (
          <TableActions model="proposal request" {...{ handleDelete, handleEdit, id: id as number }} />
        ),
      },
    ],
    [sortOption, group],
  );
  return { columns };
};
