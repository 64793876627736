import { TextField } from 'formik-material-ui';
import { Field } from 'formik';
import { Typography } from '@material-ui/core';

import { defaultFieldProps } from '../const';

export const UtilityAvailabilityLettersFields = () => (
  <>
    <Typography
      // TODO: it is temporary quick fix, need to be removed after the design is ready
      variant="body1"
      style={{
        fontWeight: 'bold',
        marginLeft: '12px',
        marginBottom: '25px',
      }}
    >
      Utility / Provider
    </Typography>
    <Field component={TextField} name="sanitaryService" label="Sanitary Sewer Service" {...defaultFieldProps} />
    <Field component={TextField} name="waterService" label="Water Service" {...defaultFieldProps} />
    <Field component={TextField} name="power" label="Power" {...defaultFieldProps} />
    <Field component={TextField} name="dataTelephoneCable" label="Data/Telephone/Cable" {...defaultFieldProps} />
    <Field component={TextField} name="gas" label="Gas" {...defaultFieldProps} />
    <Field component={TextField} name="other" label="Other" {...defaultFieldProps} />
  </>
);
