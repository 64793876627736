import { DateType } from 'types/dateType';
import { toDateWithTimeZone } from 'utils/getDateWithoutTimeZone';
import { isValidDateFormat } from 'utils/isValidDateFormat';
import { reduce } from 'utils/reduce';
import { formatAMPM } from 'utils/toDateFormat';
import { toPlaceHolder } from 'utils/toPlaceHolder';
import { toSelectedFormat } from 'utils/toSelectedFormat';

const formatFieldValue = (value: string | undefined, dataType: DateType) => {
  return isValidDateFormat(value)
    ? reduce([toDateWithTimeZone, toSelectedFormat, formatAMPM], value ?? '')
    : toPlaceHolder(dataType, value);
};

const getFormattedFieldValue = (dataType: DateType) => {
  return (fieldValue: string | undefined) => {
    return formatFieldValue(fieldValue, dataType);
  };
};

export { getFormattedFieldValue };
