import { FormControlLabel, Radio } from '@material-ui/core';
import {
  ConditionsAnswers,
  ConditionsAnswersWithCC,
  ConditionsAnswersWithDNAttend,
  ConditionsAnswersWithNA,
  ConditionsAnswersWithNAAndRetain,
  ConditionsAnswersWithNN,
  ConditionsAnswersWithNNAndUnknown,
  ConditionsAnswersWithNNAndYesNDated,
  ConditionsAnswersWithNP,
  ConditionsAnswersWithUnknown,
  PartialCondition,
  QuestionType,
  YesNoPartialCondition,
} from 'pages/ReportWriting/schemas/types';

export const getRadioOptions = (type: QuestionType, id?: string | number) => {
  if (type === QuestionType.Condition) {
    return (
      <>
        <FormControlLabel value={ConditionsAnswers.Yes} control={<Radio />} label={ConditionsAnswers.Yes} />
        <FormControlLabel value={ConditionsAnswers.No} control={<Radio />} label={ConditionsAnswers.No} />
      </>
    );
  }
  if (type === QuestionType.ConditionWithNP) {
    return (
      <>
        <FormControlLabel value={ConditionsAnswersWithNP.Yes} control={<Radio />} label={ConditionsAnswersWithNP.Yes} />
        <FormControlLabel value={ConditionsAnswersWithNP.No} control={<Radio />} label={ConditionsAnswersWithNP.No} />
        <FormControlLabel
          value={ConditionsAnswersWithNP.NotProvided}
          control={<Radio />}
          label={ConditionsAnswersWithNP.NotProvided}
        />
      </>
    );
  }
  if (type === QuestionType.ConditionWithCC) {
    return (
      <>
        <FormControlLabel value={ConditionsAnswersWithCC.Yes} control={<Radio />} label={ConditionsAnswersWithCC.Yes} />
        <FormControlLabel value={ConditionsAnswersWithCC.No} control={<Radio />} label={ConditionsAnswersWithCC.No} />
        <FormControlLabel
          value={ConditionsAnswersWithCC.CantComment}
          control={<Radio />}
          label={ConditionsAnswersWithCC.CantComment}
        />
      </>
    );
  }
  if (type === QuestionType.ConditionWithNNAndUnknown) {
    return (
      <>
        <FormControlLabel
          value={ConditionsAnswersWithNNAndUnknown.Yes}
          control={<Radio />}
          label={ConditionsAnswersWithNNAndUnknown.Yes}
        />
        <FormControlLabel
          value={ConditionsAnswersWithNNAndUnknown.No}
          control={<Radio />}
          label={ConditionsAnswersWithNNAndUnknown.No}
        />
        <FormControlLabel
          value={ConditionsAnswersWithNNAndUnknown.NotNeeded}
          control={<Radio />}
          label={ConditionsAnswersWithNNAndUnknown.NotNeeded}
        />
        <FormControlLabel
          value={ConditionsAnswersWithNNAndUnknown.Unknown}
          control={<Radio />}
          label={ConditionsAnswersWithNNAndUnknown.Unknown}
        />
      </>
    );
  }

  if (type === QuestionType.ConditionWithUnknown) {
    return (
      <>
        <FormControlLabel
          value={ConditionsAnswersWithUnknown.Yes}
          control={<Radio />}
          label={ConditionsAnswersWithUnknown.Yes}
        />
        <FormControlLabel
          value={ConditionsAnswersWithUnknown.No}
          control={<Radio />}
          label={ConditionsAnswersWithUnknown.No}
        />
        <FormControlLabel
          value={ConditionsAnswersWithUnknown.Unknown}
          control={<Radio />}
          label={ConditionsAnswersWithUnknown.Unknown}
        />
      </>
    );
  }

  if (type === QuestionType.YesNoPartialCondition) {
    return (
      <>
        <FormControlLabel value={YesNoPartialCondition.Yes} control={<Radio />} label={YesNoPartialCondition.Yes} />
        <FormControlLabel
          value={YesNoPartialCondition.Partial}
          control={<Radio />}
          label={YesNoPartialCondition.Partial}
        />
        <FormControlLabel value={YesNoPartialCondition.No} control={<Radio />} label={YesNoPartialCondition.No} />
      </>
    );
  }

  if (type === QuestionType.PartialCondition) {
    return (
      <>
        <FormControlLabel value={PartialCondition.Yes} control={<Radio />} label={PartialCondition.Yes} />
        <FormControlLabel value={PartialCondition.Partial} control={<Radio />} label={PartialCondition.Partial} />
      </>
    );
  }

  if (type === QuestionType.ConditionWithNA) {
    let label: string = ConditionsAnswersWithNA.NA;
    if (id === '69') label = 'Contract not provided';
    else if (id === '49') label = 'Development draw not provided';
    else if (id === '13') label = 'NA for this project';
    return (
      <>
        <FormControlLabel value={ConditionsAnswersWithNA.Yes} control={<Radio />} label={ConditionsAnswersWithNA.Yes} />
        <FormControlLabel value={ConditionsAnswersWithNA.No} control={<Radio />} label={ConditionsAnswersWithNA.No} />
        <FormControlLabel value={ConditionsAnswersWithNA.NA} control={<Radio />} label={label} />
      </>
    );
  }

  if (type === QuestionType.ConditionsWithNAAndRetain) {
    let labelNA: string = ConditionsAnswersWithNAAndRetain.NA;
    let labelRetain: string = ConditionsAnswersWithNAAndRetain.NA;
    if (id === '69') {
      labelNA = 'Contract not provided';
      labelRetain = 'Retainage not specified in contract';
    }

    return (
      <>
        <FormControlLabel
          value={ConditionsAnswersWithNAAndRetain.Yes}
          control={<Radio />}
          label={ConditionsAnswersWithNAAndRetain.Yes}
        />
        <FormControlLabel
          value={ConditionsAnswersWithNAAndRetain.No}
          control={<Radio />}
          label={ConditionsAnswersWithNAAndRetain.No}
        />
        <FormControlLabel value={ConditionsAnswersWithNAAndRetain.Retain} control={<Radio />} label={labelRetain} />
        <FormControlLabel value={ConditionsAnswersWithNAAndRetain.NA} control={<Radio />} label={labelNA} />
      </>
    );
  }

  if (type === QuestionType.ConditionWithNN) {
    return (
      <>
        <FormControlLabel value={ConditionsAnswersWithNN.Yes} control={<Radio />} label={ConditionsAnswersWithNN.Yes} />
        <FormControlLabel value={ConditionsAnswersWithNN.No} control={<Radio />} label={ConditionsAnswersWithNN.No} />
        <FormControlLabel
          value={ConditionsAnswersWithNN.NotNeeded}
          control={<Radio />}
          label={ConditionsAnswersWithNN.NotNeeded}
        />
      </>
    );
  }

  if (type === QuestionType.ConditionWithNNAndYesNDated) {
    return (
      <>
        <FormControlLabel
          value={ConditionsAnswersWithNNAndYesNDated.Yes}
          control={<Radio />}
          label={ConditionsAnswersWithNNAndYesNDated.Yes}
        />
        <FormControlLabel
          value={ConditionsAnswersWithNNAndYesNDated.YesNotDated}
          control={<Radio />}
          label={ConditionsAnswersWithNNAndYesNDated.YesNotDated}
        />
        <FormControlLabel
          value={ConditionsAnswersWithNNAndYesNDated.No}
          control={<Radio />}
          label={ConditionsAnswersWithNNAndYesNDated.No}
        />
        <FormControlLabel
          value={ConditionsAnswersWithNNAndYesNDated.NotNeeded}
          control={<Radio />}
          label={ConditionsAnswersWithNNAndYesNDated.NotNeeded}
        />
      </>
    );
  }

  if (type === QuestionType.ConditionWithDNAttend) {
    return (
      <>
        <FormControlLabel
          value={ConditionsAnswersWithDNAttend.Yes}
          control={<Radio />}
          label={ConditionsAnswersWithDNAttend.Yes}
        />
        <FormControlLabel
          value={ConditionsAnswersWithDNAttend.DidNotAttend}
          control={<Radio />}
          label={ConditionsAnswersWithDNAttend.DidNotAttend}
        />
        <FormControlLabel
          value={ConditionsAnswersWithDNAttend.No}
          control={<Radio />}
          label={ConditionsAnswersWithDNAttend.No}
        />
      </>
    );
  }
  if (type === QuestionType.ConditionWithIncDec) {
    return (
      <>
        <FormControlLabel value={ConditionsAnswersWithNN.No} control={<Radio />} label={ConditionsAnswersWithNN.No} />
      </>
    );
  }
};
