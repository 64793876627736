import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const AuditTrailRoute = Route(
  {
    id: Route.params.number.optional,
    search: Route.query.string,
    page: Route.query.number,
    dateRequestedStart: Route.query.string,
    dateRequestedEnd: Route.query.string,
    project: Route.query.string,
    user: Route.query.string,
  },
  ({ id }) => `/audit-trail/${id}`,
);

export const AuditTrail = AuditTrailRoute(lazy(() => import('./AuditTrail')));
