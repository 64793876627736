import { links } from 'App';
import { useHistoryContext } from 'contexts/HistoryProvider';

export const PROPOSAL_STATE_LS_KEY = 'proposalPageState';

export const useProposalLocalStorage = () => {
  const { history } = useHistoryContext();

  const lsData = localStorage.getItem(PROPOSAL_STATE_LS_KEY);
  const parsedLsData = lsData && JSON.parse(lsData);

  const isProposalOverviewPrevPage =
    history.length > 2 && history[history.length - 2].includes(`${links.ProposalRequestOverview()}/`);

  const getScrollTopPositionFromLS = () => {
    return parsedLsData && isProposalOverviewPrevPage ? parsedLsData.scrollTopPosition : 0;
  };

  const getExpandedSectionsFromLS = () => {
    return parsedLsData && isProposalOverviewPrevPage ? parsedLsData.expandedSections : [];
  };

  return { isProposalOverviewPrevPage, getExpandedSectionsFromLS, getScrollTopPositionFromLS };
};
