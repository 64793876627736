export const defaultFieldProps = { className: 'mb-16' };

export const YesOrNoOptions = [
  { id: '1', name: 'Yes' },
  { id: '2', name: 'No' },
];

export const YesNoNaOptions = [...YesOrNoOptions, { id: '3', name: 'N/A' }];
export const YesNoPartiallyOptions = [...YesOrNoOptions, { id: '3', name: 'Partially' }];
export const YesNoPartiallyNaOptions = [...YesNoPartiallyOptions, { id: '4', name: 'N/A' }];

export const stampedAndSigned = [
  ...YesOrNoOptions,
  { id: '3', name: 'Stamped but not signed' },
  { id: '4', name: 'Signed but not stamped' },
  { id: '5', name: 'Some sheets stamped and signed, but not all' },
  { id: '6', name: 'Stamped/Signed by Engineer, not Architect' },
  { id: '7', name: 'Stamped/Signed by Architect, not Engineer ' },
];

export const permittingStatus = [
  { id: '1', name: 'Marked "Released for Construction"' },
  { id: '2', name: 'Marked "Preliminary/Not for Construction"' },
  { id: '3', name: 'Marked "Issued for Permit/Permit Set"' },
  { id: '4', name: 'No revisions shown' },
  { id: '5', name: 'Some revisions, but no apparent approvals' },
];

export const geotechMatch = [...YesOrNoOptions, { id: '3', name: 'No report provided' }, { id: '4', name: 'N/A' }];

//survey
export const surveyTypesOptions = [
  { id: '1', name: 'ALTA/NSPS' },
  { id: '2', name: 'Boundary' },
  { id: '3', name: 'Topographic' },
  { id: '4', name: 'Subdivision Plat' },
  { id: '5', name: 'Unspecified' },
  { id: '6', name: 'Other' },
];

export const surveyStampedAndSignedOptions = [
  ...YesOrNoOptions,
  { id: '3', name: 'Stamped but not signed' },
  { id: '4', name: 'Signed but not stamped' },
];

//construction agreement

export const constructionAgreementRetainangeOptions = [
  { id: '1', name: '10%' },
  { id: '2', name: '5%' },
  { id: '3', name: '15%' },
  { id: '4', name: 'None' },
  { id: '5', name: 'Other' },
];

export const constructionAgreementPaymantTypeOptions = [
  { id: '1', name: 'Stipulated Sum' },
  { id: '2', name: 'Cost Plus Fee with a Guaranteed Maximum Price' },
  { id: '3', name: 'Cost Plus Fee without a Guaranteed Maximum Price' },
];

export const constructionAgreementExecutedOptions = [
  ...YesOrNoOptions,
  { id: '3', name: 'By Owner but not Contractor' },
  { id: '4', name: 'By Contractor but not Owner' },
];

export const constructionAgreementScopeOfWorkOptions = [
  { id: '1', name: 'Site Work' },
  { id: '2', name: 'Building Construction' },
  { id: '3', name: 'Site Work and Building Construction' },
  { id: '4', name: 'Tenant Improvement' },
  { id: '5', name: 'Building Shell' },
  { id: '6', name: 'FF&E' },
  { id: '7', name: 'Landscaping' },
  { id: '8', name: 'Utilities' },
  { id: '9', name: 'Elevator' },
  { id: '10', name: 'Other' },
];

export const constructionAgreementContractTypeOptions = [
  { id: '1', name: 'AIA Standard Form of Agreement between Owner and Desing-Builder' },
  { id: '2', name: 'AIA Standard Abbreviated Form of Agreement' },
  { id: '3', name: 'AIA Standard Form of Agreement where the basis of payment is a Stipulated Sum' },
  {
    id: '4',
    name: 'AIA Standard Form of Agreement where the basis of payment is the Cost of Work Plus a Fee with a Guaranteed Maximum Price',
  },
  {
    id: '5',
    name: 'AIA Standard Form of Agreement where the basis of payment is the Cost of Work Plus a Fee without a Guaranteed Maximum Price',
  },
  { id: '6', name: 'AIA Standard Short Form of Agreement' },
  { id: '7', name: 'AIA Master Agreement where work is provided under multiple Work Orders' },
  { id: '8', name: 'AIA Standard Form of Agreement between Owner and Construction Manager' },
  { id: '9', name: 'ConsensusDocs' },
  { id: '10', name: 'Proposal' },
  { id: '11', name: 'Non-standard agreement' },
  { id: '12', name: 'Other' },
];

//construction schedule
export const constructionScheduleScopeOfWorkOptions = [
  { id: '1', name: 'Site Work' },
  { id: '2', name: 'Building Construction' },
  { id: '3', name: 'Site Work and Building Construction' },
  { id: '4', name: 'Other' },
  { id: '5', name: 'Tenant Improvement' },
  { id: '6', name: 'Building Shell' },
];

//design agreements
export const designAgreementsFormOptions = [
  { id: '1', name: 'AIA Standard Form of Agreement' },
  { id: '2', name: 'Non-standard agreement' },
  { id: '3', name: 'Proposal' },
  { id: '4', name: 'ConsensusDocs' },
  { id: '5', name: 'Other' },
];

export const designAgreementsExecutedOptions = [
  ...YesOrNoOptions,
  { id: '3', name: 'By Architect but not Owner' },
  { id: '4', name: 'By Owner but not Architect' },
];

//permits
export const permitsTypeOptions = [
  { id: '1', name: 'Land Disturbance Permit' },
  { id: '2', name: 'Building Permit' },
  { id: '3', name: 'Demolition Permit' },
  { id: '4', name: 'Plumbing' },
  { id: '5', name: 'Electrical' },
  { id: '6', name: 'Mechanical' },
  { id: '7', name: 'Stormwater' },
  { id: '8', name: 'DOT' },
  { id: '9', name: 'Utility' },
  { id: '10', name: 'Off-site utility' },
  { id: '11', name: 'Foundation' },
  { id: '12', name: 'Renovation' },
  { id: '13', name: 'Commercial' },
  { id: '14', name: 'Residential' },
  { id: '15', name: 'New addition' },
  { id: '16', name: 'Other' },
];

export const textFields = [
  'additionalInformation',
  'architect',
  'engineeringFirm',
  //survey
  'surveyorFirm',
  'surveyPrepared',
  //agreement
  'projectName',
  'contractSum',
  'excludedFromRetain',
  'allowancesAgreement',
  'liquidatedDamages',
  'ownerAgreement',
  'contractorAgreement',

  //hard cost
  'contractor',
  'constructionCost',

  //schedule
  'constructionPrepared',
  'scheduleDuration',
  //total
  'hardCosts',
  'softCosts',
  'totalBudget',
  'totalContingency',

  //geo
  'geotechnicalConsultant',
  'siteSoil',

  //EnvronmentalReporting
  'environmentalConsultant',

  //permits
  'permitNumber',

  //utility
  'utilityAvailability',
  'sanitaryService',
  'waterService',
  'power',
  'dataTelephoneCable',
  'gas',
  'other',

  //design
  'architectFirm',
  'scopeOfWork',

  //loan
  'borrower',
  'lender',
  'loanTotal',
  'projectBudget',
];

export const dataFields = [
  'dateLastRevision',

  //survey
  'date',

  //agreement
  'dateOfAgreement',
  'dateOfCommencement',
  'dateOfSubstantialCompletion',

  //schedule
  'anticipatedCompletionDate',
  'dateOfCommencement',
  'dateScheduledPrepared',

  //permits
  'expirationDate',
  'issueDate',
];
