import { useMemo } from 'react';
import transformObjectToArray from 'utils/productivity-metrics/transformObjectToArray';
import { perPage_100 } from 'consts';
import { usePMAvgServicesPricesTableColumns } from './useTableColumns';
import { useHistory } from 'react-router';
import { useGetPMAvgServicesPrices } from 'pages/ProductivityMetrics/queries';
import {
  productivityMetricsTableEmptyModel,
  productivityMetricsTableEmptyTitle,
} from 'components/common/Table/parts/EmptyState/const';

import { PMAvgServicesPricesProps } from '../../types';

export const usePMAvgServicesPricesTable = ({
  match: {
    query: {
      page = 0,
      bank,
      bankContact,
      services,
      assignee,
      project,
      projectStatus,
      projectType,
      state,
      owner,
      status,
      estimatedInspectionDateStartFrom,
      estimatedInspectionDateStartTo,
      estimatedInspectionDateCompletionFrom,
      estimatedInspectionDateCompletionTo,
      proposalSentDateFrom,
      proposalSentDateTo,
    },
    query,
  },
  link,
}: PMAvgServicesPricesProps) => {
  const { push } = useHistory();
  const { columns } = usePMAvgServicesPricesTableColumns();

  const { data, isFetching } = useGetPMAvgServicesPrices({
    params: {
      perPage: perPage_100,
      page: page + 1,
      'filter[_service]': services,
      'filter[bank]': bank,
      'filter[assignee]': assignee,
      'filter[project]': project,
      'filter[_projectStatus]': projectStatus,
      'filter[_projectType]': projectType,
      'filter[state]': state,
      'filter[_bankContact]': bankContact,
      'filter[_projectOwner]': owner,
      'filter[status]': status,
      ...(estimatedInspectionDateStartTo
        ? {
            'filter[expectedDateOfFirstInspection:from]': estimatedInspectionDateStartFrom,
            'filter[expectedDateOfFirstInspection:to]': estimatedInspectionDateStartTo,
          }
        : { 'filter[expectedDateOfFirstInspection]': estimatedInspectionDateStartFrom }),
      ...(estimatedInspectionDateCompletionTo
        ? {
            'filter[expectedDateOfLastInspection:from]': estimatedInspectionDateCompletionFrom,
            'filter[expectedDateOfLastInspection:to]': estimatedInspectionDateCompletionTo,
          }
        : { 'filter[expectedDateOfLastInspection]': estimatedInspectionDateCompletionFrom }),
      ...(proposalSentDateTo
        ? {
            'filter[sentDate:from]': proposalSentDateFrom,
            'filter[sentDate:to]': proposalSentDateTo,
          }
        : { 'filter[sentDate]': proposalSentDateFrom }),
    },
    queryKey: [query],
  });

  const filteredData = useMemo(() => (data ? transformObjectToArray(data, 'serviceType') : []), [data]);

  const handleClear = () => {
    push(link());
  };

  const tableEmptyState = {
    model: productivityMetricsTableEmptyModel,
    emptyStateTitle: productivityMetricsTableEmptyTitle,
    handleClearFilters: handleClear,
  };

  return { filteredData, columns, isFetching, tableEmptyState, handleClear };
};
