import { FC } from 'react';
import Button from '@material-ui/core/Button';

import { LoadingButton } from 'components';

import styles from './ConfirmationModal.module.scss';

type ConfirmationModalProps = {
  text?: string;
  onClose: () => void;
  onSubmit: () => void;
  isLoading?: boolean;
  submitText?: string;
  cancelText?: string;
};

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  text,
  isLoading,
  onClose,
  onSubmit,
  submitText,
  cancelText,
}) => (
  <div className={styles.modalWrapper}>
    {text && (
      <div className="mb-24 mb-md-36 col-12">
        <p className={styles.text}>{text}</p>
      </div>
    )}

    <div className="flex pt-24 justify-content-end col-12">
      <Button className="mr-12" variant="contained" onClick={onClose} disabled={isLoading}>
        {cancelText ?? 'Cancel'}
      </Button>
      <LoadingButton variant="text" onClick={onSubmit} loading={isLoading}>
        {submitText ?? 'Yes, delete'}
      </LoadingButton>
    </div>
  </div>
);
