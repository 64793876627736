import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const ProjectFilesRoute = Route(
  {
    id: Route.params.number.optional,
    status: Route.query.string,
    search: Route.query.string,
    page: Route.query.number,
    type: Route.query.string,
    isFavorites: Route.query.boolean,
    isTableView: Route.query.boolean,
  },
  ({ id }) => `/project/${id}/files`,
);

export const ProjectFiles = ProjectFilesRoute(lazy(() => import('./Files')));
