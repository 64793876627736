import { createContext, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useFormModal } from 'hooks/useFormModal';
import { RolesEnum } from 'types';
import { useHistory } from 'react-router';
import { PROPOSAL_ACCESS_ROLES } from 'consts';
import { useDeleteProposalRequestMutation } from 'lib/react-query/mutations';
import { useQueryClient } from 'react-query';
import { queriesKeys } from 'lib/react-query/const';
import { ProposalStatusEnum } from 'types/proposal';
import { useAuth } from 'contexts';

import { GeneralPaginationType, ProposalRequestsContextProps, SelectedProposalRequestsDialogEnum } from './types';

const ProposalRequestsContext = createContext<ProposalRequestsContextProps>({
  generalPagination: {},
  setSelectedProposalId: () => {},
  setGeneralPagination: () => {},
  handleResetPagination: () => {},
  handleOpenProposalEdit: () => {},
  handleOpenProposalCreate: () => {},
  handleOpenProposalDelete: () => {},
  handleCloseSelectedDialog: () => {},
  handleCloseAllModals: () => {},
  handleCloseUnsavedChangesModal: () => {},
  handleCloseFormModal: () => {},
  handleDeleteProposal: () => {},
});

export const useProposalRequests = () => useContext<ProposalRequestsContextProps>(ProposalRequestsContext);

export const ProposalRequestsContextProvider = ({ children }: PropsWithChildren) => {
  const { userData } = useAuth();
  const history = useHistory();
  const queryClient = useQueryClient();
  const {
    isFormModalOpen,
    isUnsavedChangesModalOpen,
    handleCloseAllModals,
    handleCloseUnsavedChangesModal,
    handleOpenFormModal,
    handleCloseFormModal,
  } = useFormModal();

  const initialPagination = Object.values(ProposalStatusEnum).reduce((acc, key) => {
    acc[key] = 0;
    return acc;
  }, {} as GeneralPaginationType);

  const [selectedOpenDialog, setSelectedOpenDialog] = useState<SelectedProposalRequestsDialogEnum | null>(null);
  const [generalPagination, setGeneralPagination] = useState<GeneralPaginationType>(initialPagination);
  const selectedProposalId = useRef<number>();

  useEffect(() => {
    if (!PROPOSAL_ACCESS_ROLES.includes(userData?.activeRole?.name as RolesEnum)) {
      history.replace('/work-orders');
    }
  }, [userData]);

  const { mutate: deleteProposalRequestMutate, isLoading: isDeleteProposalRequestLoading } =
    useDeleteProposalRequestMutation(selectedProposalId.current as number, () => {
      toast.success('Proposal request has been successfully deleted!');
      handleCloseSelectedDialog();
      queryClient.invalidateQueries(queriesKeys.proposalRequests);
    });

  const handleOpenProposalEdit = () => handleOpenFormModal(SelectedProposalRequestsDialogEnum.ProjectRequestEdit);
  const handleOpenProposalCreate = () => handleOpenFormModal(SelectedProposalRequestsDialogEnum.ProjectRequestCreate);
  const handleOpenProposalDelete = () => setSelectedOpenDialog(SelectedProposalRequestsDialogEnum.ProjectRequestDelete);
  const handleCloseSelectedDialog = () => setSelectedOpenDialog(null);

  const setSelectedProposalId = (id: number) => {
    selectedProposalId.current = id;
  };

  const handleDeleteProposal = () => {
    deleteProposalRequestMutate();
  };

  const handleResetPagination = () => {
    setGeneralPagination(initialPagination);
  };

  return (
    <ProposalRequestsContext.Provider
      value={{
        selectedProposalId,
        setSelectedProposalId,
        isFormModalOpen,
        isUnsavedChangesModalOpen,
        selectedOpenDialog,

        generalPagination,
        setGeneralPagination,
        handleResetPagination,

        handleOpenProposalEdit,
        handleOpenProposalCreate,
        handleOpenProposalDelete,
        handleCloseSelectedDialog,

        handleCloseAllModals,
        handleCloseUnsavedChangesModal,
        handleCloseFormModal,

        handleDeleteProposal,
        isDeleteProposalRequestLoading,
      }}
    >
      {children}
    </ProposalRequestsContext.Provider>
  );
};
