import { IconProps } from 'types';

export const DocumentTrackingIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.02883 12.6174C9.40729 12.6174 9.73844 12.9486 9.73844 13.327V16.1655C9.73844 16.544 9.40729 16.8751 9.02883 16.8751C8.65037 16.8751 8.31921 16.544 8.31921 16.1655V13.327C8.31921 12.9486 8.65037 12.6174 9.02883 12.6174Z"
        fill="#212B83"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.939231 12.8067C1.08115 12.6647 1.27038 12.6174 1.45962 12.6174H16.5981C16.9765 12.6174 17.3077 12.9486 17.3077 13.327V16.1655C17.3077 16.544 16.9765 16.8751 16.5981 16.8751C16.2196 16.8751 15.8885 16.544 15.8885 16.1655V14.0367H2.16923V16.1655C2.16923 16.544 1.83808 16.8751 1.45962 16.8751C1.08115 16.8751 0.75 16.544 0.75 16.1655V13.327C0.75 13.1378 0.844615 12.9486 0.939231 12.8067Z"
        fill="#212B83"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.87878 11.4346V5.23731C2.87878 5.09538 2.92609 4.90615 3.06801 4.81154L6.47417 1.68923C6.61609 1.54731 6.75801 1.5 6.94725 1.5H13.7596C14.138 1.5 14.4692 1.64192 14.7057 1.87846C14.9896 2.115 15.1315 2.39885 15.1315 2.73V11.4346H13.7596V2.73H7.23109L4.2034 5.47385V11.4346H2.87878ZM6.28494 5.56846C5.90648 5.56846 5.62263 5.85231 5.62263 6.18346C5.62263 6.51462 5.90648 6.79846 6.28494 6.79846H11.7726C12.1511 6.79846 12.4349 6.51462 12.4349 6.18346C12.4349 5.85231 12.1511 5.56846 11.7726 5.56846H6.28494ZM6.28494 8.07577C5.90648 8.07577 5.62263 8.35962 5.62263 8.69077C5.62263 9.02192 5.90648 9.30577 6.28494 9.30577H11.7726C12.1511 9.30577 12.4349 9.02192 12.4349 8.69077C12.4349 8.35962 12.1511 8.07577 11.7726 8.07577H6.28494Z"
        fill="#212B83"
      />
    </svg>
  );
};
