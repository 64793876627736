import { TextField } from 'formik-material-ui';
import { Field } from 'formik';
import { Autocomplete } from 'components/common';

import { defaultFieldProps, geotechMatch, YesNoNaOptions } from '../const';
import { CommonPlansFields } from './CommonPlansFields';

export const StructuralPlansFields = () => (
  <>
    <Field component={TextField} name="engineeringFirm" label="Engineering Firm" className="mb-24" />
    <CommonPlansFields />
    <Field
      component={Autocomplete}
      name="geotechnicalEngineeringReport"
      placeholder="Do the plans reference the Geotechnical Engineering Report?"
      {...defaultFieldProps}
      options={YesNoNaOptions}
    />
    <Field
      component={Autocomplete}
      placeholder="Does the referenced report match what we have been given for review?"
      name="referencedReportMatch"
      {...defaultFieldProps}
      options={geotechMatch}
    />
    <Field
      component={Autocomplete}
      placeholder="Is a design allowable soil bearing pressure stated in the report?"
      name="soilBearingPressure"
      {...defaultFieldProps}
      options={geotechMatch}
    />
  </>
);
