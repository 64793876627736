import { useEffect, useState } from 'react';
import { ProposalRequestsRouteProps } from 'pages/ProposalRequests/types';
import { useGetProposalRequests } from 'lib/react-query/queries';
import { useProposalRequests } from 'pages/ProposalRequests/context';
import { getUpdatedSortingOptions } from 'utils/common';
import { emptyTableData } from 'consts';
import { ProposalStatusEnum } from 'types/proposal';

import { SortingOptions } from './types';
import { useProposalRequestsTableColumns } from './useTableColumns';
import { useScroll } from './useScroll';
import { PROPOSAL_STATE_LS_KEY, useProposalLocalStorage } from './useLocalStorage';

type PaginationState = {
  [key in ProposalStatusEnum]: number;
};

export const useProposalRequestsTable = ({
  match: {
    query: {
      search,
      itemsPerGroup = 30,
      services,
      bank,
      bankContact,
      project,
      dateReceivedRfpStart,
      dateReceivedRfpEnd,

      dateProposalSentStart,
      dateProposalSentEnd,

      dateAcceptedRpfStart,
      dateAcceptedRpfEnd,

      estimatedInspectionDateStartFrom,
      estimatedInspectionDateStartTo,
    },
  },
}: ProposalRequestsRouteProps) => {
  const { handleOpenProposalEdit, handleOpenProposalDelete, setSelectedProposalId } = useProposalRequests();
  const { scrollTopPosition, handleScrollTo } = useScroll();
  const { isProposalOverviewPrevPage, getExpandedSectionsFromLS } = useProposalLocalStorage();

  const [expandedSections, setExpandedSections] = useState<string[]>(getExpandedSectionsFromLS());

  const statuses = Object.values(ProposalStatusEnum);
  const isAllSectionsExpanded = expandedSections.length === statuses.length;
  const isAllSectionsCollapsed = expandedSections.length === 0;
  const getInitialSortOptions = (): { [key in ProposalStatusEnum]: { [key: string]: string | null } } => {
    const initialSort: { [key: string]: string | null } = {
      [SortingOptions.Name]: null,
      [SortingOptions.PrePrice]: null,
      [SortingOptions.Bank]: null,
      [SortingOptions.BankContacts]: null,
      [SortingOptions.InspectionPrice]: null,
      [SortingOptions.Ltv]: null,
      [SortingOptions.RfpRecieved]: null,
      [SortingOptions.ExpectedDateOfFirstInspection]: null,
      [SortingOptions.CreatedAt]: 'DESC',
    };

    return Object.fromEntries(Object.values(ProposalStatusEnum).map((status) => [status, { ...initialSort }])) as {
      [key in ProposalStatusEnum]: { [key: string]: string | null };
    };
  };

  const initialSortOptions = getInitialSortOptions();

  const initialPagination: PaginationState = {
    [ProposalStatusEnum.ToDo]: 0,
    [ProposalStatusEnum.InProgress]: 0,
    [ProposalStatusEnum.InReview]: 0,
    [ProposalStatusEnum.Pending]: 0,
    [ProposalStatusEnum.InRework]: 0,
    [ProposalStatusEnum.NoResponse]: 0,
    [ProposalStatusEnum.Awarded]: 0,
    [ProposalStatusEnum.Rejected]: 0,
    [ProposalStatusEnum.InWork]: 0,
    [ProposalStatusEnum.Completed]: 0,
  };

  const [sortOptions, setSortOptions] =
    useState<{ [key in ProposalStatusEnum]: { [key: string]: string | null } }>(initialSortOptions);

  const [currentTable, setCurrentTable] = useState<string | null>(null);
  const [generalPagination, setGeneralPagination] = useState(initialPagination);

  const getParamsForTable = (status: ProposalStatusEnum) => {
    const page = generalPagination[status] ?? 0;

    return {
      ...(search && { search: search }),
      perPage: itemsPerGroup,
      page: page + 1,
      'filter[_service]': services,
      'filter[bank]': bank,
      'filter[_bankContact]': bankContact,
      'filter[project]': project,
      ...(estimatedInspectionDateStartTo
        ? {
            'filter[expectedDateOfFirstInspection:from]': estimatedInspectionDateStartFrom,
            'filter[expectedDateOfFirstInspection:to]': estimatedInspectionDateStartTo,
          }
        : { 'filter[expectedDateOfFirstInspection]': estimatedInspectionDateStartFrom }),
      ...(dateReceivedRfpEnd
        ? { 'filter[receivedDate:from]': dateReceivedRfpStart, 'filter[receivedDate:to]': dateReceivedRfpEnd }
        : { 'filter[receivedDate]': dateReceivedRfpStart }),
      ...(dateProposalSentEnd
        ? { 'filter[sentDate:from]': dateProposalSentStart, 'filter[sentDate:to]': dateProposalSentEnd }
        : { 'filter[sentDate]': dateProposalSentStart }),
      ...(dateAcceptedRpfEnd
        ? { 'filter[awardDate:from]': dateAcceptedRpfStart, 'filter[awardDate:to]': dateAcceptedRpfEnd }
        : { 'filter[awardDate]': dateAcceptedRpfStart }),
      ...sortOptions[status],
      'filter[status]': status,
    };
  };

  const getProposalRequestData = (status: ProposalStatusEnum) => {
    return useGetProposalRequests({
      params: getParamsForTable(status),
      queryKey: [status, getParamsForTable(status), generalPagination[status], sortOptions[status]],
    });
  };

  const {
    data: toDoData,
    isFetching: toDoFetching,
    refetch: refetchToDo,
  } = getProposalRequestData(ProposalStatusEnum.ToDo);
  const {
    data: inProgressData,
    isFetching: inProgressFetching,
    refetch: refetchInProgress,
  } = getProposalRequestData(ProposalStatusEnum.InProgress);
  const {
    data: inReviewData,
    isFetching: inReviewFetching,
    refetch: refetchInReview,
  } = getProposalRequestData(ProposalStatusEnum.InReview);
  const {
    data: pendingData,
    isFetching: pendingFetching,
    refetch: refetchPending,
  } = getProposalRequestData(ProposalStatusEnum.Pending);
  const {
    data: inReworkData,
    isFetching: inReworkFetching,
    refetch: refetchInRework,
  } = getProposalRequestData(ProposalStatusEnum.InRework);
  const {
    data: noResponseData,
    isFetching: noResponseFetching,
    refetch: refetchNoResponse,
  } = getProposalRequestData(ProposalStatusEnum.NoResponse);
  const {
    data: awardedData,
    isFetching: awardedFetching,
    refetch: refetchAwarded,
  } = getProposalRequestData(ProposalStatusEnum.Awarded);
  const {
    data: rejectedData,
    isFetching: rejectedFetching,
    refetch: refetchRejected,
  } = getProposalRequestData(ProposalStatusEnum.Rejected);
  const {
    data: inWorkData,
    isFetching: inWorkFetching,
    refetch: refetchInWork,
  } = getProposalRequestData(ProposalStatusEnum.InWork);
  const {
    data: completedData,
    isFetching: completedFetching,
    refetch: refetchCompleted,
  } = getProposalRequestData(ProposalStatusEnum.Completed);

  useEffect(() => {
    if (isProposalOverviewPrevPage) handleScrollTo();
  }, []);

  useEffect(() => {
    return () => {
      localStorage.setItem(PROPOSAL_STATE_LS_KEY, JSON.stringify({ expandedSections, scrollTopPosition }));
    };
  }, [expandedSections, scrollTopPosition]);

  useEffect(() => {
    if (!currentTable) return;

    switch (currentTable) {
      case ProposalStatusEnum.ToDo:
        refetchToDo();
        break;
      case ProposalStatusEnum.InProgress:
        refetchInProgress();
        break;
      case ProposalStatusEnum.InReview:
        refetchInReview();
        break;
      case ProposalStatusEnum.Pending:
        refetchPending();
        break;
      case ProposalStatusEnum.InRework:
        refetchInRework();
        break;
      case ProposalStatusEnum.NoResponse:
        refetchNoResponse();
        break;
      case ProposalStatusEnum.Awarded:
        refetchAwarded();
        break;
      case ProposalStatusEnum.Rejected:
        refetchRejected();
        break;
      case ProposalStatusEnum.InWork:
        refetchInWork();
        break;
      case ProposalStatusEnum.Completed:
        refetchCompleted();
        break;
      default:
        break;
    }
  }, [sortOptions, currentTable]);

  const getDataByProposalStatus = (status: ProposalStatusEnum) => {
    switch (status) {
      case ProposalStatusEnum.ToDo:
        return toDoData;
      case ProposalStatusEnum.InProgress:
        return inProgressData;
      case ProposalStatusEnum.InReview:
        return inReviewData;
      case ProposalStatusEnum.Pending:
        return pendingData;
      case ProposalStatusEnum.InRework:
        return inReworkData;
      case ProposalStatusEnum.NoResponse:
        return noResponseData;
      case ProposalStatusEnum.Awarded:
        return awardedData;
      case ProposalStatusEnum.Rejected:
        return rejectedData;
      case ProposalStatusEnum.InWork:
        return inWorkData;
      case ProposalStatusEnum.Completed:
        return completedData;
      default:
        return { ...emptyTableData, totalLtv: 0 };
    }
  };

  const setSortingOptions = (option: SortingOptions, table: ProposalStatusEnum) => {
    const updatedSortingOptions = getUpdatedSortingOptions(option, sortOptions[table], SortingOptions.CreatedAt);
    setSortOptions((prevState) => ({
      ...prevState,
      [table]: updatedSortingOptions,
    }));
    setGeneralPagination((prevState) => ({
      ...prevState,
      [table]: 0,
    }));
    setCurrentTable(table);
  };

  const handleExpandeAllSections = () => {
    setExpandedSections(statuses);
  };

  const handleCollapseAllSections = () => {
    setExpandedSections([]);
  };

  const handleSection = (section: ProposalStatusEnum) => {
    expandedSections.includes(section)
      ? setExpandedSections((prev) => prev.filter((el) => el !== section)) // collapse
      : setExpandedSections((prev) => [...prev, section]); // expand
  };

  const handlePageChange = (direction: 'next' | 'prev', tableName?: string) => {
    if (!tableName) return;
    const currentPage = generalPagination[tableName as ProposalStatusEnum];
    const page = direction === 'prev' ? (currentPage > 0 ? currentPage - 1 : 0) : currentPage + 1;
    setGeneralPagination((prevState) => ({
      ...prevState,
      [tableName as ProposalStatusEnum]: page,
    }));
  };

  const handleEditProposalRequest = (id: number) => {
    setSelectedProposalId(id);
    handleOpenProposalEdit();
  };
  const handleDeleteProposalRequest = (id: number) => {
    setSelectedProposalId(id);
    handleOpenProposalDelete();
  };

  const generateColumns = (status: ProposalStatusEnum) => {
    return useProposalRequestsTableColumns(
      handleDeleteProposalRequest,
      handleEditProposalRequest,
      sortOptions[status],
      (option) => setSortingOptions(option, status),
      status,
    ).columns;
  };

  const columns = Object.fromEntries(
    Object.values(ProposalStatusEnum).map((status) => [status, generateColumns(status)]),
  );

  const isSectionLoading = (status: ProposalStatusEnum) => {
    switch (status) {
      case ProposalStatusEnum.ToDo:
        return toDoFetching;
      case ProposalStatusEnum.InProgress:
        return inProgressFetching;
      case ProposalStatusEnum.InReview:
        return inReviewFetching;
      case ProposalStatusEnum.Pending:
        return pendingFetching;
      case ProposalStatusEnum.InRework:
        return inReworkFetching;
      case ProposalStatusEnum.NoResponse:
        return noResponseFetching;
      case ProposalStatusEnum.Awarded:
        return awardedFetching;
      case ProposalStatusEnum.Rejected:
        return rejectedFetching;
      case ProposalStatusEnum.InWork:
        return inWorkFetching;
      case ProposalStatusEnum.Completed:
        return completedFetching;
      default:
        return false;
    }
  };

  return {
    statuses,
    columns,
    isSectionLoading,
    generalPagination,

    expandedSections,
    isAllSectionsCollapsed,
    isAllSectionsExpanded,

    handleCollapseAllSections,
    handleExpandeAllSections,
    handleSection,

    handleDeleteProposalRequest,
    handleEditProposalRequest,
    handlePageChange,

    getDataByProposalStatus,
  };
};
