import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const InspectionsRoute = Route(
  {
    status: Route.query.string,
    search: Route.query.string,
    page: Route.query.number,
    dateRequestedStart: Route.query.string,
    dateRequestedEnd: Route.query.string,
    dateInspectedStart: Route.query.string,
    dateInspectedEnd: Route.query.string,
    project: Route.query.string,
    inspector: Route.query.string,
    stateName: Route.query.string,
  },
  '/inspections',
);

export const Inspections = InspectionsRoute(lazy(() => import('./Inspections')));
