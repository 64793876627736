import { FC, PropsWithChildren } from 'react';
import { baseLayoutId } from 'consts';

import { PageLayoutProps } from './types';
import styles from './PageLayout.module.scss';

const PageLayout: FC<PropsWithChildren<PageLayoutProps>> = ({ heading, children, divId }) => {
  return (
    <div className="flex flex-column h-100 visibility-auto">
      {heading}
      <div className={styles.pageLayout} id={divId ?? baseLayoutId}>
        {children}
      </div>
    </div>
  );
};

export default PageLayout;
