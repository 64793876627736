import { IconProps } from 'types';

export const LocationIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33366 15.3333H3.66699C3.30033 15.3333 3.00033 15.0333 3.00033 14.6666L3.00033 4.66665C3.00033 4.46665 3.10033 4.23332 3.26699 4.13332L7.93366 0.799982C8.13366 0.666649 8.40032 0.633315 8.63366 0.733315C8.86699 0.866649 9.00033 1.09998 9.00033 1.33332V14.6666C9.00033 15.0333 8.70033 15.3333 8.33366 15.3333ZM4.33366 14H7.66699L7.66699 2.63332L4.33366 4.99998V14Z"
        fill="#4D4D4D"
      />
      <path
        d="M13.0003 15.3333C12.6337 15.3333 12.3337 15.0333 12.3337 14.6666V7.99998L7.93366 4.86665C7.63366 4.66665 7.56699 4.23332 7.76699 3.93332C7.96699 3.63332 8.40033 3.56665 8.70033 3.76665L13.367 7.09998C13.5337 7.23332 13.6337 7.43332 13.6337 7.63332V14.6333C13.667 15.0333 13.367 15.3333 13.0003 15.3333Z"
        fill="#4D4D4D"
      />
      <path
        d="M14.667 15.3333H1.33366C0.966992 15.3333 0.666992 15.0333 0.666992 14.6666C0.666992 14.3 0.966992 14 1.33366 14H14.667C15.0337 14 15.3337 14.3 15.3337 14.6666C15.3337 15.0333 15.0337 15.3333 14.667 15.3333Z"
        fill="#4D4D4D"
      />
    </svg>
  );
};
