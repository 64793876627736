import { Route } from 'react-router-hoc';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { ResetPassword as ResetPasswordForm } from 'components';
import { ResetFormValues } from 'types';
import api from 'api/api';
import { apiRoutes } from 'api/routes';
import { links } from 'App';

const ResetPasswordRoute = Route({ auth: Route.query.string }, '/reset-password');

const ResetPassword = ResetPasswordRoute(
  ({
    match: {
      query: { auth },
    },
  }) => {
    const history = useHistory();
    const resetPassword = useMutation('resetPassword', (values: ResetFormValues) => sendData(values), {
      onSuccess: () => {
        toast.success('Request for change password was sent');
        history.push(links.Login());
      },
    });

    const sendData = (values: ResetFormValues) => api.post(apiRoutes.changePassword, values).then((res) => res);

    const handleSubmit = async (values: ResetFormValues) => {
      await resetPassword.mutate({ ...values, auth });
    };

    return <ResetPasswordForm onSubmit={handleSubmit} />;
  },
);

export default ResetPassword;
