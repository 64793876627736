import { useHistory } from 'react-router';
import { useMemo } from 'react';
import { useGetPMInspections } from 'pages/ProductivityMetrics/queries';
import {
  productivityMetricsTableEmptyModel,
  productivityMetricsTableEmptyTitle,
} from 'components/common/Table/parts/EmptyState/const';

import { PMInspectionsProps } from '../../types';
import { usePMInspectionsTableColumns } from './useTableColumns';

export const usePMProjectPortfolioTable = ({
  match: {
    query: {
      bank,
      bankContact,
      services,
      assignee,
      project,
      projectStatus,
      projectType,
      state,
      status,
      owner,
      estimatedInspectionDateStartFrom,
      estimatedInspectionDateStartTo,
      estimatedInspectionDateCompletionFrom,
      estimatedInspectionDateCompletionTo,
      dateRangeFrom,
      dateRangeTo,
    },
    query,
  },
  link,
}: PMInspectionsProps) => {
  const { push } = useHistory();
  const { data, isFetching } = useGetPMInspections({
    params: {
      'filter[_service]': services,
      'filter[bank]': bank,
      'filter[assignee]': assignee,
      'filter[project]': project,
      'filter[_projectStatus]': projectStatus,
      'filter[_projectType]': projectType,
      'filter[state]': state,
      'filter[_bankContact]': bankContact,
      'filter[_projectOwner]': owner,
      'filter[status]': status,
      'filter[_dateRange][from]': dateRangeFrom,
      'filter[_dateRange][to]': dateRangeTo,
      ...(estimatedInspectionDateStartTo
        ? {
            'filter[expectedDateOfFirstInspection:from]': estimatedInspectionDateStartFrom,
            'filter[expectedDateOfFirstInspection:to]': estimatedInspectionDateStartTo,
          }
        : { 'filter[expectedDateOfFirstInspection]': estimatedInspectionDateStartFrom }),
      ...(estimatedInspectionDateCompletionTo
        ? {
            'filter[expectedDateOfLastInspection:from]': estimatedInspectionDateCompletionFrom,
            'filter[expectedDateOfLastInspection:to]': estimatedInspectionDateCompletionTo,
          }
        : { 'filter[expectedDateOfLastInspection]': estimatedInspectionDateCompletionFrom }),
    },
    queryKey: [query],
  });

  const { amount, count } = data ?? {};

  const getInfoFromKey = (keyName: string) => {
    switch (keyName) {
      case 'totalPlanned':
        return { label: 'Planned inspections', key: 'planned' };
      case 'totalAnticipated':
        return { label: 'Anticipated inspections', key: 'anticipated' };
      case 'totalCompleted':
        return { label: 'Completed inspections', key: 'completed' };
      case 'totalAtRisk':
        return { label: 'Inspections at risk', key: 'risk' };
      case 'totalOverestimated':
        return { label: 'Overestimated' };
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const transformObjectToArray = (obj: any) => {
    const keysArray = Object.keys(obj);
    const valuesArray = Object.values(obj);
    return valuesArray.map((el, index) => ({ name: keysArray[index], total: el }));
  };

  const transformedAmountData = useMemo(() => {
    if (!amount) return [];
    const totalArray = transformObjectToArray(amount.total);
    const monthlyArray = Object.values(amount.monthly);
    const datesArray = Object.keys(count.monthly);

    const data = totalArray.map((el) => {
      const key = getInfoFromKey(el.name)?.key;
      if (key) {
        return {
          ...el,
          name: getInfoFromKey(el.name)?.label,
          monthsDate: datesArray,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...(monthlyArray.length ? { monthsValues: monthlyArray.map((el: any) => el[key]) } : {}),
        };
      }
      return { ...el, name: getInfoFromKey(el.name)?.label };
    });

    const totalData = {
      name: 'Total per month',
      total: null,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(monthlyArray.length ? { monthsValues: monthlyArray.map((el: any) => el['total']) } : {}),
    };
    data.push(totalData);

    return data;
  }, [amount]);

  const transformedCountData = useMemo(() => {
    if (!count) return [];
    const totalArray = transformObjectToArray(count.total);
    const monthlyArray = Object.values(count.monthly);
    const datesArray = Object.keys(count.monthly);

    return totalArray.map((el) => {
      const key = getInfoFromKey(el.name)?.key;
      if (key) {
        const isPlanned = key === 'planned';
        const plannedButNotCompletedKey = 'plannedButNotCompleted';
        const { customStyle } = data;

        return {
          ...el,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          monthsValues: monthlyArray.map((el: any) => el[key]),
          name: getInfoFromKey(el.name)?.label,
          monthsDate: datesArray,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          alternativeMonthsValues: isPlanned ? monthlyArray.map((el: any) => el[plannedButNotCompletedKey]) : [],
          customStyle,
        };
      }
      return { ...el, name: getInfoFromKey(el.name)?.label };
    });
  }, [count]);

  const monthsDate = useMemo(() => {
    if (!count) return [];
    return Object.keys(count.monthly);
  }, [count]);

  const { estimatedInspectionsColumns, remainingInspectionsColumns } = usePMInspectionsTableColumns(monthsDate);

  const handleClear = () => {
    push(link());
  };

  const emptyTableState = {
    model: productivityMetricsTableEmptyModel,
    emptyStateTitle: productivityMetricsTableEmptyTitle,
    handleClearFilters: handleClear,
  };

  return {
    transformedAmountData,
    transformedCountData,
    estimatedInspectionsColumns,
    remainingInspectionsColumns,
    isFetching,
    emptyTableState,
    handleClear,
  };
};
