import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface FormChangesContext {
  checkFormChanges: (initialValues: unknown, values: unknown) => void;
  setIsFormChanged: React.Dispatch<React.SetStateAction<boolean>>;
  isFormChanged: boolean;
}

export const FormChangesContext = createContext<FormChangesContext | null>(null);

export const useFormChanges = () => useContext(FormChangesContext) as FormChangesContext;

export const FormChangesProvider = ({ children }: PropsWithChildren<unknown>) => {
  const location = useLocation();
  const [isFormChanged, setIsFormChanged] = useState(false);

  const checkFormChanges = (initialValues: unknown, values: unknown) => {
    setIsFormChanged(JSON.stringify(initialValues) !== JSON.stringify(values));
  };

  useEffect(() => {
    setIsFormChanged(false);
  }, [location]);

  return (
    <FormChangesContext.Provider
      value={{
        checkFormChanges,
        setIsFormChanged,
        isFormChanged,
      }}
    >
      {children}
    </FormChangesContext.Provider>
  );
};
