import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { ScrollTop } from '../ScrollTop';
import { mobileTableIdForTopScrolling } from 'consts';

import styles from './MobileTable.module.scss';

export const MobileTable = ({
  children,
  iconClassName,
  isPageWithMap,
  scrollTopValue,
}: PropsWithChildren<{
  scrollBlockClassName?: string;
  iconClassName?: string;
  isPageWithMap?: boolean;
  scrollTopValue?: number;
}>) => {
  return (
    <div className={clsx('d-block d-md-none px-16 relative', styles.wrap)} id={mobileTableIdForTopScrolling}>
      <ScrollTop
        className={clsx(styles.scrollTop, iconClassName)}
        scrollBlockClassName="base-layout-wrapper"
        isMobileTable
        {...{ scrollTopValue, isPageWithMap }}
      />
      {children}
    </div>
  );
};
