import { IconProps } from 'types';

export const PdfImg = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.75 25.6666C24.683 25.6666 26.25 24.0996 26.25 22.1666V5.83325C26.25 3.90026 24.683 2.33325 22.75 2.33325H5.25C3.317 2.33325 1.75 3.90025 1.75 5.83325V22.1666C1.75 24.0996 3.317 25.6666 5.25 25.6666H22.75ZM23.9167 16.8215V5.83325C23.9167 5.18892 23.3943 4.66659 22.75 4.66659H5.25C4.60567 4.66659 4.08333 5.18892 4.08333 5.83325V22.1666C4.08333 22.8109 4.60567 23.3333 5.25 23.3333H17.7996L23.9167 16.8215ZM21.001 23.3333H22.75C23.3943 23.3333 23.9167 22.8109 23.9167 22.1666V20.2294L21.001 23.3333Z"
        fill="#FF9900"
      />
      <path
        d="M5.83301 11.0833H8.86737C9.52824 11.0833 10.0225 11.2229 10.3503 11.5022C10.6807 11.7816 10.846 12.1791 10.846 12.6948C10.846 13.2248 10.666 13.639 10.306 13.9374C9.94867 14.2359 9.40198 14.3851 8.66588 14.3851H7.66652V16.3333H5.83301V11.0833ZM7.66652 13.3215H8.11382C8.46574 13.3215 8.7129 13.2678 8.85528 13.1603C8.99766 13.0505 9.06885 12.9108 9.06885 12.7413C9.06885 12.5766 9.00707 12.4369 8.88349 12.3223C8.75991 12.2077 8.52753 12.1504 8.18635 12.1504H7.66652V13.3215Z"
        fill="#FF9900"
      />
      <path
        d="M11.8292 11.0833H14.5412C15.0758 11.0833 15.507 11.1477 15.8347 11.2766C16.1652 11.4056 16.4378 11.5906 16.6527 11.8317C16.8677 12.0728 17.0235 12.3534 17.1202 12.6733C17.2169 12.9932 17.2653 13.3322 17.2653 13.6903C17.2653 14.2514 17.1927 14.6871 17.0477 14.9975C16.9053 15.3055 16.7065 15.5645 16.4513 15.7746C16.196 15.9823 15.922 16.1208 15.6292 16.19C15.2289 16.2855 14.8662 16.3333 14.5412 16.3333H11.8292V11.0833ZM13.6546 12.2722V15.1407H14.1019C14.4834 15.1407 14.7548 15.1037 14.9159 15.0297C15.0771 14.9533 15.2034 14.822 15.2947 14.6358C15.3861 14.4472 15.4317 14.1428 15.4317 13.7226C15.4317 13.1663 15.3297 12.7855 15.1255 12.5802C14.9213 12.3749 14.5828 12.2722 14.11 12.2722H13.6546Z"
        fill="#FF9900"
      />
      <path
        d="M18.2364 11.0833H22.7497V12.2113H20.0699V13.1281H22.3588V14.1881H20.0699V16.3333H18.2364V11.0833Z"
        fill="#FF9900"
      />
    </svg>
  );
};
