import { CloseIcon } from 'icons';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';
import useWindowSize from 'hooks/useWindowSize';

import styles from './FilterChip.module.scss';

type Props = {
  label?: string;
  onDelete: () => void;
  filter?: string;
  className?: string;
};

export const FilterChip = ({ label, onDelete, filter, className }: Props) => {
  if (!label || label.length === 1) return <></>;
  const { isMobile } = useWindowSize();
  const filterString = filter ? `${filter}:` : '';

  if (isMobile)
    return (
      <div className="flex text-primary align-items-center justify-content-between mb-12">
        {filterString} {label}
        <CloseIcon className={styles.removeFilterIcon} onClick={onDelete} />
      </div>
    );

  return (
    <Chip
      color="primary"
      deleteIcon={<CloseIcon className={styles.removeFilterIcon} />}
      className={clsx('mr-12 mb-10', className)}
      label={`${filterString} ${label}`}
      {...{ onDelete }}
    />
  );
};
