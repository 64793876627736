import { IconProps } from 'types';

export const UsersIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 9.75C4.62868 9.75 4.125 10.2537 4.125 10.875C4.125 11.4963 4.62868 12 5.25 12C5.87132 12 6.375 11.4963 6.375 10.875C6.375 10.2537 5.87132 9.75 5.25 9.75ZM2.625 10.875C2.625 9.42525 3.80025 8.25 5.25 8.25C6.69975 8.25 7.875 9.42525 7.875 10.875C7.875 12.3247 6.69975 13.5 5.25 13.5C3.80025 13.5 2.625 12.3247 2.625 10.875Z"
        fill="#212B83"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 9.75C12.1287 9.75 11.625 10.2537 11.625 10.875C11.625 11.4963 12.1287 12 12.75 12C13.3713 12 13.875 11.4963 13.875 10.875C13.875 10.2537 13.3713 9.75 12.75 9.75ZM10.125 10.875C10.125 9.42525 11.3003 8.25 12.75 8.25C14.1997 8.25 15.375 9.42525 15.375 10.875C15.375 12.3247 14.1997 13.5 12.75 13.5C11.3003 13.5 10.125 12.3247 10.125 10.875Z"
        fill="#212B83"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2.25C8.37868 2.25 7.875 2.75368 7.875 3.375C7.875 3.99632 8.37868 4.5 9 4.5C9.62132 4.5 10.125 3.99632 10.125 3.375C10.125 2.75368 9.62132 2.25 9 2.25ZM6.375 3.375C6.375 1.92525 7.55025 0.75 9 0.75C10.4497 0.75 11.625 1.92525 11.625 3.375C11.625 4.82475 10.4497 6 9 6C7.55025 6 6.375 4.82475 6.375 3.375Z"
        fill="#212B83"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 16.5C0.75 14.0147 2.76466 12 5.25 12C7.73534 12 9.75 14.0147 9.75 16.5C9.75 16.9142 9.41421 17.25 9 17.25C8.58579 17.25 8.25 16.9142 8.25 16.5C8.25 14.8431 6.90691 13.5 5.25 13.5C3.59309 13.5 2.25 14.8431 2.25 16.5C2.25 16.9142 1.91421 17.25 1.5 17.25C1.08579 17.25 0.75 16.9142 0.75 16.5Z"
        fill="#212B83"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 16.5C8.25 14.0147 10.2647 12 12.75 12C15.2353 12 17.25 14.0147 17.25 16.5C17.25 16.9142 16.9142 17.25 16.5 17.25C16.0858 17.25 15.75 16.9142 15.75 16.5C15.75 14.8431 14.4069 13.5 12.75 13.5C11.0931 13.5 9.75 14.8431 9.75 16.5C9.75 16.9142 9.41421 17.25 9 17.25C8.58579 17.25 8.25 16.9142 8.25 16.5Z"
        fill="#212B83"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 9C4.5 6.51466 6.51466 4.5 9 4.5C11.4853 4.5 13.5 6.51466 13.5 9C13.5 9.41421 13.1642 9.75 12.75 9.75C12.3358 9.75 12 9.41421 12 9C12 7.34309 10.6569 6 9 6C7.34309 6 6 7.34309 6 9C6 9.41421 5.66421 9.75 5.25 9.75C4.83579 9.75 4.5 9.41421 4.5 9Z"
        fill="#212B83"
      />
    </svg>
  );
};
