import { useState } from 'react';
import clsx from 'clsx';
import styles from './OverviewDescription.module.scss';
import { ArrowUpIcon, BuildingIcon, CoinsIcon, UserIcon } from 'icons';
import { getFullName } from 'utils';
import { OverviewDescriptionProps } from './types';

export const OverviewDescription = ({ name, project, role, owner, bank, bankContact }: OverviewDescriptionProps) => {
  const [descriptionOpen, setDescriptionOpen] = useState(true);
  const handleDescriptionToggle = () => setDescriptionOpen(!descriptionOpen);

  return (
    <div
      className={clsx(
        'flex flex-wrap justify-content-between py-8 px-16 px-md-32 py-sm-0',
        styles.descriptionContainer,
        !descriptionOpen && styles.descriptionContainerOpen,
      )}
    >
      <div className="p-relative w-100 d-sm-none">
        <div className={clsx(styles.descriptionToggler, 'd-sm-none c-pointer')} onClick={handleDescriptionToggle}>
          Show {descriptionOpen ? 'more' : 'less'}
          <ArrowUpIcon className={clsx('ml-4', styles.arrowUpIcon, descriptionOpen && styles.arrowDownIcon)} />
        </div>
      </div>

      <div className="flex align-items-center mb-sm-12 mr-12">
        <BuildingIcon className={styles.buildingIcon} />
        <p className="weight-600 text-14 pr-4">{name}</p>
        <p className="text-12 text-smallTextGrey">
          - {project?.name}{' '}
          {project?.projectAddress && `(${project?.projectAddress?.city}, ${project?.projectAddress?.state})`}
        </p>
      </div>

      <div className="flex flex-column flex-sm-row">
        <div className="flex align-items-center mr-12 my-8 mt-sm-0 mb-sm-12">
          <CoinsIcon className={styles.coinIcon} />
          <p className="text-14 text-smallTextGrey">
            Bank & Contact: {bank ?? project?.bank?.name}
            {(bankContact || project?.bankContact) && `, ${getFullName(bankContact ?? project?.bankContact)}`}
          </p>
        </div>
        <div className="flex align-items-center mb-sm-12">
          <UserIcon className={styles.userIcon} />
          <p className="text-14 text-smallTextGrey">
            {role}: {getFullName(owner || project?.owner)}
          </p>
        </div>
      </div>
    </div>
  );
};
