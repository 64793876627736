import React from 'react';
import InputMask from 'react-input-mask';
import { FormikProps, FormikValues, useFormikContext } from 'formik';
import FormHelperText from '@material-ui/core/FormHelperText';
import clsx from 'clsx';

import styles from './InputPhoneMask.module.scss';
import { Input } from 'types/common';

type CustomPhoneMaskProps = {
  label: string;
  field: {
    name: string;
    value: string;
  };
  form: FormikProps<FormikValues>;
  className: string;
};

export const InputPhoneMask = ({ field: { name, value }, form: { setFieldValue } }: CustomPhoneMaskProps) => {
  const formikContext = useFormikContext();
  const { error, touched } = formikContext.getFieldMeta(name);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(
      name,
      !isNaN(parseInt(e.target.value.replace(/[^0-9]/g, ''))) ? parseInt(e.target.value.replace(/[^0-9]/g, '')) : '',
    );
  };
  let number = null;
  if (name === 'personalNumber') {
    number = 'Personal number';
  } else {
    number = 'Work number';
  }
  return (
    <div className={clsx(styles.mainWrapper, !!(error && touched) && styles.error)}>
      <InputMask mask="+1(999)999-9999" value={value} onChange={handleChange}>
        {(inputProps: Input) => <input {...inputProps} className={styles.wrapper} placeholder="+1(609)513-0514" />}
      </InputMask>
      <p className={styles.label}>{number}</p>
      {error && touched && (
        <FormHelperText error className={clsx('weight-400', styles.errorMessage)}>
          {typeof error === 'object' ? (error as { name: string }).name : error}
        </FormHelperText>
      )}
    </div>
  );
};
