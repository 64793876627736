import { IconProps } from 'types';

export const ClockIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00033 15.3334C3.96699 15.3334 0.666992 12.0334 0.666992 8.00002C0.666992 3.96669 3.96699 0.666687 8.00033 0.666687C12.0337 0.666687 15.3337 3.96669 15.3337 8.00002C15.3337 12.0334 12.0337 15.3334 8.00033 15.3334ZM8.00033 2.00002C4.70033 2.00002 2.00033 4.70002 2.00033 8.00002C2.00033 11.3 4.70033 14 8.00033 14C11.3003 14 14.0003 11.3 14.0003 8.00002C14.0003 4.70002 11.3003 2.00002 8.00033 2.00002Z"
        fill="#4D4D4D"
      />
      <path
        d="M10.8337 12.1667C10.667 12.1667 10.5003 12.1 10.367 11.9667L7.53366 9.13335C7.40033 9.00002 7.33366 8.83335 7.33366 8.66669V4.66669C7.33366 4.30002 7.63366 4.00002 8.00033 4.00002C8.36699 4.00002 8.66699 4.30002 8.66699 4.66669V8.40002L11.3003 11.0334C11.567 11.3 11.567 11.7 11.3003 11.9667C11.167 12.1 11.0003 12.1667 10.8337 12.1667Z"
        fill="#4D4D4D"
      />
    </svg>
  );
};
