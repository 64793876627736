import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const SubmittedRoute = Route(
  {
    page: Route.query.number,
    project: Route.query.string,
    type: Route.query.string,
    search: Route.query.string,
  },
  '/report-writing-overview/submitted',
);

export const Submitted = SubmittedRoute(lazy(() => import('./Submitted')));
