import { IconProps } from 'types';

export const TableViewIcon = ({ className, onClick }: IconProps) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 1C0.25 0.585786 0.585786 0.25 1 0.25H14C14.4142 0.25 14.75 0.585786 14.75 1C14.75 1.41421 14.4142 1.75 14 1.75H1C0.585786 1.75 0.25 1.41421 0.25 1Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 5.875C0.25 5.46079 0.585786 5.125 1 5.125H14C14.4142 5.125 14.75 5.46079 14.75 5.875C14.75 6.28921 14.4142 6.625 14 6.625H1C0.585786 6.625 0.25 6.28921 0.25 5.875Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 10.75C0.25 10.3358 0.585786 10 1 10H14C14.4142 10 14.75 10.3358 14.75 10.75C14.75 11.1642 14.4142 11.5 14 11.5H1C0.585786 11.5 0.25 11.1642 0.25 10.75Z"
        fill="black"
      />
    </svg>
  );
};
