import { IconProps } from 'types';

export const MenuIcon = ({ className, onClick }: IconProps) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.479156 0.97933C0.479156 0.519092 0.852253 0.145996 1.31249 0.145996H14.6458C15.1061 0.145996 15.4792 0.519092 15.4792 0.97933C15.4792 1.43957 15.1061 1.81266 14.6458 1.81266H1.31249C0.852253 1.81266 0.479156 1.43957 0.479156 0.97933Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.04165 5.97933C4.04165 5.51909 4.41475 5.146 4.87498 5.146H14.6458C15.1061 5.146 15.4792 5.51909 15.4792 5.97933C15.4792 6.43957 15.1061 6.81266 14.6458 6.81266H4.87498C4.41475 6.81266 4.04165 6.43957 4.04165 5.97933Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.33332 10.9793C6.33332 10.5191 6.70641 10.146 7.16665 10.146H14.6458C15.1061 10.146 15.4792 10.5191 15.4792 10.9793C15.4792 11.4396 15.1061 11.8127 14.6458 11.8127H7.16665C6.70641 11.8127 6.33332 11.4396 6.33332 10.9793Z"
        fill="black"
      />
    </svg>
  );
};
