import { FC } from 'react';
import { LoadingButton, TableV2 } from 'components';

import { useProductivityMetricsDataTable } from './useTable';
import { ProductivityMetricsDataProps } from '../../types';
import { ProductivityMetricsDataMobileTable } from '../MobileTable';
import styles from './Table.module.scss';

export const ProductivityMetricsDataTable: FC<ProductivityMetricsDataProps> = ({ match, link }) => {
  const { items, pagination, columns, isFetching, isExportingCsv, tableEmptyState, handleClear, handleCsvExport } =
    useProductivityMetricsDataTable({
      match,
      link,
    });

  return (
    <div className="p-relative">
      {!isFetching && !!items?.length && (
        <LoadingButton
          onClick={handleCsvExport}
          className={styles.exportBtn}
          variant="contained"
          loading={isExportingCsv}
        >
          Export to CSV
        </LoadingButton>
      )}

      <TableV2
        columns={columns}
        data={items}
        loading={isFetching}
        paginationClassName="mb-20"
        {...{ pagination }}
        {...tableEmptyState}
      />
      <ProductivityMetricsDataMobileTable items={items} onClearFilters={handleClear} loading={isFetching} />
    </div>
  );
};
