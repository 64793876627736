import { IconProps } from 'types';

export const ArrowLeftIcon = ({ className, color }: IconProps) => {
  return (
    <svg
      className={className}
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4999 8.66683H4.49992C4.13325 8.66683 3.83325 8.36683 3.83325 8.00016C3.83325 7.6335 4.13325 7.3335 4.49992 7.3335H12.4999C12.8666 7.3335 13.1666 7.6335 13.1666 8.00016C13.1666 8.36683 12.8666 8.66683 12.4999 8.66683Z"
        fill={color || '#212B83'}
      />
      <path
        d="M8.49992 12.6668C8.33325 12.6668 8.16659 12.6002 8.03325 12.4668L4.03325 8.46683C3.76659 8.20016 3.76659 7.80016 4.03325 7.5335L8.03325 3.5335C8.29992 3.26683 8.69992 3.26683 8.96659 3.5335C9.23325 3.80016 9.23325 4.20016 8.96659 4.46683L5.43325 8.00016L8.96659 11.5335C9.23325 11.8002 9.23325 12.2002 8.96659 12.4668C8.83325 12.6002 8.66659 12.6668 8.49992 12.6668Z"
        fill={color || '#212B83'}
      />
    </svg>
  );
};
