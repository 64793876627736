import clsx from 'clsx';
import { ScrollTopIcon } from 'icons';

import styles from './ScrollTop.module.scss';
import { baseLayoutId, mobileTableIdForTopScrolling, tableIdForTopScrolling } from 'consts';
import useWindowSize from 'hooks/useWindowSize';
import { useEffect, useState } from 'react';

interface ScrollTopProps {
  className?: string;
  isMobileTable?: boolean;
  scrollTopValue?: number;
  scrollBlockClassName?: string;
  isPageWithMap?: boolean;
}

export const ScrollTop = ({
  className,
  isMobileTable = false,
  scrollTopValue = 200,
  scrollBlockClassName = 'overflow-auto',
  isPageWithMap = false,
}: ScrollTopProps) => {
  const { width } = useWindowSize();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const layout = document.getElementsByClassName(scrollBlockClassName)[0];
    if (!layout) return;

    const handleScroll = () => {
      if (layout.scrollTop > scrollTopValue) return setIsVisible(true);
      else setIsVisible(false);
    };

    layout.addEventListener('scroll', handleScroll);

    return () => {
      layout.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = () => {
    let table;
    if (!isMobileTable) {
      table = document.getElementById(tableIdForTopScrolling);
    } else
      table =
        width >= 768
          ? document.getElementById(tableIdForTopScrolling)
          : document.getElementById(mobileTableIdForTopScrolling);

    table?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      {isVisible && (
        <ScrollTopIcon
          className={clsx(styles.icon, className, isPageWithMap && styles.pageWithMap)}
          onClick={handleClick}
        />
      )}
    </>
  );
};

export const ScrollTopV2 = ({ className }: ScrollTopProps) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const table = document.getElementById(baseLayoutId);
    if (!table) return;

    const handleScroll = () => {
      setIsVisible(table.scrollTop > 200);
    };

    table.addEventListener('scroll', handleScroll);

    return () => {
      table.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = () => {
    const table = document.getElementById(baseLayoutId);
    table?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return <>{isVisible && <ScrollTopIcon className={clsx(styles.icon, className)} onClick={handleClick} />}</>;
};
