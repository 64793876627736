import { api, apiRoutes } from 'api';
import { useQuery } from 'react-query';
import { QueryParameters } from 'lib/react-query/types';
import {
  productivityMetricsAvgServicesPricesKeys,
  productivityMetricsDataKeys,
  productivityMetricsInspectionsKeys,
  productivityMetricsProjectPortfolioKeys,
  productivityMetricsRfpPortfolioKeys,
} from 'lib/react-query/queryKeyFactory';
import { emptyTableData } from 'consts';

export const useGetProductivityMetricsData = (parameters?: QueryParameters) => {
  const { params, options, queryKey } = parameters || {};
  const productivityMetricsDataQuery = async () =>
    (await api.get(apiRoutes.productivityMetricsData, { params: { ...params } })).data;

  return useQuery([productivityMetricsDataKeys.allData, ...queryKey], () => productivityMetricsDataQuery(), {
    ...options,
    initialData: emptyTableData,
  });
};

export const useGetProductivityMetricsProjectPortfolio = (parameters?: QueryParameters) => {
  const { params, options, queryKey } = parameters || {};
  const productivityMetricsProjectPortfolioQuery = async () =>
    (await api.get(apiRoutes.productivityMetricsProjectPortfolio, { params: { ...params } })).data;

  return useQuery(
    [productivityMetricsProjectPortfolioKeys.allData, ...queryKey],
    () => productivityMetricsProjectPortfolioQuery(),
    {
      ...options,
      initialData: emptyTableData,
    },
  );
};

export const useGetPMRfpPortfolio = (parameters?: QueryParameters) => {
  const { params, options, queryKey } = parameters || {};
  const pmRfpPortfolioQuery = async () =>
    (await api.get(apiRoutes.productivityMetricsRfpPortfolio, { params: { ...params } })).data;

  return useQuery([productivityMetricsRfpPortfolioKeys.allData, ...queryKey], () => pmRfpPortfolioQuery(), {
    ...options,
    initialData: emptyTableData,
  });
};

export const useGetPMAvgServicesPrices = (parameters?: QueryParameters) => {
  const { params, options, queryKey } = parameters || {};
  const pmAvgServicesPricesQuery = async () =>
    (await api.get(apiRoutes.productivityMetricsAvgServicesPrices, { params: { ...params } })).data;

  return useQuery([productivityMetricsAvgServicesPricesKeys.allData, ...queryKey], () => pmAvgServicesPricesQuery(), {
    ...options,
    initialData: emptyTableData,
  });
};

export const useGetPMInspections = (parameters?: QueryParameters) => {
  const { params, options, queryKey } = parameters || {};
  const pmInspectionsQuery = async () =>
    (await api.get(apiRoutes.productivityMetricsInspections, { params: { ...params } })).data;

  return useQuery([productivityMetricsInspectionsKeys.allData, ...queryKey], () => pmInspectionsQuery(), {
    ...options,
  });
};
