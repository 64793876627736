import { FC, useMemo } from 'react';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';

import { LoadingButton } from 'components';
import { DocumentCategoryFormValues } from 'types';

interface DocumentCategoryFormProps {
  onSubmit: (values: DocumentCategoryFormValues) => void | Promise<void>;
  onClose?: () => void;
  title?: string;
  submitButtonTitle: string;
}

export const DocumentCategoryForm: FC<DocumentCategoryFormProps> = ({
  title,
  onSubmit,
  onClose,
  submitButtonTitle,
}) => {
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string().required('Document name is a required field'),
      }),
    [],
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        title: title ?? '',
        required: false,
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="col-12 mb-24 mb-md-36">
            <Field component={TextField} name="title" label="Document Category Name" variant="outlined" />
          </div>

          <div className="flex pt-24 justify-content-end col-12">
            <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
              {submitButtonTitle}
            </LoadingButton>

            <Button variant="text" type="reset" onClick={onClose} className="ml-12">
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
