import { FC, Fragment } from 'react';
import { ArrowRightIcon } from 'icons';
import { NavLink } from 'react-router-dom';

import styles from './Breadcrumbs.module.scss';
import clsx from 'clsx';

interface BreadcrumbsProps {
  data: { title: string; link: string | boolean }[];
  className?: string;
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ data, className }) => {
  return (
    <div className={clsx(styles.wrapper, className)}>
      {data.map(({ title, link }, index) => {
        const isLastElement = index !== data.length - 1;

        return (
          <Fragment key={title}>
            {link ? (
              <NavLink to={link}>
                <p className="text-primary text-nowrap">{title}</p>
              </NavLink>
            ) : (
              <p className="text-table-text text-nowrap">{title}</p>
            )}
            {isLastElement && <ArrowRightIcon color="#D3D5E6" className={styles.arrowIcon} />}
          </Fragment>
        );
      })}
    </div>
  );
};
