import { Route } from 'react-router-hoc';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';

import { ForgotPasswordForm } from 'components';
import { ForgotFormValues } from 'types';
import api from 'api/api';
import { apiRoutes } from 'api/routes';

const ForgotPassword = () => {
  const mutation = useMutation('newInfo', (values: ForgotFormValues) => sendEmail(values), {
    onSuccess: () => {
      toast.success('Request for change password was sent');
    },
  });

  const sendEmail = (values: ForgotFormValues) => api.post(apiRoutes.forgot, values).then((res) => res);

  const handleSubmit = async (values: ForgotFormValues) => {
    mutation.mutate(values);
  };

  return (
    <>
      <ForgotPasswordForm onSubmit={handleSubmit} />
    </>
  );
};

export default Route('/forgot-password')(ForgotPassword);
