import { FC } from 'react';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';

import { Like, Dislike } from 'icons';
import { FileStatusEnum, FileStatusFormValues } from 'types';

import styles from './Thumbs.module.scss';

type ThumbsProps = {
  status: string | undefined;
  onSubmit: (values: FileStatusFormValues) => void;
  disabled?: boolean;
};

export const Thumbs: FC<ThumbsProps> = ({ status, onSubmit, disabled }) => {
  const handleChange = (status: FileStatusEnum) => {
    onSubmit({ status });
  };

  return (
    <div
      className={clsx(
        styles.mainContainer,
        'flex align-items-center justify-content-between',
        disabled && styles.disabled,
      )}
    >
      <IconButton
        className={clsx(styles.button, status === FileStatusEnum.Approved && styles.active)}
        onClick={() => handleChange(FileStatusEnum.Approved)}
        {...{ disabled }}
      >
        <Like />
      </IconButton>
      <IconButton
        className={clsx(styles.button, status === FileStatusEnum.Rejected && styles.rejected)}
        onClick={() => handleChange(FileStatusEnum.Rejected)}
        {...{ disabled }}
      >
        <Dislike />
      </IconButton>
    </div>
  );
};
