import { IconProps } from 'types';

export const SadSmile = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31 16C31 14.9 30.1 14 29 14C27.9 14 27 14.9 27 16H31ZM27 17C27 18.1 27.9 19 29 19C30.1 19 31 18.1 31 17H27ZM17 16C17 14.9 16.1 14 15 14C13.9 14 13 14.9 13 16H17ZM13 17C13 18.1 13.9 19 15 19C16.1 19 17 18.1 17 17H13ZM27.2 29.9C27.7 30.9 28.9 31.3 29.9 30.8C30.9 30.3 31.3 29.1 30.8 28.1L27.2 29.9ZM13.2 28.1C12.7 29.1 13.1 30.3 14.1 30.8C15.1 31.3 16.3 30.9 16.8 29.9L13.2 28.1ZM22 44C34.2 44 44 34.2 44 22H40C40 31.9 31.9 40 22 40V44ZM44 22C44 9.8 34.2 0 22 0V4C31.9 4 40 12.1 40 22H44ZM22 0C9.8 0 0 9.8 0 22H4C4 12.1 12.1 4 22 4V0ZM0 22C0 34.2 9.8 44 22 44V40C12.1 40 4 31.9 4 22H0ZM27 16V17H31V16H27ZM13 16V17H17V16H13ZM29 29C30.8 28.1 30.8 28.1 30.8 28.1C30.8 28.1 30.8 28.1 30.8 28C30.8 28 30.7 27.9 30.7 27.8C30.6 27.6 30.5 27.4 30.3 27.2C30 26.7 29.5 26.1 28.8 25.5C27.3 24.2 25.1 23 22 23V27C23.9 27 25.2 27.8 26 28.5C26.4 28.9 26.7 29.3 26.9 29.5C27 29.6 27.1 29.7 27.1 29.8V29.9C27.2 29.9 27.2 29.9 29 29ZM22 23C18.9 23 16.7 24.2 15.3 25.5C14.6 26.1 14.1 26.7 13.8 27.2C13.6 27.4 13.5 27.6 13.4 27.8C13.4 27.9 13.3 27.9 13.3 28C13.3 28 13.3 28 13.3 28.1C13.3 28.1 13.3 28.1 15.1 29C16.9 29.9 16.9 29.9 16.9 29.9C16.9 29.9 16.9 29.9 16.9 29.8C16.9 29.7 17 29.6 17.1 29.5C17.3 29.2 17.6 28.8 18 28.5C18.8 27.8 20.1 27 22 27V23Z"
        fill="#565ea1"
      />
    </svg>
  );
};
