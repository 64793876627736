import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const ProjectOverviewPageRoute = Route(
  {
    id: Route.params.number.optional,
  },
  ({ id }) => `/project/${id}`,
);

export const ProjectOverviewPage = ProjectOverviewPageRoute(lazy(() => import('./Overview')));
