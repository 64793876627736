import { api, apiRoutes } from 'api';
import { useMutation } from 'react-query';
import {
  DocumentCategoryAddInfo,
  ProjectCoverTabDTO,
  ProposalCreateDTO,
  RequestDocumentCategoryFormValues,
  RequestFileStatusFormValues,
  RequestProjectNoteFormValue,
  RequestSendRemindersFormValues,
  RoleFormValues,
} from 'types';
import { ErrorFn, SuccessFn } from './types';
import { Questions, SectionType } from 'pages/ReportWriting/schemas/types';
import { mutationsKeys } from './const';

export const useFileEditStatusMutation = (onSuccessFn: SuccessFn) => {
  const fileEditMutation = (values: RequestFileStatusFormValues, document: number) =>
    api.put(`${apiRoutes.documents}/${document}/status`, values).then((res) => res);

  return useMutation(
    'fileEditMutation',
    (values: RequestFileStatusFormValues) =>
      // eslint-disable-next-line
      fileEditMutation((({ document, ...values }) => ({ ...values }))(values), values.document as number),
    {
      onSuccess: onSuccessFn,
    },
  );
};

export const useCreateProjectNotesMutation = (onSuccessFn: SuccessFn) => {
  const createNoteMutation = (values: RequestProjectNoteFormValue) =>
    api.post(apiRoutes.projectNotes, values).then((res) => res);

  return useMutation('createNoteMutation', (values: RequestProjectNoteFormValue) => createNoteMutation(values), {
    onSuccess: onSuccessFn,
  });
};

export const useEditDocumentCategoryMutation = (onSuccessFn: SuccessFn) => {
  const categoryEditMutation = (values: RequestDocumentCategoryFormValues, documentCategory: number) =>
    api.put(`${apiRoutes.documentCategories}/${documentCategory}`, values).then((res) => res);

  return useMutation(
    'categoryEditMutation',
    (values: RequestDocumentCategoryFormValues) =>
      categoryEditMutation(
        // eslint-disable-next-line
        (({ documentCategory, ...values }) => ({ ...values }))(values),
        values.documentCategory as number,
      ),
    {
      onSuccess: onSuccessFn,
    },
  );
};

export const useCreateDocumentCategoryMutation = (id: number, onSuccessFn: SuccessFn) => {
  const categoryCreateMutation = (values: RequestDocumentCategoryFormValues) =>
    api.post(`${apiRoutes.documentCategories}/${id}`, values).then((res) => res);

  return useMutation(
    'categoryCreateMutation',
    (values: RequestDocumentCategoryFormValues) => categoryCreateMutation(values),
    {
      onSuccess: onSuccessFn,
    },
  );
};

export const useSendReminderMutation = (onSuccessFn: SuccessFn) => {
  const sendReminder = (values: RequestSendRemindersFormValues) =>
    api.post(apiRoutes.sendReminder, values).then((res) => res);

  return useMutation('sendReminderMutation', (values: RequestSendRemindersFormValues) => sendReminder(values), {
    onSuccess: onSuccessFn,
  });
};

export const useAddInfoDocumentCategoryMutation = (id: number, onSuccessFn: SuccessFn) => {
  const addInfoDocumentCategoryMutation = (values: DocumentCategoryAddInfo) =>
    api.put(`${apiRoutes.documentCategories}/${id}/add-info`, values).then((res) => res);

  return useMutation(
    'addInfoDocumentCategoryMutation',
    (values: DocumentCategoryAddInfo) => addInfoDocumentCategoryMutation(values),
    {
      onSuccess: onSuccessFn,
    },
  );
};

export const useSaveAsDraftReportMutation = (
  id: number,
  mathExpressions: { [key: string]: unknown },
  onSuccessFn: SuccessFn,
  onErrorFn: ErrorFn,
) => {
  const saveAsDraftMutation = (report: Questions[]) =>
    api
      .put(`${apiRoutes.reports}/${id}`, {
        reportData: report,
        mathExpressions,
      })
      .then((res) => res);

  return useMutation('saveAsDraftMutation', (report: Questions[]) => saveAsDraftMutation(report), {
    onSuccess: onSuccessFn,
    onError: onErrorFn,
  });
};

export const useUpdateSectionReportMutation = (id: number, onSuccessFn: SuccessFn) => {
  const updateSectionMutation = (sectionData: Partial<SectionType>) =>
    api
      .put(`${apiRoutes.reports}/${id}/category`, {
        ...sectionData,
      })
      .then((res) => res);

  return useMutation(
    'updateSectionMutation',
    (sectionData: Partial<SectionType>) => updateSectionMutation(sectionData),
    {
      onSuccess: onSuccessFn,
    },
  );
};

export const useEditProjectCoverPagesMutation = (id: number, onSuccessFn: SuccessFn) => {
  const editProjectCoverPagesMutation = (values: ProjectCoverTabDTO) =>
    api
      .put(`${apiRoutes.projects}/${id}/cover-tab`, {
        ...values,
      })
      .then((res) => res);

  return useMutation(
    'editProjectCoverPagesMutation',
    (values: ProjectCoverTabDTO) => editProjectCoverPagesMutation(values),
    {
      onSuccess: onSuccessFn,
    },
  );
};

export const useDeleteProposalRequestMutation = (id: number, onSuccessFn?: SuccessFn) => {
  const deleteProposalRequestMutation = () => api.delete(`${apiRoutes.proposalRequests}/${id}`).then((res) => res);

  return useMutation(mutationsKeys.deleteProposalRequest, () => deleteProposalRequestMutation(), {
    onSuccess: onSuccessFn,
  });
};

export const useEditProposalRequestMutation = (id: number, onSuccessFn: SuccessFn) => {
  const editProposalRequestMutation = (values: ProposalCreateDTO) =>
    api
      .put(`${apiRoutes.proposalRequests}/${id}`, {
        ...values,
      })
      .then((res) => res);

  return useMutation(
    mutationsKeys.editProposalRequest,
    (values: ProposalCreateDTO) => editProposalRequestMutation(values),
    {
      onSuccess: onSuccessFn,
    },
  );
};

export const useCreateProposalRequestMutation = (onSuccessFn: SuccessFn) => {
  const createProposalRequestMutation = (values: ProposalCreateDTO) =>
    api
      .post(apiRoutes.proposalRequests, {
        ...values,
      })
      .then((res) => res);

  return useMutation(
    mutationsKeys.createProposalRequest,
    (values: ProposalCreateDTO) => createProposalRequestMutation(values),
    {
      onSuccess: onSuccessFn,
    },
  );
};

//TO-DO change type
export const useCreateProposalRequestNoteMutation = (onSuccessFn: SuccessFn) => {
  const createProposalRequestNoteMutation = (values: unknown) =>
    api.put(apiRoutes.proposalRequestNotes, values).then((res) => res);

  return useMutation(
    'createProposalRequestNoteMutation',
    (values: unknown) => createProposalRequestNoteMutation(values),
    {
      onSuccess: onSuccessFn,
    },
  );
};

export const useDeleteProposalRequestNoteMutation = (id: number, onSuccessFn: SuccessFn) => {
  const deleteProposalRequestNoteMutation = (id: number) =>
    api.post(`${apiRoutes.proposalRequestNotes}/${id}/archive`).then((res) => res);

  return useMutation('deleteProposalRequestNoteMutation', (id: number) => deleteProposalRequestNoteMutation(id), {
    onSuccess: onSuccessFn,
  });
};

export const useDeleteProjectNoteMutation = (onSuccessFn: SuccessFn) => {
  const deleteProjectNoteMutation = (id: number) =>
    api.post(`${apiRoutes.projectNotes}/${id}/archive`).then((res) => res);

  return useMutation('deleteProjectNoteMutation', (id: number) => deleteProjectNoteMutation(id), {
    onSuccess: onSuccessFn,
  });
};

export const useDeactivateUserMutation = (id: number, onSuccessFn: SuccessFn) => {
  const deactivateUserMutation = () => api.post(`${apiRoutes.users}/${id}/deactivate`).then((res) => res.data);

  return useMutation('deactivateUserMutation', () => deactivateUserMutation(), {
    onSuccess: onSuccessFn,
  });
};

export const useActivateUserMutation = (id: number, onSuccessFn: SuccessFn) => {
  const activateUserMutation = () => api.post(`${apiRoutes.users}/${id}/activate`).then((res) => res.data);

  return useMutation('activateUserMutation', () => activateUserMutation(), {
    onSuccess: onSuccessFn,
  });
};

export const useCreateRoleMutation = (onSuccessFn: SuccessFn) => {
  const createRoleMutation = (values: RoleFormValues) => api.post(apiRoutes.roles, values).then((res) => res.data);

  return useMutation('createRoleMutation', (values: RoleFormValues) => createRoleMutation(values), {
    onSuccess: onSuccessFn,
  });
};
