import React, { FC } from 'react';
import clsx from 'clsx';

import { Sidebar } from 'components';

import styles from './BaseLayout.module.scss';

type Props = {
  children: React.ReactNode;
};

export const BaseLayout: FC<Props> = ({ children }) => {
  return (
    <div className="bg-gray flex h-100">
      <Sidebar />
      <div className="flex flex-column flex-1">
        <div className={clsx(styles.container, 'flex', 'flex-1')}>
          <div className={clsx(styles.content, 'flex-1')}>{children}</div>
        </div>
      </div>
    </div>
  );
};
