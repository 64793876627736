import { FC } from 'react';
import { TableV2 } from 'components';

import { PMProjectPortfolioProps } from '../../types';
import { usePMProjectPortfolioTable } from './useTable';
import styles from './Table.module.scss';
import { PMProjectPortfolioMobileTable, PMProjectPortfolioByMonthMobileTable } from '../MobileTable';

export const PMProjectPortfolioTable: FC<PMProjectPortfolioProps> = ({ match, link }) => {
  const {
    filteredAnticipatedStats,
    filteredCompletedStats,
    completedStatsColumns,
    anticipatedStatsColumns,
    isFetching,
    emptyTableState,
    isAnyData,
    handleClear,
  } = usePMProjectPortfolioTable({
    match,
    link,
  });

  return (
    <>
      {isAnyData && (
        <TableV2
          columns={completedStatsColumns}
          data={filteredCompletedStats}
          loading={isFetching}
          isScrollTop={false}
          className={styles.table}
          {...emptyTableState}
        />
      )}

      <TableV2
        columns={anticipatedStatsColumns}
        data={filteredAnticipatedStats}
        isScrollTop={false}
        loading={isFetching}
        className={styles.table}
        {...emptyTableState}
      />

      <PMProjectPortfolioMobileTable items={filteredCompletedStats} loading={isFetching} onClearFilters={handleClear} />
      <PMProjectPortfolioByMonthMobileTable
        items={filteredAnticipatedStats}
        loading={isFetching}
        onClearFilters={handleClear}
      />
    </>
  );
};
