import { FC } from 'react';
import { TableV2 } from 'components';

import { usePMAvgServicesPricesTable } from './useTable';
import { PMAvgServicesPricesProps } from '../../types';
import { PMAvgServicesPricesMobileTable } from '../MobileTable';
import styles from '../../../ProjectPortfolio/components/Table/Table.module.scss';

export const PMAvgServicesPricesTable: FC<PMAvgServicesPricesProps> = ({ match, link }) => {
  const { filteredData, columns, isFetching, tableEmptyState, handleClear } = usePMAvgServicesPricesTable({
    match,
    link,
  });

  return (
    <>
      <TableV2
        columns={columns}
        data={filteredData}
        className={styles.table}
        loading={isFetching}
        {...tableEmptyState}
      />
      <PMAvgServicesPricesMobileTable items={filteredData} onClearFilters={handleClear} loading={isFetching} />
    </>
  );
};
