import { Overrides } from '@material-ui/core/styles/overrides';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';

const primaryColor = '#212B83';
const dangerColor = '#CC0000';

export const overrides: Overrides & MuiPickersOverrides = {
  MuiButton: {
    root: {
      fontWeight: 700,
      transition: 'all 0.2s',
      border: '1px solid #212B83',
      textTransform: 'uppercase',
      padding: '16px 32px',
      borderRadius: '10px',
      letterSpacing: '1.25px',
      fontSize: '12px',
      // minWidth: '108px',
      minHeight: '55px',
      ['@media (max-width:1200px)']: {
        // eslint-disable-line no-useless-computed-key
        padding: '16px',
      },
      '&.Mui-disabled': {
        border: 'none',
      },
    },
    outlinedPrimary: {
      '&:hover': {
        backgroundColor: `${primaryColor} !important`,
        color: 'white',
      },
    },
    text: {
      border: 'none',
      fontSize: '12px',
      padding: '16px 32px',
      textTransform: 'none',
    },
  },
  MuiTabs: {
    root: {
      minHeight: 'auto',
    },
    flexContainer: {
      maxWidth: '576px',
      overflowX: 'auto',
      '&>*': {
        flexGrow: 1,
      },
    },
    indicator: {
      display: 'none',
    },
  },
  MuiAccordionDetails: {
    root: {
      flexDirection: 'column',
    },
  },
  MuiAccordionSummary: {
    root: {
      minHeight: '56px',
      '&.Mui-expanded': {
        // borderBottom: '2px solid #1229ff',
        // minHeight: 'auto',
      },
    },
    content: {
      '&.Mui-expanded': {
        // margin: '12px 0;',
      },
    },
  },

  MuiTab: {
    wrapper: {
      flexDirection: 'row-reverse',
      // color: '#1229ff',
      // maxWidth: '150px',
      // borderTop: '2px solid #1229ff',
      // borderLeft: '2px solid #1229ff',
      // borderRight: '2px solid #1229ff',
    },
    root: {
      maxWidth: 'none',
      borderBottom: '2px solid #D3D5E6',
      padding: '5px 10px',
      marginBottom: '0px',
      display: 'block',
      minHeight: 'none',
      textAlign: 'left',
      textTransform: 'none',
      minWidth: 'auto',
      ['@media (min-width: 600px)']: {
        // eslint-disable-line no-useless-computed-key
        minWidth: 'auto',
      },
      '&.Mui-selected': {
        borderBottom: '2px solid #212B83',
        color: '#212B83',
      },
    },
  },
  MuiSwitch: {
    colorSecondary: {
      '&$checked': {
        color: '#212B83',
      },
    },
    track: {
      opacity: 0.2,
      backgroundColor: '#212B83',
      '$checked$checked + &': {
        opacity: 0.2,
        backgroundColor: '#212B83',
      },
    },
  },
  MuiAppBar: {
    colorPrimary: {
      background: 'none',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  MuiFormControl: {
    root: {
      border: 'none',
    },
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: '15px',
      padding: '10px 8px',
      letterSpacing: '0.5px',
      color: primaryColor,
      fontSize: '16px',
      outline: 'none',
      backgroundColor: '#FFFFFF',
      '&.MuiInputBase-formControl': {
        padding: '10px 8px',
        ['@media (max-width:576px)']: {
          // eslint-disable-line no-useless-computed-key
          // padding: '4px 8px',
        },
      },
      '&:hover': {
        borderColor: primaryColor,
      },
      '&:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
        borderColor: primaryColor,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: dangerColor,
      },
      '&.Mui-disabled:hover': {
        borderColor: 'red',
      },
    },
    input: {
      padding: '10px 8px',
      '&.MuiInputBase-input': {
        padding: '10px 8px',
      },
      '&.MuiInputBase-inputAdornedEnd': {
        color: primaryColor,
      },
      '&.Mui-disabled': {
        color: '#5a5a5a',
      },
    },
    notchedOutline: {
      borderColor: '#D3D5E6',
    },
  },
  MuiInputBase: {
    root: {
      '&.MuiInputBase-formControl': {
        borderRadius: '15px',
      },
    },
  },
  MuiFormLabel: {
    root: {
      color: '#565EA1',
      fontSize: '16px',
      letterSpacing: '0.5px',
      '&.Mui-focused': {
        backgroundColor: 'transparent',
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '0.1px',
        color: primaryColor,
      },
      '&.MuiFormLabel-filled': {
        backgroundColor: 'white',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '0.1px',
        color: primaryColor,
      },
      '&.Mui-error': {
        color: dangerColor,
      },
    },
  },
  MuiInputLabel: {
    outlined: {
      transform: 'translate(14px, 20px) scale(1)',
      '&.MuiInputLabel-marginDense': {
        transform: 'translate(14px, 20px) scale(1)',
      },
      '&.MuiInputLabel-shrink': {
        fontSize: '12px',
        fontWeight: 'bold',
        transform: 'translate(14px, -6px) scale(1)',
      },
    },
    // marginDense: {

    // },
  },
  MuiFormHelperText: {
    root: {
      fontSize: '12px',
      position: 'absolute',
      top: '100%',
      '&.Mui-error': {
        color: dangerColor,
      },
    },
  },
  MuiSelect: {
    root: {
      padding: '10px 8px',
      maxHeight: '300px',
    },
    outlined: {
      '&.MuiSelect-outlined': {
        paddingRight: '24px',
      },
    },
    select: {
      paddingRight: '24px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
  MuiSvgIcon: {
    root: {
      width: '20px',
      height: '20px',
      '&': {
        fill: primaryColor,
      },
    },
  },
  MuiTypography: {
    root: {
      color: primaryColor,
      fontSize: '16px',
    },
  },
  MuiCircularProgress: {
    root: {
      position: 'absolute',
      margin: 'auto',
      top: 0,
      bottom: 0,
      right: '0',
      left: '0',
    },
  },
  MuiListItem: {
    root: {
      '&.Mui-selected, &.Mui-selected:hover': { backgroundColor: primaryColor, color: 'white' },
    },
    button: {
      '&:hover': { backgroundColor: '#E9EAF3' },
    },
  },
  MuiChip: {
    root: {
      padding: '12px',
      maxHeight: '32px',

      ['@media (max-width:576px)']: {
        whiteSpace: 'normal',
        height: 'auto',
      },
    },
  },
  MuiSlider: {
    rail: {
      height: '6px',
      borderRadius: '4px',
    },
    track: {
      height: '6px',
      borderRadius: '4px',
    },
    thumb: {
      height: '16px',
      width: '16px',
    },
    valueLabel: {
      color: 'transparent',
      top: '-20px',
      '&>span>span': {
        color: primaryColor,
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
  },
  MuiPickersToolbar: {
    toolbar: {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      overflow: 'scroll',
      zIndex: 999,
    },
  },

  MuiPickersClock: {
    container: {
      // marginTop: '96px',
    },
  },
  MuiPickersDatePickerRoot: {
    toolbar: {
      display: 'flex',
    },
  },
  MuiPickersCalendarHeader: {
    daysHeader: {
      position: 'relative',
      left: '10px',
    },
  },
  MuiPickersDay: {
    day: {
      color: primaryColor,
    },
    daySelected: {
      backgroundColor: primaryColor,
    },
    current: {
      color: primaryColor,
    },
  },
  MuiDialogActions: {
    root: {
      justifyContent: 'center',
    },
  },
  MuiDialogContent: {
    root: {
      padding: '16px',
    },
  },
  MuiDialog: {
    paperWidthSm: {
      minWidth: '400px',
      minHeight: '240px',
      padding: '40px 24px',
      maxWidth: '864px',
      overflowX: 'hidden',
      ['@media (max-width:976px)']: {
        // eslint-disable-line no-useless-computed-key
        width: '100%',
        maxWidth: '100%',
      },
      ['@media (max-width:576px)']: {
        // eslint-disable-line no-useless-computed-key
        padding: '0 0 16px',
        minWidth: '0',
      },
      '&.MuiDialog-paperFullWidth canvas': {
        width: '100%',
        height: 'auto',
        // minWidth: '850px',
      },

      '&.MuiDialog-paperFullWidth .document-container': {
        width: '100%',
      },

      '&.MuiDialog-paperFullWidth .photo-viewer-container': {
        width: '100% !important',
        height: 'auto !important',
        '& img': {
          width: '100% !important',
          height: 'auto !important',
        },
      },
    },
    paper: {
      ['@media (max-width:576px)']: {
        // eslint-disable-line no-useless-computed-key
        margin: '0',
      },
    },
    paperScrollPaper: {
      ['@media (max-width:576px)']: {
        // eslint-disable-line no-useless-computed-key
        height: 'auto',
        maxHeight: '100%',
      },
    },
    root: {
      '&.custom-dialog .MuiDialog-paper': {
        ['@media (max-width:576px)']: {
          margin: '32px',
          padding: '32px 24px 16px',
          borderRadius: '15px',
        },
      },
    },
  },

  MuiPickersModal: {
    dialogRoot: {
      ['@media (max-width:976px)']: {
        // eslint-disable-line no-useless-computed-key
        width: 'auto',
        height: 'auto',
        borderRadius: '15px',
        padding: '40px 24px',
      },
      ['@media (max-width:576px)']: {
        // eslint-disable-line no-useless-computed-key
        padding: '16px 12px',
      },
    },
  },
  MuiPaper: {
    rounded: {
      borderRadius: '15px',
      ['@media (max-width:576px)']: {
        // eslint-disable-line no-useless-computed-key
        borderRadius: '0',
      },
    },
  },
  MuiAutocomplete: {
    inputRoot: {
      minHeight: '59px',
    },
    endAdornment: {
      top: '14px',
      position: 'absolute',
    },
  },
  MuiMenu: {
    paper: {
      maxHeight: '400px',
    },
  },

  MuiDrawer: {
    paper: {
      borderRadius: '16px 16px 0px 0px;',
      padding: '28px 16px 16px',
      '&::after': {
        content: '2',
        position: 'absolute',
        top: '5px',
        display: 'block',
        height: '3px',
        width: '40px',
        backgroundColor: '#E5E5E5',
      },
    },
    paperAnchorRight: {
      borderRadius: '0px',
      minWidth: '576px',
      maxWidth: '576px',
      ['@media (max-width:576px)']: {
        minWidth: '100%',
        width: '100%',
      },
    },
  },
  MuiPickersCalendar: {},
};
