export enum QuestionType {
  PartialCondition,
  Condition,
  ConditionWithNA,
  MultiSelect,
  Select,
  PlainQuestion,
  SelectDate,
  SelectTime,
  Textarea,
  RichEditor,
  ConditionWithNNAndUnknown,
  ConditionWithNN,
  ConditionWithCC,
  ConditionWithNP,
  ConditionWithIncDec,
  ConditionWithNAAndDNAttend,
  ConditionWithUnknown,
  ConditionWithDNAttend,
  ConditionWithNNAndYesNDated,
  PhoneNumber,
  CustomMultiSelect,
  YesNoPartialCondition,
  DisabledQuestionData,
  ConditionsWithNAAndRetain,
}

export enum ConditionsAnswers {
  Yes = 'Yes',
  No = 'No',
}

export enum YesNoPartialCondition {
  Yes = 'Yes',
  Partial = 'Partial',
  No = 'No',
}

export enum PartialCondition {
  Yes = 'Yes',
  Partial = 'Partial',
}

export enum ConditionsAnswersWithNA {
  Yes = 'Yes',
  No = 'No',
  NA = 'NA',
}

export enum ConditionsAnswersWithNAAndRetain {
  Yes = 'Yes',
  No = 'No',
  Retain = 'Retain',
  NA = 'NA',
}

export enum ConditionsAnswersWithDNAttend {
  Yes = 'Yes',
  DidNotAttend = 'Did not attend',
  No = 'No',
}

export enum ConditionsAnswersWithNNAndUnknown {
  Yes = 'Yes',
  No = 'No',
  NotNeeded = 'Not needed',
  Unknown = 'Unknown',
}

export enum ConditionsAnswersWithUnknown {
  Yes = 'Yes',
  No = 'No',
  Unknown = 'Unknown',
}

export enum ConditionsAnswersWithNN {
  Yes = 'Yes',
  No = 'No',
  NotNeeded = 'Not needed',
}

export enum ConditionsAnswersWithNNAndYesNDated {
  Yes = 'Yes',
  YesNotDated = 'Yes, not dated',
  No = 'No',
  NotNeeded = 'Not needed',
}

export enum ConditionsAnswersWithCC {
  Yes = 'Yes',
  No = 'No',
  CantComment = `Can't comment`,
}

export enum ConditionsAnswersWithNP {
  Yes = 'Yes',
  No = 'No',
  NotProvided = `Not provided`,
}

export enum ConditionsAnswersWithIncDec {
  No = 'No',
  Increased = 'Increased',
  Decreased = 'Decreased',
}

export type Questions = {
  isModified?: boolean;
  id: string | number;
  categoryId: string | number;
  question: string;
  answer: string;
  type: QuestionType;
  template?: string;
  options?: {
    [x: string]: Partial<Questions>[];
  };
  title?: string;
  pattern?: string | RegExp;
  dateFormat?: string;
  isAmount?: boolean;
  hidden?: boolean;
  needToConvert?: boolean;
  isCalculation?: boolean;
  isPercentage?: boolean;
  isTextField?: boolean;
  allowEditorToEdit?: boolean;
  disabled?: boolean;
  withoutCalculation?: boolean;
  isMathematicalExp?: boolean;
  required?: boolean;
  lookBack?: boolean;
  hideInReport?: boolean;
};

export type SectionType = {
  id: string;
  name: string;
  visible: boolean;
  parentId?: string;
  checked: boolean;
  hasSubsections?: boolean;
  orderForInspector?: number;
  orderForDrafter?: number;
  orderForAll?: number;
  orderForDrafterHide?: boolean;
  orderForInspectorHide?: boolean;
  main?: boolean;
  hasIssue: boolean;
};
