import { FormikHelpers } from 'formik';
import { RoleInfo, UserFormValues } from 'types';

export interface UserFormProps {
  onSubmit: (values: UserFormValues, formikHelpers: FormikHelpers<UserFormValues>) => void | Promise<void>;
  id?: string | number;
  firstName?: string;
  lastName?: string;
  email?: string;
  personalNumber?: string;
  workNumber?: string;
  workNumberExt?: string;
  roles?: RoleInfo[];
  isActive?: boolean;
  refetchUser: () => void;
}

export enum SelectedDialogEnum {
  RoleCreation,
}
