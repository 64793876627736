import { IconProps } from 'types';

export const FilterIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.18956 12.3333C8.08956 12.3333 7.98956 12.3 7.88956 12.2667L5.48956 11.0667C5.25623 10.9667 5.1229 10.7333 5.1229 10.4667V6.53333L0.489563 1.1C0.322896 0.9 0.289563 0.633333 0.389563 0.4C0.489563 0.166667 0.722896 0 0.989563 0H12.9896C13.2562 0 13.4896 0.166667 13.5896 0.4C13.6896 0.633333 13.6562 0.9 13.4896 1.1L8.85623 6.53333V11.6667C8.85623 11.9 8.7229 12.1 8.55623 12.2333C8.4229 12.3 8.3229 12.3333 8.18956 12.3333ZM6.45623 10.0667L7.5229 10.6V6.26667C7.5229 6.1 7.58956 5.96667 7.68956 5.83333L11.5562 1.33333H2.4229L6.28956 5.83333C6.38956 5.96667 6.45623 6.1 6.45623 6.26667V10.0667Z"
        fill="black"
      />
    </svg>
  );
};
