import { Questions, QuestionType } from './types';

const getNextId = (id: number) => ++id;

const permitTemplate = (lastQuestionId: string): Partial<Questions> => {
  let currentLastId = Number(lastQuestionId);
  const questionId = ++currentLastId;
  let nextId = getNextId(questionId);
  return {
    id: `${questionId}`,
    question: 'Select Permit:',
    answer: '',
    type: QuestionType.Select,
    options: {
      ['Land Disturbance Permit (LDP)']: [
        {
          id: `${nextId}`,
          categoryId: '4',
          question: 'Permit number:',
          answer: '',
          type: QuestionType.PlainQuestion,
        },
        {
          id: `${++nextId}`,
          categoryId: '4',
          question: 'Issued by:',
          answer: '',
          type: QuestionType.PlainQuestion,
        },
        {
          id: `${++nextId}`,
          categoryId: '4',
          question: 'Date issued:',
          answer: '',
          type: QuestionType.SelectDate,
        },
      ],
      ['Building Permit ']: [
        {
          id: `${++nextId}`,
          categoryId: '4',
          question: 'Permit number:',
          answer: '',
          type: QuestionType.PlainQuestion,
        },
        {
          id: `${++nextId}`,
          categoryId: '4',
          question: 'Issued by:',
          answer: '',
          type: QuestionType.PlainQuestion,
        },
        {
          id: `${++nextId}`,
          categoryId: '4',
          question: 'Date issued:',
          answer: '',
          type: QuestionType.SelectDate,
        },
      ],
      ['Notice of Commencement (NOC)']: [
        {
          id: `${++nextId}`,
          categoryId: '4',
          question: 'Permit number:',
          answer: '',
          type: QuestionType.PlainQuestion,
        },
        {
          id: `${++nextId}`,
          categoryId: '4',
          question: 'Issued by:',
          answer: '',
          type: QuestionType.PlainQuestion,
        },
        {
          id: `${++nextId}`,
          categoryId: '4',
          question: 'Date issued:',
          answer: '',
          type: QuestionType.SelectDate,
        },
      ],
      ['Certificate of Occupancy']: [
        {
          id: `${++nextId}`,
          categoryId: '4',
          question: 'Permit number:',
          answer: '',
          type: QuestionType.PlainQuestion,
        },
        {
          id: `${++nextId}`,
          categoryId: '4',
          question: 'Issued by:',
          answer: '',
          type: QuestionType.PlainQuestion,
        },
        {
          id: `${++nextId}`,
          categoryId: '4',
          question: 'Date issued:',
          answer: '',
          type: QuestionType.SelectDate,
        },
      ],
      ['Other']: [
        {
          id: `${++nextId}`,
          categoryId: '4',
          question: 'Permit number:',
          answer: '',
          type: QuestionType.PlainQuestion,
        },
        {
          id: `${++nextId}`,
          categoryId: '4',
          question: 'Issued by:',
          answer: '',
          type: QuestionType.PlainQuestion,
        },
        {
          id: `${++nextId}`,
          categoryId: '4',
          question: 'Date issued:',
          answer: '',
          type: QuestionType.SelectDate,
        },
      ],
    },
  };
};

const getBiggestIdFromQuestion = (state: Questions[]): string => {
  let maxId = 0;
  const newID = (root: Questions[] | Partial<Questions>[]) => {
    if (Array.isArray(root)) {
      root.forEach((item) => {
        if (Number(item.id) > maxId && item.id) {
          maxId = +item.id;
        }
        if (item.options) {
          const options = Object.values(item.options as { [x: string]: Partial<Questions>[] });
          options.forEach((option) => {
            maxId = newID(option);
          });
        }
      });
      return maxId;
    }
    return maxId;
  };

  maxId = newID(state);
  return maxId.toString();
};

export const getPermitTemplate = (state: Questions[]) => permitTemplate(getBiggestIdFromQuestion(state));
