import React, { useCallback, useState } from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Tooltip } from '@material-ui/core';

import { PassVisibilityBtn } from 'components';

interface PasswordFieldProps {
  name: string;
  label: string;
  autocomplete?: string;
}
export const PasswordField = ({ name, label, autocomplete }: PasswordFieldProps) => {
  const [isPassVisible, setIsPassVisible] = useState(false);
  const togglePasswordVisibility = useCallback(() => setIsPassVisible((prev) => !prev), []);

  return (
    <>
      <Field
        component={TextField}
        type={isPassVisible ? 'text' : 'password'}
        name={name}
        variant="outlined"
        label={label}
        autoComplete={autocomplete}
        InputProps={{
          autoComplete: autocomplete,
          form: {
            autocomplete: 'off',
          },
          endAdornment: (
            <Tooltip
              aria-label={'Show password'}
              title={isPassVisible ? 'Hide password' : 'Show password'}
              placement="right"
            >
              <span className="ml-6">
                <PassVisibilityBtn isPassVisible={isPassVisible} togglePasswordVisibility={togglePasswordVisibility} />
              </span>
            </Tooltip>
          ),
        }}
      />
    </>
  );
};
