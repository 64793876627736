import { IconProps } from 'types';

export const WorkOrderIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 6.125H1.25C0.8375 6.125 0.5 5.7875 0.5 5.375V1.25C0.5 0.8375 0.8375 0.5 1.25 0.5H6.5C6.9125 0.5 7.25 0.8375 7.25 1.25V5.375C7.25 5.7875 6.9125 6.125 6.5 6.125ZM2 4.625H5.75V2H2V4.625ZM14.75 15.5H9.5C9.0875 15.5 8.75 15.1625 8.75 14.75V10.625C8.75 10.2125 9.0875 9.875 9.5 9.875H14.75C15.1625 9.875 15.5 10.2125 15.5 10.625V14.75C15.5 15.1625 15.1625 15.5 14.75 15.5ZM10.25 14H14V11.375H10.25V14ZM14.75 8.375H9.5C9.0875 8.375 8.75 8.0375 8.75 7.625V1.25C8.75 0.8375 9.0875 0.5 9.5 0.5H14.75C15.1625 0.5 15.5 0.8375 15.5 1.25V7.625C15.5 8.0375 15.1625 8.375 14.75 8.375ZM10.25 6.875H14V2H10.25V6.875ZM6.5 15.5H1.25C0.8375 15.5 0.5 15.1625 0.5 14.75V8.375C0.5 7.9625 0.8375 7.625 1.25 7.625H6.5C6.9125 7.625 7.25 7.9625 7.25 8.375V14.75C7.25 15.1625 6.9125 15.5 6.5 15.5ZM2 14H5.75V9.125H2V14Z"
        fill="black"
      />
    </svg>
  );
};
