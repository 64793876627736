import React, { ChangeEvent, FC, useMemo } from 'react';
import { Formik, Form, FormikHelpers, Field } from 'formik';
import * as Yup from 'yup';
import { CheckboxWithLabel, Switch } from 'formik-material-ui';
import FormGroup from '@material-ui/core/FormGroup';
import clsx from 'clsx';
import { useQuery } from 'react-query';
import CircularProgress from '@material-ui/core/CircularProgress';

import { api, apiRoutes } from 'api';
import { Autocomplete, LoadingButton } from 'components';
import { DropdownOption, PlatformSettingsFormValues } from 'types';
import { components } from 'generated/types';

import styles from './PlatformSettingsForm.module.scss';

type Timezone = components['schemas']['TimezoneDTO'];

// TODO: replace with actual data
const options = [
  {
    label: 'New',
    value: 'one',
  },
  {
    label: 'Changes',
    value: 'two',
  },
  {
    label: 'Other',
    value: 'three',
  },
];

interface PlatformSettingsProps {
  onSubmit: (
    values: PlatformSettingsFormValues,
    formikHelpers: FormikHelpers<PlatformSettingsFormValues>,
  ) => void | Promise<void>;
  timezone?: Timezone;
  multiAuth?: boolean;
  notificationType?: string[];
}

export const PlatformSettingsForm: FC<PlatformSettingsProps> = ({
  timezone,
  multiAuth,
  notificationType,
  onSubmit,
}) => {
  const timezonesQuery = () => api.get<Timezone[]>(apiRoutes.timezones).then((res) => res.data);

  const { data: timezones, isLoading: timezoneLoading } = useQuery<Timezone[]>('timezones', () => timezonesQuery());

  const getOptionName = ({ name }: DropdownOption) => name ?? '';

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        timezone: Yup.object()
          .shape({
            id: Yup.string().required('Time zone is a required field'),
            name: Yup.string().required('Time zone is a required field'),
          })
          .typeError('Time zone is a required field'),
        multiAuth: Yup.boolean(),
      }),
    [],
  );

  const handleAllOptionSelect = (
    { target: { checked } }: ChangeEvent<HTMLInputElement>,
    values: PlatformSettingsFormValues,
  ) => {
    const notificationTypeSet = new Set<string>();
    checked ? options.forEach(({ value }) => notificationTypeSet.add(value)) : (values.notificationType.length = 0);

    values.notificationType = Array.from(notificationTypeSet);
  };

  if (timezoneLoading) return <CircularProgress size={64} />;

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        timezone: (timezone as Timezone) ?? {},
        notificationType: notificationType ?? [],
        multiAuth: multiAuth ?? false,
      }}
      onSubmit={onSubmit}
    >
      {({ handleChange, values, isSubmitting }) => (
        <Form>
          <section>
            <span className={clsx('uppercase px-16 mb-24', styles.heading)}>- Account Settings</span>

            <div className="flex align-items-center flex-wrap">
              <div className={clsx(styles.dropdownWrapper, 'col-12 col-md-6 mb-24 mb-md-32')}>
                <Field
                  component={Autocomplete}
                  name="timezone"
                  placeholder="Time zone"
                  type="text"
                  options={[...(timezones as DropdownOption[])]}
                  getOptionLabel={getOptionName}
                />
              </div>

              <div className="col-12 col-md-6 flex align-items-center justify-content-between mb-24 mb-md-32">
                <div className="mr-12">
                  <h4 className="text-primary-label text-14 weight-700">Multi factor authentication </h4>
                  {/* TODO: replace with an actual description */}
                  <p className="text-primary-label text-14">Toggle off to disable MFA</p>
                </div>
                <Field component={Switch} type="checkbox" color="primary" name="multiAuth" />
              </div>
            </div>
          </section>
          <hr className={styles.sectionsSeparator} />
          <section className="mt-24 mt-md-32">
            <div className="col-12 mb-24">
              <h3 className="weight-700">Email Notifications</h3>
              <p className="text-12 mb-24">Manage email notifications you want to receive</p>

              <div className="pl-12">
                <FormGroup>
                  <Field
                    color="primary"
                    type="checkbox"
                    component={CheckboxWithLabel}
                    name="markAll"
                    value="all"
                    Label={{ label: 'Mark all' }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => [handleAllOptionSelect(e, values), handleChange(e)]}
                  />
                  {options.map((opt) => (
                    <Field
                      color="primary"
                      type="checkbox"
                      component={CheckboxWithLabel}
                      name="notificationType"
                      key={opt.value}
                      value={opt.value}
                      Label={{ label: opt.label }}
                    />
                  ))}
                </FormGroup>
              </div>
            </div>
          </section>

          <div className="col-12 col-sm-4 col-md-3 col-lg-2 py-24">
            <LoadingButton type="submit" fullWidth loading={isSubmitting}>
              Save
            </LoadingButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};
