import { MobileTableRow } from 'components';
import { MobileTableV2, MobileTableRowField } from 'components/common/MobileTableV2';
import {
  productivityMetricsTableEmptyModel,
  productivityMetricsTableEmptyTitle,
} from 'components/common/Table/parts/EmptyState/const';
import { useMobileTable } from 'hooks/useMobileTable';
import { MobileTableProps, ProductivityMetricsStats } from 'types';
import { ProposalStatusEnum } from 'types/proposal';
import { getLabelByProposalStatusesEnum } from 'utils/common';
import transformDateToMonthWithYear from 'utils/productivity-metrics/transformDateToMonthWithYear';

export const PMRfpPortfolioAnnualMobileTable = ({
  items,
  loading,
  onClearFilters,
}: MobileTableProps<ProductivityMetricsStats>) => {
  const { selectedRow, toggleSelectedRow } = useMobileTable();

  return (
    <MobileTableV2
      hasData={items.length > 0}
      loading={loading}
      isScrollTop={false}
      handleClearFilters={onClearFilters}
      model={productivityMetricsTableEmptyModel}
      emptyStateTitle={productivityMetricsTableEmptyTitle}
      isNewLayout
      className="mb-24"
    >
      {items.map((item: ProductivityMetricsStats) => {
        const { status, count, percentage } = item;

        return (
          <MobileTableRow
            key={status}
            id={status}
            rowTitle={getLabelByProposalStatusesEnum(status as ProposalStatusEnum)}
            handleToggle={toggleSelectedRow}
            selectedRowInfo={selectedRow}
          >
            <MobileTableRowField label="Number" value={count} />
            <MobileTableRowField label="Percentage" value={`${percentage}%`} />
          </MobileTableRow>
        );
      })}
    </MobileTableV2>
  );
};

export const PMRfpPortfolioMonthlyMobileTable = ({
  items,
  loading,
  onClearFilters,
}: MobileTableProps<ProductivityMetricsStats>) => {
  const { selectedRow, toggleSelectedRow } = useMobileTable();

  return (
    <MobileTableV2
      hasData={items.length > 0}
      loading={loading}
      handleClearFilters={onClearFilters}
      model={productivityMetricsTableEmptyModel}
      emptyStateTitle={productivityMetricsTableEmptyTitle}
      isScrollTop={false}
      isNewLayout
    >
      {items.map((item: ProductivityMetricsStats) => {
        const { month, to_do, in_progress, in_review, pending, in_rework, no_response, rejected, accepted, total } =
          item;

        return (
          <MobileTableRow
            key={month}
            id={month}
            rowTitle={transformDateToMonthWithYear(month)}
            handleToggle={toggleSelectedRow}
            selectedRowInfo={selectedRow}
          >
            <MobileTableRowField label="Total" value={total} />
            <MobileTableRowField label="To Do" value={to_do} />
            <MobileTableRowField label="In Progress" value={in_progress} />
            <MobileTableRowField label="In Review" value={in_review} />
            <MobileTableRowField label="Pending" value={pending} />
            <MobileTableRowField label="In Rework" value={in_rework} />
            <MobileTableRowField label="No Response" value={no_response} />
            <MobileTableRowField label="Rejected" value={rejected} />
            <MobileTableRowField label="Accepted" value={accepted} />
          </MobileTableRow>
        );
      })}
    </MobileTableV2>
  );
};
