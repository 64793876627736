import { IconProps } from 'types';

export const DownloadIcon = ({ className, onClick }: IconProps) => {
  return (
    <svg
      width="48"
      height="48"
      className={className}
      onClick={onClick}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 24.008V42H42V24" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M33 23L24 32L15 23" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M23.992 6V32" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
