export enum PermissionEnum {
  users_access = 'users-access',
  users_create = 'users-create',
  users_activate_deactivate = 'users-activate-deactivate',
  users_view_all = 'users-view-all',
  users_edit_all = 'users-edit-all',
  roles_access = 'roles-access',
  role_create = 'role-create',
  role_edit = 'role-edit',
  role_delete = 'role-delete',
  contacts_access = 'contacts-access',
  contact_create = 'contact-create',
  contact_view_all = 'contact-view-all',
  contact_edit_all = 'contact-edit-all',
  contact_filter_by_user = 'contact-filter-by-user',
  contact_filter_by_bank = 'contact-filter-by-bank',
  contact_filter_by_3rd_party = 'contact-filter-by-3rd-party',
  contact_delete_all = 'contact-delete-all',
  inspection_access = 'inspection-access',
  inspection_create = 'inspection-create',
  inspection_edit_all = 'inspection-edit-all',
  inspection_edit_assigned = 'inspection-edit-assigned',
  inspection_filter_by_inspector = 'inspection-filter-by-inspector',
  inspection_filter_by_status = 'inspection-filter-by-status',
  inspection_filter_by_project = 'inspection-filter-by-project',
  inspection_filter_by_location = 'inspection-filter-by-location',
  inspection_filter_by_dates = 'inspection-filter-by-dates',
  inspection_delete_all = 'inspection-delete-all',
  project_access = 'project-access',
  project_create = 'project-create',
  project_edit_all = 'project-edit-all',
  project_edit_assigned = 'project-edit-assigned',
  project_delete_all = 'project-delete-all',
  project_filter_by_assignee = 'project-filter-by-assignee',
  project_filter_by_owner = 'project-filter-by-owner',
  project_filter_by_bank = 'project-filter-by-bank',
  project_filter_by_status = 'project-filter-by-status',
  project_filter_by_location = 'project-filter-by-location',
  note_access = 'note-access',
  note_view_all = 'note-view-all',
  note_edit_all = 'note-edit-all',
  note_delete_all = 'note-delete-all',
  note_filter_by_project = 'note-filter-by-project',
  note_filter_by_date = 'note-filter-by-date',
  note_filter_by_author = 'note-filter-by-author',
  company_management_access = 'company-management-access',
  company_send_invitation = 'company-send-invitation',
  documents_access = 'documents-access',
  documents_view = 'documents-view',
  organization_access = 'organization-access',
  organization_create = 'organization-create',
  work_order_update_location = 'work-order-update-location',
  work_order_filter_by_assignee = 'work-order-filter-by-assignee',
  work_order_filter_by_type = 'work-order-filter-by-type',
  work_order_filter_by_project = 'work-order-filter-by-project',
  work_order_filter_by_location = 'work-order-filter-by-location',
  work_order_access = 'work-order-access',
  work_order_create = 'work-order-create',
  work_order_view_all = 'work-order-view-all',
  work_order_edit_all = 'work-order-edit-all',
  work_order_delete_all = 'work-order-delete-all',
  work_order_edit_assigned = 'work-order-edit-assigned',
  settings_access = 'settings-access',
  settings_view_all = 'settings-view-all',
  settings_edit_all = 'settings-edit-all',
  productivity_metrics_access = 'productivity-metrics-access',
  productivity_metrics_view_analytics_page_all_stats = 'productivity-metrics-view-analytics-page-all-stats',
  productivity_metrics_see_link_on_top_of_their_name_no_stats = 'productivity-metrics-see-link-on-top-of-their-name-no-stats',
  productivity_metrics_filter_by_inspectors = 'productivity-metrics-filter-by-inspectors',
  productivity_metrics_filter_by_work_order_type = 'productivity-metrics-filter-by-work-order-type',
  productivity_metrics_filter_by_users = 'productivity-metrics-filter-by-users',
  productivity_metrics_widget_stats = 'productivity-metrics-widget-stats',
  productivity_metrics_widget_reports_submitted_access = 'productivity-metrics-widget-reports-submitted-access',
  productivity_metrics_widget_reports_by_state_access = 'productivity-metrics-widget-reports-by-state-access',
  productivity_metrics_filter_by_date = 'productivity-metrics-filter-by-date',
}
