import clsx from 'clsx';

import { CheckedIcon } from 'icons';

import styles from './Notification.module.scss';

interface NotificationStateInterface {
  text: string;
}

export const Notification = ({ text }: NotificationStateInterface) => {
  return (
    <div className="flex flex-column w-100 justify-content-center align-items-center p-24">
      <CheckedIcon className={styles.icon} />

      <p className={clsx('text-18  text-center', styles.text)}>{text}</p>
    </div>
  );
};
