export enum ProposalStatusEnum {
  ToDo = 'to_do',
  InProgress = 'in_progress',
  InReview = 'in_review',
  Pending = 'pending',
  InRework = 'in_rework',
  NoResponse = 'no_response',
  Awarded = 'awarded',
  Rejected = 'rejected',
  InWork = 'in_work',
  Completed = 'completed',
}
