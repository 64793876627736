import { IconProps } from 'types';

export const ArrowDownIcon = ({ className, width, height, onClick }: IconProps) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width={width || '48'}
      height={height || '48'}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M37 18L25 30L13 18" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
