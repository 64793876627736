import { FC } from 'react';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import styles from './CircularProgressBar.module.scss';

interface CircularProgressBarProps extends CircularProgressProps {
  value: number;
}

const useStylesFacebook = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
    },
    bottom: {
      opacity: 0.2,
    },
    top: {
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },
    circle: {
      strokeLinecap: 'square',
    },
  }),
);

export const CircularProgressBar: FC<CircularProgressBarProps> = ({ value, ...props }) => {
  const getProgressColor = (value: number) => {
    if (value <= 79) return '#212B83';
    return '#009933';
  };

  const classes = useStylesFacebook();

  return (
    <>
      <CircularProgress
        variant="determinate"
        {...props}
        className={classes.bottom}
        size={82}
        thickness={7}
        value={100}
        {...props}
        style={{ color: getProgressColor(value) }}
      />
      <CircularProgress
        variant="determinate"
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        style={{ color: getProgressColor(value) }}
        size={82}
        thickness={7}
        value={value}
        {...props}
      />
      <div className="p-relative w-100 h-100">
        <span style={{ color: getProgressColor(value) }} className={styles.text}>
          {value}%
        </span>
      </div>
    </>
  );
};
