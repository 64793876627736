import { ReportTypesEnum, WorkOrderTypeEnum } from 'types';

export const getWorkOrderTypeColor = (type: string) => {
  if (type === WorkOrderTypeEnum.PreConstructionReview) return '#009933';
  else if (type === WorkOrderTypeEnum.InspectionReport) return '#565EA1';
  else if (type === WorkOrderTypeEnum.Pca) return '#C24600';
  else if (type === WorkOrderTypeEnum.ContractorCapabilityEvaluation) return '#2F80ED';
  return '#710099';
};

export const getWorkOrderTypeBgColor = (type: string) => {
  if (type === WorkOrderTypeEnum.PreConstructionReview) return 'rgba(0, 153, 51, 0.04)';
  else if (type === WorkOrderTypeEnum.InspectionReport) return '#F1F3F4';
  else if (type === WorkOrderTypeEnum.Pca) return 'rgba(194, 70, 0, 0.04)';
  else if (type === WorkOrderTypeEnum.ContractorCapabilityEvaluation) return 'rgba(47, 128, 237, 0.04)';
  return 'rgba(113, 0, 153, 0.04)';
};

export const getReportTypeBgColor = (type: ReportTypesEnum) => {
  switch (type) {
    case ReportTypesEnum.PreConstruction:
      return 'rgba(0, 153, 51, 0.04)';
    case ReportTypesEnum.Inspection:
      return 'rgba(113, 0, 153, 0.04)';
  }
};

export const getReportTypeColor = (type: ReportTypesEnum) => {
  switch (type) {
    case ReportTypesEnum.PreConstruction:
      return '#009933';
    case ReportTypesEnum.Inspection:
      return '#710099';
  }
};
