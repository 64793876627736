import { IconProps } from 'types';

export const ArrowUpIcon = ({ className, onClick }: IconProps) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 7L3.5 1C3.5 0.725 3.725 0.5 4 0.5C4.275 0.5 4.5 0.725 4.5 1V7C4.5 7.275 4.275 7.5 4 7.5C3.725 7.5 3.5 7.275 3.5 7Z"
        fill="white"
      />
      <path
        d="M0.5 4C0.5 3.875 0.55 3.75 0.65 3.65L3.65 0.65C3.85 0.45 4.15 0.45 4.35 0.65L7.35 3.65C7.55 3.85 7.55 4.15 7.35 4.35C7.15 4.55 6.85 4.55 6.65 4.35L4 1.7L1.35 4.35C1.15 4.55 0.849999 4.55 0.65 4.35C0.55 4.25 0.5 4.125 0.5 4Z"
        fill="white"
      />
    </svg>
  );
};
