import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { toEnUsFormat } from 'utils';
import { RouteProps } from 'types';
import { components } from 'generated/types';
import { getFormattedFieldValue } from 'utils/audit-trail/getFormattedFieldValue';
import { DateType } from 'types/dateType';

import styles from './AuditTrailItem.module.scss';

type User = components['schemas']['User'];

type AuditTrailItemProps = {
  auditTrailId?: number;
  title?: string;
  createdAt?: string;
  updatedAt?: string;
  text?: string;
  projectName?: string;
  user?: User;
};

export const AuditTrailItem = ({
  route,
  title,
  projectName,
  text,
  createdAt,
  reportChangedField,
  reportOldValue,
  reportNewValue,
  dataType,
}: RouteProps & AuditTrailItemProps) => {
  const getFieldValue = getFormattedFieldValue(dataType as DateType);
  return (
    <>
      {route ? (
        <div className={styles.noteOverviewContainer}>
          <div className="flex justify-content-between">
            <h2 className={styles.projectInfo}>-{projectName}</h2>
            <span className={styles.projectInfo}>{toEnUsFormat(createdAt)}</span>
          </div>
          <NavLink to={route}>
            <h1 className={styles.title}>{title}</h1>
          </NavLink>
          <div className={clsx('mt-8', styles.noteText)}>
            <strong>{reportChangedField}</strong> {getFieldValue(reportOldValue)}
            {` —> `}
            {getFieldValue(reportNewValue)}
          </div>
          <div className={styles.textContainer}>
            <div className={clsx('mt-8', styles.noteDescription)}>{text}</div>
          </div>
        </div>
      ) : (
        <div className={styles.noteOverviewContainer}>
          <div className="flex justify-content-between">
            <h2 className={styles.projectInfo}>-{projectName}</h2>
            <span className={styles.projectInfo}>{toEnUsFormat(createdAt)}</span>
          </div>
          <h1 className={styles.title}>{title}</h1>
          <div className={styles.textContainer}>
            <div className="mt-8">{text}</div>
          </div>
        </div>
      )}
    </>
  );
};
