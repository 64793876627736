import { TextField } from 'formik-material-ui';
import { Field } from 'formik';
import { CustomDatePicker, Autocomplete } from 'components/common';

import { stampedAndSigned, permittingStatus, defaultFieldProps, YesNoNaOptions } from '../const';

export const ArchitecturalPlansFields = () => {
  return (
    <>
      <Field component={TextField} name="architect" label="Architect" className="mb-24" />
      <Field
        component={CustomDatePicker}
        name="dateLastRevision"
        label="What is the date/last revision of the plans?"
        removeTimezone
        {...defaultFieldProps}
      />
      <Field
        component={Autocomplete}
        name="stampedAndSigned"
        placeholder="Are the plans stamped and signed by the Architect?"
        options={stampedAndSigned}
        {...defaultFieldProps}
      />
      <Field
        component={Autocomplete}
        name="approvalStamps"
        placeholder="Do the plans include approval stamps from local jurisdictions?"
        options={YesNoNaOptions}
        {...defaultFieldProps}
      />
      <Field
        component={Autocomplete}
        name="permittingStatus"
        placeholder="What is the permitting status of the plans?"
        options={permittingStatus}
        {...defaultFieldProps}
      />
      <Field
        component={Autocomplete}
        name="sufficient"
        placeholder="Do the plans include typical design information and appear sufficient for construction?"
        options={YesNoNaOptions}
        {...defaultFieldProps}
      />
    </>
  );
};
