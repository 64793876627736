export const PROJECT_DOCUMENTS_QUERY_KEY = 'projectDocumentsQuery';

export const queriesKeys = {
  proposalRequests: 'proposalRequestsQuery',
  proposalRequestById: 'proposalRequestByIdQuery',
};

export const mutationsKeys = {
  deleteProposalRequest: 'deleteProposalRequestMutation',
  editProposalRequest: 'editProposalRequestMutation',
  createProposalRequest: 'createProposalRequestMutation',
};
