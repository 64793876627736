import { IconProps } from 'types';

export const SubmitGreenIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="7.4" stroke="#009933" strokeWidth="1.2" />
      <path
        d="M6.75 11C6.625 11 6.5 10.95 6.4 10.85L3.9 8.35C3.7 8.15 3.7 7.85 3.9 7.65C4.1 7.45 4.4 7.45 4.6 7.65L6.75 9.8L11.4 5.15C11.6 4.95 11.9 4.95 12.1 5.15C12.3 5.35 12.3 5.65 12.1 5.85L7.1 10.85C7 10.95 6.875 11 6.75 11Z"
        fill="#009933"
      />
    </svg>
  );
};
