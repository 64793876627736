import { IconProps } from 'types';

export const Dislike = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99999 31H6.19999C5.59999 31 5.09999 30.9 4.59999 30.7C4.09999 30.5 3.59999 30.2 3.29999 29.7C2.89999 29.3 2.59999 28.8 2.49999 28.3C2.29999 27.8 2.29999 27.2 2.29999 26.7L3.99999 6.7C4.09999 5.7 4.49999 4.8 5.29999 4.1C5.89999 3.4 6.79999 3 7.79999 3H9.99999C11.1 3 12.1 3.4 12.8 4.2C13.6 4.9 14 5.9 14 7V27C14 28.1 13.6 29.1 12.8 29.8C12.1 30.6 11.1 31 9.99999 31ZM7.79999 7L6.19999 27H9.99999V7H7.79999ZM27.9 45C27.7 45 27.5 45 27.3 44.9C26.1 44.7 25.3 43.7 25.1 42.5C25.1 42.3 25.1 42.1 25.1 41.9V41.8C25 39.3 24.5 35.8 23.4 34C22.7 32.8 20.9 31.4 18.5 30.3C17 29.7 16 28.2 16 26.6V7C16 5.9 16.4 4.9 17.2 4.2C17.9 3.4 18.9 3 20 3H37C37.9 3 38.7 3.3 39.4 3.8C40.1 4.3 40.6 5.1 40.8 5.9L46.2 24.9C46.4 25.5 46.4 26.1 46.3 26.7C46.2 27.3 45.9 27.9 45.6 28.4C45.2 28.9 44.7 29.3 44.2 29.6C43.6 29.9 43 30 42.4 30H34.2L34.3 30.4C34.8 31.9 35.2 33.4 35.3 35.1C35.4 37.3 35.1 39.2 34.2 40.6C33.1 42.5 30.2 45 27.9 45ZM37 7H20V26.6C20 26.6 20 26.7 20.1 26.7C22.3 27.7 25.2 29.5 26.7 31.9C28.2 34.4 28.7 38.2 28.9 40.3C29.6 39.8 30.3 39.1 30.8 38.4C31.2 37.7 31.4 36.6 31.3 35.2C31.2 34 30.9 32.8 30.5 31.5L29.6 28.5C29.4 27.9 29.5 27.2 29.9 26.7C30.3 26.2 30.9 25.9 31.5 25.9H42.4L37 7Z"
        fill="black"
      />
    </svg>
  );
};
