import { IconProps } from 'types';

export const Like = ({ className, onClick }: IconProps) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99998 45H7.79998C6.79998 45 5.79998 44.5999 5.09998 44C4.39998 43.2999 3.89998 42.4 3.79998 41.4L2.09998 22.3999C2.09998 21.7999 2.09998 21.2999 2.29998 20.7999C2.49998 20.2999 2.79998 19.7999 3.09998 19.3999C3.49998 18.9999 3.89998 18.6999 4.39998 18.3999C5.09998 18.0999 5.59998 17.9999 6.19998 17.9999H9.99998C11.1 17.9999 12.1 18.3999 12.8 19.1999C13.6 19.9999 14 20.9999 14 21.9999V41C14 42.0999 13.6 43.0999 12.8 43.7999C12.1 44.5999 11.1 45 9.99998 45ZM6.19998 21.9999L7.89998 41H9.99998V21.9999H6.19998ZM37 45H20C18.9 45 17.9 44.5999 17.2 43.7999C16.4 43.0999 16 42.0999 16 41V21.3999C16 19.7999 17 18.3 18.4 17.7C20.8 16.5999 22.5 15.1999 23.3 13.9999C24.4 12.1999 24.9 8.59995 25 6.19995V5.99995C25 5.79995 25 5.59995 25 5.49995C25.2 4.19995 26 3.29995 27.2 3.09995C29.7 2.59995 32.9 5.39995 34.2 7.49995C35.1 8.89995 35.4 10.7999 35.3 12.9999C35.2 14.5999 34.7 16.0999 34.3 17.5999L34.2 17.9999H42.4C43 17.9999 43.6 18.0999 44.2 18.3999C44.7 18.6999 45.2 19.0999 45.6 19.5999C46 20.0999 46.2 20.6999 46.3 21.2999C46.4 21.8999 46.4 22.4999 46.2 23.0999L40.8 42.0999C40.6 42.8999 40.1 43.7 39.4 44.2C38.7 44.7 37.9 45 37 45ZM28.9 7.59995C28.7 9.79995 28.2 13.5999 26.7 15.9999C25.2 18.4999 22.3 20.1999 20.1 21.1999C20 21.1999 20 21.2999 20 21.2999V41H37L42.4 21.9999H31.5C30.9 21.9999 30.3 21.6999 29.9 21.1999C29.5 20.6999 29.4 19.9999 29.6 19.3999L30.5 16.4999C30.9 15.1999 31.3 13.8999 31.3 12.6999C31.4 11.2999 31.2 10.1999 30.8 9.49995C30.3 8.69995 29.6 7.99995 28.9 7.59995Z"
        fill="black"
      />
    </svg>
  );
};
