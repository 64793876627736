import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const ProjectContractRoute = Route(
  {
    id: Route.params.number.optional,
  },
  ({ id }) => `/project/${id}/schedule`,
);

export const ProjectContract = ProjectContractRoute(lazy(() => import('./Contract')));
