import { object, string } from 'yup';

export const proposalFormValidationSchema = object().shape({
  name: string().required('Proposal request name is a required field'),
  status: object()
    .shape({
      name: string().required('Status is a required field'),
    })
    .typeError('Status is a required field'),
  // projectId: object()
  //   .shape({
  //     id: string().required('Project is a required field'),
  //     name: string().required('Project is a required field'),
  //   })
  //   .typeError('Related project is a required field'),
});
