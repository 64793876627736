import React, { FC } from 'react';
import clsx from 'clsx';

import styles from './Tabs.module.scss';

type TabsProps = {
  className?: string;
  children: React.ReactNode;
};

export const Tabs: FC<TabsProps> = ({ children, className }) => {
  return <div className={clsx(styles.tabs, className)}>{children}</div>;
};
