import React from 'react';
import IconButton from '@material-ui/core/IconButton';

import { EyeIcon, EyeIconClosed } from 'icons';

import styles from './PassVisibilityBtn.module.scss';

interface PassVisibilityBtnProps {
  togglePasswordVisibility: () => void;
  isPassVisible: boolean;
  className?: string;
}

export const PassVisibilityBtn = ({ isPassVisible, togglePasswordVisibility, className }: PassVisibilityBtnProps) => {
  return (
    <IconButton size="small" onClick={togglePasswordVisibility} className={className}>
      {isPassVisible ? <EyeIconClosed className={styles.EyeIconIconActive} /> : <EyeIcon />}
    </IconButton>
  );
};

export const PassVisibilityDescriptionBtn = ({
  isPassVisible,
  togglePasswordVisibility,
  className,
}: PassVisibilityBtnProps) => {
  return (
    <IconButton size="small" onClick={togglePasswordVisibility} className={className}>
      {isPassVisible ? <EyeIcon /> : <EyeIconClosed className={styles.PassVisibilityDescriptionBtn} />}
    </IconButton>
  );
};
