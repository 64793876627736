import React from 'react';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';

import { DropdownOption } from 'types';

export const dropdownItemCallback = <T extends DropdownOption>({ id, name }: T) => (
  <MenuItem key={id} value={id}>
    {name}
  </MenuItem>
);
