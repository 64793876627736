import styles from './LinearStyles.module.scss';

const getProgressColor = (value: number) => {
  if (value <= 50) return '#CC0000';
  else if (value === 100) return '#009933';
  return '#212B83';
};

export const LinearProgressWithLabel = ({ value, vertical }: { value: number; vertical: boolean }) => {
  const color = getProgressColor(value);

  return (
    <>
      {vertical ? (
        <div className="flex justify-content-end">
          <span style={{ color }}>{value}%</span>
          <div className={styles.linearProgressVertical}>
            <div className={styles.progress} style={{ height: `${value}%`, background: color }}></div>
          </div>
        </div>
      ) : (
        <div className="flex mt-8 mb-16 flex-column">
          <span style={{ color }}>{value}% completed</span>
          <div className={styles.linearProgressHorizontal}>
            <div className={styles.progress} style={{ width: `${value}%`, background: color }}></div>
          </div>
        </div>
      )}
    </>
  );
};
