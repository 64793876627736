import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import Chip from '@material-ui/core/Chip';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import { ArchivedProjectsRoute, ProjectsRoute } from 'pages';
import { Country, DropdownOption, GetRouteProps, GridApiResponse, PermissionEnum, SortOptionEnum } from 'types';
import { useAuth } from 'contexts';
import { CustomDialog, SearchSelect } from 'components';
import { ArchiveIcon, CloseIcon, FilterIcon, SortIcon } from 'icons';
import { getFullName, getName, toDropdownObject } from 'utils';
import { links } from 'App';
import { api, apiRoutes } from 'api';
import { components } from 'generated/types';
import { emptyTableData } from 'consts';

import styles from './ProjectFilter.module.scss';

type User = components['schemas']['User'];
type Contact = components['schemas']['Contact'];
type Organization = components['schemas']['Organization'];

enum StatusEnum {
  All = 'all',
  Active = 'active',
  Inactive = 'inactive',
  Waiting = 'waiting',
}

type ProjectFiltersProps = Pick<
  GetRouteProps<typeof ProjectsRoute | typeof ArchivedProjectsRoute>,
  'match' | 'link'
> & {
  archivePage?: boolean;
};

type FilterTabsProps<T> = {
  label: T;
};

export const ProjectFilters: FC<ProjectFiltersProps> = ({
  match: {
    query: { status = StatusEnum.Active, owner, name, bank, bankContact, type, developer, contractor },
    query,
  },
  link,
  archivePage,
}) => {
  const { push } = useHistory();
  const { userData } = useAuth();

  const [openProjectsMobileFilter, setOpenProjectsMobileFilter] = useState(false);
  const [openProjectsMobileSort, setOpenProjectsMobileSort] = useState(false);

  type QueryKeys = keyof typeof query;

  const usersQuery = () => api.get<GridApiResponse<User>>(apiRoutes.users).then((res) => res.data);
  const { isLoading: usersLoading, data: users } = useQuery('usersQuery', () => usersQuery());

  const contactsQuery = () =>
    api
      .get<Contact[]>(apiRoutes.contacts, {
        params: {
          'sort[firstName]': SortOptionEnum.ASC,
          'filter[companyType]': 'bank',
          'filter[_dropdown]': true,
        },
      })
      .then((res) => res.data);

  const { isLoading: bankContactLoading, data: bankContacts } = useQuery(['contactsQuery', query], () =>
    contactsQuery(),
  );

  const developersQuery = () =>
    api
      .get<Organization[]>(apiRoutes.organization, {
        params: {
          'sort[name]': SortOptionEnum.ASC,
          'filter[typeId]': 'developer',
          'filter[_dropdown]': true,
        },
      })
      .then((res) => res.data);

  const { isLoading: developersLoading, data: developers } = useQuery(['developersQuery', query], () =>
    developersQuery(),
  );

  const contractorsQuery = () =>
    api
      .get<Organization[]>(apiRoutes.organization, {
        params: {
          'sort[name]': SortOptionEnum.ASC,
          'filter[typeId]': 'general-contractor',
          'filter[_dropdown]': true,
        },
      })
      .then((res) => res.data);

  const { isLoading: contractorsLoading, data: contractors } = useQuery(['contractorsQuery', query], () =>
    contractorsQuery(),
  );

  const organizationsQuery = () =>
    api
      .get<Organization[]>(apiRoutes.organization, {
        params: {
          'sort[name]': SortOptionEnum.ASC,
          'filter[typeId]': 'bank',
          'filter[_dropdown]': true,
        },
      })
      .then((res) => res.data);
  const { data: organizations, isLoading: organizationsLoading } = useQuery('organizationsQuery', () =>
    organizationsQuery(),
  );

  const projectTypesQuery = () => api.get<DropdownOption[]>(apiRoutes.projectTypes).then((res) => res.data);
  const { data: types, isLoading: typesLoading } = useQuery('projectTypesQuery', () => projectTypesQuery());

  const statesQuery = () =>
    axios
      .get(
        'https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-united-states-of-america-state-millesime&q=&sort=ste_name&rows=100&facet=year&facet=ste_code&facet=ste_name&facet=ste_type&refine.year=2022',
      )
      .then((res) => res.data);
  const { data: states, isLoading: statesLoading } = useQuery('statesQuery', () => statesQuery());

  const mappedStates = useMemo(() => {
    if (!states?.records?.length) return [];

    //eslint-disable-next-line
    return states?.records.map((el: any, i: number) => ({ ...el, name: el.fields['ste_name'], id: i }));
  }, [states]);

  const handleOpenProjectsFilter = () => setOpenProjectsMobileFilter(true);
  const handleCloseProjectsFilter = () => setOpenProjectsMobileFilter(false);

  const handleOpenProjectsSort = () => setOpenProjectsMobileSort(true);
  const handleCloseProjectsSort = () => setOpenProjectsMobileSort(false);

  const handleQueryParamsChange = (key: QueryKeys, value: string) =>
    //eslint-disable-next-line
    push(link({ ...(({ page, ...o }) => o)({ ...query, [key]: value }) }));

  //eslint-disable-next-line
  const handleFilterOwnerRemove = () => push(link({ ...(({ owner, page, ...o }) => o)({ ...query }) }));
  //eslint-disable-next-line
  const handleFilterStateRemove = () => push(link({ ...(({ name, page, ...o }) => o)({ ...query }) }));
  //eslint-disable-next-line
  const handleFilterBankRemove = () => push(link({ ...(({ bank, page, ...o }) => o)({ ...query }) }));
  //eslint-disable-next-line
  const handleBankContactRemove = () => push(link({ ...(({ bankContact, page, ...o }) => o)({ ...query }) }));
  //eslint-disable-next-line
  const handleDeveloperRemove = () => push(link({ ...(({ developer, page, ...o }) => o)({ ...query }) }));
  //eslint-disable-next-line
  const handleContractorRemove = () => push(link({ ...(({ contractor, page, ...o }) => o)({ ...query }) }));
  //eslint-disable-next-line
  const handleTypeRemove = () => push(link({ ...(({ type, page, ...o }) => o)({ ...query }) }));

  const handleSortOptionChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    handleQueryParamsChange('status', value);
    handleCloseProjectsSort();
  };

  const handleClear = () => {
    push(link());
  };

  const FilteredTab = ({ label }: FilterTabsProps<StatusEnum>) => {
    return (
      <Chip
        {...(status !== label && { variant: 'outlined' })}
        onClick={() => handleQueryParamsChange('status', label)}
        color="primary"
        className="capitalize mr-12 mb-12"
        label={label}
      />
    );
  };

  const filteredUsers = useMemo(() => (users?.items?.length ? users : emptyTableData), [users]);
  const filteredBankContact = useMemo(() => (bankContacts?.length ? bankContacts : []), [bankContacts]);
  const filteredProjectTypes = useMemo(() => (types?.length ? types : []), [types]);
  const filteredOrganizations = useMemo(() => (organizations?.length ? organizations : []), [organizations]);
  const filteredContractors = useMemo(() => (contractors?.length ? contractors : []), [contractors]);
  const filteredDevelopers = useMemo(() => (developers?.length ? developers : []), [developers]);
  const { items: usersItems } = filteredUsers;

  const isFiltersApplied = owner || name || bank || bankContact || type || developer || contractor;
  const isSortingApplied = status !== StatusEnum.All;

  return (
    <>
      <div className={clsx('px-16 px-lg-32 mb-0 mb-sm-24', styles.borderBottom)}>
        <div className="flex flex-wrap justify-content-between align-items-center my-sm-12">
          <div className="d-none d-sm-flex flex-wrap">
            {userData?.permissions.has(PermissionEnum.project_filter_by_owner) && (
              <div className="mb-12 mr-24">
                <SearchSelect
                  label="Owner"
                  options={toDropdownObject(
                    (usersItems as User[]).map((item) => ({
                      ...item,
                      name: getFullName(item),
                    })),
                  )}
                  loading={usersLoading}
                  onChange={(value) => handleQueryParamsChange('owner', value)}
                />
              </div>
            )}

            <div className="mb-12 mr-24">
              <SearchSelect
                label="Type"
                loading={typesLoading}
                options={toDropdownObject(filteredProjectTypes)}
                onChange={(value) => handleQueryParamsChange('type', value)}
              />
            </div>

            {userData?.permissions.has(PermissionEnum.project_filter_by_bank) && (
              <div className="mb-12 mr-24">
                <SearchSelect
                  label="Bank"
                  loading={organizationsLoading}
                  options={toDropdownObject(filteredOrganizations)}
                  onChange={(value) => handleQueryParamsChange('bank', value)}
                />
              </div>
            )}

            <div className="mb-12 mr-24">
              <SearchSelect
                label="Bank contact"
                options={toDropdownObject(
                  filteredBankContact?.map((user: Contact) => ({
                    ...user,
                    name: getFullName(user),
                  })),
                )}
                loading={bankContactLoading}
                onChange={(value) => handleQueryParamsChange('bankContact', value)}
              />
            </div>

            <div className="mb-12 mr-24">
              <SearchSelect
                label="Developer"
                loading={developersLoading}
                options={toDropdownObject(filteredDevelopers)}
                onChange={(value) => handleQueryParamsChange('developer', value)}
              />
            </div>

            <div className="mb-12 mr-24">
              <SearchSelect
                label="Contractor"
                loading={contractorsLoading}
                options={toDropdownObject(filteredContractors)}
                onChange={(value) => handleQueryParamsChange('contractor', value)}
              />
            </div>

            <div className="mb-12 mr-24">
              <SearchSelect
                label="State"
                options={toDropdownObject(
                  (mappedStates as Country[]).map((state) => ({
                    ...state,
                    label: state.id,
                    value: state.name,
                  })),
                )}
                loading={statesLoading}
                onChange={(_, name) => handleQueryParamsChange('name', name as string)}
              />
            </div>
          </div>

          <div
            className="text-smallTextGrey align-items-center d-sm-none py-12 c-pointer"
            onClick={handleOpenProjectsFilter}
          >
            <FilterIcon className="mr-4" />
            Filter
            {isFiltersApplied && <span className="sortingCountContainer" />}
          </div>

          {!archivePage && (
            <>
              <div className="d-none d-sm-flex flex-wrap justify-content-between align-items-center">
                {userData?.permissions.has(PermissionEnum.project_filter_by_status) && (
                  <div className="d-block d-sm-flex mr-12 align-items-center">
                    <p className="text-smallTextGrey align-items-center mb-12 mr-16">Show: </p>
                    <div>
                      <FilteredTab label={StatusEnum.Active} />
                      <FilteredTab label={StatusEnum.Inactive} />
                      <FilteredTab label={StatusEnum.Waiting} />
                      <FilteredTab label={StatusEnum.All} />
                    </div>
                    <NavLink
                      to={links.ArchivedProjects()}
                      className="mb-0 mb-sm-12 ml-0 ml-sm-12 uppercase text-primary flex align-items-center weight-700 text-14"
                    >
                      <ArchiveIcon className={styles.archiveIcon} />
                      Archive
                    </NavLink>
                  </div>
                )}
              </div>

              <NavLink
                to={links.ArchivedProjects()}
                className="mb-0 mb-sm-12 ml-0 ml-sm-12 uppercase text-primary weight-700 text-14 d-flex align-items-center d-sm-none"
              >
                <ArchiveIcon className={styles.archiveIcon} />
                Archive
              </NavLink>

              <div
                className="text-smallTextGrey align-items-center d-sm-none py-12 c-pointer"
                onClick={handleOpenProjectsSort}
              >
                <SortIcon className="mr-4" />
                Show me
                {isSortingApplied && <span className="sortingCountContainer" />}
              </div>
            </>
          )}
        </div>

        <div className="d-none d-sm-flex align-items-center flex-wrap">
          {owner && (
            <Chip
              color="primary"
              label={`Owner: ${getFullName(usersItems.find(({ id }) => id === +owner))}`}
              deleteIcon={<CloseIcon className={styles.closeIcon} />}
              onDelete={handleFilterOwnerRemove}
              className="mr-12 mb-10"
            />
          )}

          {type && (
            <Chip
              color="primary"
              label={`Type: ${filteredProjectTypes.find(({ id }) => id === +type)?.name}`}
              deleteIcon={<CloseIcon className={styles.closeIcon} />}
              onDelete={handleTypeRemove}
              className="mr-12 mb-10"
            />
          )}

          {bank && (
            <Chip
              color="primary"
              label={`Bank: ${organizations && organizations.find(({ id }) => id === +bank)?.name}`}
              deleteIcon={<CloseIcon className={styles.removeFilterIcon} />}
              onDelete={handleFilterBankRemove}
              className="mr-12 mb-10"
            />
          )}

          {bankContact && (
            <Chip
              color="primary"
              label={`Bank contact: ${getFullName(filteredBankContact?.find(({ id }) => id === +bankContact))}`}
              deleteIcon={<CloseIcon className={styles.removeFilterIcon} />}
              onDelete={handleBankContactRemove}
              className="mr-12 mb-10"
            />
          )}

          {developer && (
            <Chip
              color="primary"
              label={`Developer: ${getName(filteredDevelopers.find(({ id }) => id === +developer))}`}
              deleteIcon={<CloseIcon className={styles.closeIcon} />}
              onDelete={handleDeveloperRemove}
              className="mr-12 mb-10"
            />
          )}

          {contractor && (
            <Chip
              color="primary"
              label={`General contractor: ${getName(filteredContractors.find(({ id }) => id === +contractor))}`}
              deleteIcon={<CloseIcon className={styles.closeIcon} />}
              onDelete={handleContractorRemove}
              className="mr-12 mb-10"
            />
          )}

          {name && (
            <Chip
              color="primary"
              label={`State: ${name}`}
              deleteIcon={<CloseIcon className={styles.closeIcon} />}
              onDelete={handleFilterStateRemove}
              className="mr-12 mb-10"
            />
          )}
        </div>
      </div>

      {!archivePage && userData?.permissions.has(PermissionEnum.project_filter_by_status) && (
        <Drawer anchor="bottom" open={openProjectsMobileSort} onClose={handleCloseProjectsSort} className="d-sm-none">
          <h3 className="weight-700 mb-20">Show me</h3>

          <RadioGroup aria-label="gender" name="gender1" value={status} onChange={handleSortOptionChange}>
            <FormControlLabel value={StatusEnum.Active} control={<Radio />} label="Active" />
            <FormControlLabel value={StatusEnum.Inactive} control={<Radio />} label="Inactive" />
            <FormControlLabel value={StatusEnum.Waiting} control={<Radio />} label="Waiting" />
            <FormControlLabel value={StatusEnum.All} control={<Radio />} label="All" />
          </RadioGroup>
        </Drawer>
      )}

      <CustomDialog
        fullHeight
        open={openProjectsMobileFilter}
        header="Filter"
        onClose={handleCloseProjectsFilter}
        className="d-sm-none"
      >
        <div className="px-16 flex flex-column h-100">
          <div className="flex flex-column flex-1">
            <div className="flex-1">
              <div className="mb-24">
                <SearchSelect
                  fullWidth
                  label="Owner"
                  options={toDropdownObject(
                    (usersItems as User[]).map((user) => ({
                      ...user,
                      name: getFullName(user),
                    })),
                  )}
                  loading={usersLoading}
                  onChange={(value) => handleQueryParamsChange('owner', value)}
                />
              </div>
              <div className="mb-24">
                <SearchSelect
                  label="Type"
                  fullWidth
                  loading={typesLoading}
                  options={toDropdownObject(filteredProjectTypes)}
                  onChange={(value) => handleQueryParamsChange('type', value)}
                />
              </div>
              {userData?.permissions.has(PermissionEnum.project_filter_by_bank) && (
                <div className="mb-24">
                  <SearchSelect
                    fullWidth
                    label="Bank"
                    loading={organizationsLoading}
                    options={toDropdownObject(filteredOrganizations)}
                    onChange={(value) => handleQueryParamsChange('bank', value)}
                  />
                </div>
              )}
              <div className="mb-24">
                <SearchSelect
                  fullWidth
                  label="Bank contact"
                  options={toDropdownObject(
                    filteredBankContact?.map((user: Contact) => ({
                      ...user,
                      name: getFullName(user),
                    })),
                  )}
                  loading={bankContactLoading}
                  onChange={(value) => handleQueryParamsChange('bankContact', value)}
                />
              </div>
              <div className="mb-24">
                <SearchSelect
                  fullWidth
                  label="Developer"
                  loading={developersLoading}
                  options={toDropdownObject(filteredDevelopers)}
                  onChange={(value) => handleQueryParamsChange('developer', value)}
                />
              </div>
              <div className="mb-24">
                <SearchSelect
                  fullWidth
                  label="General Contractor"
                  loading={contractorsLoading}
                  options={toDropdownObject(filteredContractors)}
                  onChange={(value) => handleQueryParamsChange('contractor', value)}
                />
              </div>
              <div className="mb-24">
                <SearchSelect
                  fullWidth
                  label="State"
                  options={toDropdownObject(
                    (mappedStates as Country[]).map((state) => ({
                      ...state,
                      label: state.id,
                      value: state.name,
                    })),
                  )}
                  loading={statesLoading}
                  onChange={(_, name) => handleQueryParamsChange('name', name as string)}
                />
              </div>
            </div>

            {owner && (
              <div className="flex text-primary align-items-center justify-content-between mb-12">
                {`Owner: ${getFullName(usersItems.find(({ id }) => id === +owner))}`}
                <CloseIcon className={styles.removeFilterIcon} onClick={handleFilterOwnerRemove} />
              </div>
            )}

            {type && (
              <div className="flex text-primary align-items-center justify-content-between mb-12">
                {`Owner: ${filteredProjectTypes.find(({ id }) => id === +type)?.name}`}
                <CloseIcon className={styles.removeFilterIcon} onClick={handleTypeRemove} />
              </div>
            )}

            {bank && (
              <div className="flex text-primary align-items-center justify-content-between mb-12">
                {`Bank: ${organizations && organizations.find(({ id }) => id === +bank)?.name}`}
                <CloseIcon className={styles.removeFilterIcon} onClick={handleFilterBankRemove} />
              </div>
            )}

            {bankContact && (
              <div className="flex text-primary align-items-center justify-content-between mb-12">
                {`Bank contact: ${getFullName(filteredBankContact?.find(({ id }) => id === +bankContact))}`}
                <CloseIcon className={styles.removeFilterIcon} onClick={handleBankContactRemove} />
              </div>
            )}

            {developer && (
              <div className="flex text-primary align-items-center justify-content-between mb-12">
                {`Developer: ${getName(filteredDevelopers?.find(({ id }) => id === +developer))}`}
                <CloseIcon className={styles.removeFilterIcon} onClick={handleDeveloperRemove} />
              </div>
            )}

            {contractor && (
              <div className="flex text-primary align-items-center justify-content-between mb-12">
                {`General contractor: ${getName(filteredContractors?.find(({ id }) => id === +contractor))}`}
                <CloseIcon className={styles.removeFilterIcon} onClick={handleContractorRemove} />
              </div>
            )}

            {name && (
              <div className="flex text-primary align-items-center justify-content-between mb-12">
                {`State: ${name}`}
                <CloseIcon className={styles.removeFilterIcon} onClick={handleFilterStateRemove} />
              </div>
            )}

            {!!Object.keys(query).length && (
              <Button variant="contained" onClick={handleClear} fullWidth>
                Clear
              </Button>
            )}
          </div>
        </div>
      </CustomDialog>
    </>
  );
};
