import { ChangeEvent, FC, PropsWithChildren, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import MuiTextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Checkbox } from '@material-ui/core';
import clsx from 'clsx';
import { ProposalFormValues, User } from 'types';
import { useFormChanges } from 'contexts/FormChanges';
import { Autocomplete, CustomDatePicker, LoadingButton, RichEditor } from 'components';
import { getFullName } from 'utils';
import { getEntityOptionName, getOptionName } from 'utils/common';

import styles from './ProposalForm.module.scss';
import { ProposalRequestFormProps } from './types';
import { useProposalRequestForm } from './useProposalForm';
import { proposalFormValidationSchema } from './validation';

const FieldWrapper = ({ children }: PropsWithChildren) => (
  <div className="col-12 col-md-6 mb-24 mb-md-36">{children}</div>
);

export const ProposalForm: FC<ProposalRequestFormProps> = ({
  onSubmit,
  onClose,
  values,
  isNew,
  isLoading,
  isAllDisabled = false,
}) => {
  const { checkFormChanges } = useFormChanges();
  const {
    filteredUsers,
    filteredProjects,
    mappedStates,
    workOrderTypes,
    companies,
    proposalStatuses,
    estimateList,
    initialValues,

    formRef,

    isQueriesLoading,

    getBankContacts,
    transformSubmitValues,

    selectedServices,
    handleServices,
    handleServicesKeyPress,
    handleServicesPaste,
  } = useProposalRequestForm(values);

  if (isQueriesLoading || isLoading) return <CircularProgress size={64} />;

  const users = Array.isArray(filteredUsers) ? filteredUsers : filteredUsers?.items;

  return (
    <Formik
      innerRef={formRef}
      validationSchema={proposalFormValidationSchema}
      initialValues={initialValues}
      onSubmit={(values) => onSubmit && onSubmit(transformSubmitValues(values as ProposalFormValues))}
      enableReinitialize
    >
      {({ values, isSubmitting, initialValues, setFieldValue }) => {
        checkFormChanges(initialValues, values);

        useEffect(() => {
          if (isNew && estimateList && estimateList.length > 1) {
            setFieldValue('drawPeriod', estimateList[1]);
          }
        }, [isNew, estimateList, setFieldValue]);

        return (
          <Form>
            <div className="flex flex-wrap align-items-center">
              <div className="col-12 col-md-8 mb-24 mb-md-36">
                <Field
                  component={Autocomplete}
                  options={filteredProjects}
                  name="projectId"
                  placeholder="Project name"
                  variant="outlined"
                  disabled={isAllDisabled}
                />
              </div>

              <FieldWrapper>
                <Field component={TextField} name="name" label="Name" variant="outlined" disabled={isAllDisabled} />
              </FieldWrapper>

              <FieldWrapper>
                <Field
                  component={CustomDatePicker}
                  name="receivedDate"
                  label="RFP received date"
                  disabled={isAllDisabled}
                />
              </FieldWrapper>

              <FieldWrapper>
                <Field
                  component={Autocomplete}
                  name="bank"
                  placeholder="Bank"
                  type="text"
                  options={companies}
                  disabled={isAllDisabled}
                  onChange={() => {
                    setFieldValue('bankContacts', []);
                  }}
                />
              </FieldWrapper>

              <FieldWrapper>
                <Field
                  component={CustomDatePicker}
                  name="sentDate"
                  label="Proposal sent date"
                  disabled={isAllDisabled}
                />
              </FieldWrapper>

              <FieldWrapper>
                <Field
                  component={Autocomplete}
                  name="bankContacts"
                  placeholder="Bank contacts"
                  type="text"
                  multiple
                  options={getBankContacts(values?.bank?.id)}
                  disabled={isAllDisabled}
                />
              </FieldWrapper>

              <FieldWrapper>
                <Field
                  component={CustomDatePicker}
                  name="awardDate"
                  label="Proposal awarded date"
                  disabled={isAllDisabled}
                />
              </FieldWrapper>

              <FieldWrapper>
                <Field
                  component={Autocomplete}
                  name="state"
                  placeholder="State"
                  type="text"
                  options={mappedStates}
                  disabled={isAllDisabled}
                />
              </FieldWrapper>

              <FieldWrapper>
                <Field
                  component={Autocomplete}
                  name="drawPeriod"
                  placeholder="Estimated frequency of draw requests"
                  type="text"
                  options={estimateList}
                  disabled={isAllDisabled}
                />
              </FieldWrapper>

              <FieldWrapper>
                <Field
                  component={Autocomplete}
                  name="assignee"
                  placeholder="Assignee"
                  type="text"
                  options={(users as User[]).map((user) => ({
                    id: user.id,
                    name: getFullName(user),
                  }))}
                  getOptionLabel={getEntityOptionName}
                  disabled={isAllDisabled}
                />
              </FieldWrapper>

              <FieldWrapper>
                <Field
                  component={CustomDatePicker}
                  name="expectedDateOfFirstInspection"
                  label="First inspection is anticipated"
                  disabled={isAllDisabled}
                />
              </FieldWrapper>

              <FieldWrapper>
                <Field
                  component={Autocomplete}
                  name="reviewer"
                  placeholder="Reviewer"
                  type="text"
                  options={(users as User[]).map((user) => ({
                    id: user.id,
                    name: getFullName(user),
                  }))}
                  disabled={isAllDisabled}
                />
              </FieldWrapper>

              <FieldWrapper>
                <Field
                  component={CustomDatePicker}
                  name="expectedDateOfLastInspection"
                  label="Last inspection is anticipated"
                  minDate={values.expectedDateOfFirstInspection}
                  disabled={!values.expectedDateOfFirstInspection || isAllDisabled}
                />
              </FieldWrapper>

              <FieldWrapper>
                <Field
                  component={TextField}
                  name="ltv"
                  label="LTV"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className="pl-8">
                        $
                      </InputAdornment>
                    ),
                  }}
                  disabled
                />
              </FieldWrapper>

              <div className="flex flex-column mb-24 mb-md-36 px-16">
                <p className={clsx('text-18 mb-32', styles.title)}>Requested services</p>
                <div className="flex mb-8">
                  <div className="col-3 col-md-2 text-primary">Requested</div>
                  <div className="col-3 col-md-6 text-primary">Service name</div>
                  <div className="col-3 col-md-2 text-primary">Qty</div>
                  <div className="col-3 col-md-2 text-primary">Pricing</div>
                </div>
                <>
                  {workOrderTypes?.map((type, index) => {
                    const currentService = selectedServices.find(({ name }) => name === type.id);

                    return (
                      <div
                        className={clsx('flex align-items-center justify-content-center', styles.serviceRow)}
                        key={index}
                      >
                        <div className="col-3 col-md-2">
                          <Checkbox
                            onChange={(_: ChangeEvent<unknown>, value: boolean | string) => {
                              handleServices(type.id as string, value as string, undefined, true);
                            }}
                            className={styles.servicesField}
                            disabled={isAllDisabled}
                            checked={!!currentService}
                          />
                        </div>

                        <div className="col-3 col-md-5">
                          <p>{type.name}</p>
                        </div>

                        <div className="col-3 col-md-2">
                          <MuiTextField
                            name="quantity"
                            type="number"
                            variant="outlined"
                            className={styles.servicesField}
                            disabled={isAllDisabled}
                            onPaste={handleServicesPaste}
                            onKeyDown={handleServicesKeyPress}
                            value={currentService?.quantity ?? 0}
                            onChange={(e) => {
                              const value = e.target.value;
                              handleServices(type.id as string, value, 'quantity');
                            }}
                          />
                        </div>

                        <div className="col-3 col-md-3">
                          <MuiTextField
                            name="price"
                            value={currentService?.price ?? 0}
                            className={styles.servicesField}
                            onChange={(e) => {
                              const value = e.target.value;
                              handleServices(type.id as string, value, 'price');
                            }}
                            onPaste={handleServicesPaste}
                            onKeyDown={handleServicesKeyPress}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start" className="pl-8">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            type="number"
                            variant="outlined"
                            disabled={isAllDisabled}
                          />
                        </div>
                      </div>
                    );
                  })}
                </>
              </div>
            </div>
            <FieldWrapper>
              <Field
                component={Autocomplete}
                name="status"
                placeholder="Status"
                type="text"
                options={proposalStatuses}
                disabled={isAllDisabled}
                getOptionLabel={getOptionName}
              />
            </FieldWrapper>

            <div className="p-relative flex flex-column mb-24 mb-md-36 px-16">
              <p className={clsx('text-18 mb-12', styles.title)}>Notes</p>

              <Field
                multiline
                component={RichEditor}
                name="noteText"
                variant="outlined"
                type="text"
                disabled={isAllDisabled}
                className="mb-24 mb-md-36"
                isSpellChecker={false}
                isShowLoader
              />
            </div>

            {!isAllDisabled && (
              <div className="flex pt-24 justify-content-end px-16">
                <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                  {isNew ? 'Add proposal request' : 'Save'}
                </LoadingButton>
                <Button variant="text" type="reset" onClick={onClose} className="ml-12">
                  Cancel
                </Button>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
