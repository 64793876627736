import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import styles from './Tab.module.scss';
import { TabProps } from './types';

export const Tab: FC<TabProps> = ({ activeClassName, className, tabs }) => {
  return (
    <div className={clsx(styles.tab, 'px-0 px-sm-16 px-md-32', className)}>
      {tabs.map(({ title, route, exact, counter, icon }) => {
        return (
          <NavLink
            key={title}
            exact={exact}
            to={route}
            className={clsx(styles.tabItem, 'py-12 px-md-32 px-16')}
            activeClassName={clsx(styles.active, activeClassName)}
          >
            <span className="d-none d-md-block p-relative">{icon}</span>
            {title}
            {!!counter && <div className={styles.counter}>{counter >= 100 ? '99+' : counter}</div>}
          </NavLink>
        );
      })}
    </div>
  );
};
