import { IconProps } from 'types';

export const StarIcon = ({ className, onClick }: IconProps) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.40133 0.37337C7.51328 0.144856 7.74555 0 8.00001 0H8.00034C8.2536 0 8.48499 0.143497 8.59755 0.370364L10.5059 4.21671L14.7632 4.83731C15.0139 4.87386 15.2223 5.04943 15.3008 5.29037C15.3794 5.5313 15.3145 5.79594 15.1335 5.97327L12.0373 9.0059L12.7721 13.2188C12.8159 13.4698 12.7128 13.7238 12.5064 13.8732C12.3001 14.0227 12.0266 14.0415 11.8018 13.9216L8.00033 11.8954L4.19857 13.9217C3.97358 14.0416 3.69991 14.0227 3.49354 13.873C3.28718 13.7233 3.18426 13.469 3.22842 13.2179L3.96918 9.00554L0.867635 5.97337C0.686272 5.79607 0.621231 5.53121 0.699834 5.29006C0.778437 5.04891 0.987068 4.87326 1.23808 4.83689L5.51848 4.21669L7.40133 0.37337ZM8.00397 2.17393L6.56103 5.1193C6.46415 5.31705 6.27587 5.4542 6.05794 5.48578L2.76434 5.963L5.15272 8.29796C5.31008 8.4518 5.38138 8.67339 5.34327 8.89013L4.7785 12.1017L7.68679 10.5517C7.88278 10.4472 8.11793 10.4472 8.31392 10.5517L11.224 12.1028L10.6636 8.88954C10.6259 8.67329 10.697 8.45234 10.8539 8.29874L13.239 5.96254L9.96784 5.4857C9.75132 5.45413 9.56406 5.31832 9.46681 5.1223L8.00397 2.17393Z"
        fill="#999999"
      />
    </svg>
  );
};
