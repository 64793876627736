import React, { FC, ReactElement } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';

import { CloseIcon } from 'icons';

import styles from './Dialog.module.scss';
import clsx from 'clsx';

type ModalState = {
  header?: string;
  icon?: ReactElement;
  onClose: (_?: unknown, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  fullHeight?: boolean;
  children: React.ReactNode;
  isDelete?: boolean;
} & DialogProps;

export const CustomDialog: FC<ModalState> = ({
  children,
  open,
  onClose,
  header,
  icon,
  fullHeight,
  maxWidth,
  className,
  isDelete,
}) => (
  <Dialog
    className={clsx(className, isDelete && 'delete-dialog')}
    fullScreen={fullHeight}
    maxWidth={maxWidth}
    open={open}
    onClose={onClose}
  >
    {header && (
      <h2 className={styles.title}>
        {icon}
        {header}
        <CloseIcon onClick={onClose} className={styles.closeBtn} />
      </h2>
    )}
    {children}
  </Dialog>
);
