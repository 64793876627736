import { TextField } from 'formik-material-ui';
import { Field } from 'formik';

import { CommonPlansFields } from './CommonPlansFields';

export const MEPPlansFields = () => (
  <>
    <Field component={TextField} name="engineeringFirm" label="Engineering Firm" className="mb-24" />
    <CommonPlansFields />
  </>
);
