import { useState } from 'react';
import clsx from 'clsx';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import { ArchiveIcon, CloseIcon, DeleteIcon, EditIcon } from 'icons';

import styles from './Actions.module.scss';

export interface ActionsProps {
  id: number;
  model: string;
  handleDelete?: (id: number) => void;
  handleEdit?: (id: number) => void;
  handleArchive?: (id: number) => void;
}

export const TableActions = ({ id, model, handleDelete, handleEdit, handleArchive }: ActionsProps) => {
  const [selectedInfoOpen, setSelectedInfoOpen] = useState<number | null>();

  const handleProjectsActionsToggle = (index: number | undefined) =>
    selectedInfoOpen === index ? setSelectedInfoOpen(null) : setSelectedInfoOpen(index);

  return (
    <div className="p-relative flex justify-content-center">
      <IconButton onClick={() => id && handleProjectsActionsToggle(id)}>
        <MoreVertIcon />
      </IconButton>
      {selectedInfoOpen === id && (
        <div className={clsx(styles.modalWrapper, 'flex flex-column justify-content-center')}>
          <CloseIcon className={styles.closeModalIcon} onClick={() => setSelectedInfoOpen(null)} />

          {handleEdit && (
            <div
              className="flex align-items-center pl-8"
              onClick={() => [handleEdit(id as number), setSelectedInfoOpen(null)]}
            >
              <EditIcon className={styles.icon} />
              <p>Edit {model}</p>
            </div>
          )}
          {handleDelete && (
            <div
              className="flex mt-8 align-items-center pl-8"
              onClick={() => [handleDelete(id as number), setSelectedInfoOpen(null)]}
            >
              <DeleteIcon className={styles.icon} />
              <p>Delete {model}</p>
            </div>
          )}

          {handleArchive && (
            <div
              className="flex mt-8 align-items-center pl-8"
              onClick={() => [handleArchive(id as number), setSelectedInfoOpen(null)]}
            >
              <ArchiveIcon className={styles.icon} />
              <p>Archive {model}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
