import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const InspectionAuditTrailRoute = Route(
  {
    id: Route.params.number.optional,
    auditTrailId: Route.query.number,
    search: Route.query.string,
    page: Route.query.number,
    status: Route.query.string,
  },
  ({ id }) => `/inspection/${id}/audit-trail`,
);

export const InspectionAuditTrail = InspectionAuditTrailRoute(lazy(() => import('./AuditTrail')));
