import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const WorkOrderOverviewRoute = Route(
  {
    id: Route.params.number.optional,
    search: Route.query.string,
    status: Route.query.string,
    isTableView: Route.query.boolean,
  },
  ({ id }) => `/work-order/${id}`,
);

export const WorkOrderOverview = WorkOrderOverviewRoute(lazy(() => import('./WorkOrderOverview')));
