import { IconProps } from 'types';

export const ClipsIcon = ({ className, color }: IconProps) => {
  return (
    <svg
      className={className}
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66634 15.3334C4.04134 15.3334 3.45801 15.2084 2.91634 15.0001C2.37467 14.7501 1.87467 14.4167 1.41634 14.0001C0.541341 13.1251 0.0830078 12.0001 0.0830078 10.7501C0.0830078 9.50008 0.541341 8.37508 1.41634 7.50008L7.62467 1.33341C7.95801 1.00008 8.45801 1.00008 8.79134 1.33341C9.12467 1.66675 9.12467 2.16675 8.79134 2.50008L2.62467 8.66675C2.08301 9.20841 1.79134 9.95841 1.79134 10.7084C1.79134 11.4584 2.08301 12.2084 2.62467 12.7501C2.91634 13.0417 3.20801 13.2501 3.58301 13.3751C4.29134 13.6667 5.12467 13.6667 5.83301 13.3751C6.20801 13.2084 6.49967 13.0001 6.79134 12.7501L14.083 5.41675C14.4163 5.08341 14.583 4.66675 14.583 4.25008C14.583 3.79175 14.4163 3.37508 14.083 3.08341C13.9163 2.91675 13.7497 2.79175 13.5413 2.70841C13.1247 2.54175 12.6663 2.54175 12.2497 2.70841C12.0413 2.79175 11.8747 2.91675 11.708 3.08341L4.37467 10.4584C4.33301 10.5001 4.29134 10.5417 4.29134 10.5834C4.29134 10.6251 4.24967 10.7084 4.24967 10.7501C4.24967 10.7917 4.24967 10.8751 4.29134 10.9167C4.33301 10.9584 4.33301 11.0001 4.37467 11.0417C4.54134 11.2084 4.79134 11.2084 4.95801 11.0417L11.1247 4.87508C11.458 4.54175 11.958 4.54175 12.2913 4.87508C12.6247 5.20842 12.6247 5.70841 12.2913 6.04175L6.16634 12.2084C5.79134 12.5834 5.24967 12.8334 4.70801 12.8334C4.16634 12.8334 3.62467 12.6251 3.24967 12.2084C3.04134 12.0001 2.91634 11.7917 2.79134 11.5417C2.66634 11.2917 2.62467 11.0001 2.62467 10.7501C2.62467 10.4584 2.66634 10.2084 2.79134 9.95841C2.91634 9.70841 3.04134 9.45841 3.24967 9.29175L10.583 1.91675C10.8747 1.58341 11.2497 1.33341 11.6663 1.16675C12.4997 0.833415 13.4163 0.833415 14.208 1.16675C14.6247 1.33341 14.958 1.58341 15.2913 1.87508C15.9163 2.50008 16.2497 3.33341 16.2497 4.25008C16.2497 5.12508 15.9163 5.95841 15.2913 6.62508L7.91634 14.0001C7.49967 14.4167 6.99967 14.7501 6.41634 15.0001C5.87467 15.2084 5.29134 15.3334 4.66634 15.3334Z"
        fill={color || 'black'}
      />
    </svg>
  );
};
