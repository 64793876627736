/* eslint-disable @typescript-eslint/no-explicit-any */
import { Country, DropdownValue, User } from 'types';
import { getFullName } from './getFullName';

export const toDropdownObject = <T>(array: T[]): Array<T & DropdownValue> =>
  array?.map((item: any) => {
    item['label'] = item['name'];
    item['value'] = item['id'];

    return item;
  });

export const toDropdownUserObject = (array: User[]) =>
  toDropdownObject(
    array?.map((user) => ({
      id: user.id,
      name: getFullName(user),
    })),
  );

export const toDropdownStateObject = (array: Country[]): DropdownValue[] =>
  array.map((state) => ({
    ...state,
    label: state.name?.toString() as string,
    value: state.name as string,
  }));

export const grouper = (array: any[]) => {
  const newArray: any[] = [];

  array.reduce((previousValue, currentValue) => {
    const group = currentValue.label[0].toLocaleUpperCase();
    if (!previousValue[group]) {
      const data = (previousValue[group] = { title: group, options: [currentValue] });
      newArray.push(data);

      newArray.sort((a, b) => a.title.localeCompare(b.title));
    } else {
      previousValue[group].options.push(currentValue);
    }
    return previousValue;
  }, {});

  return newArray;
};
