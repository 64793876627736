import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const UserInfoRoute = Route(
  {
    id: Route.params.number.optional,
    search: Route.query.string,
  },
  ({ id }) => `/user-info/${id}`,
);

export const UserInfo = UserInfoRoute(lazy(() => import('./UserInfo')));
