import React, { FC, useMemo } from 'react';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import InputAdornment from '@material-ui/core/InputAdornment';
import clsx from 'clsx';

import { LoadingButton, PasswordField, InputPhoneMask } from 'components';
import { EditProfileFormValues } from 'types';
import { PASSWORD, PHONE_NUMBER } from 'consts';
import { components } from 'generated/types';
import { useFormChanges } from 'contexts/FormChanges';

import styles from './SettingsForm.module.scss';

type CompanyInfo = components['schemas']['Company'];
type RoleInfo = components['schemas']['Role'];

interface SettingsFormProps {
  onSubmit: (
    values: EditProfileFormValues,
    formikHelpers: FormikHelpers<EditProfileFormValues>,
  ) => void | Promise<void>;
  firstName?: string;
  lastName?: string;
  email?: string;
  personalNumber?: string;
  workNumber?: string;
  workNumberExt?: string;
  companyType?: string;
  activeCompany?: CompanyInfo;
  activeRole?: RoleInfo;
}

export const SettingsForm: FC<SettingsFormProps> = ({
  firstName,
  lastName,
  email,
  personalNumber,
  workNumber,
  workNumberExt,
  activeCompany,
  activeRole,
  onSubmit,
}) => {
  const { checkFormChanges } = useFormChanges();
  const validationSchema = useMemo(
    () =>
      Yup.object().shape(
        {
          firstName: Yup.string().required('First name is a required field'),
          lastName: Yup.string().required('Last name is a required field'),
          email: Yup.string().email('Invalid email address').required('Email is a required field'),
          personalNumber: Yup.string()
            .matches(PHONE_NUMBER, 'Must be a valid phone number')
            .required('Personal number is a required field'),
          workNumber: Yup.string().matches(PHONE_NUMBER, 'Must be a valid phone number'),
          workNumberExt: Yup.string().matches(/^[0-9]{3,4}$/, ' '),
          activeCompany: Yup.string(),
          companyType: Yup.string(),
          activeRole: Yup.string(),
          oldPassword: Yup.string().matches(
            PASSWORD,
            'Use 8 or more characters with a mix of letters, numbers & symbols',
          ),
          password: Yup.string()
            .when(['oldPassword'], {
              is: (oldPassword: string) => {
                return !!oldPassword;
              },
              then: Yup.string().required('New password is a required field'),
            })
            .matches(PASSWORD, 'Use 8 or more characters with a mix of letters, numbers & symbols'),
          passwordConfirmation: Yup.string()
            .when(['password'], {
              is: (newPassword: string) => {
                return !!newPassword;
              },
              then: Yup.string().required('Password confirmation should be equal to new password'),
            })
            .oneOf([Yup.ref('password')], 'Password should be matched')
            .matches(PASSWORD, 'Use 8 or more characters with a mix of letters, numbers & symbols'),
        },
        [['oldPassword', 'password']],
      ),
    [],
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        firstName: firstName ?? '',
        lastName: lastName ?? '',
        email: email ?? '',
        personalNumber: personalNumber ?? '',
        workNumber: workNumber ?? '',
        workNumberExt: workNumberExt ?? '',
        companyType: '',
        activeCompany: activeCompany?.name ?? '',
        activeRole: activeRole?.name ?? '',
        oldPassword: '',
        password: '',
        passwordConfirmation: '',
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, initialValues, values }) => {
        checkFormChanges(initialValues, values);

        return (
          <Form autoComplete="off">
            <section>
              <span className={clsx('uppercase px-16 mb-24', styles.heading)}>- Personal Info</span>

              <div className="flex flex-wrap">
                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field component={TextField} name="firstName" label="First name" variant="outlined" />
                </div>

                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field component={TextField} name="lastName" label="Last name" variant="outlined" />
                </div>

                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field
                    component={TextField}
                    name="email"
                    label="Email"
                    placeholder="test@gmail.com"
                    variant="outlined"
                    type="email"
                  />
                </div>

                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field
                    component={InputPhoneMask}
                    name="personalNumber"
                    placeholder="+1 (770) 123-4567"
                    label="Personal number"
                    variant="outlined"
                  />
                </div>
              </div>
            </section>

            <section>
              <span className={clsx('uppercase px-16 my-24', styles.heading)}>- Work Info</span>

              <div className="flex flex-wrap">
                <div className="col-12 col-md-6 flex mb-24 mb-md-36">
                  <Field
                    component={InputPhoneMask}
                    name="workNumber"
                    placeholder="+1 (770) 123-4567"
                    label="Work number"
                    variant="outlined"
                  />
                  <Field
                    component={TextField}
                    name="workNumberExtension"
                    variant="outlined"
                    placeholder="1234"
                    className={clsx(styles.workNumberExtension, 'ml-16')}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">#</InputAdornment>,
                    }}
                  />
                </div>

                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field component={TextField} disabled name="activeCompany" label="Company" variant="outlined" />
                </div>

                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field component={TextField} name="activeRole" label="Role" variant="outlined" />
                </div>
              </div>
            </section>

            <section>
              <span className={clsx('uppercase px-16 my-24', styles.heading)}>- Change password</span>

              <div className="flex flex-wrap">
                <div className="col-12 col-md-6 mb-24 mb-md-40">
                  <PasswordField name="oldPassword" label="Old password" autocomplete="new-password" />
                </div>

                <div className="col-12 col-md-6 mb-24 mb-md-40">
                  <PasswordField name="password" label="New password" />
                </div>

                <div className="col-12 col-md-6 mb-24 mb-md-40">
                  <PasswordField name="passwordConfirmation" label="Password confirmation" />
                </div>
              </div>
            </section>

            <div className="col-12 col-sm-4 col-md-3 col-lg-2 py-24">
              <LoadingButton type="submit" fullWidth loading={isSubmitting}>
                Save
              </LoadingButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
