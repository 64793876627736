import { formatNumber } from './common';

export const getCalculation = (
  answer?: string,
  isCalculation?: boolean,
  isPercentage?: boolean,
  id?: string | number,
) => {
  let result = answer;
  if (answer && isCalculation && !answer.endsWith('.')) {
    try {
      result = isPercentage ? formatNumber(eval(answer) * 100) : formatNumber(eval(answer));
      if ((id && id === '5') || id === '68') {
        result = (+result).toFixed(1).toString();
      }
    } catch (e) {
      return answer;
    }
  }
  return result;
};
