import { toast } from 'react-toastify';
import { Questions } from '../../pages/ReportWriting/schemas/types';
import { getQuestionsWithoutAnswers } from './getQuestionsWithoutAnswers';
import { getAdditionalQuestionsWithoutAnswers } from './getAdditionalQuestionsWithoutAnswers';
import { getAnswersByQuestionId } from './getAnswersByQuestionId';
import { ReportTypesEnum } from 'types';

export const checkIfInspectionTimeOrDateFieldEmpty = (
  section: string,
  questionsState: Questions[],
  setErrors: React.Dispatch<React.SetStateAction<string[]>>,
) => {
  const inspectionTimeValue = questionsState.find((el) => el.id === '139')?.answer;
  const inspectionDateValue = questionsState.find((el) => el.id === '138')?.answer;

  if (
    (section === '37' && !inspectionTimeValue && inspectionDateValue) ||
    (inspectionTimeValue && !inspectionDateValue)
  ) {
    setErrors((prev) => [...prev, inspectionDateValue ? '139' : '138']);
    const missedField = inspectionDateValue ? 'Time of inspection' : 'Inspection Date';
    toast.error(`${missedField} is a required field`);
    return true;
  }
};

export const checkIfRequiredQuestionsEmpty = (
  reportType: string,
  questionsState: Questions[],
  setInvaliedSections: (value: React.SetStateAction<string[]>) => void,
) => {
  if (
    (getQuestionsWithoutAnswers(questionsState, questionsState).length ||
      getAdditionalQuestionsWithoutAnswers(questionsState).length) &&
    reportType === ReportTypesEnum.Inspection
  ) {
    let invalidQuestions: Array<Partial<Questions> | undefined> = [];
    getAdditionalQuestionsWithoutAnswers(questionsState).forEach((id) => {
      invalidQuestions.push(getAnswersByQuestionId(id, questionsState));
    });

    getQuestionsWithoutAnswers(questionsState, questionsState).forEach((question) => {
      invalidQuestions.push(getAnswersByQuestionId(question.id as string, questionsState));
    });

    setInvaliedSections((invalidQuestions as Partial<Questions>[]).map(({ categoryId }) => categoryId as string));

    toast.error(
      <>
        You did not answer the required questions, so the report cannot be generated.
        <br />
        Please return to the sections highlighted in red in the side list of sections.
      </>,
    );
    invalidQuestions = [];
    return true;
  }
};

export const checkIfRequiredQuestionsEmptyInSection = (
  activeSection: string,
  questionsState: Questions[],
  setInvaliedSections: (value: React.SetStateAction<string[]>) => void,
) => {
  const activeSectionQuestions = questionsState.filter(({ categoryId }) => categoryId === activeSection);

  //questions which ralated with other questions in other sections
  const developmentDrawQuestionAnswer = questionsState.find(({ id }) => id === '48')?.answer;
  const cleanOfDebrisQuestionAnswer = questionsState.find((el) => el.id === '179')?.answer;

  const questionsIdWithoutAnswear = getQuestionsWithoutAnswers(activeSectionQuestions, activeSectionQuestions)
    .filter((el) => {
      if (el.id === '92' && ((el?.options && el?.options['Yes'][0].answer) || developmentDrawQuestionAnswer === 'No'))
        return;
      if (el.id === '45' && cleanOfDebrisQuestionAnswer) return;

      return el;
    })
    .map((el) => el.id as string);

  if (!questionsIdWithoutAnswear.length) {
    setInvaliedSections((prev) => [...prev.filter((sectionId) => sectionId !== activeSection)]);
  }
};
