import { useState, useEffect } from 'react';
import { breakpoints } from 'consts';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const isMobile = windowSize.width <= breakpoints.sm;
  const isDesktop = windowSize.width > breakpoints.lg;
  const isTablet = windowSize.width > breakpoints.sm;

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { ...windowSize, isMobile, isDesktop, isTablet };
};

export default useWindowSize;
