export enum SortingOptions {
  Name = 'sort[name]',
  Assignee = 'sort[assignee]',
  Bank = 'sort[bank]',
  BankContacts = 'sort[bankContacts]',
  PrePrice = 'sort[prePrice]',
  InspectionPrice = 'sort[inspectionPrice]',
  Ltv = 'sort[ltv]',
  RfpRecieved = 'sort[receivedDate]',
  SentDate = 'sort[sentDate]',
  AwardDate = 'sort[awardDate]',
  ExpectedDateOfFirstInspection = 'sort[expectedDateOfFirstInspection]',
  ExpectedDateOfLastInspection = 'sort[expectedDateOfLastInspection]',
  CreatedAt = 'sort[createdAt]',
}
