import { DropdownOption } from './dropdown';

export interface Country {
  id?: number;
  name: string;
  states: Array<{ name: string; id?: number }>;
}

export enum CompanyTypesEnum {
  Vendor = 'vendor',
  Bank = 'bank',
  '3rd-parties' = '3rd-parties',
  Developer = 'developer',
  Contractor = 'general-contractor',
}

export enum DocumentCategoryStatusEnum {
  Approved = 'approved',
  Na = 'na',
  Received = 'received',
  // Waiting = 'waiting',
  Missing = 'missing',
}
export interface OrganizationDropdown extends DropdownOption {
  typeId: '3rd-parties' | CompanyTypesEnum.Bank | CompanyTypesEnum.Developer | CompanyTypesEnum.Contractor;
}

export enum FileStatusEnum {
  Approved = 'approved',
  Rejected = 'rejected',
  Waiting = 'waiting',
}

export enum WorkOrderTypeEnum {
  PreConstructionReview = 'pre_construction_review',
  Pca = 'PCA',
  AdReport = 'a&d_report',
  InspectionReport = 'inspection_report',
  ContractorCapabilityEvaluation = 'contractor_capability_evaluation',
  FundsControl = 'funds_control',
  EntitlementReport = 'entitlement_report',
}

export enum WorkOrderStatusEnum {
  Submitted = 'submitted',
  ToDo = 'to_do',
  Waiting = 'waiting',
  ReadyToWrite = 'ready_to_write',
  ReadyForReview = 'ready_for_review',
}

export enum WorkOrderReportStatusEnum {
  'in_progress',
  drafted,
  'not_started',
}

export enum SortOptionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum RolesEnum {
  SuperAdmin = 'Super admin',
  Admin = 'Admin',
  Inspector = 'Inspector',
}

export enum ProjectStatusEnum {
  All = 'all',
  Active = 'active',
  Inactive = 'inactive',
  Waiting = 'waiting',
}
