import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const ProposalRequestOverviewRoute = Route(
  {
    id: Route.params.number.optional,
    search: Route.query.string,
    page: Route.query.number,
    isTableView: Route.query.boolean,
    status: Route.query.string,
  },
  ({ id }) => `/proposal-request/${id}`,
);

export const ProposalRequestOverview = ProposalRequestOverviewRoute(lazy(() => import('./ProposalRequestOverview')));
