import { IconProps } from 'types';

export const ArchiveIcon = ({ className, onClick }: IconProps) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="20"
      height="20"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.935 1.33003C3.01551 0.943595 3.35609 0.666656 3.75082 0.666656H16.2508C16.6455 0.666656 16.9861 0.943595 17.0666 1.33003L19.15 11.33C19.2438 11.7806 18.9547 12.2219 18.5041 12.3158C18.0535 12.4097 17.6122 12.1205 17.5183 11.67L15.5732 2.33332H4.42843L2.4833 11.67C2.38943 12.1205 1.94809 12.4097 1.49752 12.3158C1.04696 12.2219 0.757801 11.7806 0.851668 11.33L2.935 1.33003Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.833984 11.5C0.833984 11.0398 1.20708 10.6667 1.66732 10.6667H6.21315C6.58036 10.6667 6.90429 10.907 7.01071 11.2585L7.58858 13.1667H12.4128L12.991 11.2583C13.0975 10.907 13.4214 10.6667 13.7886 10.6667H18.334C18.7942 10.6667 19.1673 11.0398 19.1673 11.5V16.9167C19.1673 17.3769 18.7942 17.75 18.334 17.75H1.66732C1.20708 17.75 0.833984 17.3769 0.833984 16.9167V11.5ZM2.50065 12.3333V16.0833H17.5006V12.3333H14.4068L13.8286 14.2416C13.7221 14.593 13.3982 14.8333 13.0311 14.8333H6.97023C6.60303 14.8333 6.2791 14.593 6.17267 14.2415L5.5948 12.3333H2.50065Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.08398 4.83333C7.08398 4.3731 7.45708 4 7.91732 4H12.084C12.5442 4 12.9173 4.3731 12.9173 4.83333C12.9173 5.29357 12.5442 5.66667 12.084 5.66667H7.91732C7.45708 5.66667 7.08398 5.29357 7.08398 4.83333Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83398 8.16665C5.83398 7.70641 6.20708 7.33331 6.66732 7.33331H13.334C13.7942 7.33331 14.1673 7.70641 14.1673 8.16665C14.1673 8.62688 13.7942 8.99998 13.334 8.99998H6.66732C6.20708 8.99998 5.83398 8.62688 5.83398 8.16665Z"
        fill="black"
      />
    </svg>
  );
};
