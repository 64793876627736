import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const InspectionOverviewRoute = Route(
  {
    id: Route.params.number.optional,
    search: Route.query.string,
    status: Route.query.string,
    isTableView: Route.query.boolean,
  },
  ({ id }) => `/inspection/${id}`,
);

export const InspectionOverview = InspectionOverviewRoute(lazy(() => import('./InspectionOverview')));
