import { IconProps } from 'types';

export const XlsImg = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.75 25.6666C24.683 25.6666 26.25 24.0996 26.25 22.1666V5.83325C26.25 3.90026 24.683 2.33325 22.75 2.33325H5.25C3.317 2.33325 1.75 3.90025 1.75 5.83325V22.1666C1.75 24.0996 3.317 25.6666 5.25 25.6666H22.75ZM23.9167 16.8215V5.83325C23.9167 5.18892 23.3943 4.66659 22.75 4.66659H5.25C4.60567 4.66659 4.08333 5.18892 4.08333 5.83325V22.1666C4.08333 22.8109 4.60567 23.3333 5.25 23.3333H17.7996L23.9167 16.8215ZM21.001 23.3333H22.75C23.3943 23.3333 23.9167 22.8109 23.9167 22.1666V20.2294L21.001 23.3333Z"
        fill="#009933"
      />
      <path
        d="M6.01157 11.1698H7.94856L8.95782 12.7317L9.93602 11.1698H11.8536L10.0835 13.6286L12.0205 16.2467H10.0447L8.92288 14.6156L7.79717 16.2467H5.83301L7.79717 13.6009L6.01157 11.1698Z"
        fill="#009933"
      />
      <path d="M12.5911 11.1698H14.3496V14.9965H17.094V16.2467H12.5911V11.1698Z" fill="#009933" />
      <path
        d="M17.5869 14.5671L19.26 14.4736C19.2962 14.716 19.37 14.9007 19.4812 15.0277C19.6624 15.2332 19.9212 15.3359 20.2576 15.3359C20.5086 15.3359 20.7014 15.2839 20.836 15.1801C20.9731 15.0739 21.0417 14.9515 21.0417 14.813C21.0417 14.6814 20.977 14.5636 20.8476 14.4597C20.7182 14.3558 20.418 14.2577 19.9471 14.1654C19.1759 14.0107 18.626 13.8052 18.2973 13.549C17.9661 13.2927 17.8004 12.966 17.8004 12.5689C17.8004 12.308 17.8845 12.0621 18.0528 11.8313C18.2236 11.5981 18.4785 11.4157 18.8175 11.2841C19.1591 11.1502 19.6262 11.0833 20.2188 11.0833C20.946 11.0833 21.4998 11.2045 21.8802 11.4469C22.2632 11.687 22.4909 12.0702 22.5634 12.5966L20.9058 12.6832C20.8619 12.4546 20.7687 12.2884 20.6264 12.1845C20.4866 12.0806 20.2925 12.0287 20.0441 12.0287C19.8397 12.0287 19.6857 12.0679 19.5822 12.1464C19.4787 12.2226 19.4269 12.3161 19.4269 12.4269C19.4269 12.5077 19.4696 12.5804 19.555 12.6451C19.6378 12.712 19.8345 12.7744 20.145 12.8321C20.9136 12.9799 21.4635 13.1299 21.7948 13.2823C22.1286 13.4324 22.3706 13.6194 22.5207 13.8433C22.6733 14.0673 22.7497 14.3177 22.7497 14.5948C22.7497 14.9203 22.6488 15.2205 22.4469 15.4952C22.245 15.7699 21.963 15.9789 21.6007 16.122C21.2384 16.2628 20.7816 16.3333 20.2304 16.3333C19.2626 16.3333 18.5923 16.167 18.2197 15.8346C17.847 15.5021 17.6361 15.0796 17.5869 14.5671Z"
        fill="#009933"
      />
    </svg>
  );
};
