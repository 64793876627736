import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const ProjectCostReviewRoute = Route(
  {
    id: Route.params.number.optional,
  },
  ({ id }) => `/project/${id}/cost-review`,
);

export const ProjectCostReview = ProjectCostReviewRoute(lazy(() => import('./CostReview')));
