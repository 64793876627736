import { ProposalRequestsRoute } from 'pages/ProposalRequests/ProposalRequestsRoute';
import { GetRouteProps } from 'types';

export type FiltersProps = Pick<GetRouteProps<typeof ProposalRequestsRoute>, 'match' | 'link'>;

export enum FiltersEnum {
  Bank = 'bank',
  BankContact = 'bankContact',
  Project = 'project',
  Services = 'services',
}
