import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';

import { PassVisibilityBtn } from 'components';
import { ArrowDoubleDownIcon, ArrowLeftIcon, ArrowRightIcon, CheckedIcon, CloseIcon, WarningReportIcon } from 'icons';
import { SectionType } from 'pages/ReportWriting/schemas/types';
import { ReportTypesEnum, Report } from 'types';
import { links } from 'App';
import { NavLink } from 'react-router-dom';
import { getProjectByReportType } from 'utils/common';

import styles from './Sections.module.scss';

type SectionsProps = {
  sections: SectionType[];
  id: number;
  // eslint-disable-next-line
  updateSectionMutate: (values: SectionType) => void;
  report?: Report;
  changeSection: (id: string) => void;
  activeSection: string;
  sectionView: SectionOrder;
  setSectionView: (value: SectionOrder) => void;
  handleCloseMobileSections: () => void;
  handleOpenMobileSections: () => void;
  toggleSectionList: boolean;
  activeSections: string[];
  handleOpenSubsections: (idx: string) => void;
  handleOpenAllSubsections: () => void;
  invalidSections: string[];
};

type SectionOrder = keyof Pick<SectionType, 'orderForDrafter' | 'orderForInspector' | 'orderForAll'>;

export const Sections: FC<SectionsProps> = ({
  sections,
  id,
  updateSectionMutate,
  report,
  activeSection,
  changeSection,
  sectionView,
  setSectionView,
  handleCloseMobileSections,
  handleOpenMobileSections,
  toggleSectionList,
  activeSections,
  handleOpenSubsections,
  handleOpenAllSubsections,
  invalidSections,
}) => {
  const reportType = report?.type;
  const [sectionsList, setSectionsList] = useState(sections);

  const handleVisibilityChange = async (item: SectionType) => {
    await updateSectionMutate({ ...item, visible: !item.visible });
  };

  const handleDoneChange = async (item: SectionType) => {
    await updateSectionMutate({ ...item, checked: !item.checked });
  };

  const handleHasIssueChange = async (item: SectionType) => {
    await updateSectionMutate({ ...item, hasIssue: !item.hasIssue });
  };

  const setSelectedSection = (section: SectionType) => {
    changeSection(section.id);
    handleCloseMobileSections();
  };

  const invalidMainSectionsIds = sectionsList
    .filter((section) => {
      if (invalidSections.some((ids) => ids === section.id) && section.parentId) {
        return section.parentId;
      }
    })
    .map(({ parentId }) => parentId);

  const SectionBlock: FC<SectionType & { onClick: () => void; className?: string }> = (item) => {
    const { name, visible, checked, className, hasSubsections, parentId, onClick, id, main, hasIssue } = item;
    return (
      <div
        className={clsx(
          'flex justify-content-between align-items-start mb-16 flex-1',
          className,
          !main && styles.subsection,
          activeSections.some((id) => id === parentId) && styles.activeSubsection,
        )}
      >
        <span
          onClick={() => {
            !hasSubsections && onClick();
            !!hasSubsections && handleOpenSubsections(id);
          }}
          className={clsx(
            parentId && 'pl-16',
            id === activeSection && styles.activeSection,
            invalidSections.some((ids) => ids === id) && styles.invalidSection,
            invalidMainSectionsIds.includes(id) && styles.invalidSection,
            'pointer',
          )}
        >
          {name}
          {!!hasSubsections && (
            <ArrowRightIcon
              className={clsx(
                styles.arrowRightIcon,
                activeSections.some((idx) => idx === id) && styles.activeRightIcon,
              )}
            />
          )}
        </span>
        <div className="flex align-items-center">
          <div className={styles.visibilityStatus}>
            <PassVisibilityBtn isPassVisible={!visible} togglePasswordVisibility={() => handleVisibilityChange(item)} />
          </div>

          {checked ? (
            <CheckedIcon className="c-pointer" onClick={() => handleDoneChange(item)} />
          ) : (
            <span className={clsx(styles.uncheckedBox, 'c-pointer')} onClick={() => handleDoneChange(item)} />
          )}
          {reportType !== ReportTypesEnum.PreConstruction && (
            <span className="ml-8 c-pointer">
              {hasIssue ? (
                <WarningReportIcon className={styles.hasIssueActive} onClick={() => handleHasIssueChange(item)} />
              ) : (
                <WarningReportIcon className={styles.hasIssue} onClick={() => handleHasIssueChange(item)} />
              )}
            </span>
          )}
        </div>
      </div>
    );
  };

  const handleSectionViewChange = (value: SectionOrder) => setSectionView(value);

  const handleOpenAllSubsection = () => {
    handleOpenAllSubsections();
  };

  useEffect(() => {
    setSectionsList(sections);
  }, [sections]);

  return (
    <>
      <div className={clsx(styles.sectionsListContainer, toggleSectionList && styles.mobileSectionsListContainer)}>
        {toggleSectionList && (
          <div className={styles.mobileHeader}>
            <div className={clsx(styles.reportName, 'weight-700')}>{report?.name}</div>
            <NavLink to={links.ProjectOverview({ id: getProjectByReportType(report as Report)?.id })}>
              <p className={styles.projectName}>{getProjectByReportType(report as Report)?.name}</p>
            </NavLink>
          </div>
        )}

        <div>
          <div className="flex justify-content-between p-12 align-center">
            <div
              className={clsx(styles.collapseAll, !!activeSections.length && styles.activeCollapseAll)}
              onClick={handleOpenAllSubsection}
            >
              <ArrowDoubleDownIcon />
            </div>

            {reportType !== ReportTypesEnum.PreConstruction && (
              <div className="flex align-items-center">
                <span
                  className={clsx(styles.viewToggler, sectionView === 'orderForInspector' && styles.activeViewToggler)}
                  onClick={() => handleSectionViewChange('orderForInspector')}
                >
                  Inspector
                </span>
                <span className="text-table-text">|</span>
                <span
                  className={clsx(styles.viewToggler, sectionView === 'orderForDrafter' && styles.activeViewToggler)}
                  onClick={() => handleSectionViewChange('orderForDrafter')}
                >
                  Drafter
                </span>
                <span className="text-table-text">|</span>
                <span
                  className={clsx(styles.viewToggler, sectionView === 'orderForAll' && styles.activeViewToggler)}
                  onClick={() => handleSectionViewChange('orderForAll')}
                >
                  All
                </span>
              </div>
            )}
          </div>
          <div className={styles.progressBar}>
            <p style={{ color: report?.percentageCompleted ?? 0 < 50 ? '#212b83' : 'white' }}>
              {report?.percentageCompleted}%
            </p>
            <div className={styles.progress} style={{ width: `${report?.percentageCompleted}%` }}></div>
          </div>
        </div>
        <div className={styles.sectionsList}>
          {sectionsList.map((item) => (
            <div key={item.id}>
              <div className="flex align-items-center" id={item.id}>
                <SectionBlock onClick={() => setSelectedSection(item)} key={id} {...item} />
              </div>
            </div>
          ))}
          <div className={styles.toggle} onClick={handleCloseMobileSections}>
            <CloseIcon />
          </div>
        </div>
      </div>
      <div className={styles.sectionToggle} onClick={handleOpenMobileSections}>
        <p>Sections</p>
        <ArrowLeftIcon />
      </div>
    </>
  );
};
