import { IconProps } from 'types';

export const Na = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 33.6867V15.3133H4.10329L12.6518 27.5831V15.3133H16.5699V33.6867H12.3384L3.91808 21.7051V33.6867H0Z"
        fill="black"
      />
      <path d="M18.878 34L24.0499 15H27.0561L21.8272 34H18.878Z" fill="black" />
      <path
        d="M48 33.6867H43.4123L41.5886 29.5132H33.2395L31.5156 33.6867H27.0419L35.1772 15.3133H39.6367L48 33.6867ZM40.2351 26.4175L37.3571 19.5996L34.5361 26.4175H40.2351Z"
        fill="black"
      />
    </svg>
  );
};
