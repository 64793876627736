import { IconProps } from 'types';

export const SubmittedIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 19.0909C15.0208 19.0909 19.0909 15.0208 19.0909 10C19.0909 4.97923 15.0208 0.909091 10 0.909091C4.97923 0.909091 0.909091 4.97923 0.909091 10C0.909091 15.0208 4.97923 19.0909 10 19.0909ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill="black"
      />
      <path
        d="M8.48484 13.6364C8.33333 13.6364 8.18181 13.5758 8.0606 13.4546L5.0303 10.4243C4.78787 10.1818 4.78787 9.81819 5.0303 9.57577C5.27272 9.33334 5.63636 9.33334 5.87878 9.57577L8.48484 12.1818L14.1212 6.54547C14.3636 6.30304 14.7273 6.30304 14.9697 6.54547C15.2121 6.78789 15.2121 7.15153 14.9697 7.39395L8.90909 13.4546C8.78787 13.5758 8.63636 13.6364 8.48484 13.6364Z"
        fill="black"
      />
    </svg>
  );
};
