import { FC, Suspense, useEffect, useMemo, useState } from 'react';
import { addBusinessDays } from 'date-fns';
import { useQueryClient } from 'react-query';
import Tooltip from '@material-ui/core/Tooltip';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import {
  CustomDialog,
  DocumentCategoryForm,
  NoteForm,
  NoteItem,
  EmptyState,
  SendReminderForm,
  CircularProgressBar,
  Thumbs,
} from 'components';
import { CheckCrossIcons } from 'components/common/CheckCrossIcons/CheckCrossIcons';
import { apiRoutes } from 'api';
import {
  EditUserIcon,
  Na,
  LocationIcon,
  ArrowDownIcon,
  MailIcon,
  DocumentIcon,
  PlusIcon,
  DocManagementFileIcon,
  DocManFileRed,
  EditIcon,
  TableViewIcon,
} from 'icons';
import {
  useAddInfoDocumentCategoryMutation,
  useCreateDocumentCategoryMutation,
  useCreateProjectNotesMutation,
  useDeleteProjectNoteMutation,
  useEditDocumentCategoryMutation,
  useFileEditStatusMutation,
  useSendReminderMutation,
} from 'lib/react-query/mutations';
import { DocumentCategoryInfoForm } from '../DocumentCategoryInfoForm';
import { useGetProjectById, useGetProjectDocumentCategories, useGetProjectNotes } from 'lib/react-query/queries';
import { PROJECT_DOCUMENTS_QUERY_KEY } from 'lib/react-query/const';
import {
  CreateInspectionNoteFormValue,
  DocumentCategoryFormValues,
  FileStatusFormValues,
  SendRemindersFormValues,
  HttpErrorResponse,
  DocumentCategoryStatusEnum,
  CategoryStatusFormValues,
  FileStatusEnum,
  WorkOrderTypeEnum,
  WorkOrderStatusEnum,
  DocumentCategory,
  DocumentCategoryDTO,
  DocumentCategoryDocument,
  Note,
  DocumentCategoryAddInfo,
} from 'types';
import { toEnUsFormat } from 'utils';
import { emptyTableData } from 'consts';
import WorkOrderCreateForm from 'pages/WorkOrders/components/WorkOrderCreateForm';

import styles from './DocumentRequest.module.scss';
import { DocumentManagmentModalTabs, DocumentRequestProps, SelectedDialogEnum } from './types';

const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
        color: 'white',
        backgroundColor: '#212B83',
      },
    },
  },
});

export const DocumentRequest: FC<DocumentRequestProps> = ({ project, getNewStatistics, onSubmit }) => {
  const queryClient = useQueryClient();
  const [selectedTab, setSelectedTab] = useState<DocumentManagmentModalTabs>(DocumentManagmentModalTabs.Standard);
  const [selectedCategoryToEdit, setSelectedCategoryToEdit] = useState<
    (DocumentCategory & { customFields?: { [key: string]: unknown } }) | null
  >(null);
  const [activeCategories, setActiveCategories] = useState<Set<number>>(new Set());

  const [selectedOpenDialog, setSelectedOpenDialog] = useState<SelectedDialogEnum | null>(null);

  const { data: projectById, isLoading: projectLoading, refetch } = useGetProjectById(project?.id as number);
  const {
    data: projectNotes,
    refetch: getProjectNotes,
    isLoading: projectNotesLoading,
  } = useGetProjectNotes(project?.id as number);
  const {
    data: projectDocumentCategories,
    refetch: getCategories,
    isLoading,
  } = useGetProjectDocumentCategories(project?.id as number);

  const { mutate: fileEditMutate } = useFileEditStatusMutation(() => {
    toast.success('File has been successfully updated!');
    refetch();
    getCategories();
    getNewStatistics();
    onSubmit.emit('refresh');
  });

  const { mutate: deleteNoteMutate } = useDeleteProjectNoteMutation(() => {
    toast.success('File has been successfully deleted!');
    getProjectNotes();
  });

  const handleNoteDelete = async (id: number) => await deleteNoteMutate(id);

  const { mutateAsync: createNoteMutate } = useCreateProjectNotesMutation(() => {
    toast.success('Note has been successfully created!');
    getProjectNotes();
    handleCloseSelectedDialog();
    onSubmit.emit('refresh');
  });

  const { mutateAsync: categoryEditMutate } = useEditDocumentCategoryMutation(() => {
    toast.success('Category has been successfully updated!');
    refetch();
    getCategories();
    getNewStatistics();
    handleCloseSelectedDialog();
    onSubmit.emit('refresh');
  });

  const { mutateAsync: categoryCreateMutate } = useCreateDocumentCategoryMutation(project.id as number, () => {
    toast.success('Category has been successfully created!');
    handleCloseSelectedDialog();
    getCategories();
    getNewStatistics();
    onSubmit.emit('refresh');
  });

  const { mutateAsync: sendReminderMutation } = useSendReminderMutation(() => {
    toast.success('Reminder has been successfully sended!');
    handleCloseSelectedDialog();
  });

  const { mutateAsync: addInfoDocumentCategoryMutation } = useAddInfoDocumentCategoryMutation(
    selectedCategoryToEdit?.id as number,
    () => {
      toast.success('Category information has been successfully updated!');
      handleCloseSelectedDialog();
      getCategories();
    },
  );

  useEffect(() => {
    return () => {
      queryClient.resetQueries(PROJECT_DOCUMENTS_QUERY_KEY);
    };
  }, []);

  const filteredNotes = useMemo(() => (projectNotes?.items?.length ? projectNotes : emptyTableData), [projectNotes]);

  const { items: notes } = filteredNotes;

  const handleOpenCategoryEdit = () => setSelectedOpenDialog(SelectedDialogEnum.CategoryEdit);
  const handleOpenNoteCreate = () => setSelectedOpenDialog(SelectedDialogEnum.NoteCreate);
  const handleOpenCategoryCreate = () => setSelectedOpenDialog(SelectedDialogEnum.CategoryCreate);
  const handleSendRemindersOpen = () => setSelectedOpenDialog(SelectedDialogEnum.SendRemindersDialog);
  const handleOpenWorkOrderCreate = () => setSelectedOpenDialog(SelectedDialogEnum.WorkOrderCreate);
  const handleOpenCategoryInfoAdd = () => setSelectedOpenDialog(SelectedDialogEnum.CategoryInfoAdd);
  const handleCloseSelectedDialog = () => setSelectedOpenDialog(null);

  const handleSendReminderSubmit = async (values: SendRemindersFormValues) => {
    await sendReminderMutation({
      ...values,
      projectId: project.id as number,
      userIds: values.userIds.map(({ id }) => id as number),
      bankContactIds: values.bankContactIds.map(({ id }) => id as number),
    });
  };

  const handleToggleFiles = (id: number) => {
    activeCategories.has(id) ? activeCategories.delete(id) : activeCategories.add(id);
    setActiveCategories(new Set(activeCategories));
  };

  const handleNoteCreationSubmit = async (values: CreateInspectionNoteFormValue) => {
    await createNoteMutate({ ...values, project: project.id, documentTrackingNote: true });
  };

  const handleFileStatusSubmit = (
    documentCategoryId: number,
    document: number,
    values: FileStatusFormValues,
    prevStatus: string,
  ) => {
    const status = prevStatus === values.status ? FileStatusEnum.Waiting : values.status;
    fileEditMutate({ documentCategoryId, document, status });
  };

  const handleCategoryEditSubmit = async (documentCategory: number, values: DocumentCategoryFormValues) => {
    await categoryEditMutate({
      title: values.title as string,
      status: values.status as DocumentCategoryDTO,
      documentCategory,
    });
  };

  const handleCategoryCreateSubmit = async (values: DocumentCategoryFormValues) => {
    await categoryCreateMutate({
      ...values,
      title: values.title as string,
    });
  };

  const handleAddCategoryInfoSubmit = async (values: DocumentCategoryAddInfo) => {
    await addInfoDocumentCategoryMutation({
      ...values,
    });
  };

  const handleCategoryStatusChange = (
    documentCategory: number,
    newStatus: DocumentCategoryStatusEnum,
    oldStatus: DocumentCategoryStatusEnum,
  ) => {
    categoryEditMutate({
      status: oldStatus === newStatus ? DocumentCategoryStatusEnum.Missing : (newStatus as DocumentCategoryDTO),
      documentCategory,
    });
  };

  const handleOpenFile = async (uuid: string | undefined) => {
    try {
      window.open(`${process.env.REACT_APP_API_URL}${apiRoutes.files}/${uuid}`);
    } catch (e) {
      throw new Error((e as HttpErrorResponse).message);
    }
  };

  const defaultAssigneeForNewWorkOrder = {
    id: 26,
    lastName: 'Glover',
    firstName: 'Daniella ',
  };
  const initialValuesForNewWorkOrder = {
    project: {
      ...projectById,
      id: projectById?.id,
    },
    type: {
      id: WorkOrderTypeEnum.PreConstructionReview,
      name: 'Pre construction review',
    },
    assignee: defaultAssigneeForNewWorkOrder,
    status: { id: WorkOrderStatusEnum.ReadyToWrite, name: 'Ready to Write' },
    bank: projectById?.bank,
    bankContact: projectById?.bankContact,
    startDate: new Date().toISOString(),
    dueDate: addBusinessDays(new Date(), 10).toISOString() ?? '',
  };

  const Tab: FC<{ name: DocumentManagmentModalTabs }> = ({ name }) => (
    <div
      className={clsx(styles.tab, selectedTab === name && styles.active, 'flex justify-content-center')}
      onClick={() => setSelectedTab(name)}
    >
      {name}
    </div>
  );

  const FileTab = ({ documentCategoryDocuments, title, id }: DocumentCategory) => {
    const isDocuments = !!documentCategoryDocuments?.length;

    const isEveryApproved = documentCategoryDocuments?.every(
      (category: DocumentCategoryDocument) => category.status === DocumentCategoryStatusEnum.Approved,
    );

    const isSomeApproved = documentCategoryDocuments?.some(
      (category: DocumentCategoryDocument) => category.status === DocumentCategoryStatusEnum.Approved,
    );

    const isEveryWaiting = documentCategoryDocuments?.every(
      (category: DocumentCategoryDocument) => category.status === DocumentCategoryStatusEnum.Missing,
    );

    const tooltipTitle = isEveryApproved
      ? 'All the files have been accepted (thumbs up)'
      : 'Some files have not been accepted (thumbs down)';

    if (selectedTab === DocumentManagmentModalTabs.Standard) {
      return (
        <div className="flex align-items-center">
          {isDocuments && (
            <MuiThemeProvider theme={theme}>
              <Tooltip placement="bottom-start" title={tooltipTitle}>
                <div>
                  <DocManagementFileIcon />
                </div>
              </Tooltip>
            </MuiThemeProvider>
          )}

          <label
            className={clsx('ml-8 flex text-black', isDocuments && 'pointer')}
            onClick={() => handleToggleFiles(id as number)}
          >
            {title}
            {isDocuments && (
              <ArrowDownIcon
                className={clsx(styles.ArrowDownIcon, activeCategories.has(id as number) && styles.activeArrowDownIcon)}
              />
            )}
          </label>
        </div>
      );
    }

    const isBlueColorForOthersTabFiles = isEveryWaiting || (isSomeApproved && !isEveryWaiting);
    return (
      <div className="flex align-items-center">
        {isDocuments && (
          <MuiThemeProvider theme={theme}>
            <Tooltip placement="bottom-start" title={tooltipTitle}>
              <div>
                {isBlueColorForOthersTabFiles ? (
                  <DocManagementFileIcon color={isEveryWaiting || isSomeApproved ? '#212b83' : undefined} />
                ) : (
                  <DocManFileRed />
                )}
              </div>
            </Tooltip>
          </MuiThemeProvider>
        )}

        <label
          className={clsx(
            'ml-8',
            isBlueColorForOthersTabFiles ? styles.textBlue : styles.textRed,
            !isDocuments && styles.textBlack,
            isDocuments && 'pointer',
          )}
          onClick={() => handleToggleFiles(id as number)}
        >
          {title}
          {isDocuments && (
            <ArrowDownIcon
              className={clsx(
                isBlueColorForOthersTabFiles ? styles.arrowBlue : styles.arrowRed,
                styles.ArrowDownIcon,
                activeCategories.has(id as number) && styles.activeArrowDownIcon,
              )}
            />
          )}
        </label>
      </div>
    );
  };

  const DonatChart = () => (
    <div className={clsx(styles.chartContainer, 'flex align-items-center mr-12')}>
      <div className={styles.chartWrapper}>
        <CircularProgressBar value={Math.round(projectById?.suitableDocuments as number)} />
      </div>

      <div className="ml-8">
        <p>Documentation</p>
        <p>coverage</p>
      </div>
    </div>
  );

  if (isLoading || projectNotesLoading || projectLoading) return <CircularProgress size={64} />;

  return (
    <>
      <div className="flex flex-column mx-16">
        <div>
          <div className="flex flex-wrap mb-24">
            <Tab name={DocumentManagmentModalTabs.Standard} />
            <Tab name={DocumentManagmentModalTabs.Others} />
            <div className={styles.tabWrapper}>
              <Tab name={DocumentManagmentModalTabs.Notes} />
              {!!projectNotes?.items?.length && <div className={styles.notification} />}
            </div>
          </div>

          {selectedTab !== DocumentManagmentModalTabs.Notes && (
            <div className="flex flex-column">
              <div className="flex justify-content-between align-items-center mb-24">
                <div className="pr-12">
                  <LocationIcon className={styles.projectIcon} />
                  {project.name}
                </div>
                {selectedTab !== DocumentManagmentModalTabs.Standard ? (
                  <Button
                    variant="contained"
                    className={styles.categoryCreationButton}
                    onClick={handleOpenCategoryCreate}
                  >
                    + Add new category
                  </Button>
                ) : (
                  <DonatChart />
                )}
              </div>
              <div className="flex justify-content-between mb-12">
                <h2 className={styles.tableColumnHeading}>Document Name</h2>
                <h2 className={styles.tableColumnHeading}>Select appropriate status</h2>
              </div>
              {projectDocumentCategories?.map(
                ({
                  id,
                  required,
                  documentCategoryDocuments,
                  title,
                  status,
                  additionalInformation,
                  customFields,
                }: DocumentCategory & { customFields?: { [key: string]: unknown } }) =>
                  (selectedTab === DocumentManagmentModalTabs.Standard ? required : !required) && (
                    <div key={id} className={styles.optionContainer}>
                      <div className="flex justify-content-between align-items-center">
                        <div className="flex align-items-center">
                          <FileTab {...{ status, title, id, documentCategoryDocuments }} />
                        </div>
                        <div
                          className={clsx(styles.actionsContainer, 'flex justify-content-end px-16 align-items-center')}
                        >
                          {selectedTab === DocumentManagmentModalTabs.Standard && (
                            <CheckCrossIcons
                              status={status}
                              onSubmit={(e: CategoryStatusFormValues) => {
                                handleCategoryStatusChange(
                                  id as number,
                                  e.status as DocumentCategoryStatusEnum,
                                  status as DocumentCategoryStatusEnum,
                                );
                              }}
                            />
                          )}

                          <IconButton
                            className={clsx(
                              styles.naButton,
                              status === DocumentCategoryStatusEnum.Na && styles.naButtonActive,
                            )}
                            onClick={() =>
                              handleCategoryStatusChange(
                                id as number,
                                DocumentCategoryStatusEnum.Na,
                                status as DocumentCategoryStatusEnum,
                              )
                            }
                          >
                            <Na />
                          </IconButton>
                          {selectedTab === DocumentManagmentModalTabs.Others && (
                            <IconButton
                              className={clsx(styles.editIcon, 'ml-12')}
                              onClick={() => [
                                setSelectedCategoryToEdit({ id, title, customFields }),
                                handleOpenCategoryEdit(),
                              ]}
                            >
                              <EditUserIcon />
                            </IconButton>
                          )}
                          {selectedTab !== DocumentManagmentModalTabs.Others && (
                            <IconButton
                              className={'ml-12'}
                              onClick={() => [
                                setSelectedCategoryToEdit({ id, title, additionalInformation, customFields }),
                                handleOpenCategoryInfoAdd(),
                              ]}
                            >
                              <TableViewIcon />
                            </IconButton>
                          )}
                        </div>
                      </div>
                      {documentCategoryDocuments?.map((file: DocumentCategoryDocument) => (
                        <div
                          key={file?.document?.id}
                          className={clsx(
                            styles.fileContainer,
                            activeCategories.has(id as number) && styles.activeCategory,
                            'justify-content-between align-items-center px-16',
                          )}
                        >
                          <div
                            className={clsx('pl-12 pointer', styles.textBlack)}
                            onClick={() => handleOpenFile(file?.document?.file?.uuid)}
                          >
                            {file?.document?.name}
                          </div>
                          <Thumbs
                            status={file.status}
                            onSubmit={(newStatus: FileStatusFormValues) =>
                              handleFileStatusSubmit(
                                id as number,
                                file.document?.id as number,
                                newStatus,
                                file.status as string,
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  ),
              )}
            </div>
          )}
        </div>
        {selectedTab === DocumentManagmentModalTabs.Notes && (
          <>
            <div className="flex justify-content-end  mb-24">
              <Button variant="contained" className={styles.noteCreationButton} onClick={handleOpenNoteCreate}>
                + Add new note
              </Button>
            </div>

            <div className={clsx('flex flex-wrap', styles.wrapper)}>
              {notes?.map((note: Note) => (
                <div key={note.id} className="col-lg-6">
                  <NoteItem
                    overviewRefetch={getProjectNotes}
                    queryRoutes={apiRoutes.projectNotes}
                    refetch={getProjectNotes}
                    key={note.id}
                    projectName={project?.projectAddress?.state}
                    title={note.title}
                    text={note.text}
                    createdAt={note.createdAt}
                    updatedAt={note.updatedAt}
                    noteId={note.id}
                    handleDelete={() => handleNoteDelete(note.id as number)}
                  />
                </div>
              ))}
              {!notes.length && <EmptyState model="notes" />}
            </div>
          </>
        )}
        {selectedTab !== DocumentManagmentModalTabs.Notes && (
          <div
            className={clsx(
              'flex flex-md-row align-items-center justify-content-between mt-24',
              styles.modalBottomBtns,
            )}
          >
            {selectedTab !== DocumentManagmentModalTabs.Standard ? (
              <DonatChart />
            ) : (
              <Button variant="contained" onClick={handleOpenWorkOrderCreate}>
                + Create Work Order
              </Button>
            )}

            {projectById?.lastDocumentReminderSentDate && (
              <div className="flex flex-column align-items-end mr-24 ml-24">
                <h1 className={styles.reminderDate}>{toEnUsFormat(projectById?.lastDocumentReminderSentDate)}</h1>
                <label>Last reminder sent</label>
              </div>
            )}
            <Button variant="contained" onClick={handleSendRemindersOpen}>
              Send reminder
            </Button>
          </div>
        )}
      </div>
      <CustomDialog
        open={selectedOpenDialog === SelectedDialogEnum.CategoryCreate}
        header="Add new category"
        icon={<DocumentIcon className={styles.documentIcon} />}
        onClose={handleCloseSelectedDialog}
      >
        <DocumentCategoryForm
          submitButtonTitle="Add category"
          onSubmit={handleCategoryCreateSubmit}
          onClose={handleCloseSelectedDialog}
        />
      </CustomDialog>
      <CustomDialog
        open={selectedOpenDialog === SelectedDialogEnum.CategoryEdit}
        icon={<DocumentIcon className={styles.documentIcon} />}
        header="Edit document category"
        onClose={handleCloseSelectedDialog}
      >
        <DocumentCategoryForm
          submitButtonTitle="Save"
          onSubmit={(e: DocumentCategoryFormValues) =>
            handleCategoryEditSubmit(selectedCategoryToEdit?.id as number, e)
          }
          onClose={handleCloseSelectedDialog}
          title={selectedCategoryToEdit?.title}
        />
      </CustomDialog>
      <CustomDialog
        open={selectedOpenDialog === SelectedDialogEnum.NoteCreate}
        icon={<PlusIcon />}
        header="Add new note"
        onClose={handleCloseSelectedDialog}
      >
        <NoteForm buttonText="Add note" onClose={handleCloseSelectedDialog} onSubmit={handleNoteCreationSubmit} />
      </CustomDialog>
      <CustomDialog
        open={selectedOpenDialog === SelectedDialogEnum.SendRemindersDialog}
        icon={<MailIcon />}
        header="Send reminder"
        onClose={handleCloseSelectedDialog}
      >
        <SendReminderForm
          onSubmit={handleSendReminderSubmit}
          projectId={project.id as number}
          onClose={handleCloseSelectedDialog}
        />
      </CustomDialog>

      <CustomDialog
        open={selectedOpenDialog === SelectedDialogEnum.WorkOrderCreate}
        header="Create work order"
        icon={<PlusIcon />}
        onClose={handleCloseSelectedDialog}
      >
        <Suspense fallback={<span>Loading...</span>}>
          <WorkOrderCreateForm
            callback={() => null}
            handleClose={handleCloseSelectedDialog}
            initialValues={initialValuesForNewWorkOrder}
            defaultValues={{
              assignee: defaultAssigneeForNewWorkOrder,
            }}
            isNew={true}
          />
        </Suspense>
      </CustomDialog>

      <CustomDialog
        open={selectedOpenDialog === SelectedDialogEnum.CategoryInfoAdd}
        icon={<EditIcon />}
        header={selectedCategoryToEdit?.title as string}
        onClose={handleCloseSelectedDialog}
      >
        <DocumentCategoryInfoForm
          onClose={handleCloseSelectedDialog}
          onSubmit={handleAddCategoryInfoSubmit}
          values={{
            additionalInformation: selectedCategoryToEdit?.additionalInformation,
            ...selectedCategoryToEdit?.customFields,
          }}
          categoryName={selectedCategoryToEdit?.title as string}
        />
      </CustomDialog>
    </>
  );
};
