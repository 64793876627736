import { unstable_createMuiStrictModeTheme as createTheme } from '@material-ui/core/styles';
import { AutocompleteClassKey } from '@material-ui/lab/Autocomplete';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';

import { props } from './props';
import { overrides } from './overrides';
import { typography } from './typography';
import { palette } from './palette';

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module '@material-ui/core/styles/overrides' {
  interface ComponentNameToClassKey {
    MuiAutocomplete: AutocompleteClassKey;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}

export const theme = createTheme({
  typography,
  palette,
  props,
  overrides,
});
