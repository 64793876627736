export const perPage = 20;
export const perPage_100 = 100;
export const emptyTableData = {
  items: [],
  pagesTotal: 0,
  total: 0,
  count: 0,
  page: 0,
};

export const tableIdForTopScrolling = 'table';
export const mobileTableIdForTopScrolling = 'mobile-table';
