import { useFormChanges } from 'contexts/FormChanges';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import UnsavedChangesModal from 'components/common/UnsavedChangesModal';
import { useFormModal } from 'hooks/useFormModal';
import { UnsavedChangesModalVariants } from '../UnsavedChangesModal/UnsavedChangesModal';

type CustomPromptProps = {
  when?: boolean;
  onClickActionBtn?: () => void;
  setIsReportGenerating?: (state: boolean) => void;
  isReportGenerating?: boolean;
  isReloading?: boolean;
};

export const CustomPrompt = ({
  when,
  isReportGenerating,
  onClickActionBtn,
  setIsReportGenerating,
  isReloading,
}: CustomPromptProps) => {
  const history = useHistory();
  const { isFormChanged } = useFormChanges();
  const [pathname, setPathname] = useState<string>('');

  const { isUnsavedChangesModalOpen, handleCloseUnsavedChangesModal, handleOpenUnsavedChangesModal } = useFormModal();

  const handleLeavePage = () => {
    history.push(pathname);
    handleCloseUnsavedChangesModal();
    isReloading && window?.location.reload();
  };

  useEffect(() => {
    const unblock = history.block((location: Location) => {
      if (when && !isUnsavedChangesModalOpen) {
        handleOpenUnsavedChangesModal();
        const url = location.search ? location.pathname + location.search : location.pathname;
        setPathname(url);
        return false;
      }
      if (isFormChanged && !isUnsavedChangesModalOpen) {
        setPathname(location.pathname);
        handleOpenUnsavedChangesModal();
        return false;
      }
      handleCloseUnsavedChangesModal();
      return true;
    });

    return () => {
      unblock();
    };
  }, [isFormChanged, isUnsavedChangesModalOpen, when]);

  return (
    <UnsavedChangesModal
      open={isUnsavedChangesModalOpen}
      onClose={() => {
        handleCloseUnsavedChangesModal();
        setIsReportGenerating && setIsReportGenerating(false);
      }}
      closeAll={(isReportGenerating && onClickActionBtn) || handleLeavePage}
      variant={isReportGenerating ? UnsavedChangesModalVariants.reportGenerating : undefined}
    />
  );
};
