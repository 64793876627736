import axios from 'axios';

import { apiRoutes } from './routes';
import { localStorageManager, sessionStorageManager } from 'services';
import { REFRESH_TOKEN, REMEMBER_ME, TOKEN } from 'consts';
import { isValidToken } from 'utils';
import { AuthEmitter } from 'contexts';

const rememberMe = localStorage.getItem(REMEMBER_ME);

const api = axios.create({
  baseURL: apiRoutes.baseURL,
  headers: {
    'content-type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const token = localStorageManager.getItem(TOKEN) || sessionStorageManager.getItem(TOKEN);

  if (config.headers) config.headers['X-Token'] = `Bearer ${token}`;

  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401) {
      try {
        const refreshToken = localStorageManager.getItem(REFRESH_TOKEN) || sessionStorageManager.getItem(REFRESH_TOKEN);
        const response = await axios.post(
          `${apiRoutes.baseURL}${apiRoutes.refresh}`,
          {},
          {
            headers: {
              'X-Refresh-Token': refreshToken,
            },
          },
        );

        if (response.status == 200) {
          const { token, refreshToken: newRefreshToken } = response.data;

          const validated = isValidToken(token);

          if (validated) {
            rememberMe && [
              localStorageManager.setItem(TOKEN, token),
              localStorageManager.setItem(REFRESH_TOKEN, newRefreshToken),
            ];

            !rememberMe &&
              sessionStorageManager.getItem(REFRESH_TOKEN) && [
                sessionStorageManager.setItem(TOKEN, token),
                sessionStorageManager.setItem(REFRESH_TOKEN, newRefreshToken),
              ];
          }

          return api(originalRequest);
        }

        if (response.status !== 200) {
          rememberMe && [localStorageManager.removeItem(TOKEN), localStorageManager.removeItem(REFRESH_TOKEN)];
          !rememberMe && [sessionStorageManager.removeItem(TOKEN), sessionStorageManager.removeItem(REFRESH_TOKEN)];

          return;
        }
      } catch (err) {
        rememberMe && [localStorageManager.removeItem(TOKEN), localStorageManager.removeItem(REFRESH_TOKEN)];
        !rememberMe && [sessionStorageManager.removeItem(TOKEN), sessionStorageManager.removeItem(REFRESH_TOKEN)];
        AuthEmitter.emit('interceptorError');
      }
    }

    return Promise.reject(error.response);
  },
);

export default api;
