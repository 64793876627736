import { TextField } from 'formik-material-ui';
import { CustomDatePicker, Autocomplete } from 'components/common';
import { Field } from 'formik';

import { defaultFieldProps, surveyTypesOptions, surveyStampedAndSignedOptions } from '../const';

export const SurveyFields = () => (
  <>
    <Field component={TextField} name="surveyorFirm" label="Survey Firm" {...defaultFieldProps} />
    <Field component={CustomDatePicker} name="date" label="Date" removeTimezone {...defaultFieldProps} />
    <Field
      component={TextField}
      name="surveyPrepared"
      label="Who was the survey prepared for?"
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      name="type"
      placeholder="What type of survey has been provided?"
      options={surveyTypesOptions}
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      name="stampedSigned"
      placeholder="Is the survey stamped and signed by Land Surveyor?"
      options={surveyStampedAndSignedOptions}
      {...defaultFieldProps}
    />
  </>
);
