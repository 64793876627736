import React, { FC, useState } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { links } from 'App';
import { LocationIcon, ClockIcon, ClockRedIcon, UserIcon, WarningIcon } from 'icons';
import Tooltip from '@material-ui/core/Tooltip';
import { DropdownOption, WorkOrderReportStatusEnum, WorkOrderStatusEnum } from 'types';
import { getWorkOrderTypeBgColor, getWorkOrderTypeColor, toEnUsFormat, getFullName } from 'utils';
import { components } from 'generated/types';

import styles from './WorkOrderItem.module.scss';

type User = components['schemas']['User'];

interface WorkOrderItemProps {
  id?: number;
  name: string;
  location: string;
  projectName: string;
  date?: string;
  type: DropdownOption;
  editIcon?: React.ReactNode;
  deleteIcon?: React.ReactNode;
  archiveIcon?: React.ReactNode;
  dueDate?: string;
  status: string;
  dateSubmitted?: string;
  assignee: User;
  reportStatus?: string;
  isValidAddress?: boolean;
  updateReportStatus: (status: string) => void;
}

const CalendarBlock = ({
  value,
  status,
  dateSubmitted,
}: {
  value?: string;
  status: string;
  dateSubmitted?: string;
}) => {
  const today = new Date().toISOString();

  if (value && value < today && status !== WorkOrderStatusEnum.Submitted) {
    return (
      <>
        <ClockRedIcon className={styles.icon} />
        <span className={clsx(styles.errorMessage, 'ml-4')}>Past due: {value && toEnUsFormat(value)}</span>
      </>
    );
  } else if (((value && value > today) || value == today) && status !== WorkOrderStatusEnum.Submitted) {
    return (
      <>
        <ClockIcon className={styles.icon} />
        <span className="text-smallTextGrey ml-4">Due date: {value && toEnUsFormat(value)}</span>
      </>
    );
  } else if (status === WorkOrderStatusEnum.Submitted) {
    return (
      <>
        <ClockIcon className={styles.icon} />
        <span className="text-smallTextGrey ml-4">Submitted: {dateSubmitted && toEnUsFormat(dateSubmitted)}</span>
      </>
    );
  } else {
    return null;
  }
};

export const WorkOrderItem: FC<WorkOrderItemProps> = ({
  location,
  type,
  projectName,
  editIcon,
  deleteIcon,
  archiveIcon,
  date,
  reportStatus,
  id,
  status,
  dateSubmitted,
  assignee,
  isValidAddress,
  updateReportStatus,
}) => {
  const [openReportStatusChange, setOpenReportStatusChange] = useState(false);

  const handleReportStatusToggle = () => setOpenReportStatusChange(!openReportStatusChange);
  const handleReportStatusClose = () => setOpenReportStatusChange(false);

  const handleReportStatusChange = async (status: string) => {
    await updateReportStatus(status);
    handleReportStatusClose();
  };

  const ReportStatus = ({ reportStatus }: { reportStatus: string }) => {
    return (
      <article
        onClick={handleReportStatusToggle}
        className={clsx(
          reportStatus === WorkOrderReportStatusEnum[WorkOrderReportStatusEnum['not_started']] && styles.reportStatusNS,
          reportStatus === WorkOrderReportStatusEnum[WorkOrderReportStatusEnum['in_progress']] && styles.reportStatusIP,
          reportStatus === WorkOrderReportStatusEnum[WorkOrderReportStatusEnum.drafted] && styles.reportStatusDrafted,
          'my-4 text-12 px-8 py-4 p-relative',
        )}
      >
        <span className="capitalize"> {reportStatus?.replace(/_/g, ' ')}</span>

        <div className={clsx(styles.reportStatusDropdown, openReportStatusChange && styles.activeReportStatusDropdown)}>
          <span
            onClick={() =>
              handleReportStatusChange(WorkOrderReportStatusEnum[WorkOrderReportStatusEnum['not_started']])
            }
          >
            Not Started
          </span>
          <span
            onClick={() =>
              handleReportStatusChange(WorkOrderReportStatusEnum[WorkOrderReportStatusEnum['in_progress']])
            }
          >
            In Progress
          </span>
          <span
            className={clsx(styles.reportStatusDrafted, styles.dropdownReportStatusDrafted)}
            onClick={() => handleReportStatusChange(WorkOrderReportStatusEnum[WorkOrderReportStatusEnum.drafted])}
          >
            Drafted
          </span>
        </div>
      </article>
    );
  };

  return (
    <div className={styles.item}>
      <section className="mb-12 flex flex-wrap justify-content-between align-items-center">
        <span
          className={styles.chip}
          style={{
            color: getWorkOrderTypeColor(type.id as string),
            backgroundColor: getWorkOrderTypeBgColor(type.id as string),
          }}
        >
          {type.name}
        </span>
        <ReportStatus reportStatus={reportStatus as string} />
      </section>

      <section className={clsx(styles.location, 'mb-8 text-22')}>
        <div className="d-flex align-items-center">
          <LocationIcon className={clsx('mr-4', styles.locationIcon)} />
          <nav>
            <NavLink to={links.WorkOrderOverview({ id })}>{projectName}- </NavLink> {location}
          </nav>
          {!isValidAddress && (
            <Tooltip title="Address is not updated" placement="top" enterTouchDelay={0}>
              <div className="ml-20">
                <WarningIcon />
              </div>
            </Tooltip>
          )}
        </div>
      </section>

      <section className={clsx(styles.assignee, 'mb-12 text-22')}>
        <UserIcon className={clsx('mr-4', styles.userIcon)} />
        <span className={styles.assigneeLabel}>{getFullName(assignee)}</span>
      </section>
      <section className="flex align-items-center mt-8 text-14">
        <CalendarBlock value={date} dateSubmitted={dateSubmitted} status={status} />
      </section>

      <section className={clsx(styles.btnWrapper, 'flex align-items-center')}>
        {editIcon}
        {deleteIcon}
        {archiveIcon}
      </section>
    </div>
  );
};
