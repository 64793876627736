import { IconProps } from 'types';

export const InspectionIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.34835 1.84835C7.05161 1.14509 8.00544 0.75 9 0.75C9.99456 0.75 10.9484 1.14509 11.6517 1.84835C12.3549 2.55161 12.75 3.50544 12.75 4.5C12.75 5.49456 12.3549 6.44839 11.6517 7.15165C11.1279 7.67544 10.4651 8.02827 9.75 8.17425V14.25C9.75 14.6642 9.41421 15 9 15C8.58579 15 8.25 14.6642 8.25 14.25V8.17425C7.53492 8.02827 6.87214 7.67544 6.34835 7.15165C5.64509 6.44839 5.25 5.49456 5.25 4.5C5.25 3.50544 5.64509 2.55161 6.34835 1.84835ZM9 2.25C8.40326 2.25 7.83097 2.48705 7.40901 2.90901C6.98705 3.33097 6.75 3.90326 6.75 4.5C6.75 5.09674 6.98705 5.66903 7.40901 6.09099C7.83097 6.51295 8.40326 6.75 9 6.75C9.59674 6.75 10.169 6.51295 10.591 6.09099C11.0129 5.66903 11.25 5.09674 11.25 4.5C11.25 3.90326 11.0129 3.33097 10.591 2.90901C10.169 2.48705 9.59674 2.25 9 2.25ZM4.5 11.25C4.24924 11.25 4.01506 11.3753 3.87596 11.584L0.875963 16.084C0.722535 16.3141 0.708231 16.61 0.838745 16.8539C0.969259 17.0978 1.2234 17.25 1.5 17.25H16.5C16.7766 17.25 17.0307 17.0978 17.1613 16.8539C17.2918 16.61 17.2775 16.3141 17.124 16.084L14.124 11.584C13.9849 11.3753 13.7508 11.25 13.5 11.25H12C11.5858 11.25 11.25 11.5858 11.25 12C11.25 12.4142 11.5858 12.75 12 12.75H13.0986L15.0986 15.75H2.90139L4.90139 12.75H6C6.41421 12.75 6.75 12.4142 6.75 12C6.75 11.5858 6.41421 11.25 6 11.25H4.5Z"
        fill="black"
      />
    </svg>
  );
};
