import { IconProps } from 'types';

export const SettingIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.284 43.1709C14.9761 42.1833 11.9802 40.356 9.588 37.8669C10.3577 36.954 10.8375 35.8323 10.966 34.6451C11.0945 33.458 10.8659 32.2596 10.3094 31.2031C9.75298 30.1467 8.89395 29.2803 7.84229 28.7148C6.79063 28.1493 5.59417 27.9105 4.406 28.0289C4.13532 26.703 3.9993 25.3531 4 23.9999C4 21.9099 4.32 19.8939 4.916 17.9999H5C6.01976 18.0002 7.02277 17.7406 7.91434 17.2456C8.8059 16.7507 9.55659 16.0366 10.0955 15.1709C10.6345 14.3052 10.9439 13.3164 10.9945 12.2979C11.0452 11.2794 10.8354 10.2648 10.385 9.3499C12.7296 7.16672 15.5651 5.57973 18.652 4.7229C19.1544 5.7093 19.9198 6.5375 20.8637 7.11585C21.8076 7.69421 22.893 8.00017 24 7.9999C25.107 8.00017 26.1924 7.69421 27.1363 7.11585C28.0802 6.5375 28.8456 5.7093 29.348 4.7229C32.4349 5.57973 35.2704 7.16672 37.615 9.3499C37.1613 10.2712 36.9517 11.2935 37.0062 12.3191C37.0608 13.3446 37.3776 14.3389 37.9264 15.2069C38.4752 16.0749 39.2376 16.7876 40.1407 17.2766C41.0437 17.7656 42.0572 18.0147 43.084 17.9999C43.693 19.9416 44.0018 21.9649 44 23.9999C44 25.3799 43.86 26.7279 43.594 28.0289C42.4058 27.9105 41.2094 28.1493 40.1577 28.7148C39.1061 29.2803 38.247 30.1467 37.6906 31.2031C37.1341 32.2596 36.9055 33.458 37.034 34.6451C37.1625 35.8323 37.6423 36.954 38.412 37.8669C36.0198 40.356 33.0239 42.1833 29.716 43.1709C29.3276 41.9602 28.5647 40.9041 27.5374 40.1548C26.5102 39.4056 25.2715 39.0018 24 39.0018C22.7285 39.0018 21.4899 39.4056 20.4626 40.1548C19.4353 40.9041 18.6725 41.9602 18.284 43.1709Z"
        stroke="black"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M24 31C24.9193 31 25.8295 30.8189 26.6788 30.4672C27.5281 30.1154 28.2997 29.5998 28.9497 28.9497C29.5998 28.2997 30.1154 27.5281 30.4672 26.6788C30.8189 25.8295 31 24.9193 31 24C31 23.0807 30.8189 22.1705 30.4672 21.3212C30.1154 20.4719 29.5998 19.7003 28.9497 19.0503C28.2997 18.4002 27.5281 17.8846 26.6788 17.5328C25.8295 17.1811 24.9193 17 24 17C22.1435 17 20.363 17.7375 19.0503 19.0503C17.7375 20.363 17 22.1435 17 24C17 25.8565 17.7375 27.637 19.0503 28.9497C20.363 30.2625 22.1435 31 24 31V31Z"
        stroke="black"
        strokeWidth="4"
        strokeLinejoin="round"
      />
    </svg>
  );
};
