import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { getLabelByProposalStatusesEnum } from 'utils/common';
import commonStyles from 'styles/common.module.scss';
import transformDateToMonthWithYear from 'utils/productivity-metrics/transformDateToMonthWithYear';
import { ProposalStatusEnum } from 'types/proposal';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ProductivityMetricsStats = any;

export const usePMProjectPortfolioTableColumns = () => {
  const annualRfpStatusesColumns = useMemo(
    () => [
      {
        id: 'status',
        Header: 'Status',
        minWidth: 120,
        maxWidth: 120,
        Cell: ({
          row: {
            original: { status },
          },
        }: CellProps<ProductivityMetricsStats>) => <>{getLabelByProposalStatusesEnum(status as ProposalStatusEnum)}</>,
      },
      {
        id: 'number',
        Header: () => <span className={commonStyles.tableItemEndAlign}>Number</span>,
        minWidth: 180,
        maxWidth: 180,
        Cell: ({
          row: {
            original: { count },
          },
        }: CellProps<ProductivityMetricsStats>) => <span className={commonStyles.tableItemEndAlign}>{count}</span>,
      },
      {
        id: 'percentage',
        Header: () => <span className={commonStyles.tableItemEndAlign}>Percentage</span>,
        minWidth: 180,
        maxWidth: 180,
        Cell: ({
          row: {
            original: { percentage },
          },
        }: CellProps<ProductivityMetricsStats>) => (
          <span className={commonStyles.tableItemEndAlign}>{percentage}%</span>
        ),
      },
    ],
    [],
  );

  const monthlyRfpStatusesColumns = useMemo(
    () => [
      {
        id: 'month',
        Header: 'Month',
        minWidth: 140,
        maxWidth: 140,
        Cell: ({
          row: {
            original: { month },
          },
        }: CellProps<ProductivityMetricsStats>) => <>{transformDateToMonthWithYear(month)}</>,
      },
      {
        id: 'total',
        Header: 'Total',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({
          row: {
            original: { total },
          },
        }: CellProps<ProductivityMetricsStats>) => <span className={commonStyles.tableItemEndAlign}>{total}</span>,
      },
      {
        id: 'to do',
        Header: 'To Do',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({
          row: {
            original: { to_do },
          },
        }: CellProps<ProductivityMetricsStats>) => <span className={commonStyles.tableItemEndAlign}>{to_do}</span>,
      },
      {
        id: 'in progress',
        Header: 'In Progress',
        minWidth: 120,
        maxWidth: 120,
        Cell: ({
          row: {
            original: { in_progress },
          },
        }: CellProps<ProductivityMetricsStats>) => (
          <span className={commonStyles.tableItemEndAlign}>{in_progress}</span>
        ),
      },
      {
        id: 'in review',
        Header: 'In Review',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({
          row: {
            original: { in_review },
          },
        }: CellProps<ProductivityMetricsStats>) => <span className={commonStyles.tableItemEndAlign}>{in_review}</span>,
      },
      {
        id: 'pending',
        Header: 'Pending',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({
          row: {
            original: { pending },
          },
        }: CellProps<ProductivityMetricsStats>) => <span className={commonStyles.tableItemEndAlign}>{pending}</span>,
      },
      {
        id: 'in rework',
        Header: 'In Rework',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({
          row: {
            original: { in_rework },
          },
        }: CellProps<ProductivityMetricsStats>) => <span className={commonStyles.tableItemEndAlign}>{in_rework}</span>,
      },
      {
        id: 'no response',
        Header: 'No Response',
        minWidth: 120,
        maxWidth: 120,
        Cell: ({
          row: {
            original: { no_response },
          },
        }: CellProps<ProductivityMetricsStats>) => (
          <span className={commonStyles.tableItemEndAlign}>{no_response}</span>
        ),
      },
      {
        id: 'rejected',
        Header: 'Rejected',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({
          row: {
            original: { rejected },
          },
        }: CellProps<ProductivityMetricsStats>) => <span className={commonStyles.tableItemEndAlign}>{rejected}</span>,
      },
      {
        id: 'acccepted',
        Header: 'Accepted',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({
          row: {
            original: { accepted },
          },
        }: CellProps<ProductivityMetricsStats>) => <span className={commonStyles.tableItemEndAlign}>{accepted}</span>,
      },
    ],
    [],
  );

  return {
    annualRfpStatusesColumns,
    monthlyRfpStatusesColumns,
  };
};
