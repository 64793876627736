import React, { ChangeEvent } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { CloseIcon, SearchIcon } from 'icons';

import styles from './SearchField.module.scss';

interface SearchFieldProps {
  value: string;
  onChange: (value: string) => void;
  onSearch?: (event: React.KeyboardEvent<HTMLElement>) => void;
  className?: string;
  isFullWidth?: boolean;
  onBlur?: () => void;
  handleClear?: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiOutlinedInput-root.MuiInputBase-formControl': {
        ['@media (max-width:576px)']: {
          // eslint-disable-line no-useless-computed-key
          padding: '4px 8px',
        },
      },
    },
  }),
);

export const SearchField = ({
  value,
  onChange,
  onSearch,
  className,
  isFullWidth,
  onBlur,
  handleClear,
}: SearchFieldProps) => {
  const classes = useStyles();
  const handleSearchFieldChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => onChange(value);

  return (
    <TextField
      onChange={handleSearchFieldChange}
      autoFocus
      onKeyPress={onSearch}
      value={value}
      placeholder="Search..."
      className={clsx(styles.searchField, classes.root, className, 'mb-12')}
      fullWidth={isFullWidth}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon onClick={onBlur} className={styles.searchIcon} />
          </InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment position="end">
            <CloseIcon className={styles.closeIcon} onClick={handleClear} />
          </InputAdornment>
        ),
      }}
    />
  );
};
