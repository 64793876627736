import { IconProps } from 'types';

export const JpgImg = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.75 25.6666C24.683 25.6666 26.25 24.0996 26.25 22.1666V5.83325C26.25 3.90026 24.683 2.33325 22.75 2.33325H5.25C3.317 2.33325 1.75 3.90025 1.75 5.83325V22.1666C1.75 24.0996 3.317 25.6666 5.25 25.6666H22.75ZM23.9167 16.8215V5.83325C23.9167 5.18892 23.3943 4.66659 22.75 4.66659H5.25C4.60567 4.66659 4.08333 5.18892 4.08333 5.83325V22.1666C4.08333 22.8109 4.60567 23.3333 5.25 23.3333H17.7996L23.9167 16.8215ZM21.001 23.3333H22.75C23.3943 23.3333 23.9167 22.8109 23.9167 22.1666V20.2294L21.001 23.3333Z"
        fill="#BF2228"
      />
      <path
        d="M8.03867 11.1698H9.78496V13.928C9.78496 14.5071 9.72726 14.9478 9.61187 15.25C9.49904 15.5523 9.26954 15.8084 8.92336 16.0183C8.57974 16.2283 8.13868 16.3333 7.60018 16.3333C7.0309 16.3333 6.58985 16.264 6.277 16.1256C5.96416 15.9872 5.72183 15.7853 5.55002 15.52C5.38078 15.2523 5.28077 14.9224 5.25 14.5302L6.91551 14.326C6.91808 14.5498 6.93987 14.7159 6.9809 14.8244C7.02193 14.9328 7.09117 15.0205 7.18861 15.0874C7.25528 15.1312 7.35016 15.1531 7.47325 15.1531C7.66813 15.1531 7.81045 15.0885 7.9002 14.9593C7.99252 14.8278 8.03867 14.6075 8.03867 14.2983V11.1698Z"
        fill="#BF2228"
      />
      <path
        d="M10.9389 11.1698H13.8353C14.4661 11.1698 14.9379 11.3047 15.2508 11.5747C15.5662 11.8446 15.7239 12.2288 15.7239 12.7271C15.7239 13.2393 15.5521 13.6396 15.2084 13.928C14.8674 14.2164 14.3456 14.3606 13.6429 14.3606H12.689V16.2433H10.9389V11.1698ZM12.689 13.3328H13.116C13.4519 13.3328 13.6878 13.2808 13.8237 13.177C13.9596 13.0709 14.0276 12.9359 14.0276 12.7721C14.0276 12.6129 13.9686 12.4779 13.8507 12.3672C13.7327 12.2565 13.5109 12.2011 13.1852 12.2011H12.689V13.3328Z"
        fill="#BF2228"
      />
      <path
        d="M19.4742 14.4056V13.3501H22.1667V15.513C21.6512 15.8291 21.1948 16.0449 20.7973 16.1602C20.4024 16.2733 19.9332 16.3298 19.3895 16.3298C18.7203 16.3298 18.1741 16.2271 17.7509 16.0218C17.3304 15.8164 17.0035 15.5107 16.7701 15.1047C16.5393 14.6986 16.4239 14.2326 16.4239 13.7065C16.4239 13.1528 16.5509 12.6717 16.8047 12.2634C17.0586 11.8527 17.4304 11.5412 17.9202 11.329C18.3023 11.1652 18.8164 11.0833 19.4626 11.0833C20.0857 11.0833 20.5512 11.134 20.8589 11.2355C21.1692 11.337 21.4256 11.4951 21.6282 11.7097C21.8333 11.9219 21.9872 12.1919 22.0897 12.5195L20.4088 12.7894C20.3396 12.5979 20.2216 12.4514 20.055 12.3499C19.8909 12.2484 19.6806 12.1976 19.4242 12.1976C19.0421 12.1976 18.7369 12.3176 18.5087 12.5575C18.283 12.7952 18.1702 13.1724 18.1702 13.6892C18.1702 14.2383 18.2843 14.6305 18.5125 14.8659C18.7433 15.1012 19.0639 15.2189 19.4742 15.2189C19.669 15.2189 19.855 15.1935 20.0319 15.1427C20.2088 15.092 20.4114 15.0055 20.6396 14.8832V14.4056H19.4742Z"
        fill="#BF2228"
      />
    </svg>
  );
};
