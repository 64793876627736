import { IconProps } from 'types';

export const ArrowDoubleDownIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99992 5.00008C5.20825 5.00008 5.41659 5.08341 5.58325 5.25008L9.99992 9.66675L14.4166 5.25008C14.7499 4.91675 15.2499 4.91675 15.5833 5.25008C15.9166 5.58341 15.9166 6.08342 15.5833 6.41675L10.5833 11.4167C10.2499 11.7501 9.74992 11.7501 9.41659 11.4167L4.41658 6.41675C4.08325 6.08342 4.08325 5.58341 4.41658 5.25008C4.58325 5.08341 4.79159 5.00008 4.99992 5.00008Z"
        fill="black"
      />
      <path
        d="M4.99992 9.16659C5.20825 9.16659 5.41659 9.24992 5.58325 9.41659L9.99992 13.8333L14.4166 9.41659C14.7499 9.08325 15.2499 9.08325 15.5833 9.41659C15.9166 9.74992 15.9166 10.2499 15.5833 10.5833L10.5833 15.5833C10.2499 15.9166 9.74992 15.9166 9.41659 15.5833L4.41658 10.5833C4.08325 10.2499 4.08325 9.74992 4.41658 9.41659C4.58325 9.24992 4.79159 9.16659 4.99992 9.16659Z"
        fill="black"
      />
    </svg>
  );
};
