import { FC, useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { LoadingButton, RichEditor } from 'components';
import { useFormChanges } from 'contexts/FormChanges';

import styles from './NoteForm.module.scss';

export interface CreateNoteValues {
  title?: string;
  text?: string;
  inspection?: number;
}

interface NotesInterface {
  onSubmit: (values: CreateNoteValues) => void | Promise<void>;
  onClose: () => void;
  title?: string;
  text?: string;
  buttonText?: string;
}

export const NoteForm: FC<NotesInterface> = ({ text, title, onSubmit, onClose, buttonText }) => {
  const { checkFormChanges } = useFormChanges();
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string().required('Title is a required field'),
        text: Yup.string(),
      }),
    [],
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        title: title ?? '',
        text: text ?? '',
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, initialValues, values }) => {
        checkFormChanges(initialValues, values);

        return (
          <Form>
            <Box className={clsx('flex flex-column justify-content-between', styles.modal)}>
              <div className="flex flex-wrap">
                <div className="col-12 mb-24 mb-md-36">
                  <Field component={TextField} name="title" label="Title" variant="outlined" />
                </div>
                <div className="col-12 mb-24 mb-md-36">
                  <Field
                    component={RichEditor}
                    multiline
                    rows={6}
                    name="text"
                    label="Text"
                    variant="outlined"
                    type="text"
                    className="mb-24 mb-md-36"
                    isShowLoader
                  />
                </div>
              </div>

              <div className={clsx('flex pt-24 justify-content-end px-16')}>
                <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                  {buttonText}
                </LoadingButton>
                <Button variant="text" type="reset" onClick={onClose} className="ml-12">
                  Cancel
                </Button>
              </div>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
