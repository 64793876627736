import React from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import { BellIcon, BuildingIcon, UserIcon, ClockIcon, ClockRedIcon, CheckedIcon } from 'icons';
import { toEnUsFormat, getFullName, getReportTypeBgColor, getReportTypeColor } from 'utils';
import { LinearProgressWithLabel } from 'components';
import { components } from 'generated/types';
import { links } from 'App';
import { DropdownOption, ReportTypesEnum } from 'types';
import { getDefaultSectionByReportType, getLabelByReportTypesEnum } from 'utils/common';

import styles from './ReportCard.module.scss';

type User = components['schemas']['User'];

type ReportCardProps = {
  id: number;
  reportName?: string;
  reportType?: string;
  reportTypeName?: string;
  projectName?: string;
  assignee?: User;
  percentageCompleted?: string | number;
  notificationCount?: number | null;
  date?: Date | string;
  editIcon?: React.ReactNode;
  deleteIcon?: React.ReactNode;
  hasIssueCounter?: number;
  reportWorkOrderStatus?: DropdownOption;
};

const getWorkOrderStatus = (status?: DropdownOption) => {
  if (status) {
    let svgIcon;
    let style;
    if (status.id === 'to_do') {
      style = styles.toDo;
    }
    if (status.id === 'waiting') {
      style = styles.toDo;
      svgIcon = <ClockIcon className={clsx(style, 'ml-4')} />;
    }

    if (status.id === 'ready_to_write' || status.id === 'ready_for_review') {
      style = styles.readyToWrite;
      svgIcon = <CheckedIcon className={clsx(style)} />;
    }

    if (status.id === 'submitted') {
      style = styles.submitted;
      svgIcon = <CheckedIcon className={clsx(style)} />;
    }

    return (
      <span className={style}>
        {status.name} {svgIcon}
      </span>
    );
  }
};
const CountCircle = ({ value }: { value: number }) => {
  return <div className={styles.countCircle}>{value}</div>;
};

const CalendarBlock = ({ value }: { value: string | Date | undefined }) => {
  const today = new Date().toISOString();

  if ((value && value > today) || value == today) {
    return (
      <div className="flex pt-8 text-14">
        <ClockIcon className={styles.icon} />
        <p className="text-smallTextGrey ml-12">Due date:{toEnUsFormat(value)}</p>
      </div>
    );
  }
  return (
    <div className="flex pt-8 align-items-center text-14">
      <ClockRedIcon className={styles.icon} />
      <p className={clsx(styles.errorMessage, 'ml-12 mt-4')}>Past due {toEnUsFormat(value)}</p>
    </div>
  );
};

export const ReportCard = ({
  reportName,
  reportType,
  projectName,
  reportTypeName,
  assignee,
  percentageCompleted,
  date,
  id,
  editIcon,
  deleteIcon,
  hasIssueCounter,
  reportWorkOrderStatus,
}: ReportCardProps) => {
  return (
    <div className={clsx('flex flex-column', styles.cardWrapper)}>
      <div className={clsx(styles.header, 'flex justify-content-between align-items-center')}>
        <div className="flex flex-column align-items-start pt-16 pl-16">
          <span
            className="py-4 px-12 mb-12"
            style={{
              color: getReportTypeColor(reportType as ReportTypesEnum),
              backgroundColor: getReportTypeBgColor(reportType as ReportTypesEnum),
              borderRadius: '23px',
            }}
          >
            {getLabelByReportTypesEnum(reportTypeName as ReportTypesEnum)}
          </span>
          <span className="ml-12 mb-12 text-14">{getWorkOrderStatus(reportWorkOrderStatus)}</span>
        </div>
        {!!hasIssueCounter && (
          <div className={clsx(styles.wrapper, 'pt-8 pr-16')}>
            <BellIcon />
            <CountCircle value={hasIssueCounter} />
          </div>
        )}
      </div>
      <div className="flex flex-column ml-16">
        <p className={styles.title}>
          <Tooltip title={reportName as string}>
            <NavLink
              to={links.ReportWriting({ id, section: getDefaultSectionByReportType(reportType as ReportTypesEnum) })}
            >
              {reportName}
            </NavLink>
          </Tooltip>
        </p>
        <div className="flex mt-12">
          <BuildingIcon className={styles.icon} />
          <p className="ml-12 pr-16 text-14">{projectName}</p>
        </div>
        <div className="flex mt-8">
          <UserIcon className={styles.icon} />
          <p className="ml-12 pr-16">
            <span className="text-smallTextGrey text-14">
              assigned to: <span className="weight-600">{getFullName(assignee)}</span>
            </span>
          </p>
        </div>

        <CalendarBlock value={date} />

        <LinearProgressWithLabel vertical={false} value={percentageCompleted as number} />

        <section className={clsx(styles.btnWrapper, 'flex align-items-center')}>
          {editIcon}
          {deleteIcon}
        </section>
      </div>
    </div>
  );
};
