import { api } from 'api';
import { OPENDATASOFT_URL } from 'consts';
import { useQuery } from 'react-query';

export const useGetBoundaries = () => {
  const boundariesInfoQuery = () =>
    api.get(`${OPENDATASOFT_URL}?dataset=us-state-boundaries&q=`).then((res) => res.data);

  return useQuery('boundaries', () => boundariesInfoQuery());
};
