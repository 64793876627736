import React, { FC } from 'react';
import clsx from 'clsx';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { components } from 'generated/types';
import { Thumbs } from 'components';
import { LocationIcon } from 'icons';
import { api, apiRoutes } from 'api';
import { FileStatusFormValues, RequestFileStatusFormValues } from 'types';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './FileRequest.module.scss';

type DocumentCategoryDocument = components['schemas']['DocumentCategoryDocument'];

interface FileRequestProps {
  onClose?: () => void;
  id: number | undefined;
  getNewStatistics: () => void;
  getNewDocuments: () => void;
}

export const FileRequest: FC<FileRequestProps> = ({ id, getNewStatistics, getNewDocuments }) => {
  const fileEditMutation = (values: RequestFileStatusFormValues, document: number) =>
    api.put(`${apiRoutes.documents}/${document}/status`, values).then((res) => res);

  const { mutate: fileEditMutate, isLoading: fileEditMutationLoading } = useMutation(
    'fileEditMutation',
    (values: RequestFileStatusFormValues) =>
      // eslint-disable-next-line
      fileEditMutation((({ document, ...values }) => ({ ...values }))(values), values.document as number),
    {
      onSuccess: () => {
        toast.success('File has been successfully updated!');
        getNewStatistics();
        getNewDocuments();
      },
    },
  );

  const documentQuery = () => api.get(`${apiRoutes.documents}/${id}`).then((res) => res.data);
  const {
    data: file,
    isLoading: isFileLoading,
    isFetching: isFileFetching,
  } = useQuery(['documentsQuery'], () => documentQuery());

  const handleSubmit = (documentCategoryId: number, document: number, values: FileStatusFormValues) => {
    fileEditMutate({ ...values, documentCategoryId, document });
  };

  if (fileEditMutationLoading || isFileLoading || isFileFetching) return <CircularProgress size={64} />;

  return (
    <div className="flex flex-column mt-20 mt-sm-0">
      <div className="flex flex-wrap align-items-center justify-content-between mb-24 px-16">
        <h2 className={styles.title}>{file?.name}</h2>
        <div>
          <span className={clsx(styles.projectIcon, 'mr-4')}>
            <LocationIcon />
          </span>
          {file?.project?.name}
        </div>
      </div>
      <div className="flex flex-column mx-16">
        <div>
          <div className="flex flex-column">
            <div className="flex justify-content-between mb-12">
              <h2 className={styles.tableColumnHeading}>Category Name</h2>
              <h2 className={styles.tableColumnHeading}>Select appropriate status</h2>
            </div>
            {file?.documentCategoryDocuments?.map((documentCategoryDocument: DocumentCategoryDocument) => (
              <div key={documentCategoryDocument?.documentCategory?.id} className={styles.optionContainer}>
                <div className="flex justify-content-between align-items-center">
                  <label>{documentCategoryDocument?.documentCategory?.title}</label>
                  <div className="flex justify-content-end px-16">
                    <Thumbs
                      status={documentCategoryDocument.status}
                      onSubmit={(e: FileStatusFormValues) =>
                        handleSubmit(documentCategoryDocument?.documentCategory?.id as number, file?.id as number, e)
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
