interface IteratorInterface<T> {
  next: () => T;
  current: () => T;
  prev: () => T;
  hasPrev: () => boolean;
  hasNext: () => boolean;
}

export class Iterator<T> implements IteratorInterface<T> {
  private index: number;
  private items: T[];
  constructor(items: T[], index?: number) {
    this.index = index ?? 0;
    this.items = items;
  }
  next() {
    return this.items[++this.index];
  }
  hasPrev() {
    return this.index > 0;
  }
  prev() {
    return this.items[--this.index];
  }
  hasNext() {
    return this.index + 1 < this.items.length;
  }
  current() {
    return this.items[this.index];
  }

  getIndex() {
    return this.index;
  }
}
