import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useQuery } from 'react-query';
import CircularProgress from '@material-ui/core/CircularProgress';

import { CompanyTypesEnum, DropdownOption, WorkOrderFormValues, WorkOrderTypeEnum } from 'types';
import { api, apiRoutes } from 'api';
import { Autocomplete, CustomDatePicker, LoadingButton } from 'components';
import { getFullName } from 'utils';
import { useFormChanges } from 'contexts/FormChanges';
import { Project, BankContact, WorkOrderType, WorkOrderStatus, User, Inspection, Organization } from 'types/common';

type WorkOrderFormFormProps = {
  onClose: () => void;
  onSubmit: (values: WorkOrderFormValues, hasAssociatedInspections?: boolean) => void | Promise<void>;
  submitButtonTitle: string;
  project?: Project;
  name?: string;
  startDate?: string;
  dueDate?: string;
  bank?: Organization;
  bankContact?: BankContact;
  dateSubmitted?: string;
  type?: WorkOrderType;
  status?: WorkOrderStatus;
  assignee?: User;
  drafter?: User;
  inspection?: Inspection;
  isNew?: boolean;
  defaultValues?: {
    assignee?: User;
  };
};

export const WorkOrderForm: FC<WorkOrderFormFormProps> = ({
  onClose,
  onSubmit,
  submitButtonTitle,
  project,
  startDate,
  dueDate,
  dateSubmitted,
  name,
  type,
  bank,
  bankContact,
  status,
  assignee,
  drafter,
  inspection,
  isNew = true,
  defaultValues,
}) => {
  const { checkFormChanges } = useFormChanges();
  const [activeProject, setActiveProject] = useState<string | number | null>(null);
  //eslint-disable-next-line
  const formRef = useRef<any>();

  const projectsQuery = () =>
    api
      .get<DropdownOption[]>(apiRoutes.projects, {
        params: {
          'filter[_dropdown]': true,
          'sort[name]': 'ASC',
        },
      })
      .then((res) => res.data);

  const { isLoading: projectLoading, data: projects } = useQuery('projectsQuery', () => projectsQuery(), {
    enabled: isNew,
  });

  const inspectionsQuery = () =>
    api
      .get<DropdownOption[]>(apiRoutes.inspections, {
        params: {
          'filter[_project]': activeProject,
          'filter[_dropdown]': true,
          'filter[_unassigned]': true,
          'sort[name]': 'ASC',
        },
      })
      .then((res) => res.data);
  const { data: inspections } = useQuery(['inspectionsQuery', activeProject], () => inspectionsQuery(), {
    enabled: isNew,
  });

  const projectByIdQuery = () => api.get<Project>(`${apiRoutes.projects}/${activeProject}`).then((res) => res.data);

  const { data: projectById, refetch } = useQuery('projectByIdQuery', () => projectByIdQuery(), {
    enabled: false,
  });

  const usersQuery = () =>
    api
      .get<DropdownOption[]>(apiRoutes.users, {
        params: {
          'filter[_dropdown]': true,
          'sort[firstName]': 'ASC',
          'sort[lastName]': 'ASC',
        },
      })
      .then((res) => res.data);
  const { isLoading: usersLoading, data: users } = useQuery('usersQuery', () => usersQuery());

  const statusesQuery = () => api.get<DropdownOption[]>(apiRoutes.workOrderStatuses).then((res) => res.data);
  const { isLoading: statusesLoading, data: statuses } = useQuery('statusesQuery', () => statusesQuery());

  const workOrderTypesQuery = () => api.get<DropdownOption[]>(apiRoutes.workOrderTypes).then((res) => res.data);
  const { isLoading: typesLoading, data: types } = useQuery('workOrderTypesQuery', () => workOrderTypesQuery());

  const companiesQuery = () =>
    api
      .get<Organization[]>(apiRoutes.organization, {
        params: {
          'sort[name]': 'ASC',
        },
      })
      .then((res) => res.data);
  const { isLoading: companiesLoading, data: companies } = useQuery('companiesQuery', () => companiesQuery());

  const contactQuery = () =>
    api
      .get<BankContact[]>(apiRoutes.contacts, {
        params: {
          'filter[_dropdown]': true,
          'sort[firstName]': 'ASC',
          'sort[lastName]': 'ASC',
        },
      })
      .then((res) => res.data);

  const { isLoading: contactsLoading, data: contacts } = useQuery('contactQuery', () => contactQuery());

  const getOptionName = ({ name }: DropdownOption) => name ?? '';

  const filteredProjects = useMemo(() => (projects?.length ? projects : []), [projects]);

  useEffect(() => {
    if (projectById && isNew) {
      if (
        formRef?.current?.values.type &&
        (formRef?.current?.values.type.id === WorkOrderTypeEnum.PreConstructionReview ||
          formRef?.current?.values.type.id === WorkOrderTypeEnum.InspectionReport ||
          formRef?.current?.values.type.id === WorkOrderTypeEnum.Pca ||
          formRef?.current?.values.type.id === WorkOrderTypeEnum.ContractorCapabilityEvaluation ||
          formRef?.current?.values.type.id === WorkOrderTypeEnum.EntitlementReport)
      ) {
        const defaultAssignee = defaultValues?.assignee
          ? { ...defaultValues?.assignee, name: getFullName(defaultValues?.assignee) }
          : { ...projectById?.owner, name: getFullName(projectById?.owner) };

        formRef?.current?.setFieldValue('assignee', defaultAssignee);
        formRef?.current?.setFieldValue('bank', projectById?.bank);
        formRef?.current?.setFieldValue('bankContact', {
          ...projectById?.bankContact,
          name: getFullName(projectById?.bankContact),
        });
      }
    }
  }, [projectById]);

  const filteredContacts = useMemo(() => (contacts?.length ? contacts : []), [contacts]);
  const filteredInspections = useMemo(() => (inspections?.length ? inspections : []), [inspections]);
  const filteredUsers = useMemo(() => (users?.length ? users : []), [users]);
  const validationSchema = useMemo(
    () =>
      Yup.object().shape(
        {
          projectId: Yup.object()
            .shape({
              id: Yup.string().required('Project is a required field'),
              name: Yup.string().required('Project is a required field'),
            })
            .typeError('Project is a required field'),
          name: Yup.string().required('Work order name is a required field'),
          type: Yup.object()
            .shape({
              id: Yup.string().required('Work order type is a required field'),
              name: Yup.string().required('Work order type is a required field'),
            })
            .typeError('Work order type is a required field'),
          status: Yup.object()
            .shape({
              id: Yup.string().required('Status is a required field'),
              name: Yup.string().required('Status is a required field'),
            })
            .typeError('Project is a required field'),
          startDate: Yup.string().nullable(),
          dueDate: Yup.string().nullable(),
          dateSubmitted: Yup.string().nullable(),
          assignee: Yup.object()
            .shape({
              id: Yup.number().required('Assignee is a required field'),
              name: Yup.string().required('Assignee is a required field'),
            })
            .typeError('Assignee is a required field'),
          bank: Yup.object()
            .shape({
              id: Yup.number().required('Bank is a required field'),
              name: Yup.string().required('Bank is a required field'),
            })
            .typeError('Bank is a required field'),
          bankContact: Yup.object()
            .shape({
              id: Yup.number(),
              name: Yup.string(),
            })
            .nullable(),
          drafter: Yup.object()
            .shape({
              id: Yup.number(),
              name: Yup.string(),
            })
            .typeError(''),
          inspectionId: Yup.object()
            .shape({
              id: Yup.string(),
              name: Yup.string(),
            })
            .typeError('')
            .nullable(),
        },
        [['startDate', 'dueDate']],
      ),
    [],
  );

  const getProjectInspections = useCallback(() => {
    return activeProject ? filteredInspections?.map(({ id, name }) => ({ id, name })) : [];
  }, [filteredInspections, activeProject]);

  const getBankContacts = useCallback(
    (bankId: number | string) => {
      return bankId
        ? filteredContacts
            .filter(({ organization }) => organization?.id === bankId)
            ?.map((item) => ({ id: item.id, name: getFullName(item as User) })) ?? []
        : [];
    },
    [filteredContacts],
  );

  const setAutomatedFields = (
    value: DropdownOption,
    //eslint-disable-next-line
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    //eslint-disable-next-line
    values: any,
  ) => {
    if (
      value &&
      (value.id === WorkOrderTypeEnum.PreConstructionReview ||
        value.id === WorkOrderTypeEnum.InspectionReport ||
        value.id === WorkOrderTypeEnum.Pca ||
        value.id === WorkOrderTypeEnum.ContractorCapabilityEvaluation ||
        value.id === WorkOrderTypeEnum.FundsControl ||
        value.id === WorkOrderTypeEnum.EntitlementReport)
    ) {
      const nameEntitlementReport = 'Entitlement Report';
      const currentDate = new Date();
      const wkdy = currentDate.getDay();
      const addDays =
        value.id === WorkOrderTypeEnum.PreConstructionReview || value.id === WorkOrderTypeEnum.EntitlementReport
          ? 14
          : wkdy >= 3
          ? 5
          : 3;
      const addDaysFunds = 7;
      currentDate.setDate(
        value.id !== WorkOrderTypeEnum.FundsControl
          ? currentDate.getDate() + addDays
          : currentDate.getDate() + addDaysFunds,
      );

      setFieldValue('startDate', new Date());

      if (value.id === WorkOrderTypeEnum.EntitlementReport) {
        setFieldValue('name', nameEntitlementReport);
      }

      if (
        value.id === WorkOrderTypeEnum.PreConstructionReview ||
        value.id === WorkOrderTypeEnum.InspectionReport ||
        value.id === WorkOrderTypeEnum.FundsControl ||
        value.id === WorkOrderTypeEnum.EntitlementReport
      ) {
        setFieldValue('dueDate', currentDate);
      }

      setFieldValue(
        'status',
        statuses?.find((status) => status.id === 'to_do'),
      );

      const userForFunds = filteredUsers.find(({ id }) => id === 18);

      if (values.projectId.id && isNew) {
        value.id !== WorkOrderTypeEnum.FundsControl
          ? setFieldValue('assignee', { ...projectById?.owner, name: getFullName(projectById?.owner) })
          : setFieldValue('assignee', {
              id: userForFunds?.id,
              name: getFullName(userForFunds as User),
            });
        setFieldValue('bank', projectById?.bank);
        setFieldValue('bankContact', {
          ...projectById?.bankContact,
          name: getFullName(projectById?.bankContact),
        });
      }
    }
  };

  useEffect(() => {
    if (activeProject) {
      refetch();
    }
  }, [activeProject]);

  useEffect(() => {
    return () => {
      formRef.current = null;
    };
  }, []);

  if (projectLoading || statusesLoading || usersLoading || typesLoading || companiesLoading || contactsLoading)
    return <CircularProgress size={64} />;

  return (
    <>
      <Formik
        innerRef={formRef}
        validationSchema={validationSchema}
        initialValues={{
          projectId: (project ? { ...project, id: project.id } : {}) as DropdownOption,
          bank: (bank ?? {}) as DropdownOption,
          type: (type ?? {}) as DropdownOption,
          name: name ?? '',
          startDate: startDate ?? null,
          dueDate: dueDate ?? null,
          dateSubmitted: dateSubmitted ?? null,
          status: (status ?? {}) as DropdownOption,
          assignee: ({ ...assignee, name: getFullName(assignee) } ?? {}) as DropdownOption,
          drafter: ({ ...drafter, name: getFullName(drafter) } ?? {}) as DropdownOption,
          inspectionId: (inspection && project ? { ...inspection, id: inspection.id } : {}) as DropdownOption,
          bankContact: ({ ...bankContact, name: getFullName(bankContact) } ?? {}) as DropdownOption,
        }}
        onSubmit={(values) => onSubmit(values, isNew && !!getProjectInspections().length)}
      >
        {({ values, setFieldValue, isSubmitting, initialValues }) => {
          checkFormChanges(initialValues, values);

          return (
            <Form>
              <div className="flex flex-wrap">
                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field
                    component={Autocomplete}
                    name="projectId"
                    placeholder="Project"
                    disabled={!isNew}
                    type="text"
                    options={filteredProjects}
                    getOptionLabel={getOptionName}
                    onChange={(_: ChangeEvent<unknown>, value: DropdownOption) => {
                      setActiveProject(value?.id);
                      setFieldValue('projectId', { ...value });
                      setFieldValue('inspectionId', {});
                    }}
                  />
                </div>

                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field component={CustomDatePicker} id="startDate" name="startDate" label="Start date" />
                </div>

                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field
                    component={Autocomplete}
                    name="type"
                    placeholder="Work order type"
                    type="text"
                    disabled={!!type?.id && !isNew}
                    options={types}
                    getOptionLabel={getOptionName}
                    onChange={(_: ChangeEvent<unknown>, value: DropdownOption) => [
                      setAutomatedFields(value, setFieldValue, values),
                    ]}
                  />
                </div>

                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field
                    component={CustomDatePicker}
                    id="dueDate"
                    minDate={values.startDate}
                    name="dueDate"
                    label="Due date"
                  />
                </div>

                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field component={TextField} name="name" label="Work order name" variant="outlined" />
                </div>

                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field component={CustomDatePicker} id="dateSubmitted" name="dateSubmitted" label="Date submitted" />
                </div>

                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field
                    component={Autocomplete}
                    name="inspectionId"
                    disabled={!isNew}
                    placeholder="Associated Inspection"
                    type="text"
                    options={getProjectInspections()}
                    getOptionLabel={getOptionName}
                  />
                </div>

                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field
                    component={Autocomplete}
                    name="bank"
                    placeholder="Bank"
                    type="text"
                    disabled
                    options={companies?.filter(({ typeId }) => typeId === CompanyTypesEnum.Bank)}
                    getOptionLabel={getOptionName}
                    onChange={(_: ChangeEvent<unknown>, value: DropdownOption) => [
                      setFieldValue('bank', { ...value }),
                      setFieldValue('bankContact', {}),
                    ]}
                  />
                </div>

                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field
                    component={Autocomplete}
                    name="assignee"
                    label="Assignee"
                    placeholder="Assignee"
                    type="text"
                    options={(filteredUsers as User[]).map(
                      (user) => ({ id: user.id, name: getFullName(user) } as DropdownOption),
                    )}
                    getOptionLabel={getOptionName}
                  />
                </div>

                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field
                    component={Autocomplete}
                    name="bankContact"
                    placeholder="Bank contact"
                    type="text"
                    options={getBankContacts(values?.bank?.id as number)}
                    getOptionLabel={getOptionName}
                  />
                </div>

                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field
                    component={Autocomplete}
                    name="drafter"
                    placeholder="Drafter"
                    type="text"
                    options={(filteredUsers as User[]).map(
                      (user) => ({ id: user.id, name: getFullName(user) } as DropdownOption),
                    )}
                    getOptionLabel={getOptionName}
                  />
                </div>

                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field
                    component={Autocomplete}
                    name="status"
                    placeholder="Status"
                    type="text"
                    options={statuses}
                    getOptionLabel={getOptionName}
                  />
                </div>
              </div>

              <div className="flex pt-24 justify-content-end px-16">
                <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                  {submitButtonTitle}
                </LoadingButton>
                <Button variant="text" type="reset" onClick={onClose} className="ml-12">
                  Cancel
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
