import { IconProps } from 'types';

export const ClockRedIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00033 15.3333C3.96699 15.3333 0.666992 12.0333 0.666992 7.99996C0.666992 3.96663 3.96699 0.666626 8.00033 0.666626C12.0337 0.666626 15.3337 3.96663 15.3337 7.99996C15.3337 12.0333 12.0337 15.3333 8.00033 15.3333ZM8.00033 1.99996C4.70033 1.99996 2.00033 4.69996 2.00033 7.99996C2.00033 11.3 4.70033 14 8.00033 14C11.3003 14 14.0003 11.3 14.0003 7.99996C14.0003 4.69996 11.3003 1.99996 8.00033 1.99996Z"
        fill="#CC0000"
      />
      <path
        d="M10.8337 12.1666C10.667 12.1666 10.5003 12.1 10.367 11.9666L7.53366 9.13329C7.40033 8.99996 7.33366 8.83329 7.33366 8.66663V4.66663C7.33366 4.29996 7.63366 3.99996 8.00033 3.99996C8.36699 3.99996 8.66699 4.29996 8.66699 4.66663V8.39996L11.3003 11.0333C11.567 11.3 11.567 11.7 11.3003 11.9666C11.167 12.1 11.0003 12.1666 10.8337 12.1666Z"
        fill="#CC0000"
      />
    </svg>
  );
};
