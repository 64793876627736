import { IconProps } from 'types';

export const IrrelevantIcon = ({ className, onClick }: IconProps) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3603 14.1887C9.25416 16.8981 8 20.3026 8 24C8 32.8366 15.1634 40 24 40C27.6974 40 31.1019 38.7458 33.8113 36.6397L11.3603 14.1887ZM14.1887 11.3603L36.6397 33.8113C38.7458 31.1019 40 27.6974 40 24C40 15.1634 32.8366 8 24 8C20.3026 8 16.8981 9.25416 14.1887 11.3603ZM4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24Z"
        fill="black"
      />
    </svg>
  );
};
