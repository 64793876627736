import { InputAdornment } from '@material-ui/core';

export const amountFieldProp = {
  InputProps: {
    startAdornment: (
      <InputAdornment position="start" className="pl-8">
        $
      </InputAdornment>
    ),
  },
};
