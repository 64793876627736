import React, { Children } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import MuiTextfield from '@material-ui/core/TextField';
import { FieldInputProps } from 'formik';
import clsx from 'clsx';

import { SelectProps } from 'types';

import styles from './Select.module.scss';

interface OriginalSelectProps extends FieldInputProps<string>, SelectProps {
  error: boolean | undefined;
  helperText?: string | false | undefined;
  emptyChildrenOptionLabel?: string;
}
const DEFAULT = 'default';

export const OriginalSelect = (props: OriginalSelectProps) => {
  const { defaultText, children, value, showEmptyOption, emptyChildrenOptionLabel, ...rest } = props;
  const mappedVal = value;

  return (
    <MuiTextfield
      select
      label={defaultText}
      variant="outlined"
      value={mappedVal}
      SelectProps={{
        classes: { root: mappedVal === DEFAULT ? styles.defaultText : '' },
        // IconComponent: function icon() {
        //   return <ChevronIcon className="MuiSelect-icon" />;
        // },
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        },
      }}
      {...rest}
    >
      <MenuItem
        value={DEFAULT}
        disabled={!showEmptyOption}
        className={clsx(showEmptyOption && 'text-secondary', !showEmptyOption && 'd-none')}
      >
        {defaultText}
      </MenuItem>
      {children}
      {!Children.count(children) && (
        <MenuItem value="no-option" disabled>
          {emptyChildrenOptionLabel || 'No option'}
        </MenuItem>
      )}
    </MuiTextfield>
  );
};
