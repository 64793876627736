import { createContext, PropsWithChildren, useContext, useState } from 'react';

export const SidebarContext = createContext<SidebarContextInterface | null>(null);

export const useSidebar = () => useContext(SidebarContext) as SidebarContextInterface;

interface SidebarContextInterface {
  openSidebar: boolean;
  handleSidebarOpen: () => void;
  handleSidebarClose: () => void;
}

export const SidebarProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [openSidebar, setSidebarOpen] = useState(false);

  const handleSidebarOpen = () => setSidebarOpen(true);
  const handleSidebarClose = () => setSidebarOpen(false);

  return (
    <SidebarContext.Provider
      value={{
        openSidebar,
        handleSidebarOpen,
        handleSidebarClose,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
