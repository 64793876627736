import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import MuiTextfield from '@material-ui/core/TextField';
import clsx from 'clsx';

import { dropdownItemCallback } from 'utils';
import { DropdownOption } from 'types';

import styles from './DatePicker.module.scss';

interface DatePickerProps {
  inputDate: Date | string;
  handleDateChange: (date: Date) => void;
}

const months = new Set([
  { id: 0, name: 'January' },
  { id: 1, name: 'February' },
  { id: 2, name: 'March' },
  { id: 3, name: 'April' },
  { id: 4, name: 'May' },
  { id: 5, name: 'June' },
  { id: 6, name: 'July' },
  { id: 7, name: 'August' },
  { id: 8, name: 'September' },
  { id: 9, name: 'October' },
  { id: 10, name: 'November' },
  { id: 11, name: 'December' },
]);

const yearMinDate = 1970;
const yearMaxDate = 2050;

export const DatePicker: FC<DatePickerProps> = ({ handleDateChange, inputDate = new Date() }) => {
  const date = new Date(inputDate);

  const [selectedMonth, setSelectedMonth] = useState(new Date(date).getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date(date).getFullYear());
  const [selectedDate, setSelectedDate] = useState(new Date(date).getDate());

  const monthDays = useMemo(
    () => new Date(selectedYear, selectedMonth + 1, 0).getDate(),
    [selectedMonth, selectedYear],
  );

  const years = useMemo(() => {
    const years = new Set<DropdownOption>();

    for (let i = yearMinDate; i <= yearMaxDate; i++) {
      years.add({ id: i, name: i.toString() });
    }

    return Array.from(years);
  }, [selectedMonth, selectedYear]);

  const monthDaysArray: Array<DropdownOption> = useMemo(() => {
    const daysSet = new Set<DropdownOption>();
    for (let i = 1; i <= monthDays; i++) {
      daysSet.add({ id: i, name: i.toString() });
    }

    return Array.from(daysSet);
  }, [monthDays]);

  const handleMonthChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    const lastMonthDate = new Date(selectedYear, +value + 1, 0).getDate();
    setSelectedMonth(+value);
    setSelectedDate(lastMonthDate <= selectedDate ? lastMonthDate : selectedDate);
  };
  const handlEyeIconarChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    const lastMonthDate = new Date(+value, selectedMonth + 1, 0).getDate();
    setSelectedYear(+value);
    setSelectedDate(lastMonthDate <= selectedDate ? lastMonthDate : selectedDate);
  };
  const handleDayChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => setSelectedDate(+value);

  useEffect(() => {
    handleDateChange(new Date(selectedYear, selectedMonth, selectedDate));
  }, [selectedMonth, selectedYear, selectedDate]);

  return (
    <div className="flex w-100">
      <MuiTextfield
        select
        value={selectedMonth}
        onChange={handleMonthChange}
        name="month"
        className={clsx('mr-16', styles.monthPicker)}
      >
        {Array.from(months).map(dropdownItemCallback)}
      </MuiTextfield>
      <MuiTextfield
        select
        value={selectedDate}
        onChange={handleDayChange}
        name="day"
        className={clsx('mr-16', styles.dayPicker)}
      >
        {monthDaysArray.map(dropdownItemCallback)}
      </MuiTextfield>
      <MuiTextfield select value={selectedYear} onChange={handlEyeIconarChange} className={clsx(styles.yearPicker)}>
        {years.map(dropdownItemCallback)}
      </MuiTextfield>
    </div>
  );
};
