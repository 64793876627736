import { DocumentCategoryAddInfo } from 'types';

export interface DocumentCategoryInfoFormProps {
  onSubmit: (values: DocumentCategoryAddInfo) => void | Promise<void>;
  onClose?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any;
  categoryName: string;
}

export enum CategoryNames {
  CivilPlans = 'Civil Plans',
  ArchitecturalPlans = 'Architectural Plans',
  StructuralPlans = 'Structural Plans',
  MechanicalPlans = 'Mechanical Plans',
  ElectricalPlans = 'Electrical Plans',
  PlumbingPlans = 'Plumbing Plans',
  Survey = 'Survey',
  ConstructionAgreement = 'Construction Agreement',
  HardCostBudget = 'Hard Cost Budget',
  ConstructionSchedule = 'Construction Schedule',
  TotalProjectBudget = 'Total Project Budget',
  GeotechnicalReporting = 'Geotechnical Reporting',
  EnvironmentalReporting = 'Environmental Reporting',
  Permits = 'Permits',
  UtilityAvailabilityLetters = 'Utility Availability Letters',
  DesignAgreements = 'Design Agreements',
  LoanCommitmentLetter = 'Loan Commitment Letter',
}
