import { format } from 'date-fns';

export const toEnUsFormat = (date: string | Date | undefined) =>
  date ? new Date(date).toLocaleDateString('en-US') : '-';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

//TO-DO: refactor with date-fns
export const toMonthYYFormat = (date: string | undefined) =>
  date ? `${monthNames[new Date(date).getMonth()]} ${new Date(date).getFullYear()}` : '-';

export const formatAMPM = (date: string | Date | undefined) => {
  if (!date) return '-';
  date = new Date(date);
  let hours = date.getHours();
  let minutes: string | number = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes + ' ' + ampm;
  return `${toEnUsFormat(date)}, ${strTime}`;
};

export const formatDateToYYYYMMDD = (date?: string | Date | null) => {
  return date ? format(new Date(date), 'yyyy-MM-dd') : null;
};

export const formatDateToMMDDYYYY = (date?: string | Date | null) => {
  return date ? format(new Date(date), 'MM-dd-yyyy') : null;
};
