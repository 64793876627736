import { TextField } from 'formik-material-ui';
import { CustomDatePicker, Autocomplete } from 'components/common';
import { Field } from 'formik';
import { Typography } from '@material-ui/core';

import { YesNoNaOptions, defaultFieldProps } from '../const';

export const GeotechnicalReportingFields = () => (
  <>
    <Field component={TextField} name="geotechnicalConsultant" label="Geotechnical Consultant" {...defaultFieldProps} />
    <Field component={CustomDatePicker} name="date" label="Date" removeTimezone {...defaultFieldProps} />
    <Field
      component={Autocomplete}
      name="mapMatch"
      placeholder="Does the project description and map match the site?"
      options={YesNoNaOptions}
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      name="foundationRecommendation"
      placeholder="Has a foundation recommendation been made?"
      options={YesNoNaOptions}
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      name="soilBearingPressure"
      placeholder="Has a maximum allowable soil bearing pressure been defined in the report?"
      options={YesNoNaOptions}
      {...defaultFieldProps}
    />
    <Typography
      // TODO: it is temporary quick fix, need to be removed after the design is ready
      variant="body1"
      style={{
        fontWeight: 'bold',
        marginLeft: '12px',
        marginBottom: '25px',
      }}
    >
      Does the report present any of the following site soil concerns?
    </Typography>
    <Field
      component={Autocomplete}
      name="rockPartially"
      placeholder="Rock/Partially weathered rock"
      options={YesNoNaOptions}
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      name="groundwaterLevel"
      placeholder="High groundwater level"
      options={YesNoNaOptions}
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      name="unsuitableOnSiteSoils"
      placeholder="Unsuitable on-site soils"
      options={YesNoNaOptions}
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      name="wetSiteDrainage"
      placeholder="Wet site soils/drainage"
      options={YesNoNaOptions}
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      name="plasticitySoils"
      placeholder="High plasticity soils"
      options={YesNoNaOptions}
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      name="fillSoils"
      placeholder="Undocumented fill soils"
      options={YesNoNaOptions}
      {...defaultFieldProps}
    />
    <Field component={Autocomplete} name="other" placeholder="Other" options={YesNoNaOptions} {...defaultFieldProps} />
  </>
);
