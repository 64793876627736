export function safeEval(inputString: string, defaultValue = '0') {
  try {
    return eval(inputString);
  } catch (error) {
    return defaultValue;
  }
}

export const combineDate = (date1: string, date2: string): string => {
  const formattedDate1 = new Date(date1);
  const formattedDate2 = new Date(date2);
  const resultDate = new Date(
    formattedDate1.getFullYear(),
    formattedDate1.getMonth(),
    formattedDate1.getDate(),
    formattedDate2.getHours(),
    formattedDate2.getMinutes(),
    formattedDate2.getSeconds(),
    formattedDate2.getMilliseconds(),
  ).toISOString();
  return resultDate;
};

export const checkIfDate = (input?: string) => {
  if (input) {
    const formattedDate = new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(new Date(input));
    return formattedDate;
  }

  return input;
};

export const formatNumber = (num: number) => {
  if (num % 1 === 0) {
    return num.toString();
  } else {
    const decimalPlaces = Math.min(2, num.toString().split('.')[1].length);
    return num.toFixed(decimalPlaces);
  }
};
