import clsx from 'clsx';

import styles from './DocumentTile.module.scss';

type DocumentTileProps = {
  status: string;
  count: number;
  className?: string;
  title: string;
};

export const DocumentTile = ({ status, count, className, title }: DocumentTileProps) => (
  <div
    className={clsx(
      styles.tileContainer,
      status === 'received' && styles.received,
      status === 'rejected' && styles.rejected,
      status === 'missing' && styles.missing,
      className,
    )}
  >
    <h1 className={styles.count}>{count ? count : 0}</h1>
    <span className={styles.text}>{title}</span>
  </div>
);
