import { FC } from 'react';
import { Field, Form, Formik } from 'formik';
import Button from '@material-ui/core/Button';
import { LoadingButton, RichEditor } from 'components';

import { DocumentCategoryInfoFormProps } from './types';

import { useDocumentCategoryForm } from './useDocumentCategoryForm';

export const DocumentCategoryInfoForm: FC<DocumentCategoryInfoFormProps> = ({
  onClose,
  onSubmit,
  values,
  categoryName,
}) => {
  const { tableFields, initialValues, getTransformedValues } = useDocumentCategoryForm(categoryName, values);

  return (
    <Formik initialValues={initialValues} onSubmit={(values) => onSubmit && onSubmit(getTransformedValues(values))}>
      {({ isSubmitting }) => {
        return (
          <Form>
            <div className="overflow-hidden flex flex-column pt-10">
              {tableFields}
              <Field component={RichEditor} name="additionalInformation" variant="outlined" type="text" />
            </div>

            <div className="flex pt-24 justify-content-end px-16">
              <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                Save
              </LoadingButton>
              <Button variant="text" type="reset" onClick={onClose} className="ml-12">
                Cancel
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
