import clsx from 'clsx';

import styles from './DarkSquare.module.scss';

export const DarkSquare = ({ value }: { value: string }) => {
  const newName = value.charAt(0);
  return (
    <div className={clsx('flex align-items-center justify-content-center', styles.darkSquare)}>
      <p>{newName}</p>
    </div>
  );
};
