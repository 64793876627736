import { FC, lazy } from 'react';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import Chip from '@material-ui/core/Chip';

import { CustomDialog, NoteForm, NoteItemInfo, NoteItem, EmptyState, Pagination, ScrollTop } from 'components';
import { ArrowRightIcon, PlusIcon } from 'icons';
import { CreateProjectNoteFormValue, GridApiResponse } from 'types';
import { components } from 'generated/types';
import { perPage, tableIdForTopScrolling } from 'consts';
import { useFormModal } from 'hooks/useFormModal';
import { CreateNoteValues } from '../NoteForm';

import styles from './OverviewNotes.module.scss';
import { FoldersStatusEnum } from '../OverviewFilesTab/types';

const UnsavedChangesModal = lazy(() => import('components/common/UnsavedChangesModal'));

type Note = components['schemas']['Note'];

enum StatusEnum {
  All = 'all',
  WorkOrder = 'work order',
}

type FilterTabsProps<T> = {
  label: T;
};

type OverviewNotesProps = {
  handleQueryParamsChange: (key: 'search' | 'page' | 'status', value: string) => void;
  handleSubmit: (values: CreateProjectNoteFormValue) => Promise<void>;
  filteredNotes: GridApiResponse<Note>;
  handlePageChange: (direction: 'next' | 'prev') => void;
  page: number;
  goBack: () => void;
  noteId?: number;
  id: number;
  routeTo: (id: number) => void;
  handleDelete: (id: number) => void;
  status: StatusEnum | FoldersStatusEnum; //TO-DO delete StatusEnum
  route: string;
};

export const OverviewNotes: FC<OverviewNotesProps> = ({
  handleQueryParamsChange,
  handleSubmit,
  filteredNotes,
  handlePageChange,
  goBack,
  noteId,
  id,
  routeTo,
  handleDelete,
  status,
  route,
}) => {
  const { items, pagesTotal, page: itemsPage, total, count } = filteredNotes;

  const {
    isFormModalOpen,
    isUnsavedChangesModalOpen,
    handleCloseAllModals,
    handleCloseUnsavedChangesModal,
    handleOpenFormModal,
    handleCloseFormModal,
  } = useFormModal();

  const handleSubmitCallback = async (e: CreateNoteValues) => {
    await handleSubmit(e);
    handleCloseFormModal();
  };

  const FilteredFileTab = ({ label }: FilterTabsProps<StatusEnum>) => {
    return (
      <Chip
        {...(status !== label && { variant: 'outlined' })}
        onClick={() => handleQueryParamsChange('status', label)}
        color="primary"
        className="capitalize ml-12 mb-12"
        label={label}
      />
    );
  };

  return (
    <>
      {!noteId && (
        <div className="flex align-items-center justify-content-end my-sm-12 px-16 mt-16 mt-sm-0">
          <p className="text-smallTextGrey align-items-center mb-12">Notes folders:</p>
          <div>
            <FilteredFileTab label={StatusEnum.All} />
            <FilteredFileTab label={StatusEnum.WorkOrder} />
          </div>
        </div>
      )}
      <div className="d-block d-sm-flex justify-content-end align-items-center mb-12">
        {noteId ? (
          <div className={styles.creationBtnContainer}>
            <Button fullWidth onClick={goBack}>
              <ArrowRightIcon className={clsx('mr-12', styles.shareIcon)} />
              Back to Notes List
            </Button>
          </div>
        ) : (
          <>
            <Pagination
              perPage={perPage}
              count={count}
              total={total}
              handlePageChange={handlePageChange}
              pagesTotal={pagesTotal}
              currentPage={itemsPage}
            />
            {!!items?.length && (
              <div className={styles.creationBtnContainer}>
                <Button fullWidth variant="contained" onClick={handleOpenFormModal}>
                  + Add a note
                </Button>
              </div>
            )}
          </>
        )}
      </div>

      {noteId ? (
        <div className="px-16">
          <NoteItemInfo id={noteId} handleDelete={() => handleDelete(noteId)} goBack={goBack} route={route} />
        </div>
      ) : (
        <div className="flex flex-wrap p-relative" id={tableIdForTopScrolling} style={{ scrollMarginTop: 150 }}>
          <ScrollTop className={styles.scrollTopIcon} scrollBlockClassName="base-layout-wrapper" />
          {items?.map((note) => (
            <div key={note.id} className="col-xlg-3 col-lg-6 col-12">
              <NoteItem
                key={note.id}
                projectName={note.project?.name}
                route={routeTo(note.id as number)}
                title={note.title}
                text={note.text}
                createdAt={note.createdAt}
                updatedAt={note.updatedAt}
                id={id}
                noteId={note.id}
                user={note.user}
                handleDelete={() => handleDelete(note.id as number)}
              />
            </div>
          ))}
          {!items?.length && <EmptyState model="notes" onClick={handleOpenFormModal} btnText=" + Add a note" />}
        </div>
      )}

      <CustomDialog
        open={isFormModalOpen as boolean}
        icon={<PlusIcon />}
        header="Add new note"
        onClose={(_, reason) => handleCloseFormModal(reason)}
      >
        <UnsavedChangesModal
          open={isUnsavedChangesModalOpen}
          onClose={handleCloseUnsavedChangesModal}
          closeAll={handleCloseAllModals}
        />
        <NoteForm buttonText="Add note" onClose={handleCloseFormModal} onSubmit={handleSubmitCallback} />
      </CustomDialog>
    </>
  );
};
