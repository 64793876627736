import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { SearchField, ViewHeading } from 'components';
import { ProposalRequestOverviewRouteProps } from 'pages/ProposalRequestOverview/types';
import { usePageSearch } from 'hooks/usePageSearch';
import { links } from 'App';

export const ProposalRequestOverviewHeading: FC<ProposalRequestOverviewRouteProps> = ({
  match: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    query: { search = '' },
  },
  link,
  match,
}) => {
  const { state } = useLocation();
  const { handleEnterChange, setSearchTerm, handleClearSearch, searchTerm } = usePageSearch({ match, link });
  const isRouteWithSearch = false;
  // pathname.includes(links.ProposalRequestNotes({ id })) || pathname.includes(links.ProposalRequestFiles({ id }));

  const backLinkWithFilters = {
    link: `${links.ProposalRequests()}${state?.search}`,
  };

  return (
    <>
      {isRouteWithSearch ? (
        <ViewHeading
          hasSmartBackBtn
          label="Project Overview"
          backState={backLinkWithFilters}
          SearchField={({ className, onClose }) => (
            <>
              <SearchField
                className={className}
                onChange={setSearchTerm}
                value={searchTerm}
                onSearch={handleEnterChange}
                handleClear={handleClearSearch}
                onBlur={onClose}
              />
            </>
          )}
        >
          <SearchField
            className={'mb-12'}
            onChange={setSearchTerm}
            value={searchTerm}
            onSearch={handleEnterChange}
            handleClear={handleClearSearch}
          />

          {/* {pathname.includes(links.ProposalRequestFiles({ id })) && (
            <div className={clsx(commonStyles.creationBtnContainer, 'flex justify-content-end')}>
              <Button
                variant="contained"
                className="mb-12 d-none d-sm-block"
                //   onClick={handleDocumentRequestOpen}
              >
                Manage Document Requests
              </Button>

              <Button
                variant="contained"
                className="mb-16 d-block d-sm-none"
                fullWidth
                // onClick={handleDocumentRequestOpen}
              >
                Manage Document Requests
              </Button>
            </div>
          )} */}
        </ViewHeading>
      ) : (
        <ViewHeading hasSmartBackBtn label="Proposal Request Overview" />
      )}
    </>
  );
};
