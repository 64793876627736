import { IconProps } from 'types';

export const WarningIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.27078 15.74C9.26078 15.74 10.2508 15.56 11.1508 15.2C12.0508 14.84 12.8608 14.3 13.5808 13.58C14.3008 12.86 14.8408 12.05 15.2008 11.15C15.5608 10.25 15.7408 9.26005 15.7408 8.27005C15.7408 7.28005 15.5608 6.29005 15.2008 5.39005C14.8408 4.49005 14.3008 3.68005 13.5808 2.96005C12.8608 2.24005 12.0508 1.70005 11.1508 1.34005C10.2508 0.980049 9.26078 0.800049 8.27078 0.800049C7.28078 0.800049 6.29078 0.980049 5.39078 1.34005C4.49078 1.70005 3.68078 2.24005 2.96078 2.96005C2.24078 3.68005 1.70078 4.49005 1.34078 5.39005C0.980781 6.29005 0.800781 7.28005 0.800781 8.27005C0.800781 9.26005 0.980781 10.25 1.34078 11.15C1.70078 12.05 2.24078 12.86 2.96078 13.58C3.68078 14.3 4.49078 14.84 5.39078 15.2C6.29078 15.56 7.28078 15.74 8.27078 15.74Z"
        fill="#CC0000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.27109 13.1301C8.54109 13.1301 8.72109 13.0401 8.90109 12.8601C9.08109 12.6801 9.17109 12.4101 9.17109 12.2301C9.17109 11.9601 9.08109 11.7801 8.90109 11.6001C8.72109 11.4201 8.45109 11.3301 8.27109 11.3301C8.00109 11.3301 7.82109 11.4201 7.64109 11.6001C7.46109 11.6901 7.37109 11.9601 7.37109 12.2301C7.37109 12.5001 7.46109 12.6801 7.64109 12.8601C7.82109 13.0401 8.00109 13.1301 8.27109 13.1301Z"
        fill="white"
      />
      <path
        d="M8.27109 10.7001C7.73109 10.7001 7.37109 10.3401 7.37109 9.80012V3.77012C7.37109 3.23012 7.73109 2.87012 8.27109 2.87012C8.81109 2.87012 9.17109 3.23012 9.17109 3.77012V9.80012C9.17109 10.2501 8.81109 10.7001 8.27109 10.7001Z"
        fill="white"
      />
    </svg>
  );
};
