import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import EventEmitter from 'events';

import { localStorageManager, sessionStorageManager } from 'services';
import { isValidToken } from 'utils';
import { REFRESH_TOKEN, REMEMBER_ME, TOKEN } from 'consts';
import { components } from 'generated/types';
import { api, apiRoutes } from 'api';
import { HttpErrorResponse, LoginFormValues, SignUpFormValuesRequest, VerificationFormValues } from 'types';

type AccountInfo = components['schemas']['AccountInfo'];
type RolePermissionsDTO = components['schemas']['RolePermissionsDTO'];

export const AuthEmitter = new EventEmitter();

type LoginType = {
  success: boolean;
  token?: string;
  refreshToken?: string;
  authCode: boolean;
};

interface AuthContextInterface {
  isInitializing: boolean;
  isAuthenticated: boolean;
  userData: (AccountInfo & { permissions: Set<string> }) | null;
  signUp: (userData: SignUpFormValuesRequest) => Promise<void>;
  login: (userData: LoginFormValues) => Promise<LoginType>;
  verifyCode: (userData: VerificationFormValues) => Promise<void>;
  logout: () => void;
  updateAccountInfo: () => void;
  handleAuth: (token: string, refreshToken: string, rememberMe: boolean) => void;
}

const authAPI = axios.create({
  baseURL: apiRoutes.baseURL,
  headers: {
    'content-type': 'application/json',
  },
});

authAPI.interceptors.response.use(
  (response) => response,
  (error) => {
    throw new Error(error.response.data.message);
  },
);

const verificationCodeMutation = (verificationData: VerificationFormValues) =>
  authAPI.post(apiRoutes.checkAuthCode, verificationData).then((res) => res.data);

const signUpMutation = (userData: SignUpFormValuesRequest) =>
  api.post(apiRoutes.signUp, userData).then((res) => res.data);

const loginMutation = (userData: LoginFormValues) => authAPI.post(apiRoutes.login, userData).then((res) => res.data);

const accountQuery = () => api.get<AccountInfo & RolePermissionsDTO>(apiRoutes.account).then((res) => res?.data);

export const AuthContext = createContext<AuthContextInterface | null>(null);

export const useAuth = () => useContext(AuthContext) as AuthContextInterface;

export const AuthProvider = ({ children }: PropsWithChildren<unknown>) => {
  const rememberMe = useMemo(() => !!localStorageManager.getItem(REMEMBER_ME), []);
  const [userData, setUserData] = useState<(AccountInfo & { permissions: Set<string> }) | null>(null);
  const [token, setAuthToken] = useState(() => {
    const tokenFromStorage = localStorageManager.getItem(TOKEN) || sessionStorageManager.getItem(TOKEN);

    if (tokenFromStorage && !isValidToken(tokenFromStorage)) {
      localStorageManager.removeItem(TOKEN);

      return '';
    }

    return tokenFromStorage || '';
  });
  const [refreshToken, setAuthRefreshToken] = useState(() => {
    const tokenFromStorage = localStorageManager.getItem(REFRESH_TOKEN) || sessionStorageManager.getItem(REFRESH_TOKEN);

    if (!tokenFromStorage) {
      localStorageManager.removeItem(REFRESH_TOKEN);

      return '';
    }

    return tokenFromStorage || '';
  });

  const { mutateAsync: loginRequestMutation } = useMutation('loginMutation', (values: LoginFormValues) =>
    loginMutation(values),
  );

  const { mutateAsync: signUpRequestMutation } = useMutation('signUpMutation', (values: SignUpFormValuesRequest) =>
    signUpMutation(values),
  );

  const { mutateAsync: verificationCodeRequestMutation } = useMutation(
    'verificationCodeMutation',
    (verificationData: VerificationFormValues) => verificationCodeMutation(verificationData),
  );

  const { data: accountInfo, refetch } = useQuery('accountQuery', () => accountQuery(), {
    enabled: false,
  });

  const signUp = async (userData: SignUpFormValuesRequest) => {
    try {
      await signUpRequestMutation(userData);
    } catch (e) {
      throw new Error((e as HttpErrorResponse).message);
    }
  };

  const login = async (userData: LoginFormValues) => {
    try {
      const data = await loginRequestMutation(userData);
      const { authCode, token, refreshToken } = data;

      if (!authCode) {
        token && refreshToken && setToken(token, refreshToken, userData.rememberMe);
      }

      return data;
    } catch (e) {
      throw new Error((e as HttpErrorResponse).message);
    }
  };

  const resetToken = async () => {
    localStorageManager.removeItem(TOKEN);
    sessionStorageManager.removeItem(TOKEN);
    localStorageManager.removeItem(REFRESH_TOKEN);
    sessionStorageManager.removeItem(REFRESH_TOKEN);
    localStorageManager.removeItem(REMEMBER_ME);
  };

  const setToken = (token: string, refreshToken: string, rememberMe: boolean) => {
    const validated = isValidToken(token);

    if (validated) {
      (rememberMe ? localStorageManager : sessionStorageManager).setItem<typeof token>(TOKEN, token);
      (rememberMe ? localStorageManager : sessionStorageManager).setItem<typeof refreshToken>(
        REFRESH_TOKEN,
        refreshToken,
      );
      rememberMe && localStorageManager.setItem(REMEMBER_ME, rememberMe);
      setAuthToken(token);
      setAuthRefreshToken(refreshToken);
    }
  };

  const verifyCode = async (userData: VerificationFormValues) => {
    try {
      const response = await verificationCodeRequestMutation(userData);
      const { token, refreshToken } = response;
      token && refreshToken && setToken(token, refreshToken, userData.rememberMe as boolean);

      return response;
    } catch (e) {
      throw new Error((e as HttpErrorResponse).message);
    }
  };

  const handleAuth = (token: string, refreshToken: string, rememberMe: boolean) => {
    setToken(token, refreshToken, rememberMe);
  };

  const logout = () => {
    resetToken();
    setAuthToken(null);
    setAuthRefreshToken(null);
  };

  AuthEmitter.on('interceptorError', logout);

  useEffect(() => {
    token && refreshToken ? handleAuth(token, refreshToken, rememberMe) : logout();
  }, []);

  useEffect(() => {
    token && refreshToken && refetch();
  }, [token, refreshToken]);

  useEffect(() => {
    if (accountInfo) {
      const account = { ...accountInfo, permissions: new Set(accountInfo?.permissions) };
      setUserData(account);
    }
  }, [accountInfo]);

  const isInitializing = !!token && !userData;

  const isAuthenticated = !!(token && userData);

  return (
    <AuthContext.Provider
      value={{
        isInitializing,
        isAuthenticated,
        userData,
        updateAccountInfo: refetch,
        verifyCode,
        signUp,
        login,
        handleAuth,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
