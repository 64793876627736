import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const ProjectsRoute = Route(
  {
    search: Route.query.string,
    page: Route.query.number,
    status: Route.query.string,
    owner: Route.query.string,
    bankContact: Route.query.string,
    developer: Route.query.string,
    contractor: Route.query.string,
    name: Route.query.string,
    bank: Route.query.string,
    type: Route.query.string,
  },
  '/projects',
);

export const Projects = ProjectsRoute(lazy(() => import('./Projects')));
