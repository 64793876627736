export enum PaginationDirectionsEnum {
  Prev = 'prev',
  Next = 'next',
}

export type PaginationProps = {
  perPage: number;
  count: number;
  total: number;
  pagesTotal: number;
  tableName?: string;
  currentPage: number;
  handlePageChange: (value: PaginationDirectionsEnum, tableName?: string) => void;
  className?: string;
};
