import React, { useMemo } from 'react';
import { Route } from 'react-router-hoc';
import { useQuery } from 'react-query';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import { format } from 'date-fns';
import usLocale from 'date-fns/locale/en-US';

import { components } from 'generated/types';
import { api } from 'api';
import { RouteProps } from 'types';
import { getFullName } from 'utils';
import { getFormattedFieldValue } from 'utils/audit-trail/getFormattedFieldValue';
import { DateType } from 'types/dateType';

import styles from './AuditTrailItemInfo.module.scss';

type AuditTrail = components['schemas']['AuditTrail'];

const AuditTrailItemInfoRoute = Route(
  {
    id: Route.params.number.optional,
    auditTrailId: Route.query.number,
    route: Route.params.string.optional,
    search: Route.query.string,
  },
  ({ id, route }) => `/${route}/${id}/audit-trail`,
);

const AuditTrailItemInfo = ({ route, id }: RouteProps) => {
  const auditTrailItemQuery = () => api.get<AuditTrail>(`${route}/${id}`).then((res) => res.data);
  const {
    data: auditTrailItemById,
    isLoading,
    isFetching,
  } = useQuery(['auditTrailItemQuery'], () => auditTrailItemQuery());

  const filteredNoteById = useMemo(() => (auditTrailItemById ? auditTrailItemById : {}), [auditTrailItemById]);

  const {
    inspection,
    report,
    description,
    updatedAt,
    createdAt,
    user,
    reportChangedField,
    reportOldValue,
    reportNewValue,
    inspectionChangedField,
    inspectionOldValue,
    inspectionNewValue,
    dataType,
  } = filteredNoteById;

  const getFieldValue = getFormattedFieldValue(dataType as DateType);

  if (isLoading || isFetching) return <CircularProgress size={64} />;

  return (
    <div className={styles.noteContainer}>
      <div className="flex align-items-center justify-content-between">
        <div className={styles.projectInfo}>
          {inspection?.project ? <span>— project {inspection.project.name}</span> : '— no project'}
        </div>
      </div>
      <div className={styles.titleContainer}>
        <h1 className={clsx(styles.noteTitle, 'mr-12')}>{report?.name}</h1>
      </div>

      <p className={styles.noteText}>
        In the Inspection Report, the value of the <strong>{reportChangedField}</strong> field was changed from{' '}
        <strong>{getFieldValue(reportOldValue)}</strong> to <strong>{getFieldValue(reportNewValue)}</strong>
        .
        <br />A change in the value of this field triggered a change in the value in the related inspection, so the
        value of the <strong>{`${inspectionChangedField}`}</strong> field of the Inspection was changed from{' '}
        <strong>{getFieldValue(inspectionOldValue)}</strong> to <strong>{getFieldValue(inspectionNewValue)}</strong>.
      </p>

      <br />

      <p className={styles.noteText}>{description}</p>
      <div className={styles.noteInfoContainer}>
        <div className="d-none d-md-block" />
        <div className={clsx(styles.noteDateInfoWrapper, 'flex flex-column')}>
          <span className={styles.noteDateInfo}>
            Last modified: {updatedAt && format(new Date(updatedAt), 'h:mm a, MMMM d', { locale: usLocale })}
          </span>
          <span className={styles.noteDateInfo}>
            Created: {createdAt && format(new Date(createdAt), 'h:mm a, MMMM d', { locale: usLocale })}
          </span>
          <span className={clsx(styles.noteDateInfo, 'weight-600')}>{getFullName(user)}</span>
        </div>
      </div>
    </div>
  );
};

export default AuditTrailItemInfoRoute(AuditTrailItemInfo);
