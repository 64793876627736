import { GetRouteProps } from 'types';
import { ProposalRequestsRoute } from './ProposalRequestsRoute';
import { MutableRefObject } from 'react';

export type ProposalRequestsRouteProps = GetRouteProps<typeof ProposalRequestsRoute>;

export enum SelectedProposalRequestsDialogEnum {
  ProjectRequestCreate,
  ProjectRequestEdit,
  ProjectRequestDelete,
}

export type GeneralPaginationType = {
  [key: string]: number;
};

export interface ProposalRequestsContextProps {
  setSelectedProposalId: (id: number) => void;

  selectedProposalId?: MutableRefObject<number | undefined>;
  modalHandlers?: { [key: string]: (argument?: string) => void };
  isFormModalOpen?: unknown;
  isUnsavedChangesModalOpen?: boolean;
  selectedOpenDialog?: SelectedProposalRequestsDialogEnum | null;
  handleOpenProposalEdit: () => void;
  handleOpenProposalCreate: () => void;
  handleOpenProposalDelete: () => void;
  handleCloseSelectedDialog: () => void;
  handleCloseAllModals: () => void;
  handleCloseUnsavedChangesModal: () => void;
  handleCloseFormModal: (reason?: string) => void;

  handleDeleteProposal: () => void;
  isDeleteProposalRequestLoading?: boolean;

  generalPagination: GeneralPaginationType;
  setGeneralPagination: React.Dispatch<React.SetStateAction<GeneralPaginationType>>;
  handleResetPagination: () => void;
}
