import { FC, createRef, useEffect, useState } from 'react';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from 'mapbox-gl';

import { ArrowUpIcon } from 'icons';
import clsx from 'clsx';
import { useGetBoundaries } from 'lib/react-query/externalQueries';
import { MAPBOXQL_TOKEN } from 'consts';

import styles from './Map.module.scss';
import { MapProps } from './types';

mapboxgl.accessToken = MAPBOXQL_TOKEN;
// eslint-disable-next-line
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export const Map: FC<MapProps> = ({ items, isLoading, isFetching }) => {
  const mapContainer = createRef<HTMLDivElement>();
  const [openMobileMap, setOpenMobileMap] = useState(false);

  const handleMobileMapToggle = () => setOpenMobileMap(!openMobileMap);

  const { data: boundaries } = useGetBoundaries();

  useEffect(() => {
    if (!mapContainer.current) return;

    const outlineId = 'outline';
    const stateId = 'state';

    const mapContent = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-98.8223185136653, 31.8039734986],
      zoom: 3,
    });
    //@ts-ignore
    items.forEach(({ projectAddress, name }) => {
      if (projectAddress?.lng) {
        const el = document.createElement('span');
        el.className = `${styles.point}`;
        new mapboxgl.Marker({
          draggable: false,
          element: el,
        })
          .setLngLat([projectAddress?.lng, projectAddress?.lat])
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }).setMaxWidth('270').setHTML(
              `<div class=${styles.mapPopup}><h2>${name}</h2><p><svg width="12" height="12" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M24 44C24 44 39 32 39 19C39 10.716 32.284 4 24 4C15.716 4 9 10.716 9 19C9 32 24 44 24 44Z" stroke="black" stroke-width="4" stroke-linejoin="round"/>
              <path d="M24 25C24.7879 25 25.5681 24.8448 26.2961 24.5433C27.0241 24.2417 27.6855 23.7998 28.2426 23.2426C28.7998 22.6855 29.2417 22.0241 29.5433 21.2961C29.8448 20.5681 30 19.7879 30 19C30 18.2121 29.8448 17.4319 29.5433 16.7039C29.2417 15.9759 28.7998 15.3145 28.2426 14.7574C27.6855 14.2002 27.0241 13.7583 26.2961 13.4567C25.5681 13.1552 24.7879 13 24 13C22.4087 13 20.8826 13.6321 19.7574 14.7574C18.6321 15.8826 18 17.4087 18 19C18 20.5913 18.6321 22.1174 19.7574 23.2426C20.8826 24.3679 22.4087 25 24 25V25Z" stroke="black" stroke-width="4" stroke-linejoin="round"/>
              </svg>
              ${projectAddress.city}, ${projectAddress.state}</p><div class=${styles.dateContainer}>
              <div class="flex justify-content-center mt-12"><a target="_blank" href="https://www.google.com/maps/search/?api=1&query=${projectAddress?.lat},${projectAddress?.lng}" class="uppercase direction"<span class="ml-4">Get directions</span></a></div>
              </div>`,
            ),
          )
          .addTo(mapContent);
      }
    });

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      marker: false,
    });

    mapContent.addControl(geocoder);

    mapContent.addControl(new mapboxgl.FullscreenControl());

    const clear = () => {
      mapContent.getLayer(outlineId) && mapContent.removeLayer(outlineId);
      mapContent.getLayer(stateId) && mapContent.removeLayer(stateId);
      mapContent.getSource(stateId) && mapContent.removeSource(stateId);
    };

    mapContent.on('load', () => {
      geocoder.on('clear', () => {
        clear();
      });
      geocoder.on('result', (event) => {
        clear();
        // eslint-disable-next-line
        const state = boundaries.records.find(({ fields }: any) => fields.name.includes(event.result.text));

        if (state) {
          mapContent.addSource(stateId, {
            type: 'geojson',
            data: {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: state.fields.st_asgeojson.coordinates,
              },
            },
          });

          mapContent.addLayer({
            id: stateId,
            type: 'fill',
            source: stateId,
            layout: {},
            paint: {
              'fill-color': 'white',
              'fill-opacity': 0.3,
            },
          });

          mapContent.addLayer({
            id: outlineId,
            type: 'line',
            source: stateId,
            layout: {},
            paint: {
              'line-color': '#cc0000',
              'line-width': 1,
            },
          });
        }
      });
    });
  }, [items, isLoading, isFetching]);

  return (
    <div className={clsx(styles.mapContent, openMobileMap && styles.openMobileMap)}>
      <div className={clsx(styles.mapHeader, 'd-sm-none')}>
        <div className="c-pointer" onClick={handleMobileMapToggle}>
          View map <ArrowUpIcon className={clsx('ml-4', openMobileMap && styles.arrowDownIcon)} />
        </div>
      </div>
      <div ref={mapContainer} className="h-100" />
    </div>
  );
};
