import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const InProgressRoute = Route(
  {
    search: Route.query.string,
    page: Route.query.number,
    bank: Route.query.string,
    assignee: Route.query.string,
    type: Route.query.string,
  },
  '/report-writing-overview',
);

export const InProgress = InProgressRoute(lazy(() => import('./InProgress')));
