import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const ProposalRequestOverviewPageRoute = Route(
  {
    id: Route.params.number.optional,
  },
  ({ id }) => `/proposal-request/${id}`,
);

export const ProposalRequestOverviewPage = ProposalRequestOverviewPageRoute(lazy(() => import('./Overview')));
