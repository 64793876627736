import React, { ChangeEvent, FC, useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

import api from 'api/api';
import { apiRoutes } from 'api/routes';
import { useQuery } from 'react-query';
import { LoadingButton, InputPhoneMask, Autocomplete } from 'components';
import { CompanyTypesEnum, ContactFormValues, DropdownOption, Organization, PermissionEnum } from 'types';
import { PHONE_NUMBER } from 'consts';
import { useAuth } from 'contexts';
import { components } from 'generated/types';
import { useFormChanges } from 'contexts/FormChanges';

import styles from './ContactUserForm.module.scss';

type User = components['schemas']['User'];

interface Contacts {
  onSubmit: (values: ContactFormValues) => void | Promise<void>;
  onClose: () => void;
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  personalNumber?: string;
  personalNumberExt?: string;
  workNumber?: string;
  workNumberExt?: string;
  companyName?: string;
  companyType?: string;
  position?: string;
  submitButtonTitle?: string;
  organization?: Organization;
  user?: User;
}

export const ContactUserForm: FC<Contacts> = ({
  id,
  firstName,
  lastName,
  email,
  personalNumber,
  personalNumberExt,
  workNumber,
  workNumberExt,
  companyName,
  companyType,
  position,
  organization,
  user,
  onSubmit,
  onClose,
  submitButtonTitle,
}) => {
  const { checkFormChanges } = useFormChanges();
  const { userData } = useAuth();

  const getOptionName = ({ name }: DropdownOption) => name;

  const companyTypesQuery = () => api.get<DropdownOption[]>(apiRoutes.organizationTypes).then((res) => res.data);
  const { isLoading: companyTypesLoading, data: companyTypes } = useQuery('companyTypesQuery', () =>
    companyTypesQuery(),
  );

  const companiesQuery = () =>
    api
      .get<Organization[]>(apiRoutes.organization, {
        params: {
          'sort[name]': 'ASC',
        },
      })
      .then((res) => res.data);
  const { isLoading: companiesLoading, data: companies } = useQuery('companiesQuery', () => companiesQuery());

  const bankCompanies = useMemo(() => {
    if (!companies?.length) {
      return [];
    }
    return companies.filter(({ typeId }) => typeId === CompanyTypesEnum.Bank);
  }, [companies]);

  const thirdPartiesCompanies = useMemo(() => {
    if (!companies?.length) {
      return [];
    }
    return companies.filter(({ typeId }) => typeId === CompanyTypesEnum['3rd-parties']);
  }, [companies]);

  const developersCompanies = useMemo(() => {
    if (!companies?.length) {
      return [];
    }
    return companies.filter(({ typeId }) => typeId === CompanyTypesEnum.Developer);
  }, [companies]);

  const contractorsCompanies = useMemo(() => {
    if (!companies?.length) {
      return [];
    }
    return companies.filter(({ typeId }) => typeId === CompanyTypesEnum.Contractor);
  }, [companies]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape(
        {
          firstName: Yup.string().required('First name is a required field'),
          lastName: Yup.string().required('Last name is a required field'),
          email: Yup.string().email('Invalid email address').required('Email is a required field'),
          personalNumber: Yup.string().matches(PHONE_NUMBER, {
            message: 'Must be a valid phone number',
            excludeEmptyString: false,
          }),
          workNumber: Yup.string().matches(PHONE_NUMBER, 'Must be a valid phone number'),
          workNumberExt: Yup.string().matches(/^[0-9]{3,4}$/, ' '),
          companyName: Yup.string()
            .when(['companyType'], {
              is: (companyType: DropdownOption) => {
                return companyType?.id === CompanyTypesEnum.Vendor;
              },
              then: Yup.string().typeError('Company is a required field').required('Company is a required field'),
            })
            .nullable(),
          bankCompanyName: Yup.object()
            .when(['companyType'], {
              is: (companyType: DropdownOption) => {
                return companyType?.id === CompanyTypesEnum.Bank;
              },
              then: Yup.object()
                .shape({
                  id: Yup.number().required('Bank company name is a required field'),
                  name: Yup.string().required('Bank company name is a required field'),
                })
                .typeError('Bank company name is a required field'),
            })
            .nullable(),

          thirdPartiesCompanyName: Yup.object()
            .when(['companyType'], {
              is: (companyType: DropdownOption) => {
                return companyType?.id === CompanyTypesEnum['3rd-parties'];
              },
              then: Yup.object()
                .shape({
                  id: Yup.number().required('Company name is a required field'),
                  name: Yup.string().required('Company name is a required field'),
                })
                .typeError('Company name is a required field'),
            })
            .nullable(),
          developerCompanyName: Yup.object()
            .when(['companyType'], {
              is: (companyType: DropdownOption) => {
                return companyType?.id === CompanyTypesEnum.Developer;
              },
              then: Yup.object()
                .shape({
                  id: Yup.number().required('Developer company name is a required field'),
                  name: Yup.string().required('Developer company name is a required field'),
                })
                .typeError('Developer company name is a required field'),
            })
            .nullable(),
          contractorCompanyName: Yup.object()
            .when(['companyType'], {
              is: (companyType: DropdownOption) => {
                return companyType?.id === CompanyTypesEnum.Contractor;
              },
              then: Yup.object()
                .shape({
                  id: Yup.number().required('Contractor company name is a required field'),
                  name: Yup.string().required('Contractor name is a required field'),
                })
                .typeError('Contractor company name is a required field'),
            })
            .nullable(),
          companyType: Yup.object()
            .shape({
              id: Yup.string().required('Company type is a required field'),
              name: Yup.string().required('Company type is a required field'),
            })
            .typeError('Company type is a required field'),
          position: Yup.string().required('Role is a required field'),
        },
        [['companyType', 'companyType']],
      ),
    [],
  );

  if (companyTypesLoading || companiesLoading) return <CircularProgress size={64} />;

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        id: id ?? 0,
        firstName: firstName ?? '',
        lastName: lastName ?? '',
        email: email ?? '',
        personalNumber: personalNumber ?? '',
        personalNumberExt: personalNumberExt ?? '',
        workNumber: workNumber ?? '',
        workNumberExt: workNumberExt ?? '',
        companyName: companyType !== CompanyTypesEnum.Bank ? companyName ?? '' : '',
        position: position ?? '',
        companyType: companyType
          ? ({
              id: companyType,
              name: companyTypes?.find(({ id }) => id === companyType)?.name,
            } as DropdownOption)
          : ({} as DropdownOption),
        bankCompanyName:
          companyType === CompanyTypesEnum.Bank ? (organization as DropdownOption) : ({} as DropdownOption),
        developerCompanyName:
          companyType === CompanyTypesEnum.Developer ? (organization as DropdownOption) : ({} as DropdownOption),
        contractorCompanyName:
          companyType === CompanyTypesEnum.Contractor ? (organization as DropdownOption) : ({} as DropdownOption),
        thirdPartiesCompanyName:
          companyType === CompanyTypesEnum['3rd-parties'] ? (organization as DropdownOption) : ({} as DropdownOption),
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, errors, initialValues, setFieldValue }) => {
        checkFormChanges(initialValues, values);

        return (
          <Form>
            <>{console.log('errors', errors)}</>
            <section>
              <span className={clsx('uppercase px-16 mb-24', styles.heading)}>- Personal Info</span>

              <div className="flex flex-wrap">
                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field component={TextField} name="firstName" label="First name" variant="outlined" />
                </div>
                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field component={TextField} name="lastName" label="Last name" variant="outlined" />
                </div>
                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field component={TextField} name="email" label="Email" variant="outlined" type="email" />
                </div>
                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field
                    component={InputPhoneMask}
                    name="personalNumber"
                    label="Personal number"
                    variant="outlined"
                    type="tel"
                  />
                </div>
              </div>
            </section>

            <section>
              <span className={clsx('uppercase px-16 my-24', styles.heading)}>- Work Info</span>

              <div className="flex flex-wrap">
                <div className="col-12 col-md-6 flex mb-24 mb-md-36">
                  <Field
                    component={InputPhoneMask}
                    name="workNumber"
                    className="col-8"
                    placeholder="+1 (770) 123-4567"
                    label="Work number"
                    newName="Work number"
                    variant="outlined"
                  />

                  <Field
                    component={TextField}
                    name="workNumberExt"
                    variant="outlined"
                    placeholder="1234"
                    className={clsx(styles.workNumberExtension, 'ml-16')}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">#</InputAdornment>,
                    }}
                    inputProps={{
                      maxLength: 4,
                    }}
                  />
                </div>
                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  {values.companyType.id === CompanyTypesEnum.Vendor && (
                    <Field component={TextField} name="companyName" label="Company" variant="outlined" disabled />
                  )}
                  {values.companyType.id === CompanyTypesEnum.Bank && (
                    <Field
                      component={Autocomplete}
                      name="bankCompanyName"
                      placeholder="Bank company name"
                      type="text"
                      options={bankCompanies}
                      getOptionLabel={getOptionName}
                      disabled={!userData?.permissions.has(PermissionEnum.contact_edit_all)}
                    />
                  )}
                  {values.companyType.id === CompanyTypesEnum['3rd-parties'] && (
                    <Field
                      component={Autocomplete}
                      name="thirdPartiesCompanyName"
                      placeholder="3-rd Parties company name"
                      type="text"
                      options={thirdPartiesCompanies}
                      getOptionLabel={getOptionName}
                      disabled={!userData?.permissions.has(PermissionEnum.contact_edit_all)}
                    />
                  )}
                  {values.companyType.id === CompanyTypesEnum.Developer && (
                    <Field
                      component={Autocomplete}
                      name="developerCompanyName"
                      placeholder="Developer company name"
                      type="text"
                      options={developersCompanies}
                      getOptionLabel={getOptionName}
                      disabled={!userData?.permissions.has(PermissionEnum.contact_edit_all)}
                    />
                  )}
                  {values.companyType.id === CompanyTypesEnum.Contractor && (
                    <Field
                      component={Autocomplete}
                      name="contractorCompanyName"
                      placeholder="General contractor company name"
                      type="text"
                      options={contractorsCompanies}
                      getOptionLabel={getOptionName}
                      disabled={!userData?.permissions.has(PermissionEnum.contact_edit_all)}
                    />
                  )}
                </div>
                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field
                    component={Autocomplete}
                    name="companyType"
                    placeholder="Type of company"
                    type="text"
                    options={companyTypes && [...companyTypes]}
                    getOptionLabel={getOptionName}
                    onChange={(_: ChangeEvent<unknown>, value: DropdownOption) => {
                      setFieldValue('companyType', { ...value });
                      value?.id !== (CompanyTypesEnum.Bank || CompanyTypesEnum['3rd-parties']) &&
                        setFieldValue('companyName', userData?.activeCompany?.name);
                    }}
                    disabled={user || !userData?.permissions.has(PermissionEnum.contact_edit_all)}
                  />
                </div>
                <div className="col-12 col-md-6 mb-24 mb-md-36">
                  <Field component={TextField} name="position" label="Role" variant="outlined" />
                </div>
              </div>
            </section>
            <div className={clsx('flex pt-24 justify-content-end px-16')}>
              <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                {submitButtonTitle}
              </LoadingButton>
              <Button variant="text" type="reset" onClick={onClose} className="ml-12">
                Cancel
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
