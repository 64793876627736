import React from 'react';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import { NavLink } from 'react-router-dom';

import { BuildingIcon, UserIcon, SubmitGreenIcon, BellIcon, CheckedIcon, ClockIcon } from 'icons';
import { getFullName, getReportTypeBgColor, getReportTypeColor } from 'utils';
import { links } from 'App';
import { DropdownOption, ReportTypesEnum } from 'types';
import { getDefaultSectionByReportType, getLabelByReportTypesEnum } from 'utils/common';
import { components } from 'generated/types';

import styles from './SubmittedCard.module.scss';

type User = components['schemas']['User'];

type ReportCardProps = {
  id: number;
  reportName?: string;
  reportType?: string;
  reportTypeName?: string;
  projectName?: string;
  assignee?: User;
  reviewer?: string;
  progressStatus?: string | number;
  notificationCount?: number | null;
  date?: string;
  editIcon?: React.ReactNode;
  deleteIcon?: React.ReactNode;
  hasIssueCounter?: number;
  reportWorkOrderStatus?: DropdownOption;
};

const CountCircle = ({ value }: { value: number }) => {
  return <div className={styles.countCircle}>{value}</div>;
};

const getWorkOrderStatus = (status?: DropdownOption) => {
  if (status) {
    let svgIcon;
    let style;

    if (status.id === 'to_do') {
      style = styles.toDo;
    }
    if (status.id === 'waiting') {
      style = styles.toDo;
      svgIcon = <ClockIcon className={clsx(style, 'ml-4')} />;
    }

    if (status.id === 'ready_to_write' || status.id === 'ready_for_review') {
      style = styles.readyToWrite;
      svgIcon = <CheckedIcon className={clsx(style)} />;
    }

    if (status.id === 'submitted') {
      style = styles.submitted;
      svgIcon = <CheckedIcon className={clsx(style)} />;
    }

    return (
      <span className={style}>
        {status.name} {svgIcon}
      </span>
    );
  }
};

export const SubmittedCard = ({
  reportName,
  reportType,
  reportTypeName,
  projectName,
  assignee,
  reviewer,
  id,
  editIcon,
  deleteIcon,
  hasIssueCounter,
  reportWorkOrderStatus,
}: ReportCardProps) => {
  return (
    <div className={clsx('flex flex-column pl-16', styles.cardWrapper)}>
      {!!hasIssueCounter && (
        <div className={clsx(styles.wrapper, 'pt-16 pr-16 p-relative flex justify-content-end')}>
          <BellIcon />
          <CountCircle value={hasIssueCounter} />
        </div>
      )}
      <Tooltip title={reportName as string}>
        <div className={clsx('flex align-items-center pt-32', styles.title)}>
          <SubmitGreenIcon className={styles.icon} />
          <NavLink
            className="pl-8"
            to={links.ReportWriting({ id, section: getDefaultSectionByReportType(reportType as ReportTypesEnum) })}
          >
            {reportName}
          </NavLink>
        </div>
      </Tooltip>

      <div className="flex mt-12">
        <BuildingIcon className={styles.icon} />
        <p className="ml-12">{projectName}</p>
      </div>
      <div className="flex mt-8">
        <UserIcon className={styles.icon} />
        <p className="ml-12">
          {reviewer} <p className="text-smallTextGrey">assigned to</p> {getFullName(assignee)}
        </p>
      </div>
      <div className="flex mt-16 pr-16">
        <div className="ml-24 pb-16">
          <span
            style={{
              color: getReportTypeColor(reportType as ReportTypesEnum),
              backgroundColor: getReportTypeBgColor(reportType as ReportTypesEnum),
              lineHeight: '16px',
              padding: '8px',
              borderRadius: '23px',
            }}
          >
            {getLabelByReportTypesEnum(reportTypeName as ReportTypesEnum)}
          </span>
          <br />
          <span className="ml-8 mt-8 mb-12 text-14">{getWorkOrderStatus(reportWorkOrderStatus)}</span>
        </div>
      </div>
      <section className={clsx(styles.btnWrapper, 'flex align-items-center')}>
        {editIcon}
        {deleteIcon}
      </section>
    </div>
  );
};
