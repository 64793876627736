import React, { FC, useMemo, useState, useEffect } from 'react';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { LoadingButton } from 'components';
import { VerificationFormValues } from 'types';

import styles from './VerificationCodeForm.module.scss';

interface VerificationCodeProps {
  onSubmit: (
    values: VerificationFormValues,
    formikHelpers: FormikHelpers<VerificationFormValues>,
  ) => void | Promise<void>;
  code?: string;
}

export const VerificationCodeForm: FC<VerificationCodeProps> = (props) => {
  const { code, onSubmit } = props;
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(0);
    }
  });

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        code: Yup.string().required('Verification code is a required field'),
      }),
    [],
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        code: code ?? '',
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <div className="flex flex-column justify-content-between">
          <div className="flex flex-column juctify-content-between align-items-center padding-15">
            <div className="col-12 col-xlg-12 col-lg-6  col-md-6 col-sm-8">
              <p className={clsx('weight-normal', styles.titleFontSize)}>Verification code</p>
              <p className={clsx('weight-normal color-smallTextGrey pb-48', styles.textStyle)}>
                We’ve just sent you a verification code. Please check an email and enter the code below.
              </p>
              <Form>
                <Field
                  component={TextField}
                  name="code"
                  label="Verification code"
                  variant="outlined"
                  type="text"
                  className="mb-24 mb-md-36"
                />
                <div className="flex align-items-center justify-content-center">
                  <LoadingButton className="mb-24" type="submit" loading={isSubmitting}>
                    Submit
                  </LoadingButton>
                </div>
                <div className="flex align-items-center justify-content-around">
                  <p className=" text-smallTextGrey">Didn’t get a code?</p>
                  <NavLink to="/" className="text-primary weight-bolder ">
                    Resend in 00:{seconds}
                  </NavLink>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
