import { useHistory } from 'react-router';
import { useGetPMRfpPortfolio } from 'pages/ProductivityMetrics/queries';
import { usePMProjectPortfolioTableColumns } from './useTableColumns';
import { useMemo } from 'react';
import {
  productivityMetricsTableEmptyModel,
  productivityMetricsTableEmptyTitle,
} from 'components/common/Table/parts/EmptyState/const';
import transformObjectToArray from 'utils/productivity-metrics/transformObjectToArray';

import { PMRfpPortfolioProps } from '../../types';

export const usePMProjectPortfolioTable = ({
  match: {
    query: {
      bank,
      bankContact,
      services,
      assignee,
      project,
      projectStatus,
      projectType,
      state,
      owner,
      status,

      estimatedInspectionDateStartFrom,
      estimatedInspectionDateStartTo,
      estimatedInspectionDateCompletionFrom,
      estimatedInspectionDateCompletionTo,

      receivedDateFrom,
      receivedDateTo,

      proposalSentDateFrom,
      proposalSentDateTo,
    },
    query,
  },
  link,
}: PMRfpPortfolioProps) => {
  const { push } = useHistory();
  const { annualRfpStatusesColumns, monthlyRfpStatusesColumns } = usePMProjectPortfolioTableColumns();
  const { data, isFetching } = useGetPMRfpPortfolio({
    params: {
      'filter[_service]': services,
      'filter[bank]': bank,
      'filter[assignee]': assignee,
      'filter[project]': project,
      'filter[_projectStatus]': projectStatus,
      'filter[_projectType]': projectType,
      'filter[state]': state,
      'filter[_bankContact]': bankContact,
      'filter[_projectOwner]': owner,
      'filter[status]': status,
      'filter[_receivedDate][from]': receivedDateFrom,
      'filter[_receivedDate][to]': receivedDateTo,
      ...(estimatedInspectionDateStartTo
        ? {
            'filter[expectedDateOfFirstInspection:from]': estimatedInspectionDateStartFrom,
            'filter[expectedDateOfFirstInspection:to]': estimatedInspectionDateStartTo,
          }
        : { 'filter[expectedDateOfFirstInspection]': estimatedInspectionDateStartFrom }),
      ...(estimatedInspectionDateCompletionTo
        ? {
            'filter[expectedDateOfLastInspection:from]': estimatedInspectionDateCompletionFrom,
            'filter[expectedDateOfLastInspection:to]': estimatedInspectionDateCompletionTo,
          }
        : { 'filter[expectedDateOfLastInspection]': estimatedInspectionDateCompletionFrom }),
      ...(proposalSentDateTo
        ? {
            'filter[sentDate:from]': proposalSentDateFrom,
            'filter[sentDate:to]': proposalSentDateTo,
          }
        : { 'filter[sentDate]': proposalSentDateFrom }),
    },
    queryKey: [query],
  });

  const { dateStats, statusStats } = data;

  const filteredStatusStats = useMemo(
    () => (statusStats ? transformObjectToArray(statusStats, 'status') : []),
    [statusStats],
  );
  const filteredDateStats = useMemo(() => (dateStats ? transformObjectToArray(dateStats, 'month') : []), [dateStats]);

  const handleClear = () => {
    push(link());
  };

  const tableEmptyState = {
    model: productivityMetricsTableEmptyModel,
    emptyStateTitle: productivityMetricsTableEmptyTitle,
    handleClearFilters: handleClear,
  };
  const isAnyData = !!(filteredDateStats.length || filteredStatusStats.length);

  return {
    filteredStatusStats,
    filteredDateStats,
    isFetching,
    tableEmptyState,
    isAnyData,
    annualRfpStatusesColumns,
    monthlyRfpStatusesColumns,
    handleClear,
  };
};
