import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const ProjectCoverPagesRoute = Route(
  {
    id: Route.params.number.optional,
  },
  ({ id }) => `/project/${id}/cover`,
);

export const ProjectCover = ProjectCoverPagesRoute(lazy(() => import('./CoverPages')));
