import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import { logo, logoGroup } from 'assets/logos/index';
import { links } from 'App';
import styles from './UnauthLayout.module.scss';

interface UnAuthLayoutProps {
  children: ReactNode;
}

const NavBar = ({ className }: { className?: string }) => (
  <div className={className}>
    <NavLink to={links.About} className={styles.listStyle}>
      About
    </NavLink>
    <NavLink to={links.Privacy} className={styles.listStyle}>
      Privacy
    </NavLink>
    <NavLink to={links.TermsAndConditions} className={styles.listStyle}>
      Terms of Use
    </NavLink>
    <NavLink to={links.FAQ} className={styles.listStyle}>
      FAQ
    </NavLink>
  </div>
);

export const UnAuthLayout = ({ children }: UnAuthLayoutProps) => {
  return (
    <div className="flex h-100 bg-gray">
      <div className="col-12 col-xlg-6 flex justify-content-between p-0">
        <div className="col-2 d-none d-xlg-block" />
        <div className="col-12 col-xlg-8 flex flex-column justify-content-between p-0">
          <div className={styles.header}>
            <img src={logo} alt="logo" />
          </div>
          <div className="px-16">{children}</div>
          <div className={clsx('flex justify-content-center mb-32 pt-24', styles.downText)}>
            {/* <p className="text-smallTextGrey">Do not have an account?</p>
            <NavLink to={links.SignUp} className="text-primary weight-bolder ml-16">
              Register
            </NavLink> */}
          </div>
          <NavBar className={styles.footer} />
        </div>
        <div className="col-2 d-none d-xlg-block" />
      </div>

      <div
        className={clsx(
          styles.rightBlock,
          'col-6 flex flex-column justify-content-between align-items-center bg-primary',
        )}
      >
        <img alt="logo" className="mt-32" src={logo} />
        <img alt="logo-group" src={logoGroup} className={styles.logoGroupSize} />
        <NavBar className={clsx('flex justify-content-between mb-32', styles.wrapper)} />
      </div>
    </div>
  );
};
