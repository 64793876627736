import { IconProps } from 'types';

export const DocumentIcon = ({ className, width, height, color, onClick }: IconProps) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width={width || '36'}
      height={height || '44'}
      viewBox="0 0 36 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 44H4C2.9 44 1.9 43.6 1.2 42.8C0.4 42.1 0 41.1 0 40V4C0 2.9 0.4 1.9 1.2 1.2C1.9 0.4 2.9 0 4 0H24C24.5 0 25 0.2 25.4 0.6L35.4 10.6C35.8 11 36 11.5 36 12V40C36 41.1 35.6 42.1 34.8 42.8C34 43.5 33.1 44 32 44ZM4 4V40H32V12.8L23.2 4H4ZM28 18C28 16.9 27.1 16 26 16H10C8.9 16 8 16.9 8 18C8 19.1 8.9 20 10 20H26C27.1 20 28 19.1 28 18ZM28 26C28 24.9 27.1 24 26 24H10C8.9 24 8 24.9 8 26C8 27.1 8.9 28 10 28H26C27.1 28 28 27.1 28 26Z"
        fill={color || 'black'}
      />
    </svg>
  );
};
