import { FC } from 'react';
import { TableV2 } from 'components';

import { usePMProjectPortfolioTable } from './useTable';
import styles from '../../../ProjectPortfolio/components/Table/Table.module.scss';
import { PMRfpPortfolioProps } from '../../types';
import { PMRfpPortfolioAnnualMobileTable, PMRfpPortfolioMonthlyMobileTable } from '../MobileTable';

export const PMRfpPortfolioTable: FC<PMRfpPortfolioProps> = ({ match, link }) => {
  const {
    filteredStatusStats,
    filteredDateStats,
    annualRfpStatusesColumns,
    monthlyRfpStatusesColumns,
    isFetching,
    tableEmptyState,
    isAnyData,
    handleClear,
  } = usePMProjectPortfolioTable({
    match,
    link,
  });

  return (
    <>
      {isAnyData && (
        <TableV2
          columns={annualRfpStatusesColumns}
          data={filteredStatusStats}
          loading={isFetching}
          isScrollTop={false}
          className={styles.table}
          {...tableEmptyState}
        />
      )}

      <TableV2
        columns={monthlyRfpStatusesColumns}
        data={filteredDateStats}
        isScrollTop={false}
        loading={isFetching}
        className={styles.table}
        {...tableEmptyState}
      />

      <PMRfpPortfolioAnnualMobileTable items={filteredStatusStats} loading={isFetching} onClearFilters={handleClear} />
      <PMRfpPortfolioMonthlyMobileTable items={filteredDateStats} loading={isFetching} onClearFilters={handleClear} />
    </>
  );
};
