import { IconProps } from 'types';

export const ProjectIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 15.5H7.24999C6.83749 15.5 6.49999 15.1625 6.49999 14.75V1.25C6.49999 0.8375 6.83749 0.5 7.24999 0.5H11C11.4125 0.5 11.75 0.8375 11.75 1.25V14.75C11.75 15.1625 11.4125 15.5 11 15.5ZM7.99999 14H10.25V2H7.99999V14ZM14.75 15.5H11C10.5875 15.5 10.25 15.1625 10.25 14.75V1.25C10.25 0.8375 10.5875 0.5 11 0.5H14.75C15.1625 0.5 15.5 0.8375 15.5 1.25V14.75C15.5 15.1625 15.1625 15.5 14.75 15.5ZM11.75 14H14V2H11.75V14ZM4.43749 15.5C4.39999 15.5 4.36249 15.5 4.36249 15.5L1.17499 15.125C0.762491 15.0875 0.462491 14.7125 0.499991 14.3L1.99999 1.175C2.03749 0.9875 2.11249 0.8 2.29999 0.6875C2.44999 0.575 2.67499 0.5 2.86249 0.5375L5.86249 0.9125C6.27499 0.95 6.57499 1.325 6.49999 1.7375L5.18749 14.8625C5.14999 15.05 5.07499 15.2375 4.92499 15.3875C4.77499 15.425 4.62499 15.5 4.43749 15.5ZM2.07499 13.7L3.76249 13.8875L4.92499 2.2625L3.38749 2.075L2.07499 13.7ZM13.625 5.75V4.625C13.625 4.2125 13.2875 3.875 12.875 3.875C12.4625 3.875 12.125 4.2125 12.125 4.625V5.75C12.125 6.1625 12.4625 6.5 12.875 6.5C13.2875 6.5 13.625 6.1625 13.625 5.75ZM9.87499 5.75V4.625C9.87499 4.2125 9.53749 3.875 9.12499 3.875C8.71249 3.875 8.37499 4.2125 8.37499 4.625V5.75C8.37499 6.1625 8.71249 6.5 9.12499 6.5C9.53749 6.5 9.87499 6.1625 9.87499 5.75Z"
        fill="#212B83"
      />
    </svg>
  );
};
