import React from 'react';

interface DateTitleProps {
  className?: string;
}

export const DateTitle = ({ className }: DateTitleProps) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5002 19.1666H2.50016C1.5835 19.1666 0.833496 18.4166 0.833496 17.4999V4.16658C0.833496 3.24992 1.5835 2.49992 2.50016 2.49992H17.5002C18.4168 2.49992 19.1668 3.24992 19.1668 4.16658V17.4999C19.1668 18.4166 18.4168 19.1666 17.5002 19.1666ZM2.50016 4.16658V17.4999H17.5002V4.16658H2.50016Z"
        fill="#212B83"
      />
      <path
        d="M18.3335 9.16658H1.66683C1.2085 9.16658 0.833496 8.79158 0.833496 8.33325C0.833496 7.87492 1.2085 7.49992 1.66683 7.49992H18.3335C18.7918 7.49992 19.1668 7.87492 19.1668 8.33325C19.1668 8.79158 18.7918 9.16658 18.3335 9.16658Z"
        fill="#212B83"
      />
      <path
        d="M18.3335 14.1666H1.66683C1.2085 14.1666 0.833496 13.7916 0.833496 13.3333C0.833496 12.8749 1.2085 12.4999 1.66683 12.4999H18.3335C18.7918 12.4999 19.1668 12.8749 19.1668 13.3333C19.1668 13.7916 18.7918 14.1666 18.3335 14.1666Z"
        fill="#212B83"
      />
      <path
        d="M7.0835 5.83325C6.62516 5.83325 6.25016 5.45825 6.25016 4.99992V1.66659C6.25016 1.20825 6.62516 0.833252 7.0835 0.833252C7.54183 0.833252 7.91683 1.20825 7.91683 1.66659V4.99992C7.91683 5.45825 7.54183 5.83325 7.0835 5.83325Z"
        fill="#212B83"
      />
      <path
        d="M12.9168 5.83325C12.4585 5.83325 12.0835 5.45825 12.0835 4.99992V1.66659C12.0835 1.20825 12.4585 0.833252 12.9168 0.833252C13.3752 0.833252 13.7502 1.20825 13.7502 1.66659V4.99992C13.7502 5.45825 13.3752 5.83325 12.9168 5.83325Z"
        fill="#212B83"
      />
      <path
        d="M7.0835 19.1666C6.62516 19.1666 6.25016 18.7916 6.25016 18.3333V8.33325C6.25016 7.87492 6.62516 7.49992 7.0835 7.49992C7.54183 7.49992 7.91683 7.87492 7.91683 8.33325V18.3333C7.91683 18.7916 7.54183 19.1666 7.0835 19.1666Z"
        fill="#212B83"
      />
      <path
        d="M12.9168 19.1666C12.4585 19.1666 12.0835 18.7916 12.0835 18.3333V8.33325C12.0835 7.87492 12.4585 7.49992 12.9168 7.49992C13.3752 7.49992 13.7502 7.87492 13.7502 8.33325V18.3333C13.7502 18.7916 13.3752 19.1666 12.9168 19.1666Z"
        fill="#212B83"
      />
      <path
        d="M18.3335 17.0833C17.8752 17.0833 17.5002 16.7083 17.5002 16.2499V5.41658C17.5002 4.95825 17.8752 4.58325 18.3335 4.58325C18.7918 4.58325 19.1668 4.95825 19.1668 5.41658V16.2499C19.1668 16.7083 18.7918 17.0833 18.3335 17.0833Z"
        fill="#212B83"
      />
      <path
        d="M1.66683 17.0833C1.2085 17.0833 0.833496 16.7083 0.833496 16.2499V5.41658C0.833496 4.95825 1.2085 4.58325 1.66683 4.58325C2.12516 4.58325 2.50016 4.95825 2.50016 5.41658V16.2499C2.50016 16.7083 2.12516 17.0833 1.66683 17.0833Z"
        fill="#212B83"
      />
      <path
        d="M14.1668 19.1666H5.8335C5.37516 19.1666 5.00016 18.7916 5.00016 18.3333C5.00016 17.8749 5.37516 17.4999 5.8335 17.4999H14.1668C14.6252 17.4999 15.0002 17.8749 15.0002 18.3333C15.0002 18.7916 14.6252 19.1666 14.1668 19.1666Z"
        fill="#212B83"
      />
    </svg>
  );
};
