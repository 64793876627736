import { useMemo } from 'react';
import { CellProps } from 'react-table';
import commonStyles from 'styles/common.module.scss';
import transformDateToMonthWithYear from 'utils/productivity-metrics/transformDateToMonthWithYear';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ProductivityMetricsData = any;

export const usePMProjectPortfolioTableColumns = () => {
  const completedStatsColumns = useMemo(
    () => [
      {
        id: 'completed',
        Header: '% Completed',
        minWidth: 120,
        maxWidth: 120,
        Cell: ({
          row: {
            original: { percentage },
          },
        }: CellProps<ProductivityMetricsData>) => <>{percentage}%</>,
      },
      {
        id: 'count',
        Header: 'Active Projects',
        minWidth: 140,
        maxWidth: 140,
        Cell: ({
          row: {
            original: { countProjects },
          },
        }: CellProps<ProductivityMetricsData>) => (
          <span className={commonStyles.tableItemEndAlign}>{countProjects}</span>
        ),
      },
      {
        id: 'total projects',
        Header: '% of total projects',
        minWidth: 160,
        maxWidth: 160,
        Cell: ({
          row: {
            original: { percentageTotalProjects },
          },
        }: CellProps<ProductivityMetricsData>) => (
          <span className={commonStyles.tableItemEndAlign}>{percentageTotalProjects}%</span>
        ),
      },
    ],
    [],
  );

  const anticipatedStatsColumns = useMemo(
    () => [
      {
        id: 'month',
        Header: 'Month',
        minWidth: 160,
        maxWidth: 160,
        Cell: ({
          row: {
            original: { date },
          },
        }: CellProps<ProductivityMetricsData>) => <>{transformDateToMonthWithYear(date)}</>,
      },
      {
        id: 'anticipated projects',
        Header: 'Projects Likely to Begin',
        minWidth: 190,
        maxWidth: 190,
        Cell: ({
          row: {
            original: { anticipatedProjects },
          },
        }: CellProps<ProductivityMetricsData>) => (
          <span className={commonStyles.tableItemEndAlign}>{anticipatedProjects}</span>
        ),
      },
      {
        id: 'anticipated completing projects',
        Header: 'Projects Likely to Finish',
        minWidth: 190,
        maxWidth: 190,
        Cell: ({
          row: {
            original: { anticipatedCompletedProjects },
          },
        }: CellProps<ProductivityMetricsData>) => (
          <span className={commonStyles.tableItemEndAlign}>{anticipatedCompletedProjects}</span>
        ),
      },
      {
        id: 'variance',
        Header: 'Variance',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({
          row: {
            original: { variance },
          },
        }: CellProps<ProductivityMetricsData>) => <span className={commonStyles.tableItemEndAlign}>{variance}</span>,
      },
    ],
    [],
  );

  return {
    completedStatsColumns,
    anticipatedStatsColumns,
  };
};
