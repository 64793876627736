const onDragStart = (evt: React.DragEvent<HTMLDivElement>) => {
  const element = evt.currentTarget;
  element.classList.add('dragged');
  evt.dataTransfer.setData('text/plain', evt.currentTarget.id);
  evt.dataTransfer.effectAllowed = 'move';
};

const onDragEnd = (evt: React.DragEvent<HTMLDivElement>) => {
  evt.currentTarget.classList.remove('dragged');
};

const onDragEnter = (evt: React.DragEvent<HTMLDivElement>, styles: string) => {
  evt.preventDefault();
  const element = evt.currentTarget;
  element.classList.add(styles);
  evt.dataTransfer.dropEffect = 'move';
};

// eslint-disable-next-line
const onDragLeave = (evt: any, styles: string) => {
  const currentTarget = evt.currentTarget;
  const newTarget = evt.relatedTarget;
  if (newTarget?.parentNode === currentTarget || newTarget === currentTarget) return;
  evt.preventDefault();
  const element = evt.currentTarget;
  element.classList.remove(styles);
};
const onDragOver = (evt: React.DragEvent<HTMLDivElement>) => {
  evt.preventDefault();
  evt.dataTransfer.dropEffect = 'move';
};

// eslint-disable-next-line
const onDrop = <T,>(evt: React.DragEvent<HTMLDivElement>, callback: any, value: T, styles: string) => {
  evt.preventDefault();
  evt.currentTarget.classList.remove(styles);
  const data = evt.dataTransfer.getData('text/plain');
  callback(data, value);
};

export { onDragStart, onDragEnd, onDragEnter, onDragLeave, onDragOver, onDrop };
