import { IconProps } from 'types';

export const BellIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 1.19995C13.4 1.19995 12.8 1.39995 12.4 1.89995C12 2.29995 11.7 2.89995 11.7 3.49995H16.4C16.4 2.89995 16.2 2.29995 15.7 1.89995C15.2 1.39995 14.6 1.19995 14 1.19995Z"
        fill="black"
      />
      <path
        d="M24.5 23.2001H3.50002C3.10002 23.2001 2.70002 23.0001 2.60002 22.6001C2.40002 22.2001 2.50002 21.8001 2.80002 21.5001C2.80002 21.5001 6.00002 17.9001 6.00002 12.0001C6.00002 4.90007 8.70002 3.40007 14 3.10007C16.3 3.00007 18.3 3.60007 19.7 4.90007C21.2 6.30007 22 8.50007 22 11.1001C22 18.6001 25.1 21.4001 25.1 21.4001C25.4 21.7001 25.5 22.1001 25.4 22.5001C25.3 22.9001 24.9 23.2001 24.5 23.2001ZM5.40002 21.2001H22.4C21.3 19.4001 20 16.2001 20 11.1001C20 9.10007 19.4 7.40007 18.3 6.40007C17.3 5.40007 15.9 5.00007 14.1 5.10007C9.70002 5.30007 8.00002 6.00007 8.00002 12.0001C8.00002 16.2001 6.50002 19.4001 5.40002 21.2001Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.20005 21.5C9.40005 21.3 9.70005 21.2 10 21.2H18C18.3 21.2 18.6 21.3 18.8 21.5C19 21.7 19.1 21.9999 19 22.2999C18.8 23.4999 18.2 24.6 17.3 25.4C16.4 26.2 15.2 26.6 14 26.6C12.8 26.6 11.6 26.2 10.7 25.4C9.80005 24.6 9.20005 23.4999 9.00005 22.2999C8.90005 21.9999 9.00005 21.7 9.20005 21.5ZM11.4 23.2C11.6 23.5 11.8 23.7 12 23.9C12.6 24.4 13.3 24.7 14 24.7C14.7 24.7 15.5 24.4 16 23.9C16.2 23.7 16.5 23.4 16.6 23.2H11.4Z"
        fill="black"
      />
    </svg>
  );
};
