import { FC, lazy, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';

import { api, apiRoutes } from 'api';
import { RequestWorkOrderFormValues, WorkOrderFormValues, WorkOrderTypeEnum } from 'types';
import { CustomDialog } from 'components';
import { WorkOrderForm } from 'components/WorkOrderForm';
import { BankContact, Inspection, Project, User, WorkOrderStatus, WorkOrderType, Organization } from 'types/common';

const ConfirmationModal = lazy(() => import('components/common/ConfirmationModal'));

type WorkOrderCreateFormProps = {
  handleClose: () => void;
  callback: () => void;
  initialValues?: {
    project?: Project;
    startDate?: string;
    dueDate?: string;
    bank?: Organization;
    bankContact?: BankContact;
    dateSubmitted?: string;
    type?: WorkOrderType;
    status?: WorkOrderStatus;
    assignee?: User;
    drafter?: User;
    inspection?: Inspection;
  };
  defaultValues?: {
    assignee?: User;
  };
  isNew?: boolean;
};

export const WorkOrderCreateForm: FC<WorkOrderCreateFormProps> = ({
  handleClose,
  callback,
  initialValues,
  isNew,
  defaultValues,
}) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const workOrderDraft = useRef<WorkOrderFormValues>();

  const createWorkOrderMutation = (values: RequestWorkOrderFormValues) =>
    api.post(apiRoutes.workOrders, values).then((res) => res);
  const { mutateAsync: createWorkOrderMutate, isLoading: createWorkOrderLoading } = useMutation(
    'createWorkOrderMutation',
    (values: RequestWorkOrderFormValues) => createWorkOrderMutation(values),
    {
      onSuccess: () => {
        toast.success('Work order has been successfully created!');
        handleClose();
        callback();
      },
    },
  );

  const handleCloseConfirmDialog = () => setOpenConfirmDialog(false);

  const handleSubmit = async (
    { projectId, type, status, inspectionId, assignee, drafter, bank, bankContact, ...values }: WorkOrderFormValues,
    hasAssociatedInspections?: boolean,
  ) => {
    if (values.dueDate && values.startDate && new Date(values.dueDate) < new Date(values.startDate)) {
      toast.error('Due date cannot be set before start date');
      return;
    }

    if (hasAssociatedInspections && !inspectionId.id && type.id === WorkOrderTypeEnum.InspectionReport) {
      setOpenConfirmDialog(true);
      workOrderDraft.current = {
        projectId,
        type,
        status,
        inspectionId,
        assignee,
        drafter,
        bank,
        bankContact,
        ...values,
      };
      return;
    }

    await createWorkOrderMutate({
      ...values,
      projectId: projectId?.id as string,
      type: type.id,
      status: status.id as string,
      inspectionId: inspectionId?.id as string,
      assignee: assignee.id as number,
      drafter: drafter.id as number,
      bankId: bank.id as number,
      bankContactId: bankContact?.id as number,
    });
  };

  const handleCreateWorkOrder = async () => {
    handleClose();
    workOrderDraft.current &&
      (await createWorkOrderMutate({
        ...workOrderDraft?.current,
        projectId: workOrderDraft?.current?.projectId?.id as string,
        type: workOrderDraft?.current?.type.id,
        status: workOrderDraft?.current?.status.id as string,
        inspectionId: workOrderDraft?.current?.inspectionId?.id as string,
        assignee: workOrderDraft?.current?.assignee.id as number,
        drafter: workOrderDraft?.current?.drafter.id as number,
        bankId: workOrderDraft?.current?.bank.id as number,
        bankContactId: workOrderDraft?.current?.bankContact?.id as number,
      }));
  };

  if (createWorkOrderLoading) return <CircularProgress size={64} />;

  return (
    <>
      <WorkOrderForm
        onClose={handleClose}
        onSubmit={handleSubmit}
        submitButtonTitle="Add work order"
        isNew={isNew}
        {...initialValues}
        {...{ defaultValues }}
      />

      <CustomDialog
        open={openConfirmDialog}
        header="This project has inspections that have not yet been associated with a work order. Would you like to use an existing inspection or go ahead and create a new inspection?"
        onClose={handleCloseConfirmDialog}
      >
        <ConfirmationModal
          cancelText="Use existing"
          onClose={handleCloseConfirmDialog}
          submitText="Create new"
          onSubmit={handleCreateWorkOrder}
        />
      </CustomDialog>
    </>
  );
};
