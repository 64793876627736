import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const ArchivedWorkOrdersRoute = Route(
  {
    project: Route.query.string,
    search: Route.query.string,
    type: Route.query.string,
    assignee: Route.query.string,
    bank: Route.query.string,
    bankContact: Route.query.string,
    stateName: Route.query.string,
    page: Route.query.number,
    dateStart: Route.query.string,
    dateEnd: Route.query.string,
  },
  '/work-orders/archived',
);

export const ArchivedWorkOrders = ArchivedWorkOrdersRoute(lazy(() => import('./ArchivedWorkOrders')));
