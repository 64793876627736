import { TextField } from 'formik-material-ui';
import { CustomDatePicker, Autocomplete } from 'components/common';
import { Field } from 'formik';

import { YesNoNaOptions, defaultFieldProps } from '../const';

export const EnvironmentalReportingFields = () => (
  <>
    <Field
      component={TextField}
      name="environmentalConsultant"
      label="Environmental Consultant"
      {...defaultFieldProps}
    />
    <Field component={CustomDatePicker} name="date" label="Date" removeTimezone {...defaultFieldProps} />
    <Field
      component={Autocomplete}
      name="certifiedToBank"
      placeholder="Is the report certified to the Bank?"
      options={YesNoNaOptions}
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      name="recognizedCondition"
      placeholder="Is there a recognized environmental condition in the report?"
      options={YesNoNaOptions}
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      name="mapMatch"
      placeholder="Does the project description and map match the site?"
      options={YesNoNaOptions}
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      name="otherConcerns"
      placeholder="Does the report present other concerns that need to be addressed?"
      options={YesNoNaOptions}
      {...defaultFieldProps}
    />
  </>
);
