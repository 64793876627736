import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';

import { SadSmile } from 'icons';

import styles from './EmptyState.module.scss';

interface EmptyStateInterface {
  onClick?: () => void;
  model: string;
  btnText?: string;
  text?: string;
  title?: string;
}

export const EmptyState = ({ onClick, model, btnText = 'Reset Filters', text, title }: EmptyStateInterface) => {
  return (
    <div
      className={clsx('flex flex-column w-100 justify-content-center align-items-center p-24', styles.emptyContainer)}
    >
      <SadSmile />
      <span className={clsx('text-20  mb-8 mt-16 text-primary-label', styles.title)}>
        {title || `${model.charAt(0).toLocaleUpperCase() + model.slice(1)} list is empty`}
      </span>
      <span className={clsx('text-smallTextGrey weight-bold w-25 text-center', styles.text)}>
        {text || `Seems there are no such ${model}. Please try to reset the filters or search something else.`}
      </span>

      {onClick && (
        <Button className="mt-20" variant="contained" onClick={onClick}>
          {btnText}
        </Button>
      )}
    </div>
  );
};
