import { MobileTableRow } from 'components';
import { MobileTableV2, MobileTableRowField } from 'components/common/MobileTableV2';
import {
  productivityMetricsTableEmptyModel,
  productivityMetricsTableEmptyTitle,
} from 'components/common/Table/parts/EmptyState/const';
import { useMobileTable } from 'hooks/useMobileTable';
import { MobileTableProps } from 'types';
import getMonthsWithYearArray from 'utils/productivity-metrics/getMonthsWithYearArray';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PMEstimatedInspectionsMobileTable = ({ items, loading, onClearFilters }: MobileTableProps<any>) => {
  const { selectedRow, toggleSelectedRow } = useMobileTable();
  const months = getMonthsWithYearArray(items[0]?.monthsDate);

  return (
    <MobileTableV2
      hasData={items.length > 0}
      loading={loading}
      isScrollTop={false}
      isNewLayout
      className="mb-24"
      handleClearFilters={onClearFilters}
      model={productivityMetricsTableEmptyModel}
      emptyStateTitle={productivityMetricsTableEmptyTitle}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {items.map((item: any) => {
        const { name, assignee, total } = item;
        const columns = months.map((month) => <MobileTableRowField key={month} label={`${month}`} value={assignee} />);

        return (
          <MobileTableRow
            key={name}
            id={name}
            rowTitle={name}
            handleToggle={toggleSelectedRow}
            selectedRowInfo={selectedRow}
          >
            <MobileTableRowField label="Name" value={name} />
            <MobileTableRowField label="Total" value={total} />
            {columns}
          </MobileTableRow>
        );
      })}
    </MobileTableV2>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PMRemainingInspectionsMobileTable = ({ items, loading, onClearFilters }: MobileTableProps<any>) => {
  const { selectedRow, toggleSelectedRow } = useMobileTable();

  return (
    <MobileTableV2
      hasData={items.length > 0}
      loading={loading}
      isScrollTop={false}
      isNewLayout
      handleClearFilters={onClearFilters}
      model={productivityMetricsTableEmptyModel}
      emptyStateTitle={productivityMetricsTableEmptyTitle}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {items.map((item: any) => {
        const { name, assignee, bank } = item;

        return (
          <MobileTableRow
            key={name}
            id={name}
            rowTitle={name}
            handleToggle={toggleSelectedRow}
            selectedRowInfo={selectedRow}
          >
            <MobileTableRowField label="Month" value={name} />
            <MobileTableRowField label="Anticipated Projects" value={assignee} />
            <MobileTableRowField label="Anticipated Completing Projects" value={bank} />
            <MobileTableRowField label="Variance" value={bank} />
          </MobileTableRow>
        );
      })}
    </MobileTableV2>
  );
};
