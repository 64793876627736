import { FC } from 'react';
import { TableV2 } from 'components';

import { PMInspectionsProps } from '../../types';
import { usePMProjectPortfolioTable } from './useTable';
import styles from '../../../ProjectPortfolio/components/Table/Table.module.scss';
import { PMEstimatedInspectionsMobileTable, PMRemainingInspectionsMobileTable } from '../MobileTable';

export const PMInspectionsTable: FC<PMInspectionsProps> = ({ match, link }) => {
  const {
    transformedCountData,
    transformedAmountData,
    estimatedInspectionsColumns,
    remainingInspectionsColumns,
    isFetching,
    emptyTableState,
    handleClear,
  } = usePMProjectPortfolioTable({
    match,
    link,
  });

  return (
    <>
      <TableV2
        columns={estimatedInspectionsColumns}
        data={transformedAmountData}
        loading={isFetching}
        isScrollTop={false}
        className={styles.table}
        {...emptyTableState}
      />
      <TableV2
        columns={remainingInspectionsColumns}
        data={transformedCountData}
        isScrollTop={false}
        loading={isFetching}
        className={styles.table}
        {...emptyTableState}
      />

      <PMEstimatedInspectionsMobileTable
        items={transformedAmountData}
        loading={isFetching}
        onClearFilters={handleClear}
      />
      <PMRemainingInspectionsMobileTable
        items={transformedCountData}
        loading={isFetching}
        onClearFilters={handleClear}
      />
    </>
  );
};
