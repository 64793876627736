import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';

import { DocImg, JpgImg, XlsImg, PdfImg, DownloadIcon } from 'icons';

import styles from './PreviewCard.module.scss';

export interface PropsInterface {
  id?: number | string;
  name?: string;
  onClick?: () => void;
  onDownload?: () => void;
  imgPath?: string;
  addToDownload: () => void;
  isSelected: boolean;
}

export const PreviewCard = ({ name, id, onClick, onDownload, imgPath, addToDownload, isSelected }: PropsInterface) => {
  const fileExpansion = name?.substr(-3);

  const getFileIcon = (file?: string) => {
    if (file === 'pdf') return <PdfImg />;
    else if (file === 'ocx') return <DocImg />;
    else if (file === 'jpg') return <JpgImg />;
    else if (file === 'lsx') return <XlsImg />;
  };

  return (
    <div key={id} className={clsx(styles.wrapper, isSelected && styles.selectedItem)}>
      <div className={styles.fPart} onClick={onClick}>
        <div className={styles.btnClose}>{/* <div className={styles.imgBucket} /> */}</div>
        {(imgPath as string)?.trim().length !== 0 ? (
          <img src={`${process.env.REACT_APP__FILE_API_URL}${imgPath}`} alt="" />
        ) : (
          <div className={styles.noPreview}>No preview</div>
        )}
      </div>
      <div className="flex align-items-center p-8 pointer">
        {getFileIcon(fileExpansion)}
        {name && (
          <Tooltip title={name}>
            <p className={clsx('ml-4', styles.text)} onClick={addToDownload}>
              {name}
            </p>
          </Tooltip>
        )}
        <DownloadIcon className={styles.downloadBtn} onClick={onDownload} />
      </div>
    </div>
  );
};
