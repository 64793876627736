import { IconProps } from 'types';

export const MetricsIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 0.5C1.66421 0.5 2 0.835786 2 1.25V14H14.75C15.1642 14 15.5 14.3358 15.5 14.75C15.5 15.1642 15.1642 15.5 14.75 15.5H1.25C0.835786 15.5 0.5 15.1642 0.5 14.75V1.25C0.5 0.835786 0.835786 0.5 1.25 0.5ZM10.25 0.5C10.6642 0.5 11 0.835786 11 1.25V11.75C11 12.1642 10.6642 12.5 10.25 12.5C9.83579 12.5 9.5 12.1642 9.5 11.75V1.25C9.5 0.835786 9.83579 0.5 10.25 0.5ZM13.25 3.5C13.6642 3.5 14 3.83579 14 4.25V11.75C14 12.1642 13.6642 12.5 13.25 12.5C12.8358 12.5 12.5 12.1642 12.5 11.75V4.25C12.5 3.83579 12.8358 3.5 13.25 3.5ZM7.25 6.5C7.66421 6.5 8 6.83579 8 7.25V11.75C8 12.1642 7.66421 12.5 7.25 12.5C6.83579 12.5 6.5 12.1642 6.5 11.75V7.25C6.5 6.83579 6.83579 6.5 7.25 6.5ZM4.25 9.5C4.66421 9.5 5 9.83579 5 10.25V11.75C5 12.1642 4.66421 12.5 4.25 12.5C3.83579 12.5 3.5 12.1642 3.5 11.75V10.25C3.5 9.83579 3.83579 9.5 4.25 9.5Z"
        fill="#212B83"
      />
    </svg>
  );
};
