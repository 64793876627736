import { useHistory } from 'react-router';
import { useMemo } from 'react';
import { useGetProductivityMetricsProjectPortfolio } from 'pages/ProductivityMetrics/queries';
import {
  productivityMetricsTableEmptyModel,
  productivityMetricsTableEmptyTitle,
} from 'components/common/Table/parts/EmptyState/const';
import transformObjectToArray from 'utils/productivity-metrics/transformObjectToArray';

import { PMProjectPortfolioProps } from '../../types';
import { usePMProjectPortfolioTableColumns } from './useTableColumns';

export const usePMProjectPortfolioTable = ({
  match: {
    query: {
      bank,
      bankContact,
      services,
      assignee,
      project,
      projectStatus,
      projectType,
      state,
      owner,
      status,
      estimatedInspectionDateStartFrom,
      estimatedInspectionDateStartTo,
      estimatedInspectionDateCompletionFrom,
      estimatedInspectionDateCompletionTo,
      projectCompletionDateFrom,
      projectCompletionDateTo,
    },
    query,
  },
  link,
}: PMProjectPortfolioProps) => {
  const { push } = useHistory();
  const { completedStatsColumns, anticipatedStatsColumns } = usePMProjectPortfolioTableColumns();
  const { data, isFetching } = useGetProductivityMetricsProjectPortfolio({
    params: {
      'filter[_service]': services,
      'filter[bank]': bank,
      'filter[assignee]': assignee,
      'filter[project]': project,
      'filter[_projectStatus]': projectStatus,
      'filter[_projectType]': projectType,
      'filter[state]': state,
      'filter[_bankContact]': bankContact,
      'filter[_projectOwner]': owner,
      'filter[status]': status,
      ...(estimatedInspectionDateStartTo
        ? {
            'filter[expectedDateOfFirstInspection:from]': estimatedInspectionDateStartFrom,
            'filter[expectedDateOfFirstInspection:to]': estimatedInspectionDateStartTo,
          }
        : { 'filter[expectedDateOfFirstInspection]': estimatedInspectionDateStartFrom }),
      ...(estimatedInspectionDateCompletionTo
        ? {
            'filter[expectedDateOfLastInspection:from]': estimatedInspectionDateCompletionFrom,
            'filter[expectedDateOfLastInspection:to]': estimatedInspectionDateCompletionTo,
          }
        : { 'filter[expectedDateOfLastInspection]': estimatedInspectionDateCompletionFrom }),
      ...(projectCompletionDateTo
        ? {
            'filter[_projectCompletionDate][from]': projectCompletionDateFrom,
            'filter[_projectCompletionDate][to]': projectCompletionDateTo,
          }
        : { 'filter[_projectCompletionDate][from]': projectCompletionDateFrom }),
    },
    queryKey: [query],
  });

  const { anticipatedStats, completedStats } = data;

  const filteredAnticipatedStats = useMemo(
    () => (anticipatedStats ? transformObjectToArray(anticipatedStats, 'date') : []),
    [anticipatedStats],
  );
  const filteredCompletedStats = useMemo(
    () => (completedStats ? transformObjectToArray(completedStats, 'percentage') : []),
    [completedStats],
  );

  const handleClear = () => {
    push(link());
  };

  const emptyTableState = {
    model: productivityMetricsTableEmptyModel,
    emptyStateTitle: productivityMetricsTableEmptyTitle,
    handleClearFilters: handleClear,
  };

  const isAnyData = !!(filteredAnticipatedStats.length || filteredCompletedStats.length);

  return {
    filteredAnticipatedStats,
    filteredCompletedStats,
    completedStatsColumns,
    anticipatedStatsColumns,
    isFetching,
    emptyTableState,
    isAnyData,
    handleClear,
  };
};
