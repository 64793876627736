import { TextField } from 'formik-material-ui';
import { CustomDatePicker } from 'components/common/CustomDatePicker';
import { Field } from 'formik';
import { Autocomplete } from 'components/common';

import { defaultFieldProps, permitsTypeOptions } from '../const';

export const PermitsFields = () => (
  <>
    <Field
      component={Autocomplete}
      name="type"
      placeholder="Type"
      options={permitsTypeOptions}
      {...defaultFieldProps}
    />
    <Field component={TextField} name="permitNumber" label="Permit Number" {...defaultFieldProps} />
    <Field component={CustomDatePicker} name="issueDate" label="Issue Date" removeTimezone {...defaultFieldProps} />
    <Field
      component={CustomDatePicker}
      name="expirationDate"
      label="Expiration Date"
      removeTimezone
      {...defaultFieldProps}
    />
  </>
);
