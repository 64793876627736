import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const ProjectPermitsRoute = Route(
  {
    id: Route.params.number.optional,
  },
  ({ id }) => `/project/${id}/permits`,
);

export const ProjectPermits = ProjectPermitsRoute(lazy(() => import('./Permits')));
