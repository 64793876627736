import React, { ReactNode, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';

import { ArrowUpIcon, MenuIcon, SearchIcon } from 'icons';
import { mobLogo, logoTitle } from 'assets/images';
import { useSidebar } from 'contexts';

import styles from './ViewHeading.module.scss';

interface ViewHeadingProps {
  label?: string | ReactNode;
  children?: ReactNode;
  headingClassName?: string;
  className?: string;
  backPath?: string;
  hasSmartBackBtn?: boolean;
  sticky?: boolean;
  SearchField?: React.FC<{ className: string; onClose: () => void }>;
  backState?: { link: string; state?: { [key: string]: unknown } };
  onClickHeadingLabel?: () => void;
}

export const ViewHeading = ({
  label,
  children,
  className,
  headingClassName,
  hasSmartBackBtn,
  sticky = true,
  SearchField,
  backState,
  onClickHeadingLabel,
}: ViewHeadingProps) => {
  const { goBack, push } = useHistory();
  const { handleSidebarOpen } = useSidebar();
  const [openSearch, setOpenSearch] = useState(false);

  const handleOpenSearch = () => setOpenSearch(true);
  const handleCloseSearch = () => setOpenSearch(false);

  const renderBackBtn = () => {
    return (
      <ArrowUpIcon
        onClick={() => {
          backState ? push(backState.link, backState?.state) : goBack();
        }}
        className={styles.backBtn}
      />
    );
  };

  return (
    <div className={clsx(sticky && styles.header, headingClassName)}>
      <header className={clsx('flex flex-wrap align-items-center justify-content-between px-16 pt-12', className)}>
        <div className="flex justify-content-between d-sm-none align-items-center w-100 p-relative">
          <div>
            {SearchField && (
              <>
                <SearchIcon onClick={handleOpenSearch} className={clsx(styles.searchIcon, 'c-pointer')} />
                <SearchField
                  key="searchField"
                  className={clsx(styles.mbSearchField, openSearch && styles.active)}
                  onClose={handleCloseSearch}
                />
              </>
            )}
          </div>

          <div className="flex justify-content-center">
            <div>
              <img src={mobLogo} width="25" height="25" className={styles.logotype} alt="logotype" />
            </div>

            <div>
              <img src={logoTitle} width="100" height="32" className={styles.logoTitle} alt="logoTitle" />
            </div>
          </div>

          <div className="text-right">
            <MenuIcon onClick={handleSidebarOpen} className="c-pointer" />
          </div>
        </div>

        <div className="flex align-items-center my-16 mb-sm-12 mt-sm-0 mr-sm-0 px-0 pr-sm-20">
          {hasSmartBackBtn && renderBackBtn()}
          <h1 className="weight-700 text-24 flex align-items-center pl-lg-16 pl-0" onClick={onClickHeadingLabel}>
            {label}
          </h1>
        </div>

        {children}
      </header>
    </div>
  );
};
