import { Questions } from 'pages/ReportWriting/schemas/types';
import { getAnswersByQuestionId } from './getAnswersByQuestionId';

export const getParsedAnswers = (questionsState: Questions[], str?: string, withoutCalculation?: boolean): string => {
  const regEx = /\[answer\((\d+)\)]/g;

  let newStr: string = str ?? '';
  str?.match(regEx)?.forEach((item) => {
    const regExForId = /\d+/g;
    const id = item.match(regExForId)?.join('');
    if (id) {
      try {
        const dataToReplace = getAnswersByQuestionId(id, questionsState)?.answer;
        let result = dataToReplace;

        if (withoutCalculation) {
          newStr = newStr.replace(item, result ?? '');
          return;
        }

        result = dataToReplace && !dataToReplace?.includes(',') ? eval(dataToReplace) : dataToReplace;
        newStr = newStr.replace(item, result ?? '');
      } catch (e) {
        const dataToReplace = getAnswersByQuestionId(id, questionsState)?.answer;
        newStr = newStr.replace(item, dataToReplace ?? '');
      }
    }
  });

  if (newStr.match(regEx)?.length) return getParsedAnswers(questionsState, newStr);
  return newStr ?? '';
};
