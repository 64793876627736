import { useState, useEffect, useMemo } from 'react';
import { CellProps } from 'react-table';
import commonStyles from 'styles/common.module.scss';
import getMonthsWithYearArray from 'utils/productivity-metrics/getMonthsWithYearArray';
import formatNumberWithCommas from 'utils/formatNumberWithCommas';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ProductivityMetricsData = any;

export const usePMInspectionsTableColumns = (monthsDate: string[]) => {
  const [months, setMonths] = useState<string[]>([]);

  useEffect(() => {
    if (monthsDate && monthsDate.length > 0) {
      setMonths(getMonthsWithYearArray(monthsDate));
    }
  }, [monthsDate]);

  const estimatedInspectionsColumns = useMemo(() => {
    const columns = months.map((month, index) => ({
      id: month,
      Header: () => <p className="text-right">{month}</p>,
      minWidth: 110,
      maxWidth: 110,
      Cell: ({
        row: {
          original: { monthsValues },
        },
      }: CellProps<ProductivityMetricsData>) => (
        <span className={commonStyles.tableItemEndAlign}>
          {monthsValues && `$${formatNumberWithCommas(monthsValues[index])}`}
        </span>
      ),
    }));

    return [
      {
        id: 'name',
        Header: 'name',
        minWidth: 200,
        maxWidth: 200,
        Cell: ({
          row: {
            original: { name },
          },
        }: CellProps<ProductivityMetricsData>) => <>{name}</>,
      },
      {
        id: 'total',
        Header: () => <p className="text-right">Total</p>,
        minWidth: 130,
        maxWidth: 130,
        Cell: ({
          row: {
            original: { total },
          },
        }: CellProps<ProductivityMetricsData>) => (
          <span className={commonStyles.tableItemEndAlign}>
            {total === 0 || total ? `$${formatNumberWithCommas(total)}` : ''}
          </span>
        ),
      },
      ...columns,
    ];
  }, [months]);

  const remainingInspectionsColumns = useMemo(() => {
    const columns = months.map((month, index) => ({
      id: month,
      Header: () => <p className="text-right">{month}</p>,
      minWidth: 110,
      maxWidth: 110,
      Cell: ({
        row: {
          original: { monthsValues, alternativeMonthsValues, customStyle },
        },
      }: CellProps<ProductivityMetricsData>) => (
        <span
          className={`
            ${commonStyles.tableItemEndAlign} 
            ${
              alternativeMonthsValues && alternativeMonthsValues[index] > 0 && customStyle
                ? commonStyles.redCellWithCross
                : ''
            }
          `}
        >
          {alternativeMonthsValues && alternativeMonthsValues[index] > 0 && customStyle
            ? ''
            : monthsValues && monthsValues[index]}
        </span>
      ),
    }));

    return [
      {
        id: 'name',
        Header: 'name',
        minWidth: 200,
        maxWidth: 200,
        Cell: ({
          row: {
            original: { name },
          },
        }: CellProps<ProductivityMetricsData>) => <>{name}</>,
      },
      {
        id: 'total',
        Header: () => <p className="text-right">Total</p>,
        minWidth: 130,
        maxWidth: 130,
        Cell: ({
          row: {
            original: { total },
          },
        }: CellProps<ProductivityMetricsData>) => <span className={commonStyles.tableItemEndAlign}>{total}</span>,
      },
      ...columns,
    ];
  }, [months]);

  return {
    estimatedInspectionsColumns,
    remainingInspectionsColumns,
  };
};
