import { ReactNode } from 'react';
import clsx from 'clsx';

import { WarningIcon } from 'icons';

import styles from './WarningText.module.scss';

interface WarningTextProps {
  icon?: ReactNode;
  children?: ReactNode;
  className?: string;
  text: string;
}

export const WarningText = ({ className, icon, text }: WarningTextProps) => {
  return (
    <div className={clsx(styles.textWrapper, className)}>
      {icon || <WarningIcon />}
      <p>{text}</p>
    </div>
  );
};
