import { createContext, useContext, useState, useEffect, PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';

interface HistoryContextType {
  history: string[];
}

const HistoryContext = createContext<HistoryContextType | undefined>(undefined);

export const useHistoryContext = () => {
  const context = useContext(HistoryContext);
  if (!context) {
    throw new Error('useHistoryContext must be used within a HistoryProvider');
  }
  return context;
};

const HistoryProvider = ({ children }: PropsWithChildren<unknown>) => {
  const history = useHistory();
  const [historyList, setHistoryList] = useState<string[]>([]);

  useEffect(() => {
    setHistoryList([history.location.pathname]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const unlisten = history.listen((location: any) => {
      setHistoryList((prevHistory) => [...prevHistory, location?.pathname]);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return <HistoryContext.Provider value={{ history: historyList }}>{children}</HistoryContext.Provider>;
};

export default HistoryProvider;
