import React from 'react';
import { parseISO } from 'date-fns';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { FormikProps, FormikValues, useFormikContext } from 'formik';
import FormHelperText from '@material-ui/core/FormHelperText';
import clsx from 'clsx';
import { Calendar, CloseIcon } from 'icons';

import styles from './CustomDatePicker.module.scss';
import { formatDateToYYYYMMDD } from 'utils';

type CustomDatePickerProps = {
  label: string;
  field: {
    name: string;
  };
  form: FormikProps<FormikValues>;
  className: string;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  removeTimezone?: boolean;
};

export const CustomDatePicker = ({
  label,
  field: { name },
  form: { setFieldValue, values },
  className,
  disabled,
  minDate,
  maxDate,
  removeTimezone,
}: CustomDatePickerProps) => {
  const formikContext = useFormikContext();
  const { error, touched } = formikContext.getFieldMeta(name);

  const handleChange = (date: MaterialUiPickersDate) => setFieldValue(name, date);
  const handleClear = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setFieldValue(name, null);
  };

  const Input = (props: JSX.IntrinsicAttributes & TextFieldProps) => (
    <TextField
      {...props}
      disabled={disabled}
      label={label}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Calendar className={styles.calendar} />
          </InputAdornment>
        ),
        endAdornment: !disabled && (
          <InputAdornment position="start" onClick={handleClear}>
            <CloseIcon className={styles.calendar} />
          </InputAdornment>
        ),
      }}
    />
  );

  const formatDate = () => {
    const dateWithoutTimeZone =
      typeof values[name] === 'object' ? formatDateToYYYYMMDD(values[name]) : values[name]?.split('T')[0];
    return parseISO(dateWithoutTimeZone);
  };

  return (
    <div
      className={clsx(
        'p-relative',
        !!(error && touched) && styles.error,
        styles.autocomplete,
        styles.fullWidth,
        className,
      )}
    >
      <DatePicker
        format="MM/dd/yyyy"
        clearable
        onChange={handleChange}
        value={values[name] ? (removeTimezone ? formatDate() : new Date(values[name])) : null}
        className={className}
        TextFieldComponent={Input}
        placeholder="MM/DD/YYYY"
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
      />
      {error && touched && (
        <FormHelperText error className={clsx('weight-400', styles.errorMessage)}>
          {typeof error === 'object' ? (error as { name: string }).name : error}
        </FormHelperText>
      )}
    </div>
  );
};
