import { FC } from 'react';
import AutoComplete from '@material-ui/lab/Autocomplete';
import { CustomDropdown, DatePickerContainer, FilterChip, PageFilters, SearchSelect } from 'components';
import useWindowSize from 'hooks/useWindowSize';
import { ProposalRequestsRouteProps } from 'pages/ProposalRequests/types';
import TextField from '@material-ui/core/TextField';
import { getOptionName, getOptionSelected } from 'utils/common';

import { useFilters } from './useFilters';
import { FiltersEnum } from './types';

export const Filters: FC<ProposalRequestsRouteProps> = ({ match, link }) => {
  const { isMobile } = useWindowSize();
  const {
    itemsPerGroup = 30,

    dateReceivedRfpEnd,
    dateProposalSentEnd,
    dateAcceptedRpfEnd,
    estimatedInspectionDateStartTo,
  } = match.query;
  const {
    isFiltersApplied,
    isShowClearBtn,

    filterDateAcceptedRpfStart,
    filterDateAcceptedRpfEnd,

    filterDateProposalSentStart,
    filterDateProposalSentEnd,

    filterDateReceivedRfpStart,
    filterDateReceivedRfpEnd,

    filterDateEstimatedInspectionStartFrom,
    filterDateEstimatedInspectionStartTo,

    servicesTypesLoading,
    banksLoading,
    bankContactLoading,

    dropdownBanks,
    dropdownServices,
    dropdownBankContacts,
    dropdownProjects,

    itemsPerGroupOptions,

    selectedBank,
    selectedBankContact,
    selectedService,
    selectedReceivedRfpDate,
    selectedProposalSentDate,
    selectedEstimatedInspectionStart,
    selectedAcceptedRpfDate,
    selectedProject,

    handleClear,
    handleDateFilterStartChange,
    handleDateFilterEndChange,
    handleDateFilterChange,
    handleFilterDateRemove,

    handleFilterRemove,
    handleFilterChange,

    handleItemsPerGroupChange,
  } = useFilters({ match, link });

  const filters = (
    <>
      <SearchSelect
        fullWidth={isMobile}
        label="Project"
        options={dropdownProjects}
        onChange={(value) => handleFilterChange(FiltersEnum.Project, value)}
      />
      <SearchSelect
        fullWidth={isMobile}
        label="Bank"
        options={dropdownBanks}
        onChange={(value) => handleFilterChange(FiltersEnum.Bank, value)}
        loading={banksLoading}
      />
      <SearchSelect
        fullWidth={isMobile}
        label="Bank contact"
        options={dropdownBankContacts}
        loading={bankContactLoading}
        onChange={(value) => handleFilterChange(FiltersEnum.BankContact, value)}
      />
      <SearchSelect
        fullWidth={isMobile}
        label="Services"
        options={dropdownServices}
        onChange={(value) => handleFilterChange(FiltersEnum.Services, value)}
        loading={servicesTypesLoading}
      />
      <CustomDropdown label="RFP received date" fullWidth={isMobile}>
        {(handleClose) => {
          return (
            <DatePickerContainer
              title="RFP received date"
              isRange={!!dateReceivedRfpEnd}
              startDate={filterDateReceivedRfpStart}
              endDate={filterDateReceivedRfpEnd}
              handleStartDateChange={(date) => handleDateFilterStartChange(date, 'receivedRfpDate')}
              handleEndDateChange={(date) => handleDateFilterEndChange(date, 'receivedRfpDate')}
              handleClose={handleClose}
              applyFilter={(value) => handleDateFilterChange(value, 'receivedRfpDate')}
            />
          );
        }}
      </CustomDropdown>
      <CustomDropdown label="Proposal sent date" fullWidth={isMobile}>
        {(handleClose) => {
          return (
            <DatePickerContainer
              title="Proposal sent date"
              isRange={!!dateProposalSentEnd}
              startDate={filterDateProposalSentStart}
              endDate={filterDateProposalSentEnd}
              handleStartDateChange={(date) => handleDateFilterStartChange(date, 'proposalSentDate')}
              handleEndDateChange={(date) => handleDateFilterEndChange(date, 'proposalSentDate')}
              handleClose={handleClose}
              applyFilter={(value) => handleDateFilterChange(value, 'proposalSentDate')}
            />
          );
        }}
      </CustomDropdown>
      <CustomDropdown label="Date awarded" fullWidth={isMobile}>
        {(handleClose) => {
          return (
            <DatePickerContainer
              title="Date awarded"
              isRange={!!dateAcceptedRpfEnd}
              startDate={filterDateAcceptedRpfStart}
              endDate={filterDateAcceptedRpfEnd}
              handleStartDateChange={(date) => handleDateFilterStartChange(date, 'acceptedRpfDate')}
              handleEndDateChange={(date) => handleDateFilterEndChange(date, 'acceptedRpfDate')}
              handleClose={handleClose}
              applyFilter={(value) => handleDateFilterChange(value, 'acceptedRpfDate')}
            />
          );
        }}
      </CustomDropdown>
      <CustomDropdown label="Estimated inspection start" fullWidth={isMobile}>
        {(handleClose) => {
          return (
            <DatePickerContainer
              title="Estimated inspection start"
              isRange={!!estimatedInspectionDateStartTo}
              startDate={filterDateEstimatedInspectionStartFrom}
              endDate={filterDateEstimatedInspectionStartTo}
              handleStartDateChange={(date) => handleDateFilterStartChange(date, 'estimatedInspectionDateStart')}
              handleEndDateChange={(date) => handleDateFilterEndChange(date, 'estimatedInspectionDateStart')}
              handleClose={handleClose}
              applyFilter={(value) => handleDateFilterChange(value, 'estimatedInspectionDateStart')}
            />
          );
        }}
      </CustomDropdown>
    </>
  );

  const chips = (
    <>
      <FilterChip
        filter="RFP received date"
        label={selectedReceivedRfpDate}
        onDelete={() => handleFilterDateRemove('receivedRfpDate')}
      />
      <FilterChip
        filter="Proposal sent date"
        label={selectedProposalSentDate}
        onDelete={() => handleFilterDateRemove('proposalSentDate')}
      />
      <FilterChip
        filter="Date awarded"
        label={selectedAcceptedRpfDate}
        onDelete={() => handleFilterDateRemove('acceptedRpfDate')}
      />
      <FilterChip
        filter="Estimated inspection start"
        label={selectedEstimatedInspectionStart}
        onDelete={() => handleFilterDateRemove('estimatedInspectionDateStart')}
      />
      <FilterChip filter="Service" label={selectedService} onDelete={() => handleFilterRemove(FiltersEnum.Services)} />
      <FilterChip filter="Bank" label={selectedBank} onDelete={() => handleFilterRemove(FiltersEnum.Bank)} />
      <FilterChip
        filter="Bank contact"
        label={selectedBankContact}
        onDelete={() => handleFilterRemove(FiltersEnum.BankContact)}
      />
      <FilterChip filter="Project" label={selectedProject} onDelete={() => handleFilterRemove(FiltersEnum.Project)} />
    </>
  );

  return (
    <div className="flex justify-content-end align-items-end flex-column">
      <PageFilters
        showMobileClearBtn={isShowClearBtn}
        handleClear={handleClear}
        isFiltersApplied={isFiltersApplied as string}
        Filters={filters}
        Chips={chips}
        // ModalContent={filters}
        // onSortChange={handleSortChange}
        // sortingStatuses={Object.values(StatusEnum)}
        // activeSortingStatus={status}
        // isSortingApplied={isSortingApplied}
      />
      <AutoComplete
        className="mb-16 mr-12 flex"
        style={{ width: 230 }}
        filterSelectedOptions
        value={{ name: `${itemsPerGroup} items per group`, id: itemsPerGroup }}
        options={itemsPerGroupOptions}
        getOptionLabel={getOptionName}
        getOptionSelected={getOptionSelected}
        onChange={handleItemsPerGroupChange}
        renderInput={(params) => <TextField {...params} label="Items per group" variant="outlined" />}
        disableClearable
      />
    </div>
  );
};
