import EventEmitter from 'events';
import { Project } from 'types/common';

export interface DocumentRequestProps {
  isReceivedDocuments: boolean;
  project: Project;
  getNewStatistics: () => void;
  onSubmit: EventEmitter;
}

export enum DocumentManagmentModalTabs {
  Standard = 'Standard', //required
  Others = 'Others', //sugested
  Notes = 'Notes',
}

export enum SelectedDialogEnum {
  CategoryEdit,
  NoteCreate,
  CategoryCreate,
  SendRemindersDialog,
  WorkOrderCreate,
  CategoryInfoAdd,
}
