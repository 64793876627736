import React, { useState, useMemo, lazy } from 'react';
import { Route } from 'react-router-hoc';
import IconButton from '@material-ui/core/IconButton';
import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import { format } from 'date-fns';
import usLocale from 'date-fns/locale/en-US';

import { CustomDialog, NoteForm } from 'components';
import { api, apiRoutes } from 'api';
import { PermissionEnum, RouteProps, NoteFormValues } from 'types';
import { DeleteIcon, EditIcon, PlusIcon } from 'icons';
import { useAuth } from 'contexts';
import { getFullName } from 'utils';
import { useFormModal } from 'hooks/useFormModal';
import UnsavedChangesModal from 'components/common/UnsavedChangesModal';

const ConfirmationModal = lazy(() => import('components/common/ConfirmationModal'));

import styles from './NoteItemInfo.module.scss';

const NoteInfoRoute = Route(
  {
    id: Route.params.number.optional,
    noteId: Route.query.number,
    route: Route.params.string.optional,
    search: Route.query.string,
  },
  ({ id, route }) => `/${route}/${id}/notes`,
);

const NoteItemInfo = ({
  match: {
    query: { noteId },
  },
  route,
  handleDelete,
  goBack,
}: RouteProps) => {
  const { userData } = useAuth();
  const [noteConfirmationModal, setNoteConfirmationModal] = useState(false);

  const {
    isFormModalOpen,
    isUnsavedChangesModalOpen,
    handleCloseAllModals,
    handleCloseUnsavedChangesModal,
    handleOpenFormModal,
    handleCloseFormModal,
  } = useFormModal();

  const getNoteQuery = () => api.get(`${route}/${noteId}`).then((res) => res.data);
  const { data: noteById, isLoading, isFetching, refetch } = useQuery(['getNoteQuery'], () => getNoteQuery());

  const editNoteMutation = (values: NoteFormValues) =>
    api.put(`${apiRoutes.notes}/${noteId}`, values).then((res) => res);
  const { mutate: noteEditMutate } = useMutation(
    'editNoteMutation',
    (values: NoteFormValues) => editNoteMutation(values),
    {
      onSuccess: () => {
        handleCloseFormModal();
        toast.success('Changes has been successfully saved!');
        refetch();
      },
    },
  );

  const filteredNoteById = useMemo(() => (noteById ? noteById : {}), [noteById]);

  const handleNoteDelete = async () => {
    await handleDelete();
    handleConfirmationModalClose();
    goBack();
  };

  const handleConfirmationModalOpen = () => setNoteConfirmationModal(true);
  const handleConfirmationModalClose = () => setNoteConfirmationModal(false);

  const handleEditModalOpen = () => handleOpenFormModal();
  const handleEditModalClose = () => handleCloseFormModal();

  const handleNoteEdit = (values: NoteFormValues) => noteEditMutate(values);

  const { title, project, text, updatedAt, createdAt, user } = filteredNoteById;

  if (isLoading || isFetching) return <CircularProgress size={64} />;

  return (
    <div className={styles.noteContainer}>
      <div className="flex align-items-center justify-content-between">
        <div className={styles.projectInfo}>{project ? <span>— project {project?.name}</span> : '— no project'}</div>
        {(userData?.permissions.has(PermissionEnum.note_delete_all) || userData?.id === user?.id) && (
          <IconButton onClick={handleConfirmationModalOpen}>
            <DeleteIcon className={styles.deleteIcon} />
          </IconButton>
        )}
      </div>
      <div className={styles.titleContainer}>
        <h1 className={clsx(styles.noteTitle, 'mr-12')}>{title}</h1>
        {(userData?.permissions.has(PermissionEnum.note_edit_all) ||
          userData?.id === user?.id ||
          userData?.id === project?.owner?.id) && (
          <EditIcon className={styles.editIcon} onClick={handleEditModalOpen} />
        )}
      </div>

      <p className={styles.noteText} dangerouslySetInnerHTML={{ __html: text ?? '' }} />
      <div className={styles.noteInfoContainer}>
        <div className="d-none d-md-block" />
        <div className={clsx(styles.noteDateInfoWrapper, 'flex flex-column')}>
          <span className={styles.noteDateInfo}>
            Last modified: {updatedAt && format(new Date(updatedAt), 'h:mm a, MMMM d', { locale: usLocale })}
          </span>
          <span className={styles.noteDateInfo}>
            Created: {createdAt && format(new Date(createdAt), 'h:mm a, MMMM d', { locale: usLocale })}
          </span>
          <span className={clsx(styles.noteDateInfo, 'weight-600')}>{getFullName(user)}</span>
        </div>
      </div>

      <CustomDialog
        open={noteConfirmationModal}
        icon={<DeleteIcon />}
        header="Delete this note?"
        onClose={handleConfirmationModalClose}
      >
        <ConfirmationModal
          text="You won’t be able to restore it"
          onClose={handleConfirmationModalClose}
          onSubmit={handleNoteDelete}
        />
      </CustomDialog>

      <CustomDialog
        open={isFormModalOpen as boolean}
        icon={<PlusIcon />}
        header="Edit note"
        onClose={(_, reason) => handleCloseFormModal(reason)}
      >
        <NoteForm
          title={noteById?.title}
          text={noteById?.text}
          buttonText="Save"
          onSubmit={handleNoteEdit}
          onClose={handleEditModalClose}
        />
        <UnsavedChangesModal
          open={isUnsavedChangesModalOpen}
          onClose={handleCloseUnsavedChangesModal}
          closeAll={handleCloseAllModals}
        />
      </CustomDialog>
    </div>
  );
};

export default NoteInfoRoute(NoteItemInfo);
