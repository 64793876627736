import { IconProps } from 'types';

export const ScrollTopIcon = ({ className, onClick }: IconProps) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <g filter="url(#filter0_ddd_7120_50375)">
        <rect x="8" y="4" width="48" height="48" rx="24" fill="white" />
        <rect x="8.5" y="4.5" width="47" height="47" rx="23.5" stroke="#9095C1" />
        <path
          d="M38 34C37.75 34 37.5 33.9 37.3 33.7L32 28.4L26.7 33.7C26.3 34.1 25.7 34.1 25.3 33.7C24.9 33.3 24.9 32.7 25.3 32.3L31.3 26.3C31.7 25.9 32.3 25.9 32.7 26.3L38.7 32.3C39.1 32.7 39.1 33.3 38.7 33.7C38.5 33.9 38.25 34 38 34Z"
          fill="#212B83"
        />
        <path
          d="M38 24H26C25.45 24 25 23.55 25 23C25 22.45 25.45 22 26 22H38C38.55 22 39 22.45 39 23C39 23.55 38.55 24 38 24Z"
          fill="#212B83"
        />
      </g>
      <defs>
        <filter
          id="filter0_ddd_7120_50375"
          x="0"
          y="0"
          width="64"
          height="64"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7120_50375" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_7120_50375" result="effect2_dropShadow_7120_50375" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_7120_50375" result="effect3_dropShadow_7120_50375" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_7120_50375" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
