export const productivityMetricsDataKeys = {
  allData: ['productivityMetricsData'] as const,
};

export const productivityMetricsProjectPortfolioKeys = {
  allData: ['productivityMetricsProjectPortfolio'] as const,
};

export const productivityMetricsRfpPortfolioKeys = {
  allData: ['productivityMetricsRfpPortfolio'] as const,
};

export const productivityMetricsAvgServicesPricesKeys = {
  allData: ['productivityMetricsAvgServicesPrices'] as const,
};

export const productivityMetricsInspectionsKeys = {
  allData: ['productivityMetricsInspections'] as const,
};
