import { toast } from 'react-toastify';
import { safeEval } from 'utils/report-writing';
import { Questions } from '../schemas/types';

type QuestionTypesHandlersProps = {
  item: Questions;
  questionsState: Questions[];
  setQuestionsState: (value: React.SetStateAction<Questions[]>) => void;
  mathematicalExpHistory: { [key: string]: string };
  setMathematicalExpHistory: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >;
};

export const useQuestionTypesHandlers = ({
  item,
  questionsState,
  setQuestionsState,
  mathematicalExpHistory,
  setMathematicalExpHistory,
}: QuestionTypesHandlersProps) => {
  //plain question
  const handleBlurPlainQuestion = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    const { isAmount, isMathematicalExp, id } = item;
    if (isAmount && e.target.value) {
      if (isMathematicalExp) {
        setMathematicalExpHistory((prev: { [key: string]: string }) => {
          return { ...prev, [`${id}`]: e.target.value };
        });
        const evalExecutionValue = safeEval(e.target.value);
        if (evalExecutionValue === '0') {
          toast.error('Calculation error!');
        }
        item.answer = Number(evalExecutionValue).toFixed(2);
        return setQuestionsState([...questionsState]);
      }

      item.answer = (+e.target.value).toFixed(2);
      setQuestionsState([...questionsState]);
    }
  };

  const handleChangePlainQuestion = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (item.pattern) {
      const regex = new RegExp(item.pattern);
      if (e.target.value === '' || e.target.value === '-' || regex.test(e.target.value)) {
        item.answer = e.target.value;
        setQuestionsState([...questionsState]);
        return;
      } else return;
    }
    item.answer = e.target.value;
    setQuestionsState([...questionsState]);
  };

  const handleFocusPlainQuestion = () => {
    if (mathematicalExpHistory[item.id]) {
      item.answer = mathematicalExpHistory[item.id];
      setQuestionsState([...questionsState]);
    }
  };

  return {
    handleBlurPlainQuestion,
    handleChangePlainQuestion,
    handleFocusPlainQuestion,
  };
};
