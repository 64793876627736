import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Route } from 'react-router-hoc';
import { useHistory } from 'react-router';
import EventEmitter from 'events';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { RequestSendRemindersFormValues, SendRemindersFormValues, TableCell, DropdownOption } from 'types';
import { api, apiRoutes } from 'api';
import { components } from 'generated/types';
import { ArrowDownIcon, ArrowRightIcon, MailIcon, SortIcon, DocumentIcon } from 'icons';
import {
  CustomDialog,
  EmptyState,
  LinearProgressWithLabel,
  SendReminderForm,
  Table,
  DocumentRequest,
  MobileTable,
} from 'components';
import { getFullName, toEnUsFormat } from 'utils';
import { links } from 'App';
import { emptyTableData } from 'consts';

import styles from './MissingCategories.module.scss';

type Project = components['schemas']['Project'];
type MissingCategoriesProps = {
  clearQuery: () => void;
};
enum SortingOptions {
  SuitableDocuments = 'suitableDocuments',
  DocumentsToReview = 'documentsToReview',
  LastDocumentReminderSentDate = 'lastDocumentReminderSentDate',
}

const MissingCategoriesRoute = Route(
  { search: Route.query.string, page: Route.query.number, project: Route.query.string },
  '/document-tracking/missing-categories',
);

const perPage = 50;

export const MissingCategories = MissingCategoriesRoute<MissingCategoriesProps>(
  ({
    match: {
      query: { search = '', page = 0, project },
      query,
    },
    link,
    clearQuery,
  }) => {
    const {
      push,
      location: { pathname },
    } = useHistory();

    const [projectId, setProject] = useState<undefined | number>();
    const [openDocumentRequestDialog, setOpenDocumentRequestDialog] = useState(false);
    const [openSendRemindersDialog, setOpenSendRemindersDialog] = useState(false);
    const [activeProject, setActiveProject] = useState<Project | null>(null);
    const [clicked, setClicked] = useState<number | null>();
    const [sortOption, setSortOption] = useState<{ [x: string]: 'ASC' | 'DESC' | null }>({
      [SortingOptions.SuitableDocuments]: 'ASC',
      [SortingOptions.DocumentsToReview]: null,
      [SortingOptions.LastDocumentReminderSentDate]: null,
    });

    const missingDocumentsQuery = () =>
      api
        .get(apiRoutes.missingCategories, {
          params: {
            search: search,
            page: page + 1,
            perPage: perPage,
            'filter[_project]': project,
            'sort[suitableDocuments]': sortOption[SortingOptions.SuitableDocuments],
            'sort[lastDocumentReminderSentDate]': sortOption[SortingOptions.LastDocumentReminderSentDate],
            // 'sort[documentsToReview]': sortOption[SortingOptions.DocumentsToReview] ? 'ASC' : 'DESC',
            'sort[name]': 'ASC',
          },
        })
        .then((res) => res.data);

    const {
      isLoading,
      data: documents,
      refetch,
      error,
    } = useQuery(['missingDocumentsQuery', query, sortOption], () => missingDocumentsQuery());

    const projectsQuery = () =>
      api
        .get<DropdownOption[]>(apiRoutes.projects, {
          params: {
            'filter[_dropdown]': true,
          },
        })
        .then((res) => res.data);
    const { data: projects } = useQuery('projectsQuery', () => projectsQuery());

    const sendReminder = (values: RequestSendRemindersFormValues) =>
      api.post(apiRoutes.sendReminder, { ...values }).then((res) => res);

    const { mutateAsync: sendReminderMutation } = useMutation(
      'sendReminderMutation',
      (values: RequestSendRemindersFormValues) => sendReminder(values),
      {
        onSuccess: () => {
          toast.success('Reminder has been successfully sended!');
          refetch();
          handleSendRemindersClose();
        },
      },
    );

    const handleSendRemindersClose = () => setOpenSendRemindersDialog(false);
    const handleSendRemindersOpen = () => setOpenSendRemindersDialog(true);

    const handleDocumentRequestClose = () => setOpenDocumentRequestDialog(false);
    const handleDocumentRequestOpen = (id: number | undefined) => {
      setProject(id);
      setOpenDocumentRequestDialog(true);
    };

    const handleSubmit = new EventEmitter();

    const handlePageChange = (direction: 'next' | 'prev') =>
      push(link({ ...query, page: direction === 'prev' ? (page > 0 ? page - 1 : 0) : page + 1 }));

    const handleCategoriesToggle = (index: number | undefined) =>
      clicked === index ? setClicked(null) : setClicked(index);

    const filteredProjects = useMemo(() => (projects?.length ? projects : []), [projects]);

    const filteredDocuments = useMemo(() => (documents?.items.length ? documents : emptyTableData), [documents]);

    const { items: documentItems, pagesTotal, page: itemsPage, total, count } = filteredDocuments;

    const handleClear = () => clearQuery();

    const handleSendReminderSubmit = async (values: SendRemindersFormValues) => {
      await sendReminderMutation({
        ...values,
        projectId: activeProject?.id as number,
        userIds: values.userIds.map(({ id }) => id as number),
        bankContactIds: values.bankContactIds.map(({ id }) => id as number),
      });
    };

    const setSortingOptions = (key: SortingOptions) => {
      setSortOption({
        ...sortOption,
        [key]: sortOption[key] == null ? 'ASC' : sortOption[key] === 'ASC' ? 'DESC' : null,
      });
    };

    useEffect(() => {
      //eslint-disable-next-line
      (error as any)?.status === 404 && handleClear();
    }, [error]);

    const columns = useMemo(
      () => [
        {
          id: 'name',
          Header: 'Project name',
          Cell: function name({
            row: {
              original: { name, id },
            },
          }: TableCell<Project>) {
            return (
              <NavLink to={links.ProjectFiles({ id })}>
                <span>{name}</span>
              </NavLink>
            );
          },
        },
        {
          id: 'bankContact',
          Header: 'Bank & contact',
          Cell: function bankContact({
            row: {
              original: { bank, bankContact },
            },
          }: TableCell<Project>) {
            return (
              <span>
                {bank?.name}
                <br />
                {getFullName(bankContact)}
              </span>
            );
          },
        },
        {
          id: 'suitableDocuments',
          Header: () => (
            <span
              onClick={() => {
                setSortingOptions(SortingOptions.SuitableDocuments);
              }}
            >
              <SortIcon
                className={clsx(
                  'mr-4',
                  styles.sortOption,
                  sortOption[SortingOptions.SuitableDocuments] && styles.activeSortOption,
                )}
              />
              <span>Suitable Documents</span>
            </span>
          ),
          Cell: function suitableDocuments({
            row: {
              original: { suitableDocuments, id },
            },
          }: TableCell<Project>) {
            return (
              <div onClick={() => handleDocumentRequestOpen(id)}>
                <LinearProgressWithLabel vertical={false} value={Math.round(suitableDocuments as number)} />
              </div>
            );
          },
        },
        {
          id: 'documentsToReview',
          Header: () => (
            <span
            // onClick={() => {
            //   setSortingOptions(SortingOptions.DocumentsToReview, !sortOption[SortingOptions.DocumentsToReview]);
            // }}
            >
              {/* <SortIcon
                className={clsx(
                  'mr-4',
                  styles.sortOption,
                  sortOption[SortingOptions.DocumentsToReview] && styles.activeSortOption,
                )}
              /> */}
              <span>Documents To Review</span>
            </span>
          ),
          Cell: function documentsToReview({
            row: {
              original: { documentsToReview },
            },
          }: TableCell<Project>) {
            return <span>{documentsToReview}</span>;
          },
        },
        {
          id: 'lastDocumentReminderSentDate',
          Header: () => (
            <span onClick={() => setSortingOptions(SortingOptions.LastDocumentReminderSentDate)}>
              <SortIcon
                className={clsx(
                  'mr-4',
                  styles.sortOption,
                  sortOption[SortingOptions.LastDocumentReminderSentDate] && styles.activeSortOption,
                )}
              />
              <span>Last reminder Send Date</span>
            </span>
          ),
          Cell: function lastReminderSentDate({
            row: {
              original: { lastDocumentReminderSentDate },
            },
          }: TableCell<Project>) {
            return <span>{toEnUsFormat(lastDocumentReminderSentDate)}</span>;
          },
        },
        {
          id: 'sendReminder',
          Header: '',
          maxWidth: 90,
          Cell: function EditDocument({ row: { original } }: TableCell<Project>) {
            return (
              <div
                className="weight-600 uppercase text-14 text-primary"
                onClick={() => [handleSendRemindersOpen(), setActiveProject(original as Project)]}
              >
                Send reminder
              </div>
            );
          },
        },
      ],
      [sortOption],
    );

    if (isLoading) return <CircularProgress size={64} />;

    return (
      <>
        <h2 className="px-16 px-sm-32 weight-700 text-24 mt-12 mt-sm-0">Projects missing documents</h2>
        {documentItems && !!documentItems.length && (
          <div className={styles.paginationContainer}>
            <div className="flex align-items-center justify-content-between justify-content-sm-end px-16 px-lg-32">
              <span className="mr-12 text-14 text-smallTextGrey">
                <span className="weight-700">{(itemsPage - 1) * perPage + 1}</span> -
                <span className="weight-700 mr-8"> {(itemsPage - 1) * perPage + count}</span> from
                <span className="weight-700 ml-8"> {total}</span>
              </span>
              <div className={styles.paginationArrowContainer}>
                <ArrowRightIcon className={styles.arrowLeft} onClick={() => handlePageChange('prev')} />
                <ArrowRightIcon disabled={pagesTotal === page + 1} onClick={() => handlePageChange('next')} />
              </div>
            </div>
          </div>
        )}
        <div className="d-none d-md-block px-24 px-md-32 flex-1 overflow-auto">
          <Table
            data={documentItems}
            columns={columns}
            onClick={handleClear}
            model="documents"
            scrollIconClassName={styles.scrollTop}
          />
        </div>

        <MobileTable scrollTopValue={260}>
          {documentItems.map((document: Project) => {
            const { id, name, bank, bankContact, suitableDocuments, documentsToReview, lastDocumentReminderSentDate } =
              document;
            return (
              <div key={id}>
                <div className={styles.smTableContainer}>
                  <div
                    className="flex align-items-center justify-content-between"
                    onClick={() => handleCategoriesToggle(id)}
                  >
                    <NavLink to={links.ProjectOverview({ id })}>
                      <p>{name}</p>
                    </NavLink>
                    <ArrowDownIcon
                      className={clsx(styles.ArrowDownIcon, clicked === id && styles.ArrowDownIconRotate)}
                    />
                  </div>
                  {clicked === id && (
                    <div className={styles.smTableDropdownContainer}>
                      <div className="pt-16">
                        <span className="uppercase">Bank & contact: </span>
                        <span>
                          {bank?.name}
                          <br />
                          {getFullName(bankContact)}
                        </span>
                      </div>

                      <div className="pt-16">
                        <div>
                          <span className="uppercase">Suitable documents: </span>
                          <LinearProgressWithLabel vertical={false} value={Math.round(suitableDocuments as number)} />
                        </div>
                      </div>

                      <div className="pt-16">
                        <span className="uppercase">Documents to review: </span>
                        {documentsToReview}
                      </div>

                      <div className="pt-16">
                        <span className="uppercase">Last reminder send date: </span>
                        {toEnUsFormat(lastDocumentReminderSentDate)}
                      </div>

                      <div className="pt-16">
                        <div
                          className="weight-600 uppercase text-14 text-primary"
                          onClick={() => [handleSendRemindersOpen(), setActiveProject(document as Project)]}
                        >
                          Send reminder
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          {!documentItems.length && <EmptyState onClick={handleClear} model="documents" />}
        </MobileTable>

        {projectId && (
          <CustomDialog
            icon={<DocumentIcon className={styles.documentIcon} />}
            header="Document Management"
            open={openDocumentRequestDialog}
            onClose={handleDocumentRequestClose}
          >
            <DocumentRequest
              isReceivedDocuments={!pathname.includes(links.RejectedDocuments())}
              project={filteredProjects.find(({ id }) => id === +projectId) as Project}
              getNewStatistics={refetch}
              onSubmit={handleSubmit}
            />
          </CustomDialog>
        )}

        <CustomDialog
          open={openSendRemindersDialog}
          icon={<MailIcon />}
          header="Send reminder"
          onClose={handleSendRemindersClose}
        >
          <SendReminderForm
            onSubmit={handleSendReminderSubmit}
            projectId={activeProject?.id as number}
            onClose={handleSendRemindersClose}
          />
        </CustomDialog>
      </>
    );
  },
);

export default MissingCategories;
