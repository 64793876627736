import React from 'react';
import { HeaderGroup } from 'react-table';

import styles from './Heading.module.scss';

export interface TableHeaderProps {
  // eslint-disable-next-line
  headerGroups: Array<HeaderGroup<object>>;
}

export const Heading = ({ headerGroups }: TableHeaderProps) => {
  return (
    <thead className={styles.heading}>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()} key={`heading-row${headerGroup.id}`}>
          {headerGroup.headers.map((column) => (
            <th
              {...column.getHeaderProps({
                ...column.getSortByToggleProps(),
                style: {
                  minWidth: column.minWidth,
                  width: column.width,
                  maxWidth: column.maxWidth,
                },
              })}
              key={`heading-column${column.id}`}
            >
              <>{column.render('Header')}</>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};
