import React, { FC, useMemo } from 'react';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import { LoadingButton } from 'components';
import { ForgotFormValues } from 'types';
import { links } from 'App';

import styles from './ForgotPasswordForm.module.scss';

interface ForgotPasswordInterface {
  onSubmit: (values: ForgotFormValues, formikHelpers: FormikHelpers<ForgotFormValues>) => void | Promise<void>;
  email?: string;
}

export const ForgotPasswordForm: FC<ForgotPasswordInterface> = (props) => {
  const { email, onSubmit } = props;

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().email('Invalid email address').required('Email is a required field'),
      }),
    [],
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        email: email ?? '',
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <div className="flex flex-column justify-content-between">
          <div className="flex flex-column juctify-content-between align-items-center padding-15">
            <div>
              <p className={clsx('weight-normal', styles.titleFontSize)}>Forgot password?</p>
              <p className={clsx('weight-normal color-smallTextGrey pb-48', styles.textStyle)}>
                Please enter the email you use to login
              </p>
              <Form>
                <Field
                  component={TextField}
                  name="email"
                  label="Email"
                  variant="outlined"
                  type="email"
                  className="mb-24 mb-md-36"
                />

                <div className="flex align-items-center justify-content-center">
                  <LoadingButton type="submit" className="mb-24 mb-md-0" loading={isSubmitting}>
                    Request password reset
                  </LoadingButton>
                </div>

                <div className="flex justify-content-center align-items-center mb-32">
                  <NavLink to={links.Login()} className="text-primary weight-bolder  mt-32">
                    Back to login
                  </NavLink>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
