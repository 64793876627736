import { IconProps } from 'types';

export const InProgressIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 19.0909C15.0208 19.0909 19.0909 15.0208 19.0909 10C19.0909 4.97923 15.0208 0.909091 10 0.909091C4.97923 0.909091 0.909091 4.97923 0.909091 10C0.909091 15.0208 4.97923 19.0909 10 19.0909ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill="black"
      />
      <circle cx="10" cy="10" r="3" fill="black" />
    </svg>
  );
};
