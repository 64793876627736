import { ProposalForm } from 'components';
import { useCreateProposalRequestMutation } from 'lib/react-query/mutations';
import { toast } from 'react-toastify';
import { ProposalCreateDTO } from 'types';
import { useQueryClient } from 'react-query';
import { queriesKeys } from 'lib/react-query/const';

import { ProposalCreateFormProps } from './types';

export const ProposalCreateForm = ({ onClose }: ProposalCreateFormProps) => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useCreateProposalRequestMutation(() => {
    toast.success('Proposal has been successfully created!');
    onClose();
    queryClient.invalidateQueries(queriesKeys.proposalRequests);
  });

  const handleSubmit = async (values: ProposalCreateDTO) => {
    await mutateAsync(values);
  };

  return <ProposalForm onClose={onClose} onSubmit={handleSubmit} isNew />;
};
