export function getDateWithoutTimeZone(date?: Date | string | null): string {
  const correctDate = toDateWithoutTimeZone(date);
  return correctDate ? correctDate.toISOString() : '';
}

function toDateWithoutTimeZone(date?: Date | string | null): Date | null {
  const parsedDate = new Date(date as Date);
  if (isNaN(parsedDate.getTime())) {
    return null; // Return null if the date is invalid
  }
  const timestamp = parsedDate.getTime() - parsedDate.getTimezoneOffset() * 60000;
  return new Date(timestamp);
}

export function toDateWithTimeZone(str?: string): string {
  if (str) {
    const date = new Date(str);
    if (isNaN(date.getTime())) {
      return ''; // Return empty string if the date is invalid
    }
    const timestamp = date.getTime() + date.getTimezoneOffset() * 60000;
    return new Date(timestamp).toISOString();
  }

  return '';
}
