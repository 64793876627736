import { IconProps } from 'types';

export const ColumnViewIcon = ({ className, onClick }: IconProps) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.681204 0.681204C0.957299 0.405109 1.33176 0.25 1.72222 0.25H5.33333C5.72379 0.25 6.09826 0.405109 6.37435 0.681204C6.65045 0.957299 6.80556 1.33176 6.80556 1.72222V5.33333C6.80556 5.72379 6.65045 6.09826 6.37435 6.37435C6.09826 6.65045 5.72379 6.80556 5.33333 6.80556H1.72222C1.33176 6.80556 0.957299 6.65045 0.681204 6.37435C0.405109 6.09826 0.25 5.72379 0.25 5.33333V1.72222C0.25 1.33176 0.405109 0.957299 0.681204 0.681204ZM1.75 1.75V5.30556H5.30556V1.75H1.75ZM8.62565 0.681204C8.90174 0.405109 9.27621 0.25 9.66667 0.25H13.2778C13.6682 0.25 14.0427 0.405108 14.3188 0.681204C14.5949 0.957302 14.75 1.33177 14.75 1.72222V5.33333C14.75 5.72379 14.5949 6.09825 14.3188 6.37435C14.0427 6.65045 13.6682 6.80556 13.2778 6.80556H9.66667C9.27621 6.80556 8.90174 6.65045 8.62565 6.37435C8.34956 6.09826 8.19444 5.72379 8.19444 5.33333V1.72222C8.19444 1.33176 8.34956 0.957298 8.62565 0.681204ZM9.69444 1.75V5.30556H13.25V1.75H9.69444ZM0.681204 8.62565C0.957298 8.34956 1.33176 8.19444 1.72222 8.19444H5.33333C5.72379 8.19444 6.09826 8.34956 6.37435 8.62565C6.65045 8.90174 6.80556 9.27621 6.80556 9.66667V13.2778C6.80556 13.6682 6.65045 14.0427 6.37435 14.3188C6.09825 14.5949 5.72379 14.75 5.33333 14.75H1.72222C1.33177 14.75 0.957302 14.5949 0.681204 14.3188C0.405108 14.0427 0.25 13.6682 0.25 13.2778V9.66667C0.25 9.27621 0.405109 8.90174 0.681204 8.62565ZM1.75 9.69444V13.25H5.30556V9.69444H1.75ZM8.62565 8.62565C8.90174 8.34956 9.27621 8.19444 9.66667 8.19444H13.2778C13.6682 8.19444 14.0427 8.34955 14.3188 8.62565C14.5949 8.90175 14.75 9.27621 14.75 9.66667V13.2778C14.75 13.6682 14.5949 14.0427 14.3188 14.3188C14.0427 14.5949 13.6682 14.75 13.2778 14.75H9.66667C9.27621 14.75 8.90175 14.5949 8.62565 14.3188C8.34955 14.0427 8.19444 13.6682 8.19444 13.2778V9.66667C8.19444 9.27621 8.34956 8.90174 8.62565 8.62565ZM9.69444 9.69444V13.25H13.25V9.69444H9.69444Z"
        fill="black"
      />
    </svg>
  );
};
