import { TextField } from 'formik-material-ui';
import { CustomDatePicker, Autocomplete } from 'components/common';
import { Field } from 'formik';
import { amountFieldProp } from 'consts';

import { YesNoNaOptions, defaultFieldProps } from '../const';

export const LoanCommitmentLetterFields = () => (
  <>
    <Field component={TextField} name="lender" label="Who is the Lender shown on the letter?" {...defaultFieldProps} />
    <Field
      component={TextField}
      name="borrower"
      label="Who is the Borrower shown on the letter?"
      {...defaultFieldProps}
    />
    <Field component={CustomDatePicker} name="date" label="Date" removeTimezone {...defaultFieldProps} />
    <Field
      component={Autocomplete}
      name="executed"
      placeholder="Has the letter been executed?"
      options={YesNoNaOptions}
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      name="intentionsMatch"
      placeholder="Do the noted loan intentions generally match the project as defined in the construction documents? "
      options={YesNoNaOptions}
      {...defaultFieldProps}
    />
    <Field
      component={TextField}
      name="loanTotal"
      label="What is the loan total shown in the letter?"
      {...amountFieldProp}
      {...defaultFieldProps}
    />
    <Field
      component={TextField}
      name="projectBudget"
      label="What is the total project budget shown in the letter?"
      {...amountFieldProp}
      {...defaultFieldProps}
    />
  </>
);
