import { ChangeEvent, FC, PropsWithChildren, useState } from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import { CustomDialog } from 'components';
import { FilterIcon, SortIcon } from 'icons';

import styles from './PageFilters.module.scss';
import { PageFiltersProps } from './types';
import { FilterStatusesTab } from './components/FilterStatusesTab';

export const PageFilters: FC<PropsWithChildren<PageFiltersProps>> = ({
  isFiltersApplied,
  children,
  showMobileClearBtn,
  handleClear,
  Chips,
  Filters,

  //sorting
  isSortingApplied,
  sortingStatuses,
  activeSortingStatus,
  onSortChange,
}) => {
  const [isOpenMobileFilter, setIsOpenMobileFilter] = useState(false);
  const [openProjectsMobileSort, setOpenProjectsMobileSort] = useState(false);

  const handleOpenFilter = () => setIsOpenMobileFilter(true);
  const handleCloseFilter = () => setIsOpenMobileFilter(false);

  const handleOpenProjectsSort = () => setOpenProjectsMobileSort(true);
  const handleCloseProjectsSort = () => setOpenProjectsMobileSort(false);

  const handleSortOptionChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    onSortChange && onSortChange(value);
    handleCloseProjectsSort();
  };

  return (
    <>
      <div className={clsx('px-16 px-lg-32 mb-24', styles.borderBottom)}>
        <div className="flex justify-content-between align-items-center flex-wrap">
          <div className="d-none d-sm-flex align-items-center flex-wrap mt-sm-12 mb-sm-24 gap-24 row-gap-10">
            {Filters}
          </div>

          {children}

          {onSortChange && (
            <FilterStatusesTab onChange={onSortChange} activeStatus={activeSortingStatus} statuses={sortingStatuses} />
          )}

          <div className="text-smallTextGrey align-items-center d-sm-none py-12 c-pointer" onClick={handleOpenFilter}>
            <FilterIcon className="mr-4" />
            Filter
            {isFiltersApplied && <span className="sortingCountContainer" />}
          </div>

          {onSortChange && (
            <div
              className="text-smallTextGrey align-items-center d-sm-none py-12 c-pointer"
              onClick={handleOpenProjectsSort}
            >
              <SortIcon className="mr-4" />
              Show me
              {isSortingApplied && <span className="sortingCountContainer" />}
            </div>
          )}
        </div>

        <div className="d-none d-sm-flex align-items-center flex-wrap">{Chips}</div>
      </div>
      {onSortChange && (
        <Drawer anchor="bottom" open={openProjectsMobileSort} onClose={handleCloseProjectsSort} className="d-sm-none">
          <h3 className="weight-700 mb-20">Show me</h3>

          <RadioGroup aria-label="gender" name="gender1" value={activeSortingStatus} onChange={handleSortOptionChange}>
            {sortingStatuses?.map((status) => (
              <FormControlLabel key={status} value={status} control={<Radio />} label={status} />
            ))}
          </RadioGroup>
        </Drawer>
      )}

      <CustomDialog
        fullHeight
        open={isOpenMobileFilter}
        header="Filter"
        onClose={handleCloseFilter}
        className="d-sm-none"
      >
        <div className="px-16 flex flex-column h-100">
          <div className="flex-1 flex gap-24 flex-column">{Filters}</div>
          {Chips}

          {showMobileClearBtn && (
            <Button variant="contained" onClick={handleClear} fullWidth>
              Clear
            </Button>
          )}
        </div>
      </CustomDialog>
    </>
  );
};
