import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import clsx from 'clsx';
import { TextField } from 'formik-material-ui';
import { useQuery } from 'react-query';
import CircularProgress from '@material-ui/core/CircularProgress';
import OriginalAutoComplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import TextFieldMaterial from '@material-ui/core/TextField';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import FormHelperText from '@material-ui/core/FormHelperText';

import { CompanyFormValues, CompanyTypesEnum, Country, DropdownOption } from 'types';
import { Autocomplete, LoadingButton } from 'components';
import { api, apiRoutes } from 'api';
import axios from 'axios';
import { ArrowDownIcon, CloseIcon } from 'icons';
import { useFormChanges } from 'contexts/FormChanges';
import { getOptionName } from 'utils/common';

import styles from './CompanyForm.module.scss';

interface CompanyProps {
  onClose: () => void;
  onSubmit: (values: CompanyFormValues) => void | Promise<void>;
  submitButtonTitle: string;
  name?: string;
  typeId?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  street?: string;
  isNew?: boolean;
}

export const CompanyForm: FC<CompanyProps> = ({
  submitButtonTitle,
  onSubmit,
  onClose,
  city,
  postalCode,
  street,
  state,
  isNew,
  name,
  typeId,
}) => {
  const { checkFormChanges } = useFormChanges();

  const [selectedState, setSelectedState] = useState<string | null>(state || null);

  const { placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_API,
  });

  const companyTypesQuery = () => api.get<DropdownOption[]>(apiRoutes.organizationTypes).then((res) => res.data);
  const { isLoading: typesLoading, data: companyTypes } = useQuery('organizationTypes', () => companyTypesQuery());

  const countriesInfoQuery = () =>
    axios
      .get(
        'https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-united-states-of-america-state-millesime&q=&sort=ste_name&rows=100&facet=year&facet=ste_code&facet=ste_name&facet=ste_type&refine.year=2022',
      )
      .then((res) => res.data);
  const { data: countries } = useQuery('statesQuery', () => countriesInfoQuery());

  const citiesInfoQuery = () =>
    axios
      .get(
        `https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-united-states-of-america-place&q=&rows=1000&sort=pla_name&facet=year&facet=ste_code&facet=ste_name&facet=coty_code&facet=coty_name&facet=cousub_code&facet=cousub_name&facet=pla_code&facet=pla_name&facet=pla_type&refine.ste_name=${selectedState}`,
      )
      .then((res) => res.data);

  const { data: cities, refetch: getCities, isFetching } = useQuery(['citiesQuery'], () => citiesInfoQuery());

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required('Company name is a required field'),
        type: Yup.object()
          .shape({
            id: Yup.string().required('Company type is a required field'),
            name: Yup.string().required('Company type is a required field'),
          })
          .typeError('Company type is a required field'),
        state: Yup.object()
          .shape({
            id: Yup.number(),
            name: Yup.string(),
          })
          .typeError('State has an incorrect format')
          .nullable(),
        city: Yup.object()
          .shape({
            id: Yup.number(),
            name: Yup.string(),
          })
          .typeError('City has an incorrect format')
          .nullable(),
        street: Yup.object()
          .shape({
            id: Yup.string(),
            name: Yup.string(),
          })
          .typeError('Address has an incorrect format')
          .nullable(),
      }),

    [],
  );

  const mappedCities = useMemo(() => {
    if (!cities?.records.length)
      return selectedState === 'District of Columbia'
        ? [
            {
              id: Math.random(),
              name: selectedState,
              geometry: { coordinates: [-77.01583889168275, 38.90534520465281] },
            },
          ]
        : [];

    //eslint-disable-next-line
    return cities?.records.map((item: any) => ({
      ...item,
      id: isNaN(item.fields.coty_code) ? Math.random() : item.fields.coty_code,
      name: item.fields.pla_name,
    }));
  }, [cities, isFetching]);

  const mappedStates = useMemo(() => {
    if (!countries?.records?.length) return [];

    //eslint-disable-next-line
    return countries?.records.map((el: any, i: number) => ({ ...el, name: el.fields['ste_name'], id: i }));
  }, [countries]);

  const setCoordinates = async (v: string, setFieldValue: (name: string, value: Country) => void) => {
    const zipCodes = await axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${v},${selectedState}&key=${process.env.REACT_APP_GOOGLE_API}`,
      )
      .then((res) => res.data);

    //eslint-disable-next-line
    const city = mappedCities.find((item: any) => item.name === v);

    if (city) {
      // setFieldValue('lng', city.geometry?.coordinates[0]);
      // setFieldValue('lat', city.geometry?.coordinates[1]);
      setFieldValue(
        'postalCode',
        //eslint-disable-next-line
        zipCodes?.results[0]?.address_components.find((item: any) => item?.types?.includes('postal_code'))?.[
          'long_name'
        ] || '',
      );
    }
  };

  useEffect(() => {
    getCities();
  }, [selectedState]);

  const initialValues = useMemo(() => {
    const stateCity =
      city && mappedCities.length
        ? {
            id: mappedCities.find(({ name }: DropdownOption) => name === city)?.id,
            name: city,
          }
        : ({} as DropdownOption);
    return {
      name: name ?? '',
      type: ({ id: typeId, name: companyTypes?.find(({ id }) => id === typeId)?.name } ?? {}) as DropdownOption,
      state: (selectedState
        ? {
            id: mappedStates.find(({ name }: Country) => name === selectedState)?.id,
            name: selectedState,
          }
        : {}) as Country,
      city: stateCity,
      postalCode: postalCode ?? '',
      street: (street
        ? {
            id: street,
            name: street,
          }
        : {}) as DropdownOption,
    };
  }, [mappedCities, selectedState, companyTypes]);

  if (typesLoading) return <CircularProgress size={64} />;

  return (
    <>
      {isNew || (selectedState && mappedStates?.length && mappedCities.length) || (!isNew && !selectedState) ? (
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
          {({ isSubmitting, errors, touched, values, setFieldValue, initialValues }) => {
            checkFormChanges(initialValues, values);

            return (
              <Form>
                <div className="flex flex-wrap">
                  <div className="col-12 mb-24 mb-md-36">
                    <>{console.log('error', errors)}</>
                    <Field component={TextField} name="name" label="Company name" variant="outlined" />
                  </div>

                  <div className="col-12  mb-24 mb-md-36">
                    <Field
                      component={Autocomplete}
                      name="type"
                      placeholder="Company type"
                      type="text"
                      options={companyTypes?.filter(({ id }) => id !== CompanyTypesEnum.Vendor)}
                      disabled={!isNew}
                    />
                  </div>
                </div>

                <div
                  className={clsx(
                    'col-12 mb-24 mb-md-36 p-relative order-md-3',
                    !!(errors.street && touched.street) && styles.error,
                  )}
                >
                  <OriginalAutoComplete
                    value={values['street']}
                    onChange={(_, v) => {
                      v ? setFieldValue('street', { ...v }) : setFieldValue('street', null);
                    }}
                    popupIcon={<ArrowDownIcon className={styles.ArrowDownIcon} />}
                    options={placePredictions.map((item) => ({
                      id: item.description,
                      name: item.description,
                      ...item,
                    }))}
                    getOptionLabel={getOptionName}
                    noOptionsText={'No option'}
                    filterSelectedOptions
                    renderInput={(params: AutocompleteRenderInputParams) => {
                      return (
                        <TextFieldMaterial
                          {...params}
                          label={'Address'}
                          variant="outlined"
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            getPlacePredictions({ input: e?.target.value });
                          }}
                        />
                      );
                    }}
                    ChipProps={{
                      clickable: true,
                      deleteIcon: <CloseIcon />,
                    }}
                  />

                  {errors.street && touched.street && (
                    <FormHelperText error className={clsx('weight-400', styles.errorMessage)}>
                      {(errors.street as { name: string }).name}
                    </FormHelperText>
                  )}
                </div>

                <div className="col-12  mb-24 mb-md-36 order-md-4">
                  <Field
                    component={Autocomplete}
                    name="city"
                    freeSolo
                    placeholder="City"
                    type="text"
                    options={mappedCities}
                    onChange={async (_: ChangeEvent<unknown>, value: Country) => {
                      setCoordinates(value?.name, setFieldValue);
                    }}
                  />
                </div>

                <div className="col-12  mb-24 mb-md-36 order-md-5">
                  <Field
                    component={Autocomplete}
                    name="state"
                    placeholder="State"
                    type="text"
                    options={mappedStates}
                    onChange={(_: ChangeEvent<unknown>, value: Country) => {
                      setSelectedState(value?.name);
                      setFieldValue('city', null);
                    }}
                  />
                </div>

                <div className="col-12 mb-24 mb-md-36 order-md-7">
                  <Field component={TextField} name="postalCode" label="Postal code" variant="outlined" />
                </div>

                <div className={clsx('flex pt-24 justify-content-end px-16')}>
                  <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                    {submitButtonTitle}
                  </LoadingButton>
                  <Button variant="text" type="reset" onClick={onClose} className="ml-12">
                    Cancel
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <CircularProgress size={64} />
      )}
    </>
  );
};
