import { useState } from 'react';

export const useMobileTable = () => {
  const [selectedRow, setSelectedRow] = useState<number | null>();

  const toggleSelectedRow = (index: number | undefined) =>
    selectedRow === index ? setSelectedRow(null) : setSelectedRow(index);

  return { selectedRow, toggleSelectedRow };
};
