import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const WorkOrderOverviewPageRoute = Route(
  {
    id: Route.params.number.optional,
  },
  ({ id }) => `/work-order/${id}`,
);

export const WorkOrderOverviewPage = WorkOrderOverviewPageRoute(lazy(() => import('./Overview')));
