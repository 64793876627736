const transformDateToMonthWithYear = (date: string, divider = '-') => {
  const dateObj = new Date(date);

  if (isNaN(dateObj.getTime())) return;

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = monthNames[dateObj.getUTCMonth()];
  const year = String(dateObj.getUTCFullYear()).slice(2);

  return `${month}${divider}${year}`;
};

export default transformDateToMonthWithYear;
