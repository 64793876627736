import { Fragment, useMemo } from 'react';
import { CellProps } from 'react-table';
import { Contact } from 'types';
import { formatDateToMMDDYYYY, getFullName } from 'utils';
import { getLabelByProposalStatusesEnum } from 'utils/common';
import commonStyles from 'styles/common.module.scss';
import formatNumberWithCommas from 'utils/formatNumberWithCommas';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ProductivityMetricsStats = any; //TO-DO describe type

export const useProductivityMetricsDataTableColumns = () => {
  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: () => <span>RFP Name</span>,
        minWidth: 230,
        maxWidth: 230,
        Cell: ({
          row: {
            original: { name },
          },
        }: CellProps<ProductivityMetricsStats>) => <>{name ?? '-'}</>,
      },
      {
        id: 'assignee',
        Header: () => <span>RFP Assignee</span>,
        minWidth: 160,
        maxWidth: 160,
        Cell: ({
          row: {
            original: { assignee },
          },
        }: CellProps<ProductivityMetricsStats>) => <>{getFullName(assignee) ?? '-'}</>,
      },
      {
        id: 'client',
        Header: () => <span>Client(Bank)</span>,
        minWidth: 160,
        maxWidth: 160,
        Cell: ({
          row: {
            original: { bank },
          },
        }: CellProps<ProductivityMetricsStats>) => <>{bank?.name ?? '-'}</>,
      },
      {
        id: 'bank contacts',
        Header: 'Bank Contacts',
        minWidth: 160,
        maxWidth: 160,
        Cell: ({
          row: {
            original: { bankContact },
          },
        }: CellProps<ProductivityMetricsStats>) => (
          <>
            {bankContact.length
              ? bankContact?.map((contact: Contact) => (
                  <Fragment key={contact?.id}>
                    <p>{getFullName(contact)}</p>
                    <br />
                  </Fragment>
                ))
              : '-'}
          </>
        ),
      },
      {
        id: 'state',
        Header: 'State',
        minWidth: 160,
        maxWidth: 160,
        Cell: ({
          row: {
            original: { state },
          },
        }: CellProps<ProductivityMetricsStats>) => <>{state ?? '-'}</>,
      },
      {
        id: 'pre price',
        minWidth: 110,
        Header: () => <span className={commonStyles.tableItemEndAlign}>PRE Price</span>,
        Cell: ({
          row: {
            original: { prePrice },
          },
        }: CellProps<ProductivityMetricsStats>) => (
          <span className={commonStyles.tableItemEndAlign}>
            {prePrice ? `$${formatNumberWithCommas(prePrice)}` : '-'}
          </span>
        ),
      },
      {
        id: 'inspection price',
        minWidth: 160,
        Header: () => <span className={commonStyles.tableItemEndAlign}>Inspection Price</span>,
        Cell: ({
          row: {
            original: { inspectionPrice },
          },
        }: CellProps<ProductivityMetricsStats>) => (
          <span className={commonStyles.tableItemEndAlign}>
            {inspectionPrice ? `$${formatNumberWithCommas(inspectionPrice)}` : '-'}
          </span>
        ),
      },
      {
        id: 'Funds Control Price',
        minWidth: 140,
        Header: () => <span className={commonStyles.tableItemEndAlign}>Funds Control Price</span>,
        Cell: ({
          row: {
            original: { fundControlPrice },
          },
        }: CellProps<ProductivityMetricsStats>) => (
          <span className={commonStyles.tableItemEndAlign}>
            {fundControlPrice ? `$${formatNumberWithCommas(fundControlPrice)}` : '-'}
          </span>
        ),
      },
      {
        id: 'cce price',
        minWidth: 100,
        Header: () => <span className={commonStyles.tableItemEndAlign}>CCE Price</span>,
        Cell: ({
          row: {
            original: { ccePrice },
          },
        }: CellProps<ProductivityMetricsStats>) => (
          <span className={commonStyles.tableItemEndAlign}>
            {ccePrice ? `$${formatNumberWithCommas(ccePrice)}` : '-'}
          </span>
        ),
      },
      {
        id: 'pca price',
        minWidth: 100,
        Header: () => <span className={commonStyles.tableItemEndAlign}>PCA Price</span>,
        Cell: ({
          row: {
            original: { pcaPrice },
          },
        }: CellProps<ProductivityMetricsStats>) => (
          <span className={commonStyles.tableItemEndAlign}>
            {pcaPrice ? `$${formatNumberWithCommas(pcaPrice)}` : '-'}
          </span>
        ),
      },
      {
        id: 'ltv',
        minWidth: 100,
        Header: () => <span className={commonStyles.tableItemEndAlign}>LTV</span>,
        Cell: ({
          row: {
            original: { ltv },
          },
        }: CellProps<ProductivityMetricsStats>) => (
          <span className={commonStyles.tableItemEndAlign}>{ltv ? `$${formatNumberWithCommas(ltv)}` : '-'}</span>
        ),
      },
      {
        id: 'rfp received',
        minWidth: 140,
        Header: () => <span>RFP RECEIVED</span>,
        Cell: ({
          row: {
            original: { receivedDate },
          },
        }: CellProps<ProductivityMetricsStats>) => <>{formatDateToMMDDYYYY(receivedDate) ?? '-'}</>,
      },
      {
        id: 'ProductivityMetricsStats sent',
        minWidth: 150,
        Header: () => <span>Proposal Sent</span>,
        Cell: ({
          row: {
            original: { sentDate },
          },
        }: CellProps<ProductivityMetricsStats>) => <>{formatDateToMMDDYYYY(sentDate) ?? '-'}</>,
      },
      {
        id: 'ProductivityMetricsStats accepted',
        minWidth: 170,
        Header: () => <span>Proposal Accepted</span>,
        Cell: ({
          row: {
            original: { awardDate },
          },
        }: CellProps<ProductivityMetricsStats>) => <>{formatDateToMMDDYYYY(awardDate) ?? '-'}</>,
      },
      {
        id: 'status',
        minWidth: 170,
        Header: () => <span>RFP Status</span>,
        Cell: ({
          row: {
            original: { status },
          },
        }: CellProps<ProductivityMetricsStats>) => <>{getLabelByProposalStatusesEnum(status) ?? '-'}</>,
      },
      {
        id: 'estimated date start',
        minWidth: 180,
        Header: () => <span>Anticipated first inspection date</span>,
        Cell: ({
          row: {
            original: { expectedDateOfFirstInspection },
          },
        }: CellProps<ProductivityMetricsStats>) => <>{formatDateToMMDDYYYY(expectedDateOfFirstInspection) ?? '-'}</>,
      },
      {
        id: 'estimated date end',
        minWidth: 180,
        Header: () => <span>Anticipated last inspection date</span>,
        Cell: ({
          row: {
            original: { expectedDateOfLastInspection },
          },
        }: CellProps<ProductivityMetricsStats>) => <>{formatDateToMMDDYYYY(expectedDateOfLastInspection) ?? '-'}</>,
      },
      {
        id: 'expected number of inspections',
        minWidth: 100,
        Header: () => <span className={commonStyles.tableItemEndAlign}>Expected # of Inspections</span>,
        Cell: ({
          row: {
            original: { expectedNumberOfInspections },
          },
        }: CellProps<ProductivityMetricsStats>) => (
          <span className={commonStyles.tableItemEndAlign}>
            {expectedNumberOfInspections ? `${formatNumberWithCommas(expectedNumberOfInspections)}` : '-'}
          </span>
        ),
      },
      {
        id: 'actual number of inspections',
        minWidth: 100,
        Header: () => <span className={commonStyles.tableItemEndAlign}>Actual # of Inspections</span>,
        Cell: ({
          row: {
            original: { actualNumberOfInspections },
          },
        }: CellProps<ProductivityMetricsStats>) => (
          <span className={commonStyles.tableItemEndAlign}>
            {actualNumberOfInspections ? `${formatNumberWithCommas(actualNumberOfInspections)}` : '-'}
          </span>
        ),
      },
      {
        id: 'project',
        minWidth: 230,
        Header: () => <span>Project</span>,
        Cell: ({
          row: {
            original: { project },
          },
        }: CellProps<ProductivityMetricsStats>) => <>{project?.name ?? '-'}</>,
      },
      {
        id: 'project type',
        minWidth: 230,
        Header: () => <span>Project Type</span>,
        Cell: ({
          row: {
            original: { project },
          },
        }: CellProps<ProductivityMetricsStats>) => <>{project?.type ?? '-'}</>,
      },
      {
        id: 'project state',
        minWidth: 230,
        Header: () => <span>Project State</span>,
        Cell: ({
          row: {
            original: { project },
          },
        }: CellProps<ProductivityMetricsStats>) => <>{project?.state ?? '-'}</>,
      },
      {
        id: 'project owner',
        minWidth: 230,
        Header: () => <span>Owner</span>,
        Cell: ({
          row: {
            original: { project },
          },
        }: CellProps<ProductivityMetricsStats>) => <>{getFullName(project?.owner) ?? '-'}</>,
      },
      {
        id: 'engagement date',
        minWidth: 180,
        Header: () => <span>Engagement Date</span>,
        Cell: ({
          row: {
            original: { project },
          },
        }: CellProps<ProductivityMetricsStats>) => <>{formatDateToMMDDYYYY(project?.engagementDate) ?? '-'}</>,
      },
      {
        id: 'estimated completed',
        minWidth: 180,
        Header: () => <span>Estimated % Completed</span>,
        Cell: ({
          row: {
            original: { project },
          },
        }: CellProps<ProductivityMetricsStats>) => (
          <>{project?.estimatedPercentageCompleted ? `${project?.estimatedPercentageCompleted}%` : '-'}</>
        ),
      },
      {
        id: 'projected completion date',
        minWidth: 180,
        Header: () => <span>Projected Completion Date</span>,
        Cell: ({
          row: {
            original: { project },
          },
        }: CellProps<ProductivityMetricsStats>) => <>{formatDateToMMDDYYYY(project?.completionDate) ?? '-'}</>,
      },
      {
        id: 'original hard costs',
        minWidth: 180,
        Header: () => <span>Original Hard Costs</span>,
        Cell: ({
          row: {
            original: { originalHardCost },
          },
        }: CellProps<ProductivityMetricsStats>) => (
          <span className={commonStyles.tableItemEndAlign}>
            {originalHardCost ? `${formatNumberWithCommas(originalHardCost)}` : '-'}
          </span>
        ),
      },
      {
        id: 'change orders',
        minWidth: 180,
        Header: () => <span>Change Orders</span>,
        Cell: ({
          row: {
            original: { changeOrders },
          },
        }: CellProps<ProductivityMetricsStats>) => (
          <span className={commonStyles.tableItemEndAlign}>
            {changeOrders ? `${formatNumberWithCommas(changeOrders)}` : '-'}
          </span>
        ),
      },
      {
        id: 'current hard costs',
        minWidth: 180,
        Header: () => <span>Current Hard Costs</span>,
        Cell: ({
          row: {
            original: { currentHardCosts },
          },
        }: CellProps<ProductivityMetricsStats>) => (
          <span className={commonStyles.tableItemEndAlign}>
            {currentHardCosts ? `${formatNumberWithCommas(currentHardCosts)}` : '-'}
          </span>
        ),
      },
    ],
    [],
  );
  return {
    columns,
  };
};
