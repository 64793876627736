import { IconProps } from 'types';

export const DeleteIcon = ({ className, onClick }: IconProps) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15 12L16.2 5H31.8L33 12" stroke="black" strokeWidth="4" strokeLinejoin="round" />
      <path d="M6 12H42" stroke="black" strokeWidth="4" strokeLinecap="round" />
      <path d="M37 12L35 43H13L11 12H37Z" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19 35H29" stroke="black" strokeWidth="4" strokeLinecap="round" />
    </svg>
  );
};
