import { QuestionType } from 'pages/ReportWriting/schemas/types';

export const validRadioQuestionTypes = [
  QuestionType.Condition,
  QuestionType.ConditionWithNP,
  QuestionType.ConditionWithCC,
  QuestionType.ConditionWithNNAndUnknown,
  QuestionType.PartialCondition,
  QuestionType.ConditionWithNA,
  QuestionType.ConditionWithNN,
  QuestionType.ConditionWithIncDec,
  QuestionType.ConditionWithNAAndDNAttend,
  QuestionType.ConditionWithUnknown,
  QuestionType.ConditionWithDNAttend,
  QuestionType.ConditionWithNNAndYesNDated,
  QuestionType.YesNoPartialCondition,
  QuestionType.ConditionsWithNAAndRetain,
];
