import { IconProps } from 'types';

export const Left = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 23.9919H36" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24 36L12 24L24 12" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
