import { components } from 'generated/types';
import { Questions, QuestionType } from 'pages/ReportWriting/schemas/types';
import { toDateWithTimeZone, getAnswersByQuestionId, getParsedAnswers, formatNumber, checkIfDate } from 'utils';

type Organization = components['schemas']['Organization'];

const regexForSpanContent = /<span[^>]*>(.*?)<\/span>/g;
const regexForId = /data-id="([^"]+)"/;
const regexForCalculation = /data-calculation="true"/;
const regexForPercentage = /data-percentage="true"/;
const regexForDate = /data-date="true"/;

const getTextBetweenSpans = (input: string) => {
  const matches = [];
  let match;
  while ((match = regexForSpanContent.exec(input)) !== null) {
    // Extract the text between <span> and </span> using capturing group (.*?) in the regex
    const textBetweenSpans = match[1];
    matches.push(textBetweenSpans);
  }
  return matches;
};

export const getParsedTemplate = (questionsState: Questions[], str?: string): string => {
  let updatedStr = str;

  if (updatedStr) {
    const regex = /(<span[^>]*data-id="([^"]+)"[^>]*>)([^<]*)(<\/span>)/g;

    const spanElements = updatedStr.match(regex);

    spanElements?.forEach((item) => {
      const regexForSpan = /<span data-id="[^"]*"[^>]*>/;
      const matchForSpan = item.match(regexForSpan);

      const matchId = regexForId.exec(item);
      const matchCalculation = regexForCalculation.exec(item);
      const matchDate = regexForDate.exec(item);
      const matchPercentage = regexForPercentage.exec(item);

      if (matchId) {
        const questionId = matchId[1];
        const answer = getAnswersByQuestionId(questionId, questionsState)?.answer;

        if (getTextBetweenSpans(item)[0]) {
          return;
        }

        if (
          questionId === '128' &&
          getAnswersByQuestionId(questionId, questionsState)?.type === QuestionType.CustomMultiSelect
        ) {
          const data = JSON.parse(answer ?? '');
          const names = data?.map((item: Organization) => item.name);
          const resultString = names.join(', ');
          try {
            updatedStr = updatedStr?.replace(item, `${resultString}</span>`);
          } catch (e) {
            console.log('error');
          }
          return;
        }

        if (matchCalculation && matchCalculation[0]) {
          if (questionId === '68') {
            try {
              updatedStr = updatedStr?.replace(
                item,
                `${matchForSpan?.[0]}${formatNumber(
                  +(eval(getParsedAnswers(questionsState, answer)) * 100).toFixed(1),
                )}</span>`,
              );
            } catch (e) {
              console.log('error');
            }
            return;
          }
          try {
            updatedStr = matchPercentage
              ? updatedStr?.replace(
                  item,
                  `${matchForSpan?.[0]}${formatNumber(eval(getParsedAnswers(questionsState, answer)) * 100)}</span>`,
                )
              : updatedStr?.replace(
                  item,
                  `${matchForSpan?.[0]}${formatNumber(eval(getParsedAnswers(questionsState, answer)))}</span>`,
                );
          } catch (e) {
            console.log('error');
          }

          return;
        }

        if (matchDate && matchDate[0]) {
          updatedStr = updatedStr?.replace(
            item,
            `${matchForSpan?.[0]}${checkIfDate(toDateWithTimeZone(getParsedAnswers(questionsState, answer)))}</span>`,
          );
          return;
        }

        updatedStr = updatedStr?.replace(
          item,
          `${matchForSpan?.[0]}${getParsedAnswers(questionsState, answer)}</span>`,
        );
      }
    });
    return updatedStr;
  }
  return '';
};
