import { ChangeEvent } from 'react';
import TextField from '@material-ui/core/TextField';
import OriginalAutoComplete, {
  AutocompleteRenderInputParams,
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import { useFormikContext } from 'formik';
import clsx from 'clsx';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ArrowDownIcon, CloseIcon } from 'icons';
import { DropdownOption } from 'types';

import { getOptionName, getOptionSelected } from 'utils/common';

import styles from './Autocomplete.module.scss';

const filter = createFilterOptions<DropdownOption>();

interface AutocompleteProps {
  options: DropdownOption[];
  placeholder: string;
  noOptions?: string;
  className?: string;
  onChange?: (_: ChangeEvent<unknown>, value: DropdownOption | DropdownOption[] | null) => void;
  field: {
    name: string;
  };
  name: string;
  form: {
    setFieldValue: (name: string, value: unknown) => void;
  };
  multiple: boolean;
  freeSolo?: boolean;
}

export const Autocomplete = (props: AutocompleteProps) => {
  const {
    options,
    noOptions,
    onChange,
    className,
    field: { name },
    field,
    form: { setFieldValue },
    multiple = false,
    freeSolo = false,
  } = props;

  const formikContext = useFormikContext();
  const { error, touched } = formikContext.getFieldMeta(name);

  const handleFieldValue = (_: ChangeEvent<unknown>, value: DropdownOption | DropdownOption[] | null) => {
    setFieldValue(name, value);
  };
  const filterOptions = (options: DropdownOption[], params: FilterOptionsState<DropdownOption>) => {
    if (!freeSolo) return options;
    const filtered = filter(options, params);
    const { inputValue } = params;
    const isExisting = options.some((option) => inputValue === option.name);
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        id: 0,
        name: inputValue,
      });
    }

    return filtered;
  };

  const handleRenderInput = (params: AutocompleteRenderInputParams) => {
    return <TextField {...params} label={props.placeholder} variant="outlined" size="small" fullWidth />;
  };

  return (
    <div
      className={clsx(
        'p-relative',
        !!(error && touched) && styles.error,
        styles.autocomplete,
        styles.fullWidth,
        className,
      )}
    >
      <OriginalAutoComplete
        {...props}
        {...field}
        onChange={(e, v) => {
          handleFieldValue(e, v as DropdownOption);
          onChange && onChange(e, v as DropdownOption);
        }}
        multiple={multiple}
        freeSolo={freeSolo}
        filterOptions={freeSolo ? filterOptions : undefined}
        className={className}
        popupIcon={<ArrowDownIcon className={styles.ArrowDownIcon} />}
        options={options ?? []}
        getOptionLabel={getOptionName}
        noOptionsText={noOptions || 'No option'}
        filterSelectedOptions
        renderInput={handleRenderInput}
        ChipProps={{
          clickable: true,
          deleteIcon: <CloseIcon className={styles.deleteIcon} />,
        }}
        getOptionSelected={getOptionSelected}
      />
      {error && touched && (
        <FormHelperText error className={clsx('weight-400', styles.errorMessage)}>
          {typeof error === 'object' ? (error as { name: string }).name : error}
        </FormHelperText>
      )}
    </div>
  );
};
