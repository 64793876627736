import { Autocomplete, CustomDatePicker } from 'components/common';
import { Field } from 'formik';

import { stampedAndSigned, permittingStatus, defaultFieldProps, YesNoNaOptions } from '../const';

export const CommonPlansFields = () => {
  return (
    <>
      <Field
        component={CustomDatePicker}
        name="dateLastRevision"
        label="What is the date/last revision of the plans?"
        removeTimezone
        {...defaultFieldProps}
      />
      <Field
        component={Autocomplete}
        name="stampedAndSigned"
        placeholder="Are the plans stamped and signed by the Engineer?"
        options={stampedAndSigned}
        {...defaultFieldProps}
      />
      <Field
        component={Autocomplete}
        name="approvalStamps"
        placeholder="Do the plans include approval stamps from local jurisdictions?"
        options={YesNoNaOptions}
        {...defaultFieldProps}
      />
      <Field
        component={Autocomplete}
        name="permittingStatus"
        placeholder="What is the permitting status of the plans?"
        options={permittingStatus}
        {...defaultFieldProps}
      />
      <Field
        component={Autocomplete}
        name="sufficient"
        placeholder="Do the plans include typical design information and appear sufficient for construction?"
        options={YesNoNaOptions}
        {...defaultFieldProps}
      />
    </>
  );
};
