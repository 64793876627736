import { ChangeEvent } from 'react';
import Slider from '@material-ui/core/Slider';

interface SliderProps {
  field: {
    name: string;
  };
  form: {
    setFieldValue: (name: string, value: number | number[]) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any;
  };
  multiple: boolean;
  disabled?: boolean;
}

export const CustomSlider = (props: SliderProps) => {
  const {
    form: { setFieldValue, values },
    field: { name },
    disabled,
  } = props;

  const handleFieldValue = (_: ChangeEvent<unknown>, value: number | number[]) => {
    setFieldValue(name, value);
  };

  return (
    <Slider
      disabled={disabled}
      defaultValue={0}
      value={values[name]}
      onChange={handleFieldValue}
      valueLabelDisplay="on"
      valueLabelFormat={(number) => number + '%'}
    />
  );
};
