import { Fragment } from 'react';
import { MobileTableRow } from 'components';
import { MobileTableV2, MobileTableRowField } from 'components/common/MobileTableV2';
import { Contact, MobileTableProps, WorkOrderTypeEnum } from 'types';
import { formatDateToYYYYMMDD, getFullName } from 'utils';
import { useMobileTable } from 'hooks/useMobileTable';
import { getLabelByProposalStatusesEnum } from 'utils/common';
import {
  productivityMetricsTableEmptyModel,
  productivityMetricsTableEmptyTitle,
} from 'components/common/Table/parts/EmptyState/const';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ProductivityMetricsDataMobileTable = ({ items, loading, onClearFilters }: MobileTableProps<any>) => {
  const { selectedRow, toggleSelectedRow } = useMobileTable();

  return (
    <MobileTableV2
      hasData={items.length > 0}
      handleClearFilters={onClearFilters}
      model={productivityMetricsTableEmptyModel}
      emptyStateTitle={productivityMetricsTableEmptyTitle}
      {...{ loading }}
      isNewLayout
    >
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {items.map((item: any) => {
        const {
          name,
          id,
          assignee,
          ltv,
          bankContact,
          bank,
          receivedDate,
          sentDate,
          awardDate,
          expectedDateOfFirstInspection,
          expectedDateOfLastInspection,
          services,
          state,
          status,
          fundControlPrice,
          ccePrice,
          pcaPrice,
        } = item;

        const contacts = (
          <>
            {bankContact?.map((contact: Contact, index: number) => (
              <Fragment key={contact?.id}>
                <p>{getFullName(contact)}</p>
                {bankContact.length !== index + 1 && <br />}
              </Fragment>
            ))}
          </>
        );
        //@ts-ignore
        const prePrice = services?.find(({ name }) => name === WorkOrderTypeEnum.PreConstructionReview)?.price;
        //@ts-ignore
        const inspectionPrice = services?.find(({ name }) => name === WorkOrderTypeEnum.InspectionReport)?.price;

        const formattedPrePrice = prePrice && `${prePrice}$`;
        const formattedInspectionPrice = inspectionPrice && `${inspectionPrice}$`;
        const formattedLtv = ltv && `${ltv}$`;
        const formattedFundControlPrice = fundControlPrice && `${fundControlPrice}$`;
        const formattedCcePrice = ccePrice && `${ccePrice}$`;
        const formattedPcaPrice = pcaPrice && `${pcaPrice}$`;

        const formattedReceivedDate = formatDateToYYYYMMDD(receivedDate);
        const formattedSentDate = formatDateToYYYYMMDD(sentDate);
        const formattedAwardDate = formatDateToYYYYMMDD(awardDate);
        const formattedExpectedDateOfFirstInspection = formatDateToYYYYMMDD(expectedDateOfFirstInspection);
        const formattedExpectedDateOfLastInspection = formatDateToYYYYMMDD(expectedDateOfLastInspection);

        return (
          <MobileTableRow
            key={id}
            id={id}
            rowTitle={name}
            handleToggle={toggleSelectedRow}
            selectedRowInfo={selectedRow}
          >
            <MobileTableRowField label="RFP Name" value={name} />
            <MobileTableRowField label="RFP Assignee" value={getFullName(assignee)} />
            <MobileTableRowField label="Client(Bank)" value={bank?.name} />
            <MobileTableRowField label="Bank Contacts" value={contacts} />
            <MobileTableRowField label="State" value={state} />
            <MobileTableRowField label="Pre Price" value={formattedPrePrice} />
            <MobileTableRowField label="Inspection Price" value={formattedInspectionPrice} />
            <MobileTableRowField label="Funds Control Price" value={formattedFundControlPrice} />
            <MobileTableRowField label="CCE Price" value={formattedCcePrice} />
            <MobileTableRowField label="PCA Price" value={formattedPcaPrice} />
            <MobileTableRowField label="LTV" value={formattedLtv} />
            <MobileTableRowField label="Rfp Recieved" value={formattedReceivedDate} />
            <MobileTableRowField label="Proposal Sent" value={formattedSentDate} />
            <MobileTableRowField label="Proposal Accepted" value={formattedAwardDate} />
            <MobileTableRowField label="RFP Status" value={getLabelByProposalStatusesEnum(status)} />
            <MobileTableRowField
              label="Anticipated first inspection date"
              value={formattedExpectedDateOfFirstInspection}
            />
            <MobileTableRowField
              label="Anticipated last inspection date"
              value={formattedExpectedDateOfLastInspection}
            />
          </MobileTableRow>
        );
      })}
    </MobileTableV2>
  );
};
