import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const ProjectWorkOrdersRoute = Route(
  {
    id: Route.params.number.optional,
    search: Route.query.string,
    page: Route.query.number,
  },
  ({ id }) => `/project/${id}/work-orders`,
);

export const ProjectWorkOrders = ProjectWorkOrdersRoute(lazy(() => import('./WorkOrders')));
