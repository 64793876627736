import React, { ErrorInfo } from 'react';

import styles from './ErrorBoundary.module.scss';

export class ErrorBoundary extends React.Component {
  //eslint-disable-next-line
  constructor(props: string) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if ((this.state as Readonly<{ hasError: boolean }>).hasError) {
      // You can render any custom fallback UI
      return (
        <div className={styles.errorBoundary}>
          <h1 className="text-32 weight-600">Something went wrong.</h1>
          <br />
          <p className="text-secondary">Please reload the page</p>
        </div>
      );
    }

    //eslint-disable-next-line
    return (this.props as any).children;
  }
}
