import { useEffect, useState } from 'react';
import qs from 'query-string';
import { useHistory } from 'react-router';
import { GetRouteProps } from 'types';

type Props = Pick<GetRouteProps<unknown>, 'match' | 'link'>;

export const usePageSearch = ({
  link,
  match: {
    query: { search = '' },
    query,
  },
}: Props) => {
  const {
    push,
    location: { pathname },
    location,
  } = useHistory();
  const [searchTerm, setSearchTerm] = useState(search);

  useEffect(() => {
    setSearchTerm(search);
  }, [search]);

  const changeQueryParams = () => {
    const parsed = qs.parse(location.search);
    push({ pathname: pathname, search: qs.stringify({ ...parsed, search: searchTerm, page: 0 }) });
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    push(link({ ...(({ search, ...o }) => o)({ ...query }) }));
  };

  const handleEnterChange = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      changeQueryParams();
    }
  };
  return { handleEnterChange, setSearchTerm, handleClearSearch, searchTerm };
};
