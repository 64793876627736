import { TextField } from 'formik-material-ui';
import { Autocomplete, CustomDatePicker } from 'components/common';
import { Field } from 'formik';

import { YesNoNaOptions, constructionScheduleScopeOfWorkOptions, defaultFieldProps } from '../const';

export const ConstructionScheduleFields = () => (
  <>
    <Field
      component={TextField}
      name="constructionPrepared"
      label="Who was the construction schedule prepared by?"
      {...defaultFieldProps}
    />
    <Field
      component={CustomDatePicker}
      name="dateScheduledPrepared"
      label="What date was the scheduled prepared on?"
      removeTimezone
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      name="scopeOfWork"
      placeholder="What is the scope of work? "
      options={constructionScheduleScopeOfWorkOptions}
      {...defaultFieldProps}
    />
    <Field
      component={CustomDatePicker}
      name="dateOfCommencement"
      label="Date of Commencement"
      removeTimezone
      {...defaultFieldProps}
    />
    <Field component={TextField} name="scheduleDuration" label="Schedule Duration" {...defaultFieldProps} />
    <Field
      component={CustomDatePicker}
      name="anticipatedCompletionDate"
      label="What is the anticipated completion date of construction? "
      removeTimezone
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      name="reasonableEstimate"
      placeholder="Is this a reasonable estimate to complete construction?"
      options={YesNoNaOptions}
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      name="scheduleMatch"
      placeholder="Does the schedule generally match the terms of construction duration in the Construction Agreement? "
      options={YesNoNaOptions}
      {...defaultFieldProps}
    />
  </>
);
