import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { mobileTableIdForTopScrolling, tableIdForTopScrolling } from 'consts';

import styles from './MobileTable.module.scss';
import { EmptyState } from '../Table';
import { Pagination } from '../Table/parts/Pagination';
import { ScrollTop, ScrollTopV2 } from '../ScrollTop';
import { MobileTableProps } from './types';

export const MobileTableV2 = ({
  children,
  hasData,
  model = '',
  emptyStateTitle,
  handleClearFilters,
  pagination,
  isPageWithMap,
  isScrollTop = true,
  loading,
  className,
  isNewLayout, //TO-DO remove this props when whole app layout will be refactored
}: PropsWithChildren<MobileTableProps>) => {
  if (loading)
    return (
      <div className="p-relative d-block d-md-none">
        <CircularProgress className="mt-20" size={64} />
      </div>
    );

  return (
    <div
      className={clsx('d-block d-md-none px-16', styles.wrap, className)}
      id={isNewLayout ? tableIdForTopScrolling : mobileTableIdForTopScrolling}
    >
      {hasData && pagination && (
        <Pagination
          perPage={pagination?.perPage}
          count={pagination?.count}
          total={pagination?.total}
          handlePageChange={pagination?.handlePageChange}
          pagesTotal={pagination?.pagesTotal}
          tableName={pagination?.tableName}
          currentPage={pagination?.currentPage}
        />
      )}
      {hasData ? children : <EmptyState onClick={handleClearFilters} title={emptyStateTitle} model={model} />}
      {isScrollTop &&
        (isNewLayout ? (
          <ScrollTopV2 className={clsx(styles.scrollTop)} />
        ) : (
          <ScrollTop
            className={clsx(styles.scrollTop)}
            scrollBlockClassName="base-layout-wrapper"
            isMobileTable
            {...{ isPageWithMap }}
          />
        ))}
    </div>
  );
};
