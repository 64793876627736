import { CategoryNames } from '../types';
import {
  ArchitecturalPlansFields,
  CivilPlansFields,
  ConstructionAgreementFields,
  ConstructionScheduleFields,
  DesignAgreementsFields,
  EnvironmentalReportingFields,
  GeotechnicalReportingFields,
  HardCostBudgetFields,
  LoanCommitmentLetterFields,
  MEPPlansFields,
  PermitsFields,
  StructuralPlansFields,
  SurveyFields,
  TotalProjectBudgetFields,
  UtilityAvailabilityLettersFields,
} from '../CategoryFields';

export const getTableFieldsByCategoryName = (categoryName: string) => {
  switch (categoryName) {
    case CategoryNames.CivilPlans:
      return <CivilPlansFields />;

    case CategoryNames.ArchitecturalPlans:
      return <ArchitecturalPlansFields />;

    case CategoryNames.MechanicalPlans:
    case CategoryNames.ElectricalPlans:
    case CategoryNames.PlumbingPlans:
      return <MEPPlansFields />;

    case CategoryNames.StructuralPlans:
      return <StructuralPlansFields />;

    case CategoryNames.Survey:
      return <SurveyFields />;

    case CategoryNames.ConstructionAgreement:
      return <ConstructionAgreementFields />;

    case CategoryNames.HardCostBudget:
      return <HardCostBudgetFields />;

    case CategoryNames.ConstructionSchedule:
      return <ConstructionScheduleFields />;

    case CategoryNames.TotalProjectBudget:
      return <TotalProjectBudgetFields />;

    case CategoryNames.GeotechnicalReporting:
      return <GeotechnicalReportingFields />;

    case CategoryNames.EnvironmentalReporting:
      return <EnvironmentalReportingFields />;

    case CategoryNames.Permits:
      return <PermitsFields />;

    case CategoryNames.UtilityAvailabilityLetters:
      return <UtilityAvailabilityLettersFields />;

    case CategoryNames.DesignAgreements:
      return <DesignAgreementsFields />;

    case CategoryNames.LoanCommitmentLetter:
      return <LoanCommitmentLetterFields />;

    default:
      <></>;
  }
};
