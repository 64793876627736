import React, { ReactNode, useState } from 'react';
import clsx from 'clsx';

import { ArrowDownIcon } from 'icons';

import styles from './CustomDropdown.module.scss';

interface DropdownMenuProps {
  children: (handleDialogStatusChange: () => void) => ReactNode;
  label: string;
  fullWidth?: boolean;
}

export const CustomDropdown = ({ children, label, fullWidth }: DropdownMenuProps) => {
  const [open, setOpen] = useState(false);

  const handleDialogStatusChange = () => setOpen(!open);
  const handleClose = () => setOpen(false);

  return (
    <div className={clsx(styles.dropdownContainer, fullWidth && 'w-100')}>
      <div
        className={clsx(
          'flex align-items-center justify-content-between weight-600',
          styles.dropdown,
          open && styles.activeDropdown,
        )}
        onClick={() => handleDialogStatusChange()}
      >
        <span className="mr-8">{label}</span> <ArrowDownIcon className={styles.ArrowDownIcon} />
      </div>
      {open ? <div className={clsx(styles.customDropdown, fullWidth && 'w-100')}>{children(handleClose)}</div> : null}
    </div>
  );
};
