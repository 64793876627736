import { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getLinks } from 'react-router-hoc';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import { useAuth } from 'contexts';
import { AppLoadingLayout, BaseLayout, UnAuthLayout } from 'components';
import {
  Login,
  VerificationCode,
  ForgotPassword,
  ResetPassword,
  Inspections,
  Settings,
  Contacts,
  Notes,
  About,
  Privacy,
  FAQ,
  TermsAndConditions,
  DocumentTracking,
  PlatformSettings,
  EditProfile,
  Projects,
  Users,
  WorkOrders,
  UserInfo,
  InspectionOverview,
  InspectionOverviewPage,
  InspectionNotes,
  InspectionFiles,
  ProjectOverviewPage,
  ProjectFiles,
  ProjectOverview,
  ProjectNotes,
  ProjectWorkOrders,
  ProjectInspections,
  ProjectDescription,
  WorkOrderOverview,
  WorkOrderNotes,
  WorkOrderFiles,
  WorkOrderOverviewPage,
  MissingCategories,
  RejectedDocuments,
  ReceivedDocuments,
  ReportWriting,
  ReportWritingOverview,
  ArchivedWorkOrders,
  ArchivedProjects,
  ProjectContract,
  ProjectPermits,
  ProjectCostReview,
  AuditTrail,
  InspectionAuditTrail,
  ProposalRequests,
  ProjectCover,
  ProposalRequestOverview,
  // ProposalRequestNotes,
  // ProposalRequestFiles,
  ProductivityMetrics,
  ProductivityMetricsData,
  ProductivityMetricsWorkOrders,
  ProductivityMetricsProjectPortfolio,
  ProductivityMetricsRfpPortfolio,
  ProductivityMetricsInspections,
  ProductivityMetricsAvgServicesPrices,
} from 'pages';
import { ErrorBoundary } from 'components/ErrorBoundary';

export const links = getLinks({
  Login,
  VerificationCode,
  ForgotPassword,
  ResetPassword,
  Settings,
  About,
  Privacy,
  Inspections,
  Contacts,
  Notes,
  FAQ,
  TermsAndConditions,
  DocumentTracking,
  PlatformSettings,
  EditProfile,
  Projects,
  InspectionFiles,
  InspectionOverview,
  InspectionNotes,
  InspectionAuditTrail,
  InspectionOverviewPage,
  Users,
  WorkOrders,
  UserInfo,
  ProjectOverviewPage,
  ProjectFiles,
  ProjectOverview,
  ProjectNotes,
  ProjectWorkOrders,
  ProjectInspections,
  WorkOrderOverview,
  WorkOrderNotes,
  WorkOrderFiles,
  WorkOrderOverviewPage,
  MissingCategories,
  RejectedDocuments,
  ReceivedDocuments,
  ReportWriting,
  ReportWritingOverview,
  ArchivedWorkOrders,
  ArchivedProjects,
  ProjectDescription,
  ProjectContract,
  ProjectPermits,
  ProjectCostReview,
  AuditTrail,
  ProposalRequests,
  ProjectCover,
  ProposalRequestOverview,
  // ProposalRequestNotes,
  // ProposalRequestFiles,

  ProductivityMetricsData,
  ProductivityMetricsWorkOrders,
  ProductivityMetricsProjectPortfolio,
  ProductivityMetricsRfpPortfolio,
  ProductivityMetricsInspections,
  ProductivityMetricsAvgServicesPrices,
});

const UnAuthRoutes = () => (
  <UnAuthLayout>
    <Switch>
      <Login />
      <VerificationCode />
      <ForgotPassword />
      <ResetPassword />
    </Switch>
  </UnAuthLayout>
);

// const UnAuthSignUpRoute = () => (
//   <UnAuthSignUpLayout>
//     <SignUp />
//   </UnAuthSignUpLayout>
// );

const AuthRoutes = () => (
  <ErrorBoundary>
    <BaseLayout>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Switch>
          <Suspense fallback={<AppLoadingLayout />}>
            <ArchivedWorkOrders />
            <WorkOrders exact />
            <Inspections />
            <ArchivedProjects />
            <Projects exact />
            <Notes />
            <AuditTrail />
            <DocumentTracking />
            <Contacts />
            <Users />
            <Settings />
            <ReportWriting />
            <UserInfo />
            <InspectionOverview />
            <ProjectOverview />
            <WorkOrderOverview />
            <ReportWritingOverview />

            <ProposalRequests />
            <ProposalRequestOverview />

            <ProductivityMetrics />
          </Suspense>
        </Switch>
      </MuiPickersUtilsProvider>
    </BaseLayout>
  </ErrorBoundary>
);

const App = () => {
  const { isAuthenticated, isInitializing } = useAuth();

  if (isInitializing) {
    return <AppLoadingLayout />;
  }

  return (
    <>
      <Switch>
        <Redirect exact from="/" to={isAuthenticated ? links.WorkOrders() : links.Login()} />

        <Route path={[links.Login(), links.VerificationCode(), links.ForgotPassword(), links.ResetPassword()]}>
          {isAuthenticated ? <Redirect to={links.WorkOrders()} /> : <UnAuthRoutes />}
        </Route>

        {/* <Route path={links.SignUp()}>
          {isAuthenticated ? <Redirect to={links.WorkOrders()} /> : <UnAuthSignUpRoute />}
        </Route> */}

        {isAuthenticated ? <AuthRoutes /> : <Redirect to={links.Login()} />}
      </Switch>
    </>
  );
};

export default App;
