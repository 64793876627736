import React from 'react';
import { Row, TableBodyProps } from 'react-table';
import clsx from 'clsx';

import styles from './Body.module.scss';

export interface BodyProps {
  getTableBodyProps: () => TableBodyProps;
  // eslint-disable-next-line
  rows: Row<object>[];
  // eslint-disable-next-line
  prepareRow: (row: Row<object>) => void;
}

export const Body = ({ getTableBodyProps, rows, prepareRow }: BodyProps) => {
  return (
    <tbody {...getTableBodyProps()}>
      {rows.map((row) => {
        prepareRow(row);

        return (
          <tr {...row.getRowProps()} key={`body-row${row.id}`}>
            {row.cells.map((cell, index) => {
              return (
                <td
                  {...cell.getCellProps({
                    style: {
                      minWidth: cell.column.minWidth,
                      width: cell.column.width,
                      maxWidth: cell.column.maxWidth,
                    },
                  })}
                  key={`body-column${cell.column.Header}-${index}`}
                  className={clsx(styles.column)}
                >
                  <>{cell.render('Cell')}</>
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};
