import { useState, FC, useMemo } from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { TextField, CheckboxWithLabel } from 'formik-material-ui';
import * as Yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import { InputPhoneMask, LoadingButton, PasswordField, CustomDialog, RoleForm } from 'components';

import { RoleFormValues, PermissionEnum, RolesEnum, RoleInfo } from 'types';
import { PASSWORD, PHONE_NUMBER } from 'consts';
import { PlusIcon } from 'icons';
import { toStringArray } from 'utils';
import { useFormChanges } from 'contexts/FormChanges';
import { useAuth } from 'contexts';
import { useActivateUserMutation, useCreateRoleMutation, useDeactivateUserMutation } from 'lib/react-query/mutations';
import { useGetRoles } from 'lib/react-query/queries';

import styles from './UserForm.module.scss';

import { SelectedDialogEnum, UserFormProps } from './types';

export const UserForm: FC<UserFormProps> = ({
  id,
  firstName,
  lastName,
  email,
  personalNumber,
  workNumber,
  workNumberExt,
  roles,
  isActive,
  onSubmit,
  refetchUser,
}) => {
  const { userData } = useAuth();
  const { checkFormChanges } = useFormChanges();

  const [selectedOpenDialog, setSelectedOpenDialog] = useState<SelectedDialogEnum | null>(null);

  const handleCreateModalOpen = () => setSelectedOpenDialog(SelectedDialogEnum.RoleCreation);

  const handleCloseSelectedDialog = () => setSelectedOpenDialog(null);

  const { isLoading: rolesLoading, isFetching, data: allRoles, refetch } = useGetRoles();

  const { mutate: ceateRoleMutation } = useCreateRoleMutation(() => {
    handleCloseSelectedDialog();
    toast.success('New platform role created!');
    refetch();
  });

  const { mutate: deactivateUserMutation } = useDeactivateUserMutation(id as number, () => {
    handleCloseSelectedDialog();
    toast.success('User deactivated!');
    refetchUser();
  });

  const { mutate: activateUserMutation } = useActivateUserMutation(id as number, () => {
    handleCloseSelectedDialog();
    toast.success('User activated!');
    refetchUser();
  });

  const handleRoleCreation = (values: RoleFormValues) => ceateRoleMutation(values);

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.checked ? activateUserMutation() : deactivateUserMutation();
  };

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required('First name is a required field'),
        lastName: Yup.string().required('Last name is a required field'),
        email: Yup.string().email('Field must be email format').required('Email is a required field'),
        personalNumber: Yup.string()
          .matches(PHONE_NUMBER, 'Must be a valid phone number')
          .required('Personal number is a required field'),
        workNumber: Yup.string().matches(PHONE_NUMBER, 'Must be a valid phone number'),
        workNumberExt: Yup.string().matches(/^[0-9]{3,4}$/, 'Extension is not valid'),
        roles: Yup.array().of(Yup.string()),
        password: Yup.string().matches(PASSWORD, 'Use 8 or more characters with a mix of letters, numbers & symbols'),
        passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
      }),
    [],
  );
  const switchLabel = isActive ? 'Active user' : 'Inactive user';
  const isSuperAdmin = userData?.activeRole?.name === RolesEnum.SuperAdmin;

  return (
    <div>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          firstName: firstName ?? '',
          lastName: lastName ?? '',
          email: email ?? '',
          personalNumber: personalNumber ?? '',
          workNumber: workNumber ?? '',
          workNumberExt: workNumberExt ?? '',
          roles: roles?.length ? toStringArray<RoleInfo>(roles) : [],
          oldPassword: '',
          password: '',
          passwordConfirmation: '',
        }}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, initialValues, values }) => {
          checkFormChanges(initialValues, values);

          return (
            <Form>
              <div className={clsx(styles.topContainer, 'px-16 px-md-32 mt-24 mt-sm-12')}>
                <h1 className={styles.blockTitle}>Edit Profile</h1>
              </div>

              {isSuperAdmin && (
                <section className="px-md-16">
                  <span className={clsx('uppercase pl-16 mb-24', styles.heading)}>- General Settings</span>
                  <div className="col-12 col-md-6 mb-24 mb-md-36">
                    <FormControlLabel
                      control={<Switch onChange={handleSwitchChange} checked={isActive} />}
                      label={switchLabel}
                    />
                  </div>
                </section>
              )}

              <section className="px-md-16">
                <span className={clsx('uppercase pl-16 mb-24', styles.heading)}>- Personal Info</span>

                <div className="flex flex-wrap">
                  <div className="col-12 col-md-6 mb-24 mb-md-36">
                    <Field component={TextField} name="firstName" label="First name" variant="outlined" />
                  </div>

                  <div className="col-12 col-md-6 mb-24 mb-md-36">
                    <Field component={TextField} name="lastName" label="Last name" variant="outlined" />
                  </div>

                  <div className="col-12 col-md-6 mb-24 mb-md-36">
                    <Field
                      component={TextField}
                      name="email"
                      label="Email"
                      placeholder="test@gmail.com"
                      variant="outlined"
                      type="email"
                    />
                  </div>

                  <div className="col-12 col-md-6 mb-24 mb-md-36">
                    <Field
                      component={InputPhoneMask}
                      name="personalNumber"
                      placeholder="+1 (770) 123-4567"
                      label="Personal number"
                      variant="outlined"
                    />
                  </div>
                </div>
              </section>
              <section className="px-md-16">
                <span className={clsx('uppercase pl-16 mb-24', styles.heading)}>- Work Info</span>

                <div className="flex flex-wrap">
                  <div className="col-12 col-md-6 flex mb-24 mb-md-36">
                    <Field
                      component={InputPhoneMask}
                      name="workNumber"
                      placeholder="+1 (770) 123-4567"
                      label="Work number"
                      variant="outlined"
                    />
                    <Field
                      component={TextField}
                      name="workNumberExt"
                      variant="outlined"
                      placeholder="1234"
                      className={clsx(styles.workNumberExtension, 'ml-16')}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">#</InputAdornment>,
                      }}
                    />
                  </div>
                </div>
              </section>
              <section className="px-md-16">
                <span className={clsx('uppercase pl-16 mb-24', styles.heading)}>- Change password</span>

                <div className="flex flex-wrap">
                  <div className="col-12 col-md-6 mb-24 mb-md-36">
                    <PasswordField name="password" label="New password" />
                  </div>

                  <div className="col-12 col-md-6 mb-24 mb-md-36">
                    <PasswordField name="passwordConfirmation" label="Password confirmation" />
                  </div>
                </div>
              </section>
              <hr className={styles.separator} />
              {userData?.permissions.has(PermissionEnum.roles_access) && (
                <div className="px-16 px-md-32 mb-24">
                  <div className="my-32">
                    <h1 className={clsx(styles.blockTitle, 'mb-4')}>Platform Role</h1>
                    <span className={styles.helperText}>Permissions depends on the role.</span>
                  </div>
                  <div className={clsx(styles.rolesContainer, 'mb-24')}>
                    {rolesLoading || isFetching ? (
                      <div className="p-relative">
                        <CircularProgress />
                      </div>
                    ) : (
                      <FieldArray
                        name="roles"
                        render={() => (
                          <div className={styles.optionsWrapper}>
                            {allRoles?.map((role: RoleInfo) => (
                              <div className={styles.optionContainer} key={role.uuid}>
                                <Field
                                  component={CheckboxWithLabel}
                                  value={role.uuid}
                                  Label={{ label: role.name }}
                                  name="roles"
                                  color="primary"
                                  type="checkbox"
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      />
                    )}
                    {userData?.permissions.has(PermissionEnum.role_create) && (
                      <Button variant="text" onClick={handleCreateModalOpen} className={styles.createRoleButton}>
                        <PlusIcon className={styles.plusIcon} /> Create a new role
                      </Button>
                    )}
                  </div>
                </div>
              )}
              <div className="flex align-items-center py-24">
                {userData?.permissions.has(PermissionEnum.roles_access) && (
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2 ml-md-16">
                    <LoadingButton fullWidth type="submit" loading={isSubmitting}>
                      Save
                    </LoadingButton>
                  </div>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
      <CustomDialog
        open={selectedOpenDialog === SelectedDialogEnum.RoleCreation}
        icon={<PlusIcon />}
        header="Create a new role"
        onClose={handleCloseSelectedDialog}
      >
        <RoleForm submitButtonTitle="create role" onSubmit={handleRoleCreation} close={handleCloseSelectedDialog} />
      </CustomDialog>
    </div>
  );
};
