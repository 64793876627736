import { useMemo } from 'react';
import { CellProps } from 'react-table';
import commonStyles from 'styles/common.module.scss';
import { getLabelByWorkOrderTypeEnum } from 'utils/common';
import formatNumberWithCommas from 'utils/formatNumberWithCommas';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ProductivityMetricsStats = any; //TO-DO describe type

export const usePMAvgServicesPricesTableColumns = () => {
  const columns = useMemo(
    () => [
      {
        id: 'service type',
        Header: () => <span>Service Type</span>,
        minWidth: 200,
        maxWidth: 200,
        Cell: ({
          row: {
            original: { serviceType },
          },
        }: CellProps<ProductivityMetricsStats>) => <>{getLabelByWorkOrderTypeEnum(serviceType)}</>,
      },
      {
        id: 'average price',
        Header: () => <span>Avg Price</span>,
        minWidth: 130,
        maxWidth: 130,
        Cell: ({
          row: {
            original: { averagePrice },
          },
        }: CellProps<ProductivityMetricsStats>) => (
          <span className={commonStyles.tableItemEndAlign}>
            {averagePrice && `$${formatNumberWithCommas(averagePrice)}`}
          </span>
        ),
      },
      {
        id: 'median price',
        Header: () => <span>Median Price</span>,
        minWidth: 130,
        maxWidth: 130,
        Cell: ({
          row: {
            original: { medianPrice },
          },
        }: CellProps<ProductivityMetricsStats>) => (
          <span className={commonStyles.tableItemEndAlign}>
            {medianPrice && `$${formatNumberWithCommas(medianPrice)}`}
          </span>
        ),
      },
    ],
    [],
  );
  return {
    columns,
  };
};
