import { IconProps } from 'types';

export const AddUserIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 18C12.6 18 10.3 17.1 8.6 15.4C6.9 13.7 6 11.4 6 9C6 6.6 6.9 4.3 8.6 2.6C10.3 0.9 12.6 0 15 0C16.2 0 17.3 0.2 18.4 0.7C19.5 1.2 20.5 1.8 21.3 2.7C22.1 3.5 22.8 4.5 23.3 5.6C23.8 6.7 24 7.9 24 9C24 10.1 23.8 11.3 23.3 12.4C22.8 13.5 22.2 14.5 21.4 15.3C20.6 16.1 19.6 16.8 18.5 17.3C17.3 17.8 16.2 18 15 18ZM15 4C13.7 4 12.4 4.5 11.5 5.5C10.5 6.4 10 7.7 10 9C10 10.3 10.5 11.6 11.5 12.5C12.9 13.9 15.1 14.3 16.9 13.6C17.5 13.3 18.1 13 18.5 12.5C19 12 19.3 11.5 19.6 10.9C19.9 10.3 20 9.6 20 9C20 8.4 19.9 7.7 19.6 7.1C19.3 6.5 19 5.9 18.5 5.5C18 5 17.5 4.7 16.9 4.4C16.3 4.1 15.7 4 15 4Z"
        fill="black"
      />
      <path
        d="M32 39C30.9 39 30 38.1 30 37V33H26C24.9 33 24 32.1 24 31C24 29.9 24.9 29 26 29H30V25C30 23.9 30.9 23 32 23C33.1 23 34 23.9 34 25V29H38C39.1 29 40 29.9 40 31C40 32.1 39.1 33 38 33H34V37C34 38.1 33.1 39 32 39Z"
        fill="black"
      />
      <path
        d="M23 40H2C0.9 40 0 39.1 0 38V36.8C0 32 -1.19209e-07 29.6 1.1 27.5C2.1 25.6 3.6 24.1 5.5 23.1C7.6 22 10 22 14.8 22H23C24.1 22 25 22.9 25 24C25 25.1 24.1 26 23 26H14.8C10.6 26 8.6 26 7.3 26.7C6.2 27.3 5.3 28.2 4.7 29.3C4 30.5 4 32.4 4 36H23C24.1 36 25 36.9 25 38C25 39.1 24.1 40 23 40Z"
        fill="black"
      />
    </svg>
  );
};
