import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { ArrowRightIcon } from 'icons';

import styles from './Pagination.module.scss';
import { PaginationDirectionsEnum, PaginationProps } from './types';

export const Pagination = ({
  perPage,
  count,
  total,
  pagesTotal,
  tableName,
  currentPage,
  handlePageChange,
  className,
}: PropsWithChildren<PaginationProps>) => {
  const isDisabledNext = pagesTotal === currentPage;
  const isDisabledPrev = currentPage - 1 === 0;

  return (
    <>
      {!!total && (
        <div
          className={clsx(
            'flex align-items-center justify-content-between justify-content-sm-end py-12 px-16 px-md-32',
            className,
          )}
        >
          <span className="mr-12 text-14 text-smallTextGrey">
            <span className="weight-700">{(currentPage - 1) * perPage + 1}</span> -
            <span className="weight-700 mr-8"> {(currentPage - 1) * perPage + count}</span> from
            <span className="weight-700 ml-8"> {total}</span>
          </span>
          <div className="flex">
            <ArrowRightIcon
              className={clsx(isDisabledPrev && styles.disabled, styles.arrowLeft, styles.arrow)}
              onClick={() => handlePageChange(PaginationDirectionsEnum.Prev, tableName)}
            />
            <ArrowRightIcon
              className={clsx(styles.arrow, isDisabledNext && styles.disabled)}
              onClick={() => handlePageChange(PaginationDirectionsEnum.Next, tableName)}
            />
          </div>
        </div>
      )}
    </>
  );
};
