import { IconProps } from 'types';

export const ReportWritingIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.875 14.75C0.875 14.3358 1.21079 14 1.625 14H15.125C15.5392 14 15.875 14.3358 15.875 14.75C15.875 15.1642 15.5392 15.5 15.125 15.5H1.625C1.21079 15.5 0.875 15.1642 0.875 14.75Z"
        fill="#212B83"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8855 0.5C11.0845 0.49996 11.2754 0.579023 11.4161 0.719779L14.1554 3.46028C14.4482 3.75314 14.4482 4.22785 14.1554 4.52071L6.39932 12.2802C6.25866 12.4209 6.06784 12.5 5.86887 12.5H3.125C2.71079 12.5 2.375 12.1642 2.375 11.75V9.02C2.375 8.82124 2.45389 8.63062 2.59435 8.48999L10.355 0.71999C10.4956 0.579179 10.6865 0.50004 10.8855 0.5ZM10.8858 2.31109L3.875 9.33039V11H5.55812L12.5646 3.99051L10.8858 2.31109Z"
        fill="#212B83"
      />
    </svg>
  );
};
