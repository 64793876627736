import { MobileTableRow } from 'components';
import { MobileTableV2, MobileTableRowField } from 'components/common/MobileTableV2';
import { MobileTableProps } from 'types';
import { useMobileTable } from 'hooks/useMobileTable';
import { getLabelByWorkOrderTypeEnum } from 'utils/common';
import {
  productivityMetricsTableEmptyModel,
  productivityMetricsTableEmptyTitle,
} from 'components/common/Table/parts/EmptyState/const';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PMAvgServicesPricesMobileTable = ({ items, loading, onClearFilters }: MobileTableProps<any>) => {
  const { selectedRow, toggleSelectedRow } = useMobileTable();

  return (
    <MobileTableV2
      hasData={items.length > 0}
      handleClearFilters={onClearFilters}
      model={productivityMetricsTableEmptyModel}
      emptyStateTitle={productivityMetricsTableEmptyTitle}
      {...{ loading }}
      isNewLayout
    >
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {items.map((proposalRequest: any) => {
        const { medianPrice, averagePrice, serviceType } = proposalRequest;

        const formattedAveragePrice = averagePrice && `${averagePrice}$`;
        const formattedMedianPrice = medianPrice && `${medianPrice}$`;

        return (
          <MobileTableRow
            key={serviceType}
            id={serviceType}
            rowTitle={getLabelByWorkOrderTypeEnum(serviceType)}
            handleToggle={toggleSelectedRow}
            selectedRowInfo={selectedRow}
          >
            <MobileTableRowField label="Service Type" value={getLabelByWorkOrderTypeEnum(serviceType)} />
            <MobileTableRowField label="Average Price" value={formattedAveragePrice} />
            <MobileTableRowField label="Median Price" value={formattedMedianPrice} />
          </MobileTableRow>
        );
      })}
    </MobileTableV2>
  );
};
