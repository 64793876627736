export const apiRoutes = {
  baseURL: process.env.REACT_APP_API_URL,
  signUp: '/api/auth/register',
  login: '/api/auth/login',
  forgot: '/api/auth/forgot-password',
  logout: '/api/auth/logout',
  refresh: '/api/auth/refresh',
  changePassword: '/api/auth/change-password',
  checkAuthCode: '/api/auth/check-auth-code',
  contacts: '/api/contacts',
  contactTypes: '/api/contact-types',
  companyTypes: '/api/contacts/company-types',
  notes: '/api/notes',
  roles: '/api/roles',
  timezones: '/api/timezones',
  account: '/api/account',
  settings: '/api/account/settings',
  inspections: '/api/inspections',
  inspectionNotes: '/api/inspection-notes',
  projects: '/api/projects',
  documents: '/api/documents',
  users: '/api/users',
  workOrders: '/api/work-orders',
  workOrderStatuses: '/api/work-orders/status-list',
  workOrderTypes: '/api/work-orders/type-list',
  permissions: '/api/permissions',
  documentCategories: '/api/document-categories',
  missingCategories: '/api/document-categories/missing-projects',
  documentStatistic: '/api/documents/statistic',
  documentStatuses: '/api/documents/statuses',
  documentTypes: '/api/document-types',
  projectAssignees: '/api/projects/users',
  workOrderAssignees: '/api/work-orders/users',
  inspectionsAssignees: '/api/inspections/users',
  documentUploadUrl: '/api/files/upload-url',
  documentConnectUrl: '/api/documents',
  projectNotes: '/api/project-notes',
  workOrderNotes: '/api/work-order-notes',
  projectTypes: '/api/projects/types',
  projectScopes: '/api/projects/scopes',
  organizationTypes: '/api/organizations/types',
  organization: '/api/organizations',
  files: '/api/files',
  reminderText: '/api/document-reminder/email-data',
  sendReminder: '/api/document-reminder/send-reminder',
  reports: '/api/reports',
  auditTrail: '/api/audit-trail',

  //proposal
  proposalRequests: '/api/proposal',
  proposalRequestStatuses: '/api/proposal/status-list',
  proposalEstimateList: '/api/proposal/estimate-list',
  proposalRequestNotes: '/api/proposal-request-notes',

  //productivity-metrics
  turnaroundMetrics: '/api/productivity-metrics/turnaround-by-users',
  reportsMetrics: '/api/productivity-metrics/reports-by-users',
  reportsMetricsStatistics: '/api/productivity-metrics/widget-data',
  organizations: 'api/organizations',
  reportsSubmitted: '/api/productivity-metrics/reports-submitted',
  reportsByState: '/api/productivity-metrics/reports-by-state',
  inspectorTurnaroundTimes: '/api/productivity-metrics/inspector-turnaround-times',
  reportsByMonth: '/api/productivity-metrics/reports-by-month',
  productivityWorkOrders: '/api/productivity-metrics/work-orders',
  productivityMetricsData: '/api/productivity-metrics/rfp-data',
  productivityMetricsDataExportCsv: '/api/productivity-metrics/rfp-data/export-csv',
  productivityMetricsProjectPortfolio: '/api/productivity-metrics/rfp-project-portfolio',
  productivityMetricsRfpPortfolio: '/api/productivity-metrics/rfp-portfolio',
  productivityMetricsAvgServicesPrices: '/api/productivity-metrics/rfp-avg-services',
  productivityMetricsInspections: '/api/productivity-metrics/rfp-estimated-remaining-inspections',
};
