import { ProposalRequestFilesRoute } from 'pages';
import { GetRouteProps } from 'types';

export type OverviewFilesTabProps = {
  isSortingApplied?: boolean;
} & GetRouteProps<typeof ProposalRequestFilesRoute>;

export type FilterTabsProps<T> = {
  label: T;
};

export enum FilesExtensionEnum {
  All = 'all',
  Pdf = 'pdf',
  Doc = 'doc',
  Jpg = 'jpg',
  Xls = 'xls',
}

export enum FoldersStatusEnum {
  All = 'all',
  WorkOrder = 'work order',
}
