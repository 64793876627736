import { ChangeEvent } from 'react';
import { useQuery } from 'react-query';
import { Formik, Form, Field, FieldArray } from 'formik';
import { TextField, CheckboxWithLabel } from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';

import { LoadingButton } from 'components';
import { api, apiRoutes } from 'api';
import { PermissionInstance, Permission, RoleFormValues } from 'types';

import styles from './RoleForm.module.scss';

type RoleFormProps = {
  submitButtonTitle: string;
  onSubmit: (values: RoleFormValues) => void;
  close: () => void;
};

export const RoleForm = ({ submitButtonTitle, onSubmit, close }: RoleFormProps) => {
  const permissionsQuery = () => api.get(apiRoutes.permissions).then((res) => res.data);
  const { isLoading, data: permissions } = useQuery('permissionsQuery', () => permissionsQuery());

  const handleAllOptionSelect = (
    { target: { checked } }: ChangeEvent<HTMLInputElement>,
    values: RoleFormValues,
    options: Permission[],
  ) => {
    const permissions = new Set(values.permissions);

    options.forEach(({ permission }) => {
      if (checked) permissions.add(permission);
      if (!checked) {
        permissions.delete(permission);
      }
    });

    values.permissions = Array.from(permissions);
  };

  if (isLoading) return <CircularProgress size={64} />;

  return (
    <Formik initialValues={{ name: '', permissions: [] }} onSubmit={onSubmit}>
      {({ isSubmitting, handleChange, values }) => (
        <Form>
          <div className={clsx(styles.inputWrapper, 'col-12')}>
            <Field
              component={TextField}
              name="name"
              label="Platform role name"
              variant="outlined"
              type="text"
              className="mb-24 mb-md-36"
            />
          </div>
          <h2 className={clsx(styles.sectionTitle, 'col-12')}>Role permissions</h2>
          <FieldArray
            name="permissions"
            render={() => (
              <div className={clsx(styles.checkboxesContainer, 'mt-32 mb-36 col-12')}>
                {permissions.map((instance: PermissionInstance) => (
                  <div className={clsx(styles.instanceContainer, 'col-lg-5')} key={instance.permission}>
                    <Field
                      component={CheckboxWithLabel}
                      value={instance.permission}
                      Label={{ label: instance.name }}
                      name="permissions"
                      color="primary"
                      type="checkbox"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => [
                        handleAllOptionSelect(e, values, instance.children),
                        handleChange(e),
                      ]}
                    />
                    <div className={styles.permissionsContainer}>
                      {instance.children.map((permission: Permission) => (
                        <Field
                          key={permission.permission}
                          component={CheckboxWithLabel}
                          value={permission.permission}
                          Label={{ label: permission.name }}
                          name="permissions"
                          color="primary"
                          type="checkbox"
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          />

          <div className="col-12 flex justify-content-end pt-24">
            <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
              {submitButtonTitle}
            </LoadingButton>
            <Button className="ml-12" type="reset" variant="text" onClick={() => close()}>
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
