import { DocumentIcon } from 'icons';

const ClipboardButton = ({ text }: { text: string }) => {
  const handleCopyClick = () => {
    const isHtml = /<\/?[a-z][\s\S]*>/i.test(text);
    const mimeType = isHtml ? 'text/html' : 'text/plain';

    const blob = new Blob([text], { type: mimeType });
    const clipboardItem = new ClipboardItem({ [mimeType]: blob });

    navigator.clipboard.write([clipboardItem]);
  };

  return <DocumentIcon className="c-pointer" color="grey" width={25} height={25} onClick={handleCopyClick} />;
};

export default ClipboardButton;
