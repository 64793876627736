import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';

import { EmptyState, Pagination, AuditTrailItem, AuditTrailItemInfo } from 'components';
import { ArrowRightIcon } from 'icons';
import { GridApiResponse } from 'types';
import { components } from 'generated/types';
import { perPage } from 'consts';

import styles from './OverviewAuditTrail.module.scss';

type AuditTrail = components['schemas']['AuditTrail'];

enum StatusEnum {
  All = 'all',
  WorkOrder = 'work order',
}

type OverviewAuditTrailProps = {
  handleQueryParamsChange: (key: 'search' | 'page' | 'status', value: string) => void;
  filteredAuditTrailItems: GridApiResponse<AuditTrail>;
  handlePageChange: (direction: 'next' | 'prev') => void;
  page: number;
  goBack: () => void;
  anditTrailId?: number;
  id: number;
  routeTo: (id: number) => void;
  status: StatusEnum;
  route: string;
};

export const OverviewAuditTrail: FC<OverviewAuditTrailProps> = ({
  filteredAuditTrailItems,
  handlePageChange,
  goBack,
  anditTrailId,
  routeTo,
  route,
}) => {
  const { items, pagesTotal, page: itemsPage, total, count } = filteredAuditTrailItems;

  return (
    <>
      <div className="d-block d-sm-flex justify-content-end align-items-center mb-12">
        {anditTrailId ? (
          <div className={styles.creationBtnContainer}>
            <Button fullWidth onClick={goBack}>
              <ArrowRightIcon className={clsx('mr-12', styles.shareIcon)} />
              Back to Audit trail item List
            </Button>
          </div>
        ) : (
          <>
            <Pagination
              perPage={perPage}
              count={count}
              total={total}
              handlePageChange={handlePageChange}
              pagesTotal={pagesTotal}
              currentPage={itemsPage}
            />
          </>
        )}
      </div>

      {anditTrailId ? (
        <div className="px-16">
          <AuditTrailItemInfo id={anditTrailId} goBack={goBack} route={route} />
        </div>
      ) : (
        <div className="flex flex-wrap">
          {items?.map(
            ({
              inspection,
              id,
              createdAt,
              updatedAt,
              user,
              description,
              report,
              reportChangedField,
              reportOldValue,
              reportNewValue,
              dataType,
            }) => (
              <div key={id} className="col-xlg-3 col-lg-6 col-12">
                <AuditTrailItem
                  key={id}
                  projectName={inspection?.project?.name}
                  route={routeTo(id as number)}
                  title={report?.name}
                  text={description}
                  createdAt={createdAt}
                  updatedAt={updatedAt}
                  id={id}
                  auditTrailId={id}
                  user={user}
                  {...{ reportChangedField, reportOldValue, reportNewValue, dataType }}
                />
              </div>
            ),
          )}
          {!items?.length && <EmptyState model="audit trail items" />}
        </div>
      )}
    </>
  );
};
