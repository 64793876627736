import { IconProps } from 'types';

export const ArrowRightIcon = ({ className, onClick, disabled, color }: IconProps) => {
  return (
    <svg
      className={className}
      onClick={!disabled ? onClick : () => {}}
      width="15"
      height="15"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 12L31 24L19 36"
        stroke={color || 'black'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
