import React from 'react';
import { Route } from 'react-router-hoc';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { HttpErrorResponse, VerificationFormValues } from 'types';
import { VerificationCodeForm } from 'components';
import { useAuth } from 'contexts';
import { links } from 'App';

const VerificationCodeRoute = Route({ email: Route.query.string, rememberMe: Route.query.boolean }, '/verification');

const VerificationCode = VerificationCodeRoute(
  ({
    match: {
      query: { email, rememberMe },
    },
  }) => {
    const history = useHistory();
    const { verifyCode } = useAuth();

    const handleSubmit = async (verificationData: VerificationFormValues) => {
      try {
        await verifyCode(verificationData);
        history.push(links.WorkOrders());
      } catch (e) {
        toast.error((e as HttpErrorResponse).message);
      }
    };

    return (
      <>
        <VerificationCodeForm onSubmit={(code) => handleSubmit({ ...code, email, rememberMe })} />
      </>
    );
  },
);

export default VerificationCode;
