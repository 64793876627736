import { FC, Profiler, Suspense, lazy, useEffect, useMemo, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import Drawer from '@material-ui/core/Drawer';
import Switch from '@material-ui/core/Switch';

import { Breadcrumbs, CustomDialog, CustomPrompt, ViewHeading } from 'components';
import { ArrowLeftIcon, ArrowRightIcon, DeleteIcon } from 'icons';
import { HttpErrorResponse, Report, ReportTypesEnum } from 'types';
import { api, apiRoutes } from 'api';
import {
  checkIfInspectionTimeOrDateFieldEmpty,
  checkIfRequiredQuestionsEmpty,
  checkIfRequiredQuestionsEmptyInSection,
  Iterator,
} from 'utils';

import { useGetReportById, useGetReportLookBackData } from 'lib/react-query/queries';
import { useSaveAsDraftReportMutation, useUpdateSectionReportMutation } from 'lib/react-query/mutations';
import { getDefaultSectionByReportType, getProjectByReportType } from 'utils/common';
import { useMultiRefs } from 'hooks/useMultiRefs';
import { links } from 'App';
import useWindowSize from 'hooks/useWindowSize';

const ConfirmationModal = lazy(() => import('components/common/ConfirmationModal'));
const PhotoDrawer = lazy(() => import('./components/PhotoDrawer'));

import styles from './ReportWriting.module.scss';
import { ReportWritingProps, SectionOrder } from './types';
import { Sections } from './components';
import { Questions, SectionType, ConditionsAnswers } from './schemas/types';
import { useSetupQuestions } from './hooks/useSetupQuestions';
import { getPermitTemplate } from './schemas/permit';
import { LookBackModal } from './components/LookBackModal';

// import { preConstructionQuestions, preConstructionSections } from './schemas/preConstruction/preConstruction';
// import { inspectionReportQuestions } from './schemas/inspectionReport';

const stringify = <T,>(questions: T[]): string => {
  return JSON.stringify(questions);
};

const ReportWriting: FC<ReportWritingProps> = ({
  match: {
    params: { id },
    params,
    query: { section = '', view = 'orderForInspector' },
    query,
  },
  link,
}) => {
  const { push, replace } = useHistory();
  const { isMobile, isTablet } = useWindowSize();
  const [openFileList, setOpenFileList] = useState(false);
  const [openMobileNavigationBar, setOpenMobileNavigationBar] = useState(false);
  // const [questionsState, setQuestionsState] = useState<Questions[]>(inspectionReportQuestions);
  const [questionsState, setQuestionsState] = useState<Questions[]>([]);
  const [sections, setSections] = useState<SectionType[]>([]);
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [mathematicalExpHistory, setMathematicalExpHistory] = useState<{ [key: string]: string }>({});
  const [isAttemptToGenerateReport, setIsAttemptToGenerateReport] = useState(false);

  const [invalidSections, setInvaliedSections] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const [openPermitDelete, setOpenPermitDelete] = useState(false);
  const permitToDelete = useRef<string | null>();

  const [sectionsList, setSectionsList] = useState<SectionType[]>([]);

  const [toggleSectionList, setToggleSectionList] = useState(false);

  const [reportDataCopyString, setReportDataCopyString] = useState('');
  const [isChanged, setIsChanged] = useState(false);

  const [openLookBack, setOpenLookBack] = useState(false);
  const selectedLookBackFieldId = useRef<number | null>();
  const findQuestionInOptions = (
    options: { [key: string]: Partial<Questions>[] },
    id: string | number | null | undefined,
  ): Questions | undefined => {
    for (const option of Object.values(options)) {
      for (const question of option) {
        if (question.id === id) {
          return question as Questions;
        }
        if (question.options) {
          const foundQuestion = findQuestionInOptions(question.options, id);
          if (foundQuestion) {
            return foundQuestion;
          }
        }
      }
    }
  };

  const lookBackQuestion = useMemo(() => {
    let question = questionsState.find((el) => el.id === selectedLookBackFieldId.current);

    if (!question) {
      for (const q of questionsState) {
        if (q.options) {
          question = findQuestionInOptions(q.options, selectedLookBackFieldId.current);
          if (question) break;
        }
      }
    }

    return question;
  }, [openLookBack]);

  const [hideInReport, setHideInReport] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    if (questionsState.length > 0) {
      const sectionQuestions = questionsState.filter((q) => q.categoryId === section && q.hideInReport !== undefined);
      if (sectionQuestions.length > 0) {
        const allTrue = sectionQuestions.every((q) => q.hideInReport === true);
        const allFalse = sectionQuestions.every((q) => q.hideInReport === false);
        setHideInReport((prevState) => ({
          ...prevState,
          [section]: allTrue ? true : allFalse ? false : false,
        }));
      }
    }
  }, [questionsState, section]);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setHideInReport((prevState) => ({
      ...prevState,
      [section]: newValue,
    }));

    setQuestionsState((prevQuestions) =>
      prevQuestions.map((question) =>
        question.categoryId === section && question.hideInReport !== undefined
          ? { ...question, hideInReport: newValue }
          : question,
      ),
    );
  };

  const hasHideInReportQuestions = (questions: Questions[], section: string) => {
    return questions.some((q) => q.categoryId === section && q.hideInReport !== undefined);
  };

  // State for collapsed menu
  const [activeSections, setActiveSections] = useState<string[]>([]);

  const [getRefs, addRef] = useMultiRefs();

  const questionsRef = useRef<HTMLDivElement | null>(null);
  const initialReport = useRef<string>();

  const sectionIterator = new Iterator(
    sectionsList,
    sectionsList.findIndex(({ id }) => id === section),
  );

  const { data: report, isLoading, refetch } = useGetReportById(id as number);
  const { data: lookBackData, isFetching: isLookBackFetching } = useGetReportLookBackData(id as number, {
    options: { enabled: !!selectedLookBackFieldId.current },
    params: { id: selectedLookBackFieldId.current },
  });

  const { mutateAsync: saveAsDraftMutate, isLoading: saveAsDraftLoading } = useSaveAsDraftReportMutation(
    id as number,
    mathematicalExpHistory,
    () => {
      toast.success('Report has been successfully saved!');
      refetch();
    },
    () => {
      toast.error('Something went wrong!');
    },
  );

  const { mutateAsync: updateSectionMutate, isLoading: updateSectionLoading } = useUpdateSectionReportMutation(
    id as number,
    () => {
      toast.success('Section has been successfully updated!');
      refetch();
    },
  );

  useEffect(() => {
    report?.mathExpressions && setMathematicalExpHistory(report?.mathExpressions as { [key: string]: string });

    //@ts-ignore
    setReportDataCopyString(stringify(report?.reportData));
    if (!section) {
      replace(link({ ...params, ...query, section: getDefaultSectionByReportType(report?.type as ReportTypesEnum) }));
    }
  }, [report]);

  useEffect(() => {
    if (reportDataCopyString && questionsState.length !== 0) {
      setIsChanged(!!(reportDataCopyString !== stringify(questionsState)));
    }
  }, [reportDataCopyString, questionsState]);

  useEffect(() => {
    const selectedSection = sections.find(({ id, parentId }) => id === section && parentId);

    selectedSection?.parentId && setActiveSections([...activeSections, selectedSection.parentId]);
  }, [section]);

  useEffect(() => {
    if (sections.length && view) {
      const newArr = sections.filter((item) => !item[`${view}Hide` as keyof SectionType]);
      newArr.sort((sectionA, sectionB) => {
        const orderA = sectionA[view];
        const orderB = sectionB[view];

        return (orderA ?? Infinity) - (orderB ?? Infinity);
      });
      setSectionsList(newArr);
    }
  }, [view, sections]);

  useEffect(() => {
    if (questionsRef.current) {
      questionsRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [report]);

  useEffect(() => {
    if (report) {
      const sectionsState = stringify(report.reportCategoriesData as unknown as SectionType[]);

      initialReport.current = reportDataCopyString;
      reportDataCopyString && setQuestionsState(JSON.parse(reportDataCopyString));
      setSections(JSON.parse(sectionsState));
    }
  }, [report, section, reportDataCopyString, view]);

  useEffect(() => {
    window.onbeforeunload = (event: BeforeUnloadEvent) => {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const handleOpenFileList = () => setOpenFileList(true);
  const handleCloseFileList = () => setOpenFileList(false);

  const handleToggleMobileNavigationBar = () => {
    setOpenMobileNavigationBar(!openMobileNavigationBar);
  };

  const handleOpenPermitDelete = () => setOpenPermitDelete(true);
  const handleClosePermitDelete = () => setOpenPermitDelete(false);

  const handleOpenLookBack = (id: number) => {
    setOpenLookBack(true);
    selectedLookBackFieldId.current = id;
  };
  const handleCloseLookBack = () => {
    setOpenLookBack(false);
    selectedLookBackFieldId.current = null;
  };

  const changeSection = (idx: string) => {
    if (sectionsList.length) {
      const sectionViewIterator = new Iterator(
        sectionsList,
        sectionsList.findIndex(({ id }) => id === idx),
      );
      const nextSectionPath = sectionViewIterator.current()?.hasSubsections
        ? link({ ...params, ...query, section: sectionViewIterator.next().id })
        : link({ ...params, ...query, section: idx });
      push(nextSectionPath);
    }
  };

  const handleOpenSubsections = (idx: string) => {
    activeSections.some((id) => id === idx)
      ? setActiveSections(activeSections.filter((id) => id !== idx))
      : setActiveSections([...activeSections, idx]);
  };

  const handleOpenAllSubsections = () => {
    if (activeSections.length) {
      setActiveSections([]);
      return;
    }
    const mainSections = sectionsList.filter(({ main }) => main);
    setActiveSections([...mainSections.map(({ id }) => id)]);
  };

  const handleOpenMobileSections = () => setToggleSectionList(true);
  const handleCloseMobileSections = () => setToggleSectionList(false);

  const handleDelete = () => {
    const mainQuestion = questionsState?.find(({ id }) => id === '183');
    if (mainQuestion && mainQuestion.options) {
      const permitQuestion = mainQuestion?.options[ConditionsAnswers.Yes].find(({ id }) => id === '184');
      if (permitQuestion && permitQuestion.options) {
        permitQuestion.options[ConditionsAnswers.Yes] = permitQuestion.options[ConditionsAnswers.Yes].filter(
          ({ id }) => id !== permitToDelete.current,
        );
        setQuestionsState([...questionsState]);
      }
    }
    handleClosePermitDelete();
  };

  const handleDeletePermit = (idx: string) => {
    permitToDelete.current = idx;
    handleOpenPermitDelete();
  };

  const addPermit = () => {
    const permit = getPermitTemplate(questionsState);
    const mainQuestion = questionsState.find(({ id }) => id === '183');
    if (mainQuestion && mainQuestion.options) {
      const permitQuestion = mainQuestion?.options[ConditionsAnswers.Yes].find(({ id }) => id === '184');
      if (permitQuestion && permitQuestion.options) {
        permitQuestion.options[ConditionsAnswers.Yes].push(permit as never);
      }
    }
    setQuestionsState([...questionsState]);
  };

  const { setupQuestions } = useSetupQuestions({
    questionsState,
    setQuestionsState,
    setErrors,
    errors,
    section,
    addPermit,
    handleDeletePermit,
    mathematicalExpHistory,
    setMathematicalExpHistory,
    addRef,
    handleOpenLookBack,
    reportType: report?.type as string,
  });

  const handleViewChange = (value: SectionOrder) => {
    push(
      link({
        ...params,
        ...query,
        view: value,
      }),
    );
  };

  const handleChangeSections = async (direction: string) => {
    if (section === '37' && checkIfInspectionTimeOrDateFieldEmpty(section, questionsState, setErrors)) return;
    invalidSections.length && checkIfRequiredQuestionsEmptyInSection(section, questionsState, setInvaliedSections);

    const selectedSectionIterator = new Iterator(
      sectionsList,
      sectionsList.findIndex(({ id }) => id === section),
    );

    const nextLink =
      direction === 'next' &&
      link({
        ...params,
        ...query,
        section: selectedSectionIterator.next().hasSubsections
          ? selectedSectionIterator.next().id
          : selectedSectionIterator.current().id,
      });

    const prevLink = link({
      ...params,
      ...query,
      section: sectionIterator.prev().hasSubsections ? sectionIterator.prev().id : sectionIterator.current().id,
    });

    const sectionLink = direction === 'prev' ? prevLink : nextLink;

    setIsChanged(false);
    try {
      getRefs().forEach((item) => {
        item?.current?.save();
      });
      setTimeout(async () => {
        setIsChanged(false);
        initialReport.current = JSON.stringify(questionsState);

        const sectionQuestions = questionsState.filter(
          ({ categoryId, isModified }) => categoryId === section || isModified,
        );

        await saveAsDraftMutate(sectionQuestions);
        push(sectionLink);
      }, 300);
    } catch (e) {
      setTimeout(async () => {
        initialReport.current = JSON.stringify(questionsState);
        const sectionQuestions = questionsState.filter(
          ({ categoryId, isModified }) => categoryId === section || isModified,
        );

        await saveAsDraftMutate(sectionQuestions);
        push(sectionLink);
      }, 300);
    }
  };

  const questionBlock = (questionsState: Questions[]) => {
    const selectedQuestions = questionsState.filter(({ categoryId }) => categoryId === section);
    let allowGoBack = false;
    sectionIterator.getIndex() === 0 && (allowGoBack = true);
    sectionIterator.hasPrev() &&
      sectionIterator.prev() &&
      sectionIterator.current().hasSubsections &&
      sectionIterator.getIndex() === 0 &&
      (allowGoBack = true);

    const backBtn = (
      <Button
        onClick={() => handleChangeSections('prev')}
        className={styles.prevSectionBtn}
        variant="outlined"
        disabled={allowGoBack}
      >
        <ArrowLeftIcon className={clsx(allowGoBack && styles.disabledIcon)} />
        Save & Previous
      </Button>
    );
    sectionIterator.next();
    const nextBtn = (
      <Button className="ml-16" onClick={() => handleChangeSections('next')} disabled={!sectionIterator.hasNext()}>
        Save & Next
        <ArrowLeftIcon
          color="white"
          className={clsx(styles.rightBtnArrow, !sectionIterator.hasNext() && styles.disabledIcon)}
        />
      </Button>
    );

    return (
      <div
        className="flex flex-column align-items-start px-sm-32 pt-16 pt-sm-16 px-24"
        style={{ overflowY: 'auto', height: '100%' }}
        ref={questionsRef}
      >
        <div className="flex-1 w-100">{setupQuestions(selectedQuestions)}</div>
        <div className={clsx('mb-24 flex', styles.sectionBottomBtns)}>
          {backBtn}
          {nextBtn}
        </div>
      </div>
    );
  };

  const checkChangesBeforeReportGenarating = () => {
    if (!isChanged) {
      return handleReportGeneration();
    }
    setIsAttemptToGenerateReport(true);
    push(link()); //to call navigation action for prompt
  };

  const handleReportGeneration = async () => {
    if (checkIfRequiredQuestionsEmpty(report?.type as string, questionsState, setInvaliedSections)) return;

    setIsAttemptToGenerateReport(false);
    try {
      setInvaliedSections([]);
      setIsReportLoading(true);
      const response = await api.get(`${apiRoutes.reports}/${id}/download`, {
        responseType: 'blob',
      });
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      a.href = url;
      a.download = 'report.pdf';
      document.body.append(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      setIsReportLoading(false);
    } catch (e) {
      setIsReportLoading(false);
      toast.error((e as HttpErrorResponse).message);
    }
  };

  const updateSections = async (values: SectionType) => {
    getRefs().forEach((item) => {
      item?.current?.save();
    });

    if (section === '37' && checkIfInspectionTimeOrDateFieldEmpty(section, questionsState, setErrors)) return;

    initialReport.current = JSON.stringify(questionsState);
    const sectionQuestions = questionsState.filter(({ categoryId }) => categoryId === section);
    await saveAsDraftMutate(sectionQuestions);

    updateSectionMutate(values);
  };

  if (isReportLoading)
    return (
      <h2 className="w-100 h-100 flex-column flex align-items-center text-24 weight-500 text-primary justify-content-center text-center pt-24">
        <p className="p-relative w-100" style={{ top: '-74px' }}>
          <CircularProgress size={64} />
        </p>
        <p>
          Just a moment
          <br /> while we get your report ready.
        </p>
      </h2>
    );

  const breadcrumbs = [
    { title: 'Project', link: links.ProjectOverview({ id: getProjectByReportType(report as Report)?.id }) },
    {
      title: 'Work Order',
      link:
        (!!report?.inspection?.workOrder?.id && links.WorkOrderOverview({ id: report?.inspection?.workOrder?.id })) ||
        (!!report?.workOrder?.id && links.WorkOrderOverview({ id: report?.workOrder?.id })),
    },
    {
      title: 'Inspection',
      link: !!report?.inspection?.id && links.InspectionOverview({ id: report?.inspection?.id }),
    },
  ];

  return (
    <Profiler id="Report writing" onRender={() => {}}>
      <div className="flex flex-column h-100 p-relative overflow-hidden" style={{ touchAction: 'none' }}>
        {(isLoading || saveAsDraftLoading || updateSectionLoading) && (
          <div className={styles.loading}>
            <CircularProgress size={64} />
          </div>
        )}

        <ViewHeading
          hasSmartBackBtn={false}
          label="Report Writing Editor"
          onClickHeadingLabel={handleToggleMobileNavigationBar}
        >
          {isMobile && (
            <ArrowRightIcon
              className={clsx(styles.collapsedArrow, openMobileNavigationBar && styles.expandedArrow)}
              onClick={handleToggleMobileNavigationBar}
            />
          )}
          {(openMobileNavigationBar || isTablet) && (
            <>
              <Breadcrumbs data={breadcrumbs} className={styles.mobBreadcrumbsContainer} />
              <div className={clsx(styles.btnsWrapper, 'flex align-items-center flex-wrap')}>
                <Button
                  variant="contained"
                  className="mr-12 d-none d-sm-block"
                  onClick={checkChangesBeforeReportGenarating}
                >
                  Report generation
                </Button>
                <Button variant="contained" className="d-none d-sm-block " onClick={handleOpenFileList}>
                  PHOTOS
                </Button>
                <Button variant="outlined" className="d-block d-sm-none mr-16 col-6 w-100" onClick={handleOpenFileList}>
                  UPLOAD PHOTOS
                </Button>
                <Button
                  variant="outlined"
                  className="d-block d-sm-none col-6 w-100"
                  onClick={checkChangesBeforeReportGenarating}
                >
                  Report generation
                </Button>
              </div>
            </>
          )}
        </ViewHeading>

        <div className="flex flex-1 mt-12" style={{ overflowY: 'hidden' }}>
          <Sections
            sections={sectionsList}
            activeSections={activeSections}
            handleOpenSubsections={handleOpenSubsections}
            id={id as number}
            updateSectionMutate={updateSections}
            activeSection={section}
            report={report}
            changeSection={changeSection}
            sectionView={view}
            setSectionView={handleViewChange}
            handleCloseMobileSections={handleCloseMobileSections}
            handleOpenMobileSections={handleOpenMobileSections}
            toggleSectionList={toggleSectionList}
            handleOpenAllSubsections={handleOpenAllSubsections}
            invalidSections={invalidSections}
          />
          <div className="w-100 mr-sm-16 overflow-hidden h-100">
            <div className={styles.breadcrumbsContainer}>
              <div className="flex flex-row gap-8 align-items-center flex-nowrap">
                <div className={clsx(styles.reportName, 'weight-700')}>{report?.name}</div>
                <NavLink to={links.ProjectOverview({ id: getProjectByReportType(report as Report)?.id })}>
                  <p className={styles.projectName}>{getProjectByReportType(report as Report)?.name}</p>
                </NavLink>
              </div>

              <Breadcrumbs data={breadcrumbs} />
            </div>

            <div className={clsx(styles.editorContainer, section && styles.mobileEditorContainer, 'overflow-auto')}>
              <div className={clsx(styles.mobileSectionTitle, 'd-flex justify-content-between align-items-center')}>
                <div>{sections.find(({ id }) => id === section)?.name}</div>
                {hasHideInReportQuestions(questionsState, section) && (
                  <div className="d-flex align-items-center">
                    <span className="mr-2">Hide Table</span>
                    <Switch
                      checked={!!hideInReport[section]}
                      onChange={handleSwitchChange}
                      color="primary"
                      inputProps={{ 'aria-label': 'Hide in report' }}
                    />
                  </div>
                )}
              </div>
              {questionBlock(questionsState)}
            </div>
          </div>
        </div>
        <CustomPrompt
          setIsReportGenerating={setIsAttemptToGenerateReport}
          when={isChanged}
          isReportGenerating={isAttemptToGenerateReport}
          onClickActionBtn={handleReportGeneration}
          isReloading={window.innerWidth <= 576}
        />
        <Drawer anchor="right" open={openFileList} onClose={handleCloseFileList}>
          <Suspense fallback={<span>Loading...</span>}>
            <PhotoDrawer
              data={report?.reportFiles || []}
              reportId={id as number}
              handleClose={handleCloseFileList}
              reportType={report?.type}
            />
          </Suspense>
        </Drawer>
      </div>
      <div className={'drag-drop-area'}></div>

      <CustomDialog
        open={openPermitDelete}
        icon={<DeleteIcon />}
        header="Delete this permit?"
        onClose={handleClosePermitDelete}
      >
        <Suspense fallback={<span>Loading...</span>}>
          <ConfirmationModal
            text="You won’t be able to restore it"
            onClose={handleClosePermitDelete}
            onSubmit={handleDelete}
          />
        </Suspense>
      </CustomDialog>

      <LookBackModal
        open={openLookBack}
        isLoading={isLookBackFetching}
        data={lookBackData}
        onClose={handleCloseLookBack}
        addRef={addRef}
        question={lookBackQuestion as Questions}
      />
    </Profiler>
  );
};

export default ReportWriting;
