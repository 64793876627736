import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthProvider, SidebarProvider } from 'contexts';
import { theme } from 'styles/muiTheme';
import { queryClient } from 'api';
import App from 'App';
import './serviceWorker';

import 'index.scss';
import { FormChangesProvider } from 'contexts/FormChanges';
import HistoryProvider from 'contexts/HistoryProvider';

const container = document.getElementById('root');
// eslint-disable-next-line
const root = createRoot(container!);
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <HistoryProvider>
              <FormChangesProvider>
                <SidebarProvider>
                  <AuthProvider>
                    <App />
                    <ToastContainer
                      position="bottom-right"
                      autoClose={2000}
                      closeButton={false}
                      hideProgressBar
                      toastClassName="toastContainer"
                    />
                  </AuthProvider>
                </SidebarProvider>
              </FormChangesProvider>
            </HistoryProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </StylesProvider>
    </React.StrictMode>
  </BrowserRouter>,
);
