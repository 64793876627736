import { lazy, useEffect, useMemo, useRef, useState } from 'react';
import { Route } from 'react-router-hoc';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import EventEmitter from 'events';

import { DocumentFormValues, RequestDocumentFormValues, TableCell } from 'types';
import { CustomDialog, DocumentForm, FileRequest, Table, Notification, MobileTable } from 'components';
import { components } from 'generated/types';
import { getFullName, toEnUsFormat } from 'utils';
import { api, apiRoutes } from 'api';
import { links } from 'App';
import { ArrowDownIcon, ArrowRightIcon, Like, EditIcon, DocumentIcon, IrrelevantIcon } from 'icons';
import { emptyTableData, perPage } from 'consts';
import { HttpErrorResponse } from 'types';
import { useFormModal } from 'hooks/useFormModal';

const UnsavedChangesModal = lazy(() => import('components/common/UnsavedChangesModal'));

import styles from './ReceivedDocuments.module.scss';

type Document = components['schemas']['Document'];

const ReceivedDocumentsRoute = Route(
  { search: Route.query.string, page: Route.query.number, project: Route.query.string },
  '/document-tracking',
);

type ReceivedDocumentsProps = {
  getNewStatistics: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
    //eslint-disable-next-line
  ) => Promise<QueryObserverResult<any, unknown>>;
  refetchDocuments: EventEmitter;
  clearQuery: () => void;
};

export const ReceivedDocuments = ReceivedDocumentsRoute<ReceivedDocumentsProps>(
  ({
    match: {
      query: { search = '', page = 0, project },
      query,
    },
    link,
    getNewStatistics,
    refetchDocuments,
    clearQuery,
  }) => {
    const { push } = useHistory();

    const [openFileRequestDialog, setOpenFileRequestDialog] = useState(false);
    const [documentToEdit, setDocumentToEdit] = useState<Document | null>(null);
    const idFileToEdit = useRef<number | undefined>(undefined);

    const {
      isFormModalOpen,
      isUnsavedChangesModalOpen,
      handleCloseAllModals,
      handleCloseUnsavedChangesModal,
      handleOpenFormModal,
      handleCloseFormModal,
    } = useFormModal();

    const [clicked, setClicked] = useState<number | null>();

    const documentsQuery = () =>
      api
        .get(apiRoutes.documents, {
          params: {
            search: search,
            page: page + 1,
            perPage: perPage,
            'filter[_status]': 'received',
            'filter[_project]': project,
            'sort[createdAt]': 'DESC',
          },
        })
        .then((res) => res.data);

    const {
      isLoading,
      isFetching,
      data: documents,
      refetch: getDocuments,
      error,
    } = useQuery(['documentsQuery', query], () => documentsQuery());

    const documentEditMutation = (values: RequestDocumentFormValues) =>
      api.put(`${apiRoutes.documents}/${documentToEdit?.id}`, values).then((res) => res);

    const { mutateAsync: documentEdit } = useMutation(
      'documentEditMutation',
      (values: RequestDocumentFormValues) => documentEditMutation(values),
      {
        onSuccess: () => {
          toast.success('File has been successfully updated!');
          getDocuments();
          handleCloseFormModal();
        },
      },
    );

    const documentIrrelevantMutation = (id: number) =>
      api.put(`${apiRoutes.documents}/${id}/irrelevant`).then((res) => res);

    const { mutateAsync: documentIrrelevant } = useMutation(
      'documentIrrelevantMutation',
      (id: number) => documentIrrelevantMutation(id),
      {
        onSuccess: () => {
          toast.success('File has been successfully updated!');
          getNewStatistics();
          getDocuments();
        },
      },
    );

    refetchDocuments.on('refresh', getDocuments);

    const handleCategoriesToggle = (index: number | undefined) =>
      clicked === index ? setClicked(null) : setClicked(index);

    const handleDocumentIrrelevant = async (id: number) => {
      await documentIrrelevant(id);
    };

    const handleDocumentToEditOpen = (document: Document) => {
      setDocumentToEdit(document);
      handleOpenFormModal();
    };

    const handleDocumentPreview = (id: number) => {
      idFileToEdit.current = id;
      handleFileRequestOpen();
    };

    const handlePageChange = (direction: 'next' | 'prev') =>
      push(link({ ...query, page: direction === 'prev' ? (page > 0 ? page - 1 : 0) : page + 1 }));

    const handleClear = () => clearQuery();

    const filteredDocuments = useMemo(() => (documents?.items?.length ? documents : emptyTableData), [documents]);

    const { items: documentItems, pagesTotal, page: itemsPage, total, count } = filteredDocuments;

    const handleSubmit = async (values: DocumentFormValues) => {
      await documentEdit({
        ...values,
        documentCategories: values.documentCategories.map(({ id }) => id as number),
      });
    };

    const handleFileRequestClose = () => setOpenFileRequestDialog(false);
    const handleFileRequestOpen = () => setOpenFileRequestDialog(true);

    const handleOpenFile = async (id: string | undefined) => {
      try {
        window.open(`${process.env.REACT_APP_API_URL}${apiRoutes.files}/${id}`);
      } catch (e) {
        throw new Error((e as HttpErrorResponse).message);
      }
    };

    const columns = useMemo(
      () => [
        {
          id: 'name',
          Header: 'file name',
          Cell: function name({
            row: {
              original: { name, file },
            },
          }: TableCell<Document>) {
            return <span onClick={() => handleOpenFile(file?.uuid)}>{name}</span>;
          },
        },
        {
          id: 'project',
          Header: 'Project',
          Cell: function projectName({
            row: {
              original: { project },
            },
          }: TableCell<Document>) {
            return (
              <NavLink to={links.ProjectFiles({ id: project?.id })}>
                <span>{project?.name}</span>
              </NavLink>
            );
          },
        },
        {
          id: 'uploadedBy',
          Header: 'uploaded by',
          Cell: function uploadedBy({
            row: {
              original: { createdBy },
            },
          }: TableCell<Document>) {
            return <span>{getFullName(createdBy)}</span>;
          },
        },
        {
          id: 'dateUploaded',
          Header: 'date uploaded',

          Cell: function dateUploaded({
            row: {
              original: { createdAt },
            },
          }: TableCell<Document>) {
            return <span>{toEnUsFormat(createdAt)}</span>;
          },
        },
        {
          id: 'edit-document',
          maxWidth: 80,
          Header: '',
          Cell: function EditDocument({ row: { original } }: TableCell<Document>) {
            return (
              <div className="flex">
                <EditIcon
                  className={clsx(styles.editIcon, 'mr-8')}
                  onClick={() => handleDocumentToEditOpen(original)}
                />
                <Like
                  className={clsx(styles.editIcon, 'mr-8')}
                  onClick={() => handleDocumentPreview(original?.id as number)}
                />

                <IrrelevantIcon
                  className={clsx(styles.editIcon)}
                  onClick={() => handleDocumentIrrelevant(original?.id as number)}
                />
              </div>
            );
          },
        },
      ],
      [],
    );

    useEffect(() => {
      //eslint-disable-next-line
      if ((error as any)?.status === 404) {
        handleClear();
      }
    }, [error]);

    if (isLoading || isFetching) return <CircularProgress size={64} />;

    return (
      <>
        <h2 className="px-16 px-sm-32 weight-700 text-24 mt-12 mt-sm-0">Documents Received</h2>

        {documentItems && !!documentItems.length && (
          <div className={styles.paginationContainer}>
            <div className="flex align-items-center justify-content-between justify-content-sm-end px-16 px-lg-32">
              <span className="mr-12 text-14 text-smallTextGrey">
                <span className="weight-700">{(itemsPage - 1) * perPage + 1}</span> -
                <span className="weight-700 mr-8"> {(itemsPage - 1) * perPage + count}</span> from
                <span className="weight-700 ml-8"> {total}</span>
              </span>
              <div className={styles.paginationArrowContainer}>
                <ArrowRightIcon className={styles.arrowLeft} onClick={() => handlePageChange('prev')} />
                <ArrowRightIcon disabled={pagesTotal === page + 1} onClick={() => handlePageChange('next')} />
              </div>
            </div>
          </div>
        )}

        <div className="d-none d-md-block px-24 px-md-32 flex-1 overflow-auto">
          {!documentItems.length ? (
            <Notification text="Great job getting through all the files...that was a lot of work! For now, there`s nothing more to review." />
          ) : (
            <Table
              data={documentItems}
              columns={columns}
              onClick={handleClear}
              model="documents"
              scrollIconClassName={styles.scrollTop}
            />
          )}
        </div>
        <MobileTable scrollTopValue={260}>
          {documentItems.map((document: Document) => {
            const { id, name, project, createdBy, createdAt } = document;
            return (
              <div key={id}>
                <div className={styles.smColumnContainer}>
                  <div
                    className="flex flex-1 align-items-center justify-content-between"
                    onClick={() => handleCategoriesToggle(id)}
                  >
                    <span>{name}</span>
                    <ArrowDownIcon
                      className={clsx(styles.ArrowDownIcon, clicked === id && styles.ArrowDownIconRotate)}
                    />
                  </div>

                  {clicked === id && (
                    <div className={styles.smColumnDropdownContainer}>
                      <div className="pt-16">
                        <span className="uppercase">Project: </span>
                        {project?.name}
                      </div>
                      <div className="pt-16">
                        <span className="uppercase">Uploaded By: </span>
                        {getFullName(createdBy)}
                      </div>
                      <div className="pt-16">
                        <span className="uppercase">Date Uploaded: </span>
                        {toEnUsFormat(createdAt)}
                      </div>
                      <div
                        className={clsx(
                          'pt-16 flex align-items-center justify-content-center',
                          styles.smEditBtnContainer,
                        )}
                        onClick={() => handleDocumentToEditOpen(document)}
                      >
                        <EditIcon className={styles.editIcon} />
                        Edit
                      </div>

                      <div
                        className={clsx(
                          'pt-16 flex align-items-center justify-content-center',
                          styles.smEditBtnContainer,
                          styles.smBtnContainer,
                        )}
                        onClick={() => handleDocumentPreview(document.id as number)}
                      >
                        <Like className={styles.editIcon} />
                        Like
                      </div>

                      <div
                        className={clsx(
                          'pt-16 flex align-items-center justify-content-center',
                          styles.smEditBtnContainer,
                          styles.smBtnContainer,
                        )}
                        onClick={() => handleDocumentIrrelevant(id as number)}
                      >
                        <IrrelevantIcon className={styles.editIcon} />
                        Irrelevant
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          {!documentItems.length && (
            <Notification text="Great job getting through all the files...that was a lot of work! For now, there`s nothing more to review." />
          )}
        </MobileTable>

        <CustomDialog
          open={isFormModalOpen as boolean}
          icon={<DocumentIcon className={styles.documentIcon} />}
          header="Edit document"
          onClose={(_, reason) => handleCloseFormModal(reason)}
        >
          <DocumentForm document={documentToEdit as Document} onSubmit={handleSubmit} onClose={handleCloseFormModal} />
          <UnsavedChangesModal
            open={isUnsavedChangesModalOpen}
            onClose={handleCloseUnsavedChangesModal}
            closeAll={handleCloseAllModals}
          />
        </CustomDialog>

        <CustomDialog open={openFileRequestDialog} onClose={handleFileRequestClose}>
          <FileRequest
            id={idFileToEdit.current}
            getNewDocuments={getDocuments}
            getNewStatistics={getNewStatistics}
            onClose={handleFileRequestClose}
          />
        </CustomDialog>
      </>
    );
  },
);

export default ReceivedDocuments;
