import React, { FC, useMemo } from 'react';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { useQuery } from 'react-query';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Autocomplete, LoadingButton } from 'components';
import { DocumentFormValues, DropdownOption } from 'types';
import { components } from 'generated/types';
import { api, apiRoutes } from 'api';

import styles from './DocumentForm.module.scss';
import { useFormChanges } from 'contexts/FormChanges';

type Document = components['schemas']['Document'];

interface DocumentFormProps {
  onSubmit: (values: DocumentFormValues) => void | Promise<void>;
  onClose?: () => void;
  document: Document;
  tags?: DropdownOption[];
}

export const DocumentForm: FC<DocumentFormProps> = ({ document, onClose, tags, onSubmit }) => {
  const { checkFormChanges } = useFormChanges();
  const dropdownOptions = [
    { id: 1, name: 'Name 1' },
    { id: 2, name: 'Name 2 32 gergerg' },
    { id: 3, name: 'Name 3' },
    { id: 4, name: 'Name 4' },
  ];

  const documentCategoriesQuery = () =>
    api.get(`${apiRoutes.projects}/${document?.project?.id}/document-categories`).then((res) => res.data);

  const { data: documentCategories, isLoading: documentCategoriesLoading } = useQuery('documentCategoriesQuery', () =>
    documentCategoriesQuery(),
  );

  const categories = useMemo(
    () =>
      document.documentCategoryDocuments?.length
        ? document.documentCategoryDocuments?.map(({ documentCategory }) => ({
            id: documentCategory?.id as number,
            name: documentCategory?.title as string,
          }))
        : [],
    [document],
  );

  const documentCategoriesItems = useMemo(() => {
    if (!documentCategories?.length) return [];
    return documentCategories.map(({ id, title }: { id: number; title: string }) => ({ id, name: title }));
  }, [documentCategories]);

  const filteredOptionsForAutoComplete = useMemo(() => {
    return dropdownOptions.filter(({ id }) => !tags?.some(({ id: idx }) => id === idx));
  }, [tags]);

  const getOptionName = ({ name }: DropdownOption) => name;

  const getDocumentCategories = (documentCategories: DropdownOption[]) => {
    return documentCategoriesItems.filter(
      ({ id }: { id: number; title: string }) => !documentCategories.map(({ id }) => id).includes(id),
    );
  };

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required('File name is a required field'),
        tags: Yup.object()
          .shape({
            id: Yup.string(),
            name: Yup.string(),
          })
          .nullable(),
        documentCategories: Yup.array()
          .of(
            Yup.object()
              .shape({
                id: Yup.number().required('Associate with is a required field'),
                name: Yup.string().required('Associate with is a required field'),
              })
              .typeError('Associate with is a required field')
              .required('Associate with is a required field'),
          )
          .min(0),
      }),
    [],
  );

  if (documentCategoriesLoading) return <CircularProgress size={64} />;

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        name: (document.name as string) ?? '',
        tags: tags ?? [],
        documentCategories: categories ?? [],
      }}
      onSubmit={onSubmit}
    >
      {({ values, isSubmitting, initialValues }) => {
        checkFormChanges(initialValues, values);

        return (
          <Form>
            <div className="flex flex-wrap">
              <div className="col-12 mb-24 mb-md-36">
                <Field component={TextField} name="name" label="Document name" variant="outlined" />
              </div>

              <div className={clsx('col-12 mb-24 mb-md-36', styles.autoCompleteContainer)}>
                <Field
                  component={Autocomplete}
                  name="tags"
                  placeholder="Tags"
                  type="text"
                  options={[...filteredOptionsForAutoComplete]}
                  getOptionLabel={getOptionName}
                  multiple
                />
              </div>

              <div className={clsx('col-12 mb-24 mb-md-36', styles.autoCompleteContainer)}>
                <Field
                  component={Autocomplete}
                  name="documentCategories"
                  placeholder={!values.documentCategories.length ? 'Associate with' : 'Document category'}
                  type="text"
                  options={[...getDocumentCategories(values.documentCategories)]}
                  getOptionLabel={getOptionName}
                  multiple
                />
              </div>
            </div>

            <div className="flex pt-24 justify-content-end px-16">
              <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                Save
              </LoadingButton>

              <Button variant="text" type="reset" onClick={onClose} className="ml-12">
                Cancel
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
