import { useHistory } from 'react-router';
import clsx from 'clsx';
import { ArrowRightIcon, ColumnViewIcon, TableViewIcon } from 'icons';
import qs from 'query-string';
import { perPage_100 } from 'consts';

import styles from './OverviewFilesPagination.module.scss';
import { OverviewFilesPaginationProps } from './types';

export const OverviewFilesPagination = ({
  perPage = perPage_100,
  page,
  data,
  match,
  link,
}: OverviewFilesPaginationProps) => {
  const { pagesTotal, page: itemsPage, total, count } = data;
  const {
    push,
    location: { pathname },
  } = useHistory();

  const {
    params,
    query: { isTableView = false },
    query,
  } = match;

  const handlePageChange = (direction: 'next' | 'prev') => {
    const pageNumber = direction === 'prev' ? (page > 0 ? page - 1 : 0) : page + 1;
    push({ pathname: pathname, search: qs.stringify({ ...query, page: pageNumber }) });
  };

  const toggleTableView = () => push(link({ ...query, ...params, isTableView: true }));
  const toggleColumnView = () => push(link({ ...query, ...params, isTableView: false }));

  //   if (!!items?.length) return <></>;

  return (
    <div className={styles.paginationContainer}>
      <div className="flex align-items-center justify-content-between justify-content-sm-end">
        <span className="mr-12 text-14 text-smallTextGrey">
          <span className="weight-700">{(itemsPage - 1) * perPage + 1}</span> -
          <span className="weight-700 mr-8">{(itemsPage - 1) * perPage + count}</span>
          from
          <span className="weight-700 ml-8"> {total}</span>
        </span>
        <div className={styles.paginationArrowContainer}>
          <ArrowRightIcon className={styles.arrowLeft} onClick={() => handlePageChange('prev')} />
          <ArrowRightIcon disabled={pagesTotal === page + 1} onClick={() => handlePageChange('next')} />
        </div>
      </div>
      <div className="flex justify-content-end">
        <ColumnViewIcon
          className={clsx(styles.viewToggleIcon, !isTableView && styles.activeViewToggleIcon)}
          onClick={toggleColumnView}
        />
        <TableViewIcon
          className={clsx(styles.viewToggleIcon, isTableView && styles.activeViewToggleIcon)}
          onClick={toggleTableView}
        />
      </div>
    </div>
  );
};
