import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const ProductivityMetricsWorkOrdersRoute = Route(
  {
    startDate: Route.query.string,
    endDate: Route.query.string,
    activeRangeType: Route.query.string,
    page: Route.query.number,
    type: Route.query.string,
    bank: Route.query.string,
    bankContact: Route.query.string,
    reportsByMonthType: Route.query.string,
    assignee: Route.query.string,
  },
  '/productivity-metrics/work-orders',
);

export const ProductivityMetricsWorkOrders = ProductivityMetricsWorkOrdersRoute(lazy(() => import('./WorkOrders')));
