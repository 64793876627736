import { Button } from '@material-ui/core';
import { links } from 'App';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { SearchField, ViewHeading } from 'components';
import useWindowSize from 'hooks/useWindowSize';
import { useProposalRequests } from 'pages/ProposalRequests/context';
import { ProposalRequestsRouteProps } from 'pages/ProposalRequests/types';
import commonStyles from 'styles/common.module.scss';
import { usePageSearch } from '../../../../hooks/usePageSearch';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ProposalRequestsHeading = ({ match, link }: ProposalRequestsRouteProps) => {
  const { isMobile } = useWindowSize();
  const { handleOpenProposalCreate } = useProposalRequests();
  const { handleEnterChange, setSearchTerm, handleClearSearch, searchTerm } = usePageSearch({ match, link });

  return (
    <ViewHeading hasSmartBackBtn={false} label="Proposal Requests">
      {
        <SearchField
          className={'mb-12'}
          onChange={setSearchTerm}
          value={searchTerm}
          onSearch={handleEnterChange}
          handleClear={handleClearSearch}
        />
      }

      <div className={commonStyles.creationBtnContainer}>
        <NavLink to={links.ProductivityMetricsAvgServicesPrices()} className={clsx('mb-12', isMobile && 'w-100')}>
          <Button variant="contained" fullWidth={isMobile} className="mr-12">
            AVG Services
          </Button>
        </NavLink>
        <Button variant="contained" fullWidth={isMobile} className="mb-12" onClick={handleOpenProposalCreate}>
          + Add new proposal request
        </Button>
      </div>
    </ViewHeading>
  );
};
