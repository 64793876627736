import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const ProposalRequestsRoute = Route(
  {
    page: Route.query.number,
    search: Route.query.string,
    services: Route.query.string,
    bankContact: Route.query.string,
    bank: Route.query.string,
    project: Route.query.string,

    dateReceivedRfpStart: Route.query.string,
    dateReceivedRfpEnd: Route.query.string,

    dateProposalSentStart: Route.query.string,
    dateProposalSentEnd: Route.query.string,

    dateAcceptedRpfStart: Route.query.string,
    dateAcceptedRpfEnd: Route.query.string,

    estimatedInspectionDateStartFrom: Route.query.string,
    estimatedInspectionDateStartTo: Route.query.string,

    itemsPerGroup: Route.query.number,
  },
  '/proposal-requests',
);

export const ProposalRequests = ProposalRequestsRoute(lazy(() => import('./ProposalRequests')));
