import { MobileTableRow } from 'components';
import { MobileTableV2, MobileTableRowField } from 'components/common/MobileTableV2';
import {
  productivityMetricsTableEmptyModel,
  productivityMetricsTableEmptyTitle,
} from 'components/common/Table/parts/EmptyState/const';
import { useMobileTable } from 'hooks/useMobileTable';
import { MobileTableProps } from 'types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PMProjectPortfolioMobileTable = ({ items, loading, onClearFilters }: MobileTableProps<any>) => {
  const { selectedRow, toggleSelectedRow } = useMobileTable();

  return (
    <MobileTableV2
      hasData={items.length > 0}
      loading={loading}
      isScrollTop={false}
      isNewLayout
      className="mb-24"
      handleClearFilters={onClearFilters}
      model={productivityMetricsTableEmptyModel}
      emptyStateTitle={productivityMetricsTableEmptyTitle}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {items.map((item: any) => {
        const { name, id, assignee, bank } = item;

        return (
          <MobileTableRow
            key={id}
            id={id}
            rowTitle={name}
            handleToggle={toggleSelectedRow}
            selectedRowInfo={selectedRow}
          >
            <MobileTableRowField label="% Completed" value={name} />
            <MobileTableRowField label="Number of projects" value={assignee} />
            <MobileTableRowField label="% of total projects" value={bank} />
          </MobileTableRow>
        );
      })}
    </MobileTableV2>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PMProjectPortfolioByMonthMobileTable = ({ items, loading, onClearFilters }: MobileTableProps<any>) => {
  const { selectedRow, toggleSelectedRow } = useMobileTable();

  return (
    <MobileTableV2
      hasData={items.length > 0}
      loading={loading}
      isScrollTop={false}
      isNewLayout
      handleClearFilters={onClearFilters}
      model={productivityMetricsTableEmptyModel}
      emptyStateTitle={productivityMetricsTableEmptyTitle}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {items.map((item: any) => {
        const { name, id, assignee, bank } = item;

        return (
          <MobileTableRow
            key={id}
            id={id}
            rowTitle={name}
            handleToggle={toggleSelectedRow}
            selectedRowInfo={selectedRow}
          >
            <MobileTableRowField label="Month" value={name} />
            <MobileTableRowField label="Anticipated Projects" value={assignee} />
            <MobileTableRowField label="Anticipated Completing Projects" value={bank} />
            <MobileTableRowField label="Variance" value={bank} />
          </MobileTableRow>
        );
      })}
    </MobileTableV2>
  );
};
