import { useState } from 'react';
import { perPage_100 } from 'consts';
import { useProductivityMetricsDataTableColumns } from './useTableColumns';
import { useHistory } from 'react-router';
import { api, apiRoutes } from 'api';
import { HttpErrorResponse } from 'types';
import { toast } from 'react-toastify';
import { PaginationDirectionsEnum } from 'components/common/Table/parts/Pagination/types';
import { useGetProductivityMetricsData } from 'pages/ProductivityMetrics/queries';
import {
  productivityMetricsTableEmptyModel,
  productivityMetricsTableEmptyTitle,
} from 'components/common/Table/parts/EmptyState/const';

import { ProductivityMetricsDataProps } from '../../types';

export const useProductivityMetricsDataTable = ({
  match: {
    query: {
      page = 0,
      bank,
      bankContact,
      services,
      assignee,
      project,
      projectStatus,
      projectType,
      state,
      owner,
      status,
      estimatedInspectionDateStartFrom,
      estimatedInspectionDateStartTo,
      estimatedInspectionDateCompletionFrom,
      estimatedInspectionDateCompletionTo,
      proposalAcceptedDateFrom,
      proposalAcceptedDateTo,
      proposalSentDateFrom,
      proposalSentDateTo,
      projectCompletionDateTo,
      projectCompletionDateFrom,
      receivedDateFrom,
      receivedDateTo,
    },
    query,
  },
  link,
}: ProductivityMetricsDataProps) => {
  const { push } = useHistory();
  const { columns } = useProductivityMetricsDataTableColumns();
  const [isExportingCsv, setIsExportingCsv] = useState(false);
  const filters = {
    'filter[_service]': services,
    'filter[bank]': bank,
    'filter[assignee]': assignee,
    'filter[project]': project,
    'filter[_projectStatus]': projectStatus,
    'filter[_projectType]': projectType,
    'filter[state]': state,
    'filter[_bankContact]': bankContact,
    'filter[_projectOwner]': owner,
    'filter[status]': status,
    ...(estimatedInspectionDateStartTo
      ? {
          'filter[expectedDateOfFirstInspection:from]': estimatedInspectionDateStartFrom,
          'filter[expectedDateOfFirstInspection:to]': estimatedInspectionDateStartTo,
        }
      : { 'filter[expectedDateOfFirstInspection]': estimatedInspectionDateStartFrom }),
    ...(estimatedInspectionDateCompletionTo
      ? {
          'filter[expectedDateOfLastInspection:from]': estimatedInspectionDateCompletionFrom,
          'filter[expectedDateOfLastInspection:to]': estimatedInspectionDateCompletionTo,
        }
      : { 'filter[expectedDateOfLastInspection]': estimatedInspectionDateCompletionFrom }),
    ...(proposalAcceptedDateTo
      ? {
          'filter[awardDate:from]': proposalAcceptedDateFrom,
          'filter[awardDate:to]': proposalAcceptedDateTo,
        }
      : { 'filter[awardDate]': proposalAcceptedDateFrom }),
    'filter[_projectCompletionDate][from]': projectCompletionDateFrom,
    'filter[_projectCompletionDate][to]': projectCompletionDateTo,
    'filter[_receivedDate][from]': receivedDateFrom,
    'filter[_receivedDate][to]': receivedDateTo,
    ...(proposalSentDateTo
      ? {
          'filter[sentDate:from]': proposalSentDateFrom,
          'filter[sentDate:to]': proposalSentDateTo,
        }
      : { 'filter[sentDate]': proposalSentDateFrom }),
  };

  const { data, isFetching } = useGetProductivityMetricsData({
    params: {
      perPage: perPage_100,
      page: page + 1,
      ...filters,
    },
    queryKey: [query],
  });

  const { items, page: itemsPage, total, count, pagesTotal } = data;

  const handleCsvExport = async () => {
    try {
      setIsExportingCsv(true);
      const response = (
        await api.get(apiRoutes.productivityMetricsDataExportCsv, {
          responseType: 'blob',
          params: {
            ...filters,
          },
        })
      ).data;
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(new Blob([response]));
      a.href = url;
      a.download = 'Data.csv';
      document.body.append(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      setIsExportingCsv(false);
    } catch (e) {
      setIsExportingCsv(false);
      toast.error((e as HttpErrorResponse).message);
    }
  };

  const handleClear = () => {
    push(link());
  };

  const handlePageChange = (direction: PaginationDirectionsEnum) =>
    push(link({ ...query, page: direction === PaginationDirectionsEnum.Prev ? (page > 0 ? page - 1 : 0) : page + 1 }));

  const pagination = {
    perPage: perPage_100,
    currentPage: itemsPage,
    count,
    total,
    pagesTotal,
    handlePageChange,
  };

  const tableEmptyState = {
    model: productivityMetricsTableEmptyModel,
    emptyStateTitle: productivityMetricsTableEmptyTitle,
    handleClearFilters: handleClear,
  };

  return { items, columns, isFetching, isExportingCsv, pagination, tableEmptyState, handleClear, handleCsvExport };
};
