import React, { useState, FC, DragEvent, useEffect, ReactNode, useRef, lazy, Suspense, useReducer } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import axios from 'axios';
import { IHeaderOverride, IDocument } from '@cyntler/react-doc-viewer';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';

import { UploadFile, CloseIcon, DownloadIcon } from 'icons';
import { apiRoutes } from 'api/routes';
import api from 'api/api';
import { PreviewCard } from './components';
import { EmptyState } from '../Table/parts/EmptyState';
import { components } from 'generated/types';
import { HttpErrorResponse } from 'types';
const TableView = lazy(() => import('./components/TableView'));

import styles from './Dropzone.module.scss';

type Document = components['schemas']['Document'];
type FileInterface = components['schemas']['File'];

export interface customIDocument extends IDocument {
  name: string;
}
export interface ArrayInt {
  path?: string;
  lastModified?: number;
  name?: string;
  size?: number;
  type?: string;
  webkitRelativePath?: string;
}

interface ConnectUrl {
  name: string;
  path: string;
  mimeType: string;
  documentCategories?: string[];
  trackingDocument?: boolean;
  projectId?: string;
}
interface UploadFile {
  path: string;
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
}

type DragDropDownloadProps = {
  projectId?: string;
  modal?: boolean;
  pathUpload: string;
  id?: string | number;
  filteredFiles?: Document[];
  inspectionWorkOrderFiles?: Document[];
  filteredFilesFetching?: boolean;
  refetch: () => void;
  searchQuery?: string | undefined;
  isTableView?: boolean;
  handleClear?: () => void;
};

const MyHeader: IHeaderOverride = (state) => {
  if (!state.currentDocument || state.config?.header?.disableFileName) {
    return null;
  }

  return (
    <div className="mb-16">
      <h2 className="mb-12 pr-64 pl-12 pl-sm-0">{(state.currentDocument as customIDocument).name}</h2>
    </div>
  );
};

export const DragDropDownload: FC<DragDropDownloadProps> = ({
  projectId,
  pathUpload,
  id,
  filteredFiles,
  refetch,
  isTableView,
  handleClear,
}) => {
  const [dragActive, setDragActive] = useState<boolean>(false);
  const [lazyViewer, setLazyViewer] = useState<ReactNode | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [event, updateEvent] = useReducer(
    (prev: { selectedFiles: number[] }, next: { selectedFiles: number[] }) => {
      return { ...prev, ...next };
    },
    { selectedFiles: [] },
  );

  const startFile = useRef<number>(0);

  const documentsUploadUrlQuery = (name: string) =>
    api.get(`${apiRoutes.documentUploadUrl}?filename=${name}`).then((res) => res.data);

  const { mutateAsync: getFileUrl } = useMutation('documentsUploadUrlQuery', (name: string) =>
    documentsUploadUrlQuery(name),
  );

  const documentsConnectUrlQuery = (fileData: ConnectUrl) =>
    api.post(apiRoutes.documentConnectUrl, fileData).then((res) => res.data);

  const { mutateAsync: connectUrl } = useMutation('documentsConnectUrlQuery', (fileData: ConnectUrl) =>
    documentsConnectUrlQuery(fileData),
  );

  const handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDrop = function (e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const files = e.dataTransfer.files;
    handleChange(files);
  };

  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleClickChange = function (e: React.ChangeEvent) {
    const target = e.target as HTMLInputElement;
    const files = target.files;
    handleChange(files);
  };

  const handleChange = async (files: FileList | null) => {
    const dragDropArea = document.querySelector('.drag-drop-area') as HTMLElement;

    if (files) {
      const fileToArray: File[] = Object.values(files);

      try {
        for (let i = 0; i < fileToArray.length; i++) {
          const { path, url } = await getFileUrl(fileToArray[i].name as string);

          const data = await axios.request({
            method: 'put',
            url: url,
            data: fileToArray[i],
            onUploadProgress: ({ loaded, total }) => {
              const fileLoaded = Math.floor((loaded / total) * 100);
              const progressHTML = `<div>
                                      <li class=${styles.row}>
                                        <div class=${styles.textWrapper}>
                                          <p className="name">${fileLoaded}% ${i} files from ${fileToArray.length} uploaded...</p>
                                        </div>
                                      </li>
                                    </div>`;
              dragDropArea.innerHTML = progressHTML;
            },
          });

          if (data.status === 200) {
            await connectUrl({
              name: fileToArray[i].name as string,
              path,
              mimeType: fileToArray[i].type as string,
              projectId: projectId,
              trackingDocument: false,
              [pathUpload]: id,
            });
          }
        }
        refetch();
        toast.success('File has been successfully saved!');
      } catch (e) {
        toast.error('Something go wrong, please try again');
      }
    }
  };

  const handleOpenFile = (file: FileInterface, fileIndex: number) => {
    try {
      setOpenDialog(true);
      startFile.current = fileIndex;
    } catch (e) {
      throw new Error((e as HttpErrorResponse).message);
    }
  };

  const handleDownloadFile = async (file: FileInterface) => {
    try {
      setIsLoading(true);
      const response = await api.get(`${apiRoutes.files}/${file.uuid}`, { responseType: 'blob' });
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      a.href = url;
      if (file.name) {
        a.download = file.name;
      }
      document.body.append(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw new Error((e as HttpErrorResponse).message);
    }
  };

  const documents = filteredFiles?.map(
    (doc: Document) =>
      ({
        id: doc.id,
        uri: `${process.env.REACT_APP_API_URL}${apiRoutes.files}/${doc.file?.uuid}`,
        name: doc.name,
      } as customIDocument),
  ) as IDocument[];

  const setAllFileSelected = () => {
    const filesIds = filteredFiles?.map(({ id }) => id);
    event.selectedFiles.length === filteredFiles?.length
      ? updateEvent({ selectedFiles: [] })
      : updateEvent({ selectedFiles: filesIds as number[] });
  };

  const setFileSelected = (idx: number) => {
    const hasSelectedFileId = event.selectedFiles?.some((id: number) => id === idx);
    hasSelectedFileId
      ? updateEvent({ selectedFiles: event.selectedFiles.filter((id: number) => id !== idx) })
      : updateEvent({ selectedFiles: [...event.selectedFiles, idx] });
  };

  const handleDownloadSelectedFiles = async () => {
    try {
      if (event.selectedFiles.length) {
        // TODO: find better solution with query
        setIsLoading(true);
        const response = await api.get(
          `${apiRoutes.documents}/batch/download?${`&documents[]=${event.selectedFiles.join('&documents[]=')}`}`,
          { responseType: 'blob' },
        );
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(new Blob([response.data]));
        a.href = url;
        a.download = 'Files.zip';
        document.body.append(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      throw new Error((e as HttpErrorResponse).message);
    }
  };

  useEffect(() => {
    if (openDialog) {
      const getImports = async () => {
        const {
          PNGRenderer,
          JPGRenderer,
          DocViewerRenderers,
          default: DocViewer,
          PDFRenderer,
        } = await import('@cyntler/react-doc-viewer');

        return { PNGRenderer, JPGRenderer, PDFRenderer, DocViewerRenderers, DocViewer };
      };

      getImports()
        .then(({ PNGRenderer, JPGRenderer, DocViewerRenderers, DocViewer, PDFRenderer }) =>
          setLazyViewer(
            <DocViewer
              prefetchMethod="GET"
              pluginRenderers={[...DocViewerRenderers, PDFRenderer, PNGRenderer, JPGRenderer]}
              documents={documents}
              initialActiveDocument={documents[startFile.current]}
              className={styles.docViewer}
              config={{
                header: {
                  overrideComponent: MyHeader,
                },
              }}
            />,
          ),
        )
        .catch(console.error);
    }
    return () => {
      setLazyViewer(null);
    };
  }, [openDialog]);

  return (
    <>
      {isLoading && (
        <div className={styles.progressBar}>
          <LinearProgress />
        </div>
      )}
      <div className={styles.wrapperMain}>
        <div className={dragActive ? styles.activeWrapper : styles.wrapper} onClick={(e) => e.stopPropagation()}>
          <div className={styles.formWrapper}>
            <form onDragEnter={handleDrag} onDragLeave={handleDragLeave} onDragOver={handleDrag} onDrop={handleDrop}>
              <label className={clsx('drag-drop-area', styles.dragDropArea)} htmlFor="file-upload">
                <UploadFile className={styles.img} />
                <p className={styles.greyText}>Drag & drop files here or</p>
                <p className={styles.textBlue}>Browse</p>
              </label>
              <input type="file" id="file-upload" className="file-input" multiple onChange={handleClickChange} />
            </form>
          </div>
        </div>

        <div>
          {!isTableView && (
            <>
              <div className="flex align-items-center justify-content-between mt-16">
                {!!filteredFiles?.length && (
                  <div className="flex align-items-center">
                    <Checkbox
                      checked={event.selectedFiles.length === filteredFiles?.length}
                      color="primary"
                      onChange={setAllFileSelected}
                    />
                    <p className="uppercase text-14"> Select all files</p>
                  </div>
                )}
                {!!event.selectedFiles.length && (
                  <DownloadIcon className={styles.downloadAllBtn} onClick={handleDownloadSelectedFiles} />
                )}
              </div>
              <div className="flex flex-wrap mt-24">
                {filteredFiles?.map((doc: Document, i: number) => (
                  <PreviewCard
                    id={doc.uuid}
                    onDownload={() => handleDownloadFile(doc.file as File)}
                    onClick={() => handleOpenFile(doc?.file as File, i)}
                    addToDownload={() => setFileSelected(doc.id as number)}
                    key={doc.uuid}
                    name={doc.name}
                    imgPath={doc.file?.thumbPath}
                    isSelected={event.selectedFiles.includes(doc.id as number)}
                  />
                ))}
                {!filteredFiles?.length && <EmptyState model="files" onClick={handleClear} />}
              </div>
            </>
          )}

          {isTableView && (
            <Suspense fallback={<div>Loading...</div>}>
              {filteredFiles?.length ? (
                <TableView
                  filteredFiles={filteredFiles as Document[]}
                  handleDownloadFile={handleDownloadFile}
                  handleOpenFile={handleOpenFile}
                  handleDownloadSelectedFiles={handleDownloadSelectedFiles}
                  setAllFileSelected={setAllFileSelected}
                  setFileSelected={setFileSelected}
                  event={event}
                  refetch={refetch}
                />
              ) : (
                <EmptyState model="files" onClick={handleClear} />
              )}
            </Suspense>
          )}
        </div>
      </div>

      <Dialog fullWidth maxWidth={'sm'} open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          <CloseIcon className={styles.closeIcon} onClick={() => setOpenDialog(false)} />
        </DialogTitle>
        {lazyViewer ? lazyViewer : <></>}
      </Dialog>
    </>
  );
};
