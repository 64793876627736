import { Route } from 'react-router-hoc';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { LoginForm } from 'components';
import { HttpErrorResponse, LoginFormValues } from 'types';
import { useAuth } from 'contexts';
import { links } from 'App';

export const Login = () => {
  const { login } = useAuth();
  const history = useHistory();

  const handleSubmit = async (values: LoginFormValues) => {
    const { email, rememberMe } = values;

    try {
      const { authCode } = await login(values);

      history.push(authCode ? links.VerificationCode({ email, rememberMe }) : links.WorkOrders());
    } catch (e) {
      toast.error((e as HttpErrorResponse).message);
    }
  };

  return <LoginForm onSubmit={handleSubmit} />;
};

export default Route('/login')(Login);
