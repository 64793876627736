import { Suspense } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CustomDialog, ReportRichEditor } from 'components';
import ClipboardButton from 'components/common/ClipboardButton/ClipboardButton';
import { ArchiveIcon } from 'icons';
import { FormControl, InputAdornment } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import { getRadioOptions } from 'utils';
import { QuestionType } from 'pages/ReportWriting/schemas/types';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@material-ui/pickers';
import AutoComplete from '@material-ui/lab/Autocomplete';

import { LookBackModalProps } from './types';

export const LookBackModal = ({ open, isLoading, data, question, addRef, onClose }: LookBackModalProps) => {
  const { type, isAmount, isPercentage } = question || {};

  return (
    <CustomDialog icon={<ArchiveIcon />} header="Look back to the previous reports" {...{ onClose, open }}>
      <Suspense fallback={<span>Loading...</span>}>
        {isLoading ? (
          <CircularProgress size={64} />
        ) : (
          <>
            {data?.map(({ projectName, data, reportName }, index) => {
              const isRichEditor = type === QuestionType.RichEditor;
              const isPlainQuestion = type === QuestionType.PlainQuestion;
              const isSelectDate = type === QuestionType.SelectDate;
              const isSelect = type === QuestionType.Select;
              let question = (
                <FormControl component="fieldset" className="mb-16">
                  <RadioGroup aria-label="radio-select" name="radio-select" value={data}>
                    {getRadioOptions(type)}
                  </RadioGroup>
                </FormControl>
              );

              if (isRichEditor) {
                question = (
                  <ReportRichEditor
                    addRef={addRef}
                    value={data ?? ''}
                    onChange={() => {}}
                    isObservation={true}
                    disabled
                  />
                );
              }
              if (isPlainQuestion) {
                question = (
                  <TextField
                    value={data}
                    disabled
                    InputProps={{
                      startAdornment:
                        (isAmount && (
                          <InputAdornment position="start" className="pl-8">
                            $
                          </InputAdornment>
                        )) ||
                        (isPercentage && (
                          <InputAdornment position="start" className="pl-8">
                            %
                          </InputAdornment>
                        )),
                    }}
                  />
                );
              }
              if (isSelect) {
                question = (
                  <AutoComplete
                    value={data}
                    options={[]}
                    disabled
                    renderInput={(params) => <TextField {...params} label="Combo box" variant="outlined" />}
                  />
                );
              }
              if (isSelectDate) {
                question = (
                  <DatePicker
                    onChange={() => {}}
                    format={'MM/dd/yyyy'}
                    value={data || null}
                    placeholder={'MM/dd/yyyy'}
                    disabled
                  />
                );
              }

              return (
                <div key={index} className="mb-16 mx-16">
                  <div className="flex w-100 justify-content-between align-center mb-12">
                    <p>
                      <span style={{ fontWeight: 600 }}>{reportName}</span> {projectName}
                    </p>

                    {(isRichEditor || isPlainQuestion) && <ClipboardButton text={data ?? ''} />}
                  </div>
                  {question}
                </div>
              );
            })}

            {!data?.length && (
              <p className="mb-16 mx-16">
                No reports have been submitted yet so there’s nothing to show at the moment.{' '}
              </p>
            )}
          </>
        )}
      </Suspense>
    </CustomDialog>
  );
};
