import { IconProps } from 'types';

export const EyeIconClosed = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="21"
      height="16"
      viewBox="0 0 21 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 7.49657C19.7 7.49657 19.5 7.39657 19.3 7.19657L16.5 4.39657C16.1 3.99657 16.1 3.39657 16.5 2.99657C16.9 2.59657 17.5 2.59657 17.9 2.99657L20.7 5.79657C21.1 6.19657 21.1 6.79657 20.7 7.19657C20.5 7.39657 20.3 7.49657 20 7.49657Z"
        fill="#565EA1"
      />
      <path
        d="M14 9.89657C13.6 9.89657 13.2 9.59658 13 9.19658L12 5.29657C11.9 4.79657 12.2 4.19657 12.7 4.09657C13.2 3.99657 13.8 4.29657 13.9 4.79657L14.9 8.69657C15 9.19657 14.7 9.79657 14.2 9.89657C14.2 9.89657 14.1 9.89657 14 9.89657Z"
        fill="#565EA1"
      />
      <path
        d="M1 7.49657C0.7 7.49657 0.5 7.39657 0.3 7.19657C-0.1 6.79657 -0.1 6.19657 0.3 5.79657L3.1 2.99657C3.5 2.59657 4.1 2.59657 4.5 2.99657C4.9 3.39657 4.9 3.99657 4.5 4.39657L1.7 7.19657C1.5 7.39657 1.3 7.49657 1 7.49657Z"
        fill="#565EA1"
      />
      <path
        d="M7 9.89657C6.9 9.89657 6.8 9.89657 6.7 9.89657C6.2 9.79657 5.8 9.19657 6 8.69657L7 4.79657C7.1 4.29657 7.7 3.89657 8.2 4.09657C8.7 4.19657 9.1 4.79657 8.9 5.29657L7.9 9.19658C7.8 9.59658 7.4 9.89657 7 9.89657Z"
        fill="#565EA1"
      />
      <path
        d="M10.5 5.99657C7.3 5.99657 4.3 5.09657 2.3 3.49657C1.5 2.89657 1 2.19657 0.6 1.49657C0.4 0.996575 0.5 0.396575 1 0.0965745C1.5 -0.103425 2.1 -0.00342539 2.4 0.496575C2.6 0.996575 3 1.49657 3.5 1.89657C5.1 3.19657 7.7 3.99657 10.5 3.99657C13.3 3.99657 15.9 3.19657 17.5 1.89657C18 1.49657 18.4 0.996575 18.7 0.496575C18.9 -0.00342539 19.5 -0.103425 20 0.0965745C20.5 0.396575 20.6 0.996575 20.4 1.49657C20 2.19657 19.5 2.89657 18.7 3.49657C16.7 5.09657 13.7 5.99657 10.5 5.99657Z"
        fill="#565EA1"
      />
    </svg>
  );
};
