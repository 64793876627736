import { TextField } from 'formik-material-ui';
import { CustomDatePicker, Autocomplete } from 'components/common';
import { Field } from 'formik';
import { amountFieldProp } from 'consts';

import { YesNoNaOptions, defaultFieldProps } from '../const';

export const TotalProjectBudgetFields = () => (
  <>
    <Field component={CustomDatePicker} name="date" label="Date" removeTimezone {...defaultFieldProps} />
    <Field component={TextField} name="totalBudget" label="Total Budget" {...amountFieldProp} {...defaultFieldProps} />
    <Field component={TextField} name="hardCosts" label="Hard Costs" {...amountFieldProp} {...defaultFieldProps} />
    <Field component={TextField} name="softCosts" label="Soft Costs" {...amountFieldProp} {...defaultFieldProps} />
    <Field
      component={Autocomplete}
      name="contingencyIncluded"
      placeholder="Is a contingnecy included in the budget?"
      options={YesNoNaOptions}
      {...defaultFieldProps}
    />
    <Field
      component={TextField}
      name="totalContingency"
      label="Total Contingency"
      {...amountFieldProp}
      {...defaultFieldProps}
    />
  </>
);
