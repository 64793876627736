import React, { lazy, useState } from 'react';
import { NavLink } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';

import { toEnUsFormat } from 'utils';
import { DeleteIcon } from 'icons';
import { CustomDialog } from 'components';
import { RouteProps, PermissionEnum } from 'types';
import { components } from 'generated/types';
import { useAuth } from 'contexts';
const ConfirmationModal = lazy(() => import('components/common/ConfirmationModal'));

import styles from './NoteItem.module.scss';

type User = components['schemas']['User'];

type NotePartProps = {
  noteId?: number;
  title?: string;
  createdAt?: string;
  updatedAt?: string;
  text?: string;
  projectName?: string;
  user?: User;
  handleDelete?: () => void;
};

export const NoteItem = ({
  route,
  title,
  projectName,
  text,
  createdAt,
  user,
  handleDelete,
}: RouteProps & NotePartProps) => {
  const { userData } = useAuth();

  const [noteConfirmationModal, setNoteConfirmationModal] = useState(false);

  const handleConfirmationModalOpen = () => setNoteConfirmationModal(true);
  const handleConfirmationModalClose = () => setNoteConfirmationModal(false);

  return (
    <>
      {route ? (
        <div className={styles.noteOverviewContainer}>
          <div className="flex justify-content-between">
            <h2 className={styles.projectInfo}>-{projectName}</h2>
            <span className={styles.projectInfo}>{toEnUsFormat(createdAt)}</span>
          </div>
          <NavLink to={route}>
            <h1 className={styles.title}>{title}</h1>
          </NavLink>
          <div className={styles.textContainer}>
            <div className={clsx('mt-8', styles.noteDescription)} dangerouslySetInnerHTML={{ __html: text ?? '' }} />
            {(userData?.permissions.has(PermissionEnum.note_delete_all) || userData?.id === user?.id) && (
              <IconButton onClick={handleConfirmationModalOpen} className={styles.deleteButton}>
                <DeleteIcon className={styles.imgBucket} />
              </IconButton>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.noteOverviewContainer}>
          <div className="flex justify-content-between">
            <h2 className={styles.projectInfo}>-{projectName}</h2>
            <span className={styles.projectInfo}>{toEnUsFormat(createdAt)}</span>
          </div>
          <h1 className={styles.title}>{title}</h1>
          <div className={styles.textContainer}>
            <div className="mt-8" dangerouslySetInnerHTML={{ __html: text ?? '' }} />
            {(userData?.permissions.has(PermissionEnum.note_delete_all) || userData?.id === user?.id) && (
              <IconButton onClick={handleConfirmationModalOpen} className={styles.deleteButton}>
                <DeleteIcon className={styles.imgBucket} />
              </IconButton>
            )}
          </div>
        </div>
      )}

      <CustomDialog
        open={noteConfirmationModal}
        icon={<DeleteIcon />}
        header="Delete this note?"
        onClose={handleConfirmationModalClose}
      >
        <ConfirmationModal
          text="You won’t be able to restore it"
          onClose={handleConfirmationModalClose}
          onSubmit={handleDelete ? handleDelete : () => {}} // TODO: replace with good function
        />
      </CustomDialog>
    </>
  );
};
