import { Questions } from 'pages/ReportWriting/schemas/types';

export const getAdditionalQuestionsWithoutAnswers = (state: Questions[]) => {
  const questionsIds = ['39', '146'];
  const questionsWithoutAnswers: string[] = [];

  questionsIds.forEach((ids) => {
    const question = state.find(({ id }) => id === ids);
    if (!question?.answer) {
      questionsWithoutAnswers.push(ids);
    }
  });
  return questionsWithoutAnswers;
};
