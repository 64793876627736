import { IconProps } from 'types';

export const NoteIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.25 17.25H1.75C1.3375 17.25 0.9625 17.1 0.7 16.8C0.4 16.5375 0.25 16.1625 0.25 15.75V2.25C0.25 1.8375 0.4 1.4625 0.7 1.2C0.9625 0.9 1.3375 0.75 1.75 0.75H9.25C9.4375 0.75 9.625 0.825 9.775 0.975L13.525 4.725C13.675 4.875 13.75 5.0625 13.75 5.25V15.75C13.75 16.1625 13.6 16.5375 13.3 16.8C13 17.0625 12.6625 17.25 12.25 17.25ZM1.75 2.25V15.75H12.25V5.55L8.95 2.25H1.75ZM10.75 7.5C10.75 7.0875 10.4125 6.75 10 6.75H4C3.5875 6.75 3.25 7.0875 3.25 7.5C3.25 7.9125 3.5875 8.25 4 8.25H10C10.4125 8.25 10.75 7.9125 10.75 7.5ZM10.75 10.5C10.75 10.0875 10.4125 9.75 10 9.75H4C3.5875 9.75 3.25 10.0875 3.25 10.5C3.25 10.9125 3.5875 11.25 4 11.25H10C10.4125 11.25 10.75 10.9125 10.75 10.5Z"
        fill="#212B83"
      />
    </svg>
  );
};
