import { useSortBy, useTable } from 'react-table';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import clsx from 'clsx';

import { Heading, Body, EmptyState } from './parts';
import { tableIdForTopScrolling } from 'consts';
import { ScrollTop, ScrollTopV2 } from '../ScrollTop';

import styles from './Table.module.scss';
import { TableProps, TableV2Props } from './types';
import { Pagination } from './parts/Pagination';

export const Table = ({
  data,
  columns,
  onClick,
  loading,
  hasError,
  model,
  scrollBlockClassName,
  isPageWithMap,
  scrollIconClassName,
  scrollTopValue,
  isScrollTop = true,
  customEmptyState,
  pagination,
  paginationClassName,
  showAllToggle,
  onToggleShowAll,
  showAll,
}: TableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      disableSortBy: true,
      initialState: {
        pageSize: data && data.length,
        hiddenColumns: columns.filter(({ show }) => show === false).map(({ accessor }) => accessor) as string[],
      },
    },
    useSortBy,
  );

  if (loading) return <CircularProgress size={64} />;

  return (
    <>
      {data.length && !hasError ? (
        <div className={clsx(styles.table, 'p-relative w-100')} id={tableIdForTopScrolling}>
          {isScrollTop && (
            <ScrollTop
              {...{ scrollBlockClassName, scrollTopValue }}
              className={isPageWithMap ? clsx(styles.scrollTopIcon, scrollIconClassName) : scrollIconClassName}
            />
          )}

          <div className={styles.paginationContainer}>
            {showAllToggle && (
              <FormControlLabel
                control={<Switch checked={showAll} onChange={onToggleShowAll} name="showAll" color="primary" />}
                label="View all"
              />
            )}
            {pagination && (
              <div className={styles.paginationWrapper}>
                <Pagination
                  perPage={pagination?.perPage}
                  count={pagination?.count}
                  total={pagination?.total}
                  handlePageChange={pagination?.handlePageChange}
                  pagesTotal={pagination?.pagesTotal}
                  currentPage={pagination?.currentPage}
                  className={paginationClassName}
                  tableName={pagination?.tableName}
                />
              </div>
            )}
          </div>

          <table {...getTableProps()} className="w-100">
            <Heading headerGroups={headerGroups} />
            <Body getTableBodyProps={getTableBodyProps} rows={rows} prepareRow={prepareRow} />
          </table>
        </div>
      ) : (
        <>{customEmptyState || <EmptyState onClick={onClick} model={model ?? ''} />}</>
      )}
    </>
  );
};

export const TableV2 = ({
  data,
  columns,
  handleClearFilters,
  loading,
  hasError,
  model,
  scrollClassName,
  isScrollTop = true,
  customEmptyState,
  pagination,
  paginationClassName,
  className,
  emptyStateTitle,
}: TableV2Props) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      disableSortBy: true,
      initialState: {
        pageSize: data && data.length,
        hiddenColumns: columns.filter(({ show }) => show === false).map(({ accessor }) => accessor) as string[],
      },
    },
    useSortBy,
  );

  if (loading) {
    return (
      <div className="p-relative d-none d-md-block">
        <CircularProgress className="mt-20" size={64} />
      </div>
    );
  }

  return (
    <>
      {pagination && (
        <Pagination
          perPage={pagination?.perPage}
          count={pagination?.count}
          total={pagination?.total}
          handlePageChange={pagination?.handlePageChange}
          pagesTotal={pagination?.pagesTotal}
          tableName={pagination?.tableName}
          currentPage={pagination?.currentPage}
          className={paginationClassName}
        />
      )}
      {data.length && !hasError ? (
        <div
          className={clsx(
            styles.table,
            'd-none d-md-block w-100 pl-16 pl-md-32 pt-16 pt-md-0 mb-20 overflow-auto',
            className,
          )}
        >
          {isScrollTop && <ScrollTopV2 className={scrollClassName} />}

          <table {...getTableProps()} className="w-100">
            <Heading {...{ headerGroups }} />
            <Body {...{ getTableBodyProps, rows, prepareRow }} />
          </table>
        </div>
      ) : (
        <div className="d-none d-md-block">
          {customEmptyState || <EmptyState onClick={handleClearFilters} title={emptyStateTitle} model={model ?? ''} />}
        </div>
      )}
    </>
  );
};
