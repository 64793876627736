import { IconProps } from 'types';

export const ChangeCategoryIcon = ({ className, onClick }: IconProps) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.75 11.625H14.25V1.875" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.25 7.875H3.75V16.125" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.5 4.125L14.25 1.875L12 4.125" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 13.875L3.75 16.125L1.5 13.875" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
