import { useMemo } from 'react';
import { formatDateToYYYYMMDD } from 'utils';
import { getTableFieldsByCategoryName } from './utils';
import { dataFields, textFields } from './const';
import { CategoryNames } from './types';

export const useDocumentCategoryForm = (categoryName: string, values: { [key: string]: unknown }) => {
  const tableFields = useMemo(() => getTableFieldsByCategoryName(categoryName), [categoryName]);

  const initialValues = useMemo(() => {
    return Object.keys(values).reduce((acc, curr) => {
      if (textFields.includes(curr as string) || dataFields.includes(curr as string)) {
        if (curr === 'scopeOfWork' && categoryName !== CategoryNames.DesignAgreements) {
          acc[curr] = { name: values[curr] };
          return acc;
        }

        acc[curr] = values[curr];
        return acc;
      } else acc[curr] = { name: values[curr] };

      return acc;
    }, {} as { [key: string]: unknown });
  }, [values]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getTransformedValues = (values: { [key: string]: any }) => {
    const customFields = Object.keys(values).reduce((acc, curr) => {
      if (textFields.includes(curr as string)) {
        if (curr === 'scopeOfWork' && categoryName !== CategoryNames.DesignAgreements) {
          acc[curr] = values[curr]?.name;
          return acc;
        }

        acc[curr] = values[curr];
        return acc;
      }
      if (dataFields.includes(curr as string)) {
        acc[curr] = values[curr] ? formatDateToYYYYMMDD(values[curr]) : null;
        return acc;
      } else {
        acc[curr] = values[curr]?.name;
      }

      return acc;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, {} as { [key: string]: any });

    return {
      customFields,
      additionalInformation: values['additionalInformation'] as string,
    };
  };

  return { tableFields, initialValues, getTransformedValues };
};
