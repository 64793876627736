import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const ReportWritingRoute = Route(
  {
    id: Route.params.number.optional,
    section: Route.query.string,
    view: Route.query.oneOf('orderForDrafter', 'orderForInspector', 'orderForAll'),
  },
  ({ id }) => `/report-writing/${id}`,
);

export const ReportWriting = ReportWritingRoute(lazy(() => import('./ReportWriting')));
