import { Chip } from '@material-ui/core';

type FilterStatusesTabProps = {
  statuses?: string[];
  activeStatus?: string;
  onChange: (value: string) => void;
};

export const FilterStatusesTab = ({ activeStatus, onChange, statuses }: FilterStatusesTabProps) => {
  return (
    <div className="d-none d-sm-flex mr-12 align-items-center mb-12">
      <p className="text-smallTextGrey align-items-center mb-12 mr-16 ">Show: </p>
      <div className="flex gap-12 mb-12 flex-wrap">
        {statuses?.map((status: string) => (
          <Chip
            key={status}
            {...(status !== activeStatus && { variant: 'outlined' })}
            onClick={() => onChange(status)}
            color="primary"
            className="capitalize"
            label={status}
          />
        ))}
      </div>
    </div>
  );
};
