import { TextField } from 'formik-material-ui';
import { Field } from 'formik';

import {
  YesNoPartiallyNaOptions,
  defaultFieldProps,
  constructionAgreementContractTypeOptions,
  constructionAgreementExecutedOptions,
  constructionAgreementPaymantTypeOptions,
  constructionAgreementRetainangeOptions,
  constructionAgreementScopeOfWorkOptions,
} from '../const';
import { CustomDatePicker, Autocomplete } from 'components/common';
import { amountFieldProp } from 'consts';

export const ConstructionAgreementFields = () => (
  <>
    <Field
      component={Autocomplete}
      placeholder="What type of Contract is used?"
      name="typeOfContract"
      options={constructionAgreementContractTypeOptions}
      {...defaultFieldProps}
    />
    <Field
      component={CustomDatePicker}
      name="dateOfAgreement"
      label="Date of Agreement"
      removeTimezone
      {...defaultFieldProps}
    />
    <Field component={TextField} name="projectName" label="Project Name" {...defaultFieldProps} />
    <Field
      component={Autocomplete}
      placeholder="What is the scope of work shown in the agreement?"
      name="scopeOfWork"
      options={constructionAgreementScopeOfWorkOptions}
      {...defaultFieldProps}
    />
    <Field
      component={TextField}
      label="Who is the Owner listed in the agreement? "
      name="ownerAgreement"
      {...defaultFieldProps}
    />
    <Field
      component={TextField}
      label="Who is the Contractor listed in the agreement?"
      name="contractorAgreement"
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      placeholder="Was the agreement executed by both parties?"
      name="executedParties"
      options={constructionAgreementExecutedOptions}
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      placeholder="What type of Payment is to be used?"
      name="typeOfPayment"
      options={constructionAgreementPaymantTypeOptions}
      {...defaultFieldProps}
    />
    <Field
      component={TextField}
      name="contractSum"
      label="Contract Sum / Guarnateed Maximum Price"
      {...amountFieldProp}
      {...defaultFieldProps}
    />
    <Field
      component={CustomDatePicker}
      name="dateOfCommencement"
      label="Date of Commencement"
      removeTimezone
      {...defaultFieldProps}
    />
    <Field
      component={CustomDatePicker}
      name="dateOfSubstantialCompletion"
      label="Date of Substantial Completion"
      removeTimezone
      {...defaultFieldProps}
    />
    <Field
      component={TextField}
      label="What allowances are shown in the agreement? "
      name="allowancesAgreement"
      {...defaultFieldProps}
    />
    <Field
      component={TextField}
      label="What liquidated damages and/or completion bonus are shown in the agreement?"
      name="liquidatedDamages"
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      placeholder="What retainange is to be whithheld?"
      name="retainWithheld"
      options={constructionAgreementRetainangeOptions}
      {...defaultFieldProps}
    />
    <Field
      component={TextField}
      label="What items are to be excluded from retainage?"
      name="excludedFromRetain"
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      placeholder="Are the project drawings enumerated?"
      name="projectEnumerated"
      options={YesNoPartiallyNaOptions}
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      placeholder="Do the drawings enumerated in the contract match those provided for review?"
      name="contractMatch"
      options={YesNoPartiallyNaOptions}
      {...defaultFieldProps}
    />
  </>
);
