import { links } from 'App';
import { MobileTableRow } from 'components';
import { ProposalRequestsMobileTableProps } from './types';
import { MobileTableV2, MobileTableRowField } from 'components/common/MobileTableV2';
import { Proposal, WorkOrderTypeEnum } from 'types';
import { formatDateToMMDDYYYY, getFullName } from 'utils';
import { Fragment } from 'react';
import { useMobileTable } from 'hooks/useMobileTable';
import formatNumberWithCommas from 'utils/formatNumberWithCommas';

export const ProposalRequestsMobileTable = ({ items, actionHandlers }: ProposalRequestsMobileTableProps) => {
  const { handleEditProposalRequest, handleDeleteProposalRequest } = actionHandlers;
  const { selectedRow, toggleSelectedRow } = useMobileTable();

  return (
    <MobileTableV2 hasData={items.length > 0} model="proposal requests" isScrollTop={false}>
      {items.map((proposalRequest: Proposal) => {
        const {
          name,
          id,
          ltv,
          bankContacts,
          bank,
          receivedDate,
          sentDate,
          awardDate,
          expectedDateOfFirstInspection,
          services,
        } = proposalRequest;

        const contacts = (
          <>
            {bankContacts?.map((contact, index) => (
              <Fragment key={contact?.id}>
                <p>{getFullName(contact)}</p>
                {bankContacts.length !== index + 1 && <br />}
              </Fragment>
            ))}
          </>
        );
        const prePrice = services?.find(({ name }) => name === WorkOrderTypeEnum.PreConstructionReview)?.price;
        const inspectionPrice = services?.find(({ name }) => name === WorkOrderTypeEnum.InspectionReport)?.price;

        const formattedPrePrice = prePrice && `$${formatNumberWithCommas(prePrice)}`;
        const formattedInspectionPrice = inspectionPrice && `$${formatNumberWithCommas(inspectionPrice)}`;
        const formattedLtv = ltv && `$${formatNumberWithCommas(ltv)}`;

        const formattedReceivedDate = formatDateToMMDDYYYY(receivedDate);
        const formattedSentDate = formatDateToMMDDYYYY(sentDate);
        const formattedAwardDate = formatDateToMMDDYYYY(awardDate);
        const formattedExpectedDateOfFirstInspection = formatDateToMMDDYYYY(expectedDateOfFirstInspection);

        return (
          <MobileTableRow
            key={id}
            id={id}
            rowTitle={name}
            link={links.ProposalRequestOverview({ id })}
            selectedRowInfo={selectedRow}
            handleToggle={toggleSelectedRow}
            handleEdit={handleEditProposalRequest}
            handleDelete={handleDeleteProposalRequest}
          >
            <MobileTableRowField label="Name" value={name} />
            <MobileTableRowField label="Client(Bank)" value={bank?.name} />
            <MobileTableRowField label="Bank Contacts" value={contacts} />
            <MobileTableRowField label="Pre Price" value={formattedPrePrice} />
            <MobileTableRowField label="Inspection Price" value={formattedInspectionPrice} />
            <MobileTableRowField label="LTV" value={formattedLtv} />
            <MobileTableRowField label="Rfp Recieved" value={formattedReceivedDate} />
            <MobileTableRowField label="Proposal Sent" value={formattedSentDate} />
            <MobileTableRowField label="Awarded" value={formattedAwardDate} />
            <MobileTableRowField label="First Inspection" value={formattedExpectedDateOfFirstInspection} />
          </MobileTableRow>
        );
      })}
    </MobileTableV2>
  );
};
