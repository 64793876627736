import { RefObject } from 'react';
import { Editor as TinyMCEEditor } from 'tinymce';

export const useMultiRefs = (): [() => Array<RefObject<TinyMCEEditor>>, (ref: RefObject<TinyMCEEditor>) => void] => {
  const arrayOfRefs: Array<RefObject<TinyMCEEditor>> = [];
  const refs = new Set(arrayOfRefs);

  const getRefs = () => {
    return Array.from(refs);
  };

  const addRef = (ref: RefObject<TinyMCEEditor>) => {
    if (ref !== null) refs.add(ref);
  };

  return [getRefs, addRef];
};
