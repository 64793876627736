import React, { FC, lazy, Suspense, useMemo, useRef, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import { DocumentFormValues, RequestDocumentFormValues, TableCell } from 'types';
import { formatAMPM, getFullName } from 'utils';
import { components } from 'generated/types';
import {
  ActiveStarIcon,
  ArrowDownIcon,
  DeleteIcon,
  DocImg,
  DocumentIcon,
  DownloadIcon,
  EditIcon,
  JpgImg,
  PdfImg,
  StarIcon,
  XlsImg,
} from 'icons';
import { CustomDialog, DocumentForm, EmptyState, Table } from 'components';
import { api, apiRoutes } from 'api';
import { useFormModal } from 'hooks/useFormModal';
import UnsavedChangesModal from 'components/common/UnsavedChangesModal';

const ConfirmationModal = lazy(() => import('components/common/ConfirmationModal'));

import styles from './TableView.module.scss';

type Document = components['schemas']['Document'];
type FileInterface = components['schemas']['File'];

type TableViewProps = {
  filteredFiles: Document[];
  handleDownloadFile: (file: FileInterface) => Promise<void>;
  handleOpenFile: (file: FileInterface, fileIndex: number) => void;
  refetch: () => void;
  handleDownloadSelectedFiles: () => Promise<void>;
  setAllFileSelected: () => void;
  setFileSelected: (id: number) => void;
  event: {
    selectedFiles: number[];
  };
};

export const TableView: FC<TableViewProps> = ({
  filteredFiles,
  handleDownloadFile,
  handleOpenFile,
  refetch,
  handleDownloadSelectedFiles,
  setAllFileSelected,
  setFileSelected,
  event,
}) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const {
    isFormModalOpen,
    isUnsavedChangesModalOpen,
    handleCloseAllModals,
    handleCloseUnsavedChangesModal,
    handleOpenFormModal,
    handleCloseFormModal,
  } = useFormModal();

  const [clicked, setClicked] = useState<number | null>();

  const documentToEdit = useRef<Document>();
  const fileToDelete = useRef<number>();

  const documentEditMutation = (values: RequestDocumentFormValues) =>
    api.put(`${apiRoutes.documents}/${documentToEdit.current?.id}`, values).then((res) => res);

  const { mutateAsync: documentEdit } = useMutation(
    'documentEditMutation',
    (values: RequestDocumentFormValues) => documentEditMutation(values),
    {
      onSuccess: () => {
        toast.success('File has been successfully updated!');
        refetch();
        handleCloseFormModal();
      },
    },
  );

  const deleteFileMutation = (id: number) => api.delete(`${apiRoutes.documents}/${id}`).then((res) => res);

  const { mutate: deleteFileMutate } = useMutation('deleteFileMutation', (id: number) => deleteFileMutation(id), {
    onSuccess: () => {
      toast.success('File has been successfully deleted!');
      handleCloseFileDelete();
      refetch();
    },
  });

  const makeFavoriteQuery = (document: Document) =>
    api.put(`${apiRoutes.documents}/${document.id}/favorite`).then((res) => res.data);

  const { mutateAsync: makeFavorite } = useMutation(
    'makeFavoriteQuery',
    (document: Document) => makeFavoriteQuery(document),
    {
      onSuccess: () => {
        toast.success('File has been successfully updated!');
        refetch();
      },
    },
  );

  const handleOpenFileDelete = () => setOpenDeleteDialog(true);
  const handleOpenFileEdit = () => handleOpenFormModal();

  const handleCloseFileDelete = () => setOpenDeleteDialog(false);

  const handleDocumentToEditOpen = (document: Document) => {
    documentToEdit.current = document;
    handleOpenFileEdit();
  };

  const handleDeleteFile = (id: number) => {
    fileToDelete.current = id;
    handleOpenFileDelete();
  };

  const handleSubmit = async (values: DocumentFormValues) => {
    await documentEdit({
      ...values,
      documentCategories: values.documentCategories.map(({ id }) => id as number),
    });
  };

  const handleDelete = () => deleteFileMutate(fileToDelete.current as number);

  const handleMakeFavorite = async (document: Document) => {
    await makeFavorite(document);
  };

  const toggle = (index: number | undefined) => {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  const getFileIcon = (file?: string) => {
    if (file === 'pdf') return <PdfImg />;
    else if (file === 'ocx') return <DocImg />;
    else if (file === 'jpg') return <JpgImg />;
    else if (file === 'lsx') return <XlsImg />;
  };

  const columns = useMemo(
    () => [
      {
        id: 'selectFile',
        Header: () => (
          <Checkbox
            checked={event.selectedFiles.length === filteredFiles?.length}
            color="primary"
            onChange={setAllFileSelected}
          />
        ),
        maxWidth: 20,
        Cell: function selectFile({
          row: {
            original: { id },
          },
        }: TableCell<Document>) {
          return (
            <Checkbox
              checked={event.selectedFiles.some((idx: number) => id === idx)}
              color="primary"
              className={styles.checkbox}
              onChange={() => setFileSelected(id as number)}
            />
          );
        },
      },
      {
        id: 'name',
        Header: 'file name',
        minWidth: 360,
        maxWidth: 300,
        Cell: function name({
          row: {
            original: { name, file },
            index,
          },
        }: TableCell<Document>) {
          return (
            <div onClick={() => handleOpenFile(file as File, index)} className={styles.fileName}>
              <span className={styles.fileIcon}>{getFileIcon(name?.substr(-3))}</span>
              <Tooltip title={name as string}>
                <p>{name}</p>
              </Tooltip>
            </div>
          );
        },
      },

      {
        id: 'dateUploaded',
        Header: 'Last Modified',
        maxWidth: 100,
        Cell: function dateUploaded({
          row: {
            original: { createdAt, createdBy },
          },
        }: TableCell<Document>) {
          return (
            <span>
              {getFullName(createdBy)}
              <br />
              {formatAMPM(createdAt)}
            </span>
          );
        },
      },
      {
        id: 'edit-document',
        maxWidth: 90,

        Header: <DownloadIcon className={styles.downloadAllBtn} onClick={handleDownloadSelectedFiles} />,
        Cell: function editDocument({ row: { original } }: TableCell<Document>) {
          return (
            <div className="flex">
              {original.favorite ? (
                <ActiveStarIcon className={styles.favIcon} onClick={() => handleMakeFavorite(original)} />
              ) : (
                <StarIcon className={styles.favIcon} onClick={() => handleMakeFavorite(original)} />
              )}

              <DownloadIcon
                className={styles.downloadBtn}
                onClick={() => handleDownloadFile(original?.file as FileInterface)}
              />
              <EditIcon className={styles.editIcon} onClick={() => handleDocumentToEditOpen(original)} />
              <DeleteIcon className={styles.editIcon} onClick={() => handleDeleteFile(original.id as number)} />
            </div>
          );
        },
      },
    ],

    [event.selectedFiles],
  );

  return (
    <>
      <div className="mt-12 w-100 d-none d-md-block">
        <Table data={filteredFiles} columns={columns} model="files" />
      </div>
      <div className="d-block d-md-none relative w-100">
        {filteredFiles?.map((doc: Document, i: number) => {
          const { id, name, createdBy, createdAt } = doc;
          return (
            <div key={id}>
              <div className={styles.smTableContainer}>
                <div className="flex align-items-center justify-content-between" onClick={() => toggle(id)}>
                  <div className="flex align-items-start">
                    <span className={styles.fileIcon}>{getFileIcon(name?.substr(-3))}</span>
                    {name && name?.length > 25 ? (
                      <span onClick={() => handleOpenFile(doc?.file as File, i)}>
                        {name?.substr(0, 25)}...{name?.substr(-4)}
                      </span>
                    ) : (
                      <span onClick={() => handleOpenFile(doc?.file as File, i)}>{name}</span>
                    )}
                  </div>

                  <ArrowDownIcon className={clsx(styles.ArrowDownIcon, clicked === id && styles.ArrowDownIconRotate)} />
                </div>
                {clicked === id && (
                  <div className={styles.smTableDropdownContainer}>
                    <div className="pt-16">
                      <span className="uppercase">Created by: </span>
                      {getFullName(createdBy)}
                    </div>
                    <div className="pt-16">
                      <span className="uppercase">Date and time uploaded: </span>
                      {formatAMPM(createdAt)}
                    </div>
                    <div className="flex align-items-center">
                      <div
                        className={clsx(
                          'pt-16 flex align-items-center flex-1 justify-content-center',
                          styles.smEditBtnContainer,
                        )}
                        onClick={() => handleMakeFavorite(doc)}
                      >
                        {doc.favorite ? (
                          <ActiveStarIcon className={styles.favIcon} />
                        ) : (
                          <StarIcon className={styles.favIcon} />
                        )}
                      </div>

                      <>
                        <div className={styles.verticalLine}></div>
                        <div
                          className={clsx(
                            'pt-16 flex align-items-center flex-1 justify-content-center',
                            styles.smEditBtnContainer,
                          )}
                          onClick={() => handleDownloadFile(doc?.file as File)}
                        >
                          <DownloadIcon className={styles.downloadBtn} />
                        </div>
                      </>
                      <>
                        <div className={styles.verticalLine}></div>
                        <div
                          className={clsx(
                            'pt-16 flex align-items-center flex-1 justify-content-center',
                            styles.smEditBtnContainer,
                          )}
                          onClick={() => handleDocumentToEditOpen(doc)}
                        >
                          <EditIcon className={styles.editIcon} />
                        </div>
                      </>
                      <>
                        <div className={styles.verticalLine}></div>
                        <div
                          className={clsx(
                            'pt-16 flex align-items-center flex-1 justify-content-center',
                            styles.smEditBtnContainer,
                          )}
                          onClick={() => handleDeleteFile(doc.id as number)}
                        >
                          <DeleteIcon className={styles.editIcon} />
                        </div>
                      </>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
        {!filteredFiles?.length && <EmptyState onClick={() => {}} model="files" />}
      </div>

      <CustomDialog
        open={isFormModalOpen as boolean}
        icon={<DocumentIcon className={styles.documentIcon} />}
        header="Edit document"
        onClose={(_, reason) => handleCloseFormModal(reason)}
      >
        <DocumentForm
          document={documentToEdit.current as Document}
          onSubmit={handleSubmit}
          onClose={handleCloseFormModal}
        />
        <UnsavedChangesModal
          open={isUnsavedChangesModalOpen}
          onClose={handleCloseUnsavedChangesModal}
          closeAll={handleCloseAllModals}
        />
      </CustomDialog>

      <CustomDialog
        open={openDeleteDialog}
        icon={<DeleteIcon />}
        header="Delete this file?"
        onClose={handleCloseFileDelete}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <ConfirmationModal
            text="You won’t be able to restore it"
            onClose={handleCloseFileDelete}
            onSubmit={handleDelete}
          />
        </Suspense>
      </CustomDialog>
    </>
  );
};
