import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const ProjectDescriptionRoute = Route(
  {
    id: Route.params.number.optional,
  },
  ({ id }) => `/project/${id}/description`,
);

export const ProjectDescription = ProjectDescriptionRoute(lazy(() => import('./Description')));
