import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const ProductivityMetricsInspectionsRoute = Route(
  {
    page: Route.query.number,
    bank: Route.query.string,
    bankContact: Route.query.string,
    services: Route.query.string,
    assignee: Route.query.string,
    project: Route.query.string,
    projectStatus: Route.query.string,
    projectType: Route.query.string,
    state: Route.query.string,
    owner: Route.query.string,
    status: Route.query.string,

    estimatedInspectionDateStartFrom: Route.query.string,
    estimatedInspectionDateStartTo: Route.query.string,

    estimatedInspectionDateCompletionFrom: Route.query.string,
    estimatedInspectionDateCompletionTo: Route.query.string,

    dateRangeFrom: Route.query.string,
    dateRangeTo: Route.query.string,
  },
  '/productivity-metrics/inspections',
);

export const ProductivityMetricsInspections = ProductivityMetricsInspectionsRoute(lazy(() => import('./Inspections')));
