const isValidDateFormat = (input?: string) => {
  if (input) {
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    const dateFormatRegex2 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(\+|-)\d{2}:\d{2}$/;

    if (!dateFormatRegex.test(input) && !dateFormatRegex2.test(input)) {
      return false;
    }

    const parsedDate = Date.parse(input);
    return !isNaN(parsedDate);
  }
  return false;
};

export { isValidDateFormat };
