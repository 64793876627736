import { useState } from 'react';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import { isValid, parseISO } from 'date-fns';

import { DateTitle } from 'icons';
import { DateFilterEnum } from 'types';

import styles from './DatePickerContainer.module.scss';
import Button from '@material-ui/core/Button';

interface DatePickerContainerProps {
  title: string;
  className?: string;
  isRange?: boolean;
  isShowRange?: boolean;
  isShowYear?: boolean;
  isOneDay?: boolean;
  startDate: string | undefined;
  endDate: string | undefined;
  removeTimezone?: boolean;
  handleStartDateChange: (date: Date | null) => void;
  handleEndDateChange: (date: Date | null) => void;
  handleClose: () => void;
  applyFilter: (isRange: boolean) => void;
  isMonthYearPicker?: boolean;
}

const validateDate = (dateString: string): boolean => {
  try {
    const date = parseISO(dateString);
    return isValid(date);
  } catch {
    return false;
  }
};

export const DatePickerContainer = ({
  title,
  className,
  startDate,
  endDate,
  isRange,
  isOneDay = true,
  isShowRange = true,
  isShowYear = false,
  removeTimezone,
  handleStartDateChange,
  handleEndDateChange,
  handleClose,
  applyFilter,
  isMonthYearPicker = false,
}: DatePickerContainerProps) => {
  const [isDateFilterRange, setIsDateFilterRange] = useState(isRange);
  const [startDateError, setStartDateError] = useState<string | null>(null);
  const [endDateError, setEndDateError] = useState<string | null>(null);

  const handleApply = () => {
    const isStartDateValid = !startDate || validateDate(startDate);
    const isEndDateValid = !endDate || validateDate(endDate);

    if (isStartDateValid && isEndDateValid) {
      applyFilter(!!isDateFilterRange);
      handleClose();
      setStartDateError(null);
      setEndDateError(null);
    } else {
      if (!isStartDateValid) {
        setStartDateError('Invalid start date');
      } else {
        setStartDateError(null);
      }

      if (!isEndDateValid) {
        setEndDateError('Invalid end date');
      } else {
        setEndDateError(null);
      }
    }
  };

  return (
    <div className={className}>
      <div className={clsx('flex align-items-center justify-content-between mr-12', styles.dropdownTitleContainer)}>
        <div className="text-primary weight-700 inline-flex align-items-center mr-12 mb-12">
          <DateTitle className="mr-12" />
          <span className={styles.titleText}>{title}</span>
        </div>
        <div className="flex mb-12">
          {isShowRange && (
            <Chip
              {...(!isDateFilterRange && { variant: 'outlined' })}
              onClick={() => setIsDateFilterRange(true)}
              color="primary"
              className="mr-12 capitalize"
              label={DateFilterEnum.Range}
            />
          )}
          {isOneDay && (
            <Chip
              {...(isDateFilterRange && { variant: 'outlined' })}
              onClick={() => setIsDateFilterRange(false)}
              color="primary"
              className="capitalize"
              label={DateFilterEnum.OneDay}
            />
          )}
        </div>
      </div>
      <div className={clsx(styles.datePickerContainer, 'flex')}>
        <div className={styles.datePicker}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="mr-0 mr-lg-24 mb-12 mb-sm-24"
              disableToolbar={!isShowYear}
              variant={!isShowYear ? 'inline' : undefined}
              views={isMonthYearPicker ? ['year', 'month'] : ['year', 'month', 'date']}
              format={isMonthYearPicker ? 'MM/yyyy' : 'MM/dd/yyyy'}
              margin="normal"
              id="date-picker-inline"
              label={isRange ? 'Start date' : 'Select date'}
              value={startDate ? (removeTimezone ? parseISO(startDate as string) : startDate) : null}
              onChange={(date) => {
                if (date && !isValid(date)) {
                  setStartDateError('Invalid start date');
                } else {
                  setStartDateError(null);
                  handleStartDateChange(date);
                }
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              error={!!startDateError}
              helperText={startDateError}
            />
          </MuiPickersUtilsProvider>
        </div>
        {isDateFilterRange && (
          <>
            <span className="mx-12">-</span>
            <div className={styles.datePicker}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className="mb-24"
                  disableToolbar={!isShowYear}
                  variant={!isShowYear ? 'inline' : undefined}
                  views={isMonthYearPicker ? ['year', 'month'] : ['year', 'month', 'date']}
                  format={isMonthYearPicker ? 'MM/yyyy' : 'MM/dd/yyyy'}
                  margin="normal"
                  id="date-picker-inline"
                  label="End date"
                  value={endDate ? (removeTimezone ? parseISO(endDate as string) : endDate) : null}
                  onChange={(date) => {
                    if (date && !isValid(date)) {
                      setEndDateError('Invalid end date');
                    } else {
                      setEndDateError(null);
                      handleEndDateChange(date);
                    }
                  }}
                  minDate={startDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  error={!!endDateError}
                  helperText={endDateError}
                />
              </MuiPickersUtilsProvider>
            </div>
          </>
        )}
      </div>
      <div className={clsx('flex justify-content-end mt-16', styles.dropdownButtonContainer)}>
        <Button variant="text" className="mr-12" onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleApply}>Apply</Button>
      </div>
    </div>
  );
};
