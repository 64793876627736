import { IconProps } from 'types';

export const AllDoneIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.39551 12.75C6.13509 12.75 5.87468 12.6458 5.66634 12.4375L0.458008 7.22917C0.0413411 6.8125 0.0413411 6.1875 0.458008 5.77083C0.874675 5.35417 1.49967 5.35417 1.91634 5.77083L6.39551 10.25L16.083 0.5625C16.4997 0.145833 17.1247 0.145833 17.5413 0.5625C17.958 0.979167 17.958 1.60417 17.5413 2.02083L7.12467 12.4375C6.91634 12.6458 6.65592 12.75 6.39551 12.75Z"
        fill="#D3D5E6"
      />
    </svg>
  );
};
