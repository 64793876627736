import { FC } from 'react';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { ArrowDownIcon, CheckedIcon } from 'icons';
import { Pagination, TableV2 } from 'components';
import { ProposalRequestsRouteProps } from 'pages/ProposalRequests/types';
import { getLabelByProposalStatusesEnum } from 'utils/common';
import formatNumberWithCommas from 'utils/formatNumberWithCommas';

import styles from './Table.module.scss';
import { useProposalRequestsTable } from './useTable';
import { ProposalRequestsMobileTable } from '../MobileTable';

export const ProposalRequestsTable: FC<ProposalRequestsRouteProps> = ({ match, link }) => {
  const {
    statuses,
    columns,
    isSectionLoading,
    expandedSections,
    isAllSectionsCollapsed,
    isAllSectionsExpanded,

    handleCollapseAllSections,
    handleExpandeAllSections,
    handleSection,

    handleDeleteProposalRequest,
    handleEditProposalRequest,
    handlePageChange,

    getDataByProposalStatus,
  } = useProposalRequestsTable({ match, link });

  const TableEmptyState = () => (
    <div className="w-100 flex justify-content-center align-items-center py-24">
      <CheckedIcon />
      <p className="ml-8 weight-600">All done</p>
    </div>
  );

  return (
    <div className="flex flex-column px-12 p-relative mb-20">
      <div className={clsx('flex gap-10 mb-16', styles.actionsContainer)}>
        <p onClick={handleExpandeAllSections} className={clsx(isAllSectionsExpanded && styles.disabled)}>
          Expand all
        </p>
        <p onClick={handleCollapseAllSections} className={clsx(isAllSectionsCollapsed && styles.disabled)}>
          Collapse all
        </p>
      </div>

      {statuses.map((status, index) => {
        const isOpen = expandedSections.includes(status);
        //@ts-ignore
        const { items, totalLtv, page: itemsPage, total, count, pagesTotal } = getDataByProposalStatus(status);

        return (
          <div className="flex flex-column p-relative" key={index}>
            <div
              onClick={() => handleSection(status)}
              className={clsx(styles.section, isOpen && styles.expandedSection)}
            >
              <p>
                {getLabelByProposalStatusesEnum(status)} - {total} {total !== 1 ? 'items' : 'item'}
                <ArrowDownIcon className={styles.sectionArrow} />
              </p>
              <p>
                <span>${formatNumberWithCommas(totalLtv)}</span>
              </p>
            </div>
            {isOpen && (
              <>
                {isSectionLoading(status) ? (
                  <CircularProgress size={32} />
                ) : (
                  <>
                    <Pagination
                      perPage={match.query.itemsPerGroup ?? 30}
                      count={count}
                      total={total}
                      handlePageChange={handlePageChange}
                      pagesTotal={pagesTotal}
                      tableName={status}
                      currentPage={itemsPage}
                      className={styles.pagination}
                    />

                    <TableV2
                      columns={columns[status]}
                      isScrollTop={false}
                      data={items}
                      model="proposal requests"
                      customEmptyState={<TableEmptyState />}
                      className="mt-20"
                    />

                    <ProposalRequestsMobileTable
                      items={items}
                      actionHandlers={{ handleDeleteProposalRequest, handleEditProposalRequest }}
                    />
                  </>
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
