import { FC } from 'react';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';

import { SubmitGreenIcon, CloseIcon } from 'icons';
import { CategoryStatusFormValues, DocumentCategoryStatusEnum } from 'types';

import styles from './CheckCrossIcons.module.scss';

type CheckCrossIconsProps = {
  status: string | undefined;
  onSubmit: (values: CategoryStatusFormValues) => void;
};

export const CheckCrossIcons: FC<CheckCrossIconsProps> = ({ status, onSubmit }) => {
  const handleChange = (status: DocumentCategoryStatusEnum) => {
    onSubmit({ status });
  };

  return (
    <div className={clsx(styles.mainContainer, 'flex align-items-center justify-content-between')}>
      <IconButton
        className={clsx(styles.button, status === DocumentCategoryStatusEnum.Approved && styles.active)}
        onClick={() => handleChange(DocumentCategoryStatusEnum.Approved)}
      >
        <SubmitGreenIcon />
      </IconButton>
      <IconButton
        className={clsx(styles.button, status === DocumentCategoryStatusEnum.Received && styles.rejected)}
        onClick={() => handleChange(DocumentCategoryStatusEnum.Received)}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};
