import { Questions } from 'pages/ReportWriting/schemas/types';

export const getAnswersByQuestionId = (
  idx: string,
  arr: Questions[] | Partial<Questions>[],
): Partial<Questions> | undefined => {
  try {
    for (const obj of arr) {
      if (obj.id === idx) return obj;
      if (obj.options) {
        for (const child of Object.values(obj.options)) {
          const result = getAnswersByQuestionId(idx, child);
          if (result) return result;
        }
      }
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};
