import { useFormChanges } from 'contexts/FormChanges';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

export const useFormModal = (isHandlingBackBtn = true) => {
  const history = useHistory();
  const { isFormChanged, setIsFormChanged } = useFormChanges();
  const [isFormModalOpen, setIsFormModalOpen] = useState<boolean | unknown>(false);
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] = useState(false);
  const [isBackButtonPressed, setIsBackButtonPressed] = useState(false);
  const [backPathname, setBackPathname] = useState('');
  const [openModalsCount, setOpenModalsCount] = useState(0);

  const handleLeavePage = async () => {
    history.push(backPathname);
    setIsFormChanged(false);
  };

  useEffect(() => {
    const unblock = history.block((location: Location, action: string) => {
      if (isHandlingBackBtn && action === 'POP' && isFormChanged && openModalsCount > 0) {
        handleOpenUnsavedChangesModal();
        setBackPathname(location.pathname);
        return false;
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [isFormChanged, openModalsCount]);

  useEffect(() => {
    const handleBackButton = (e: PopStateEvent) => {
      isHandlingBackBtn && isFormChanged && e.preventDefault();
      setIsBackButtonPressed(true);
    };
    window.addEventListener('popstate', handleBackButton);
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  const handleCloseFormModal = (reason?: string) => {
    if ((reason === 'backdropClick' || reason === 'escapeKeyDown') && isFormChanged)
      return handleOpenUnsavedChangesModal();

    setOpenModalsCount((prevCount) => Math.max(prevCount - 1, 0));
    setIsFormModalOpen(false);
  };

  const handleOpenFormModal = (enumKey?: unknown) => {
    setOpenModalsCount(1);
    if (!isNaN(enumKey as number)) {
      return setIsFormModalOpen(enumKey);
    }
    return setIsFormModalOpen(true);
  };

  const handleOpenUnsavedChangesModal = () => {
    setIsUnsavedChangesModalOpen(true);
  };

  const handleCloseUnsavedChangesModal = () => {
    setIsUnsavedChangesModalOpen(false);
    setIsBackButtonPressed(false);
  };

  const handleCloseAllModals = () => {
    handleCloseFormModal();
    handleCloseUnsavedChangesModal();
    setOpenModalsCount(0);

    if (!isFormChanged) {
      setIsFormChanged(false);
    }

    isHandlingBackBtn && isBackButtonPressed && handleLeavePage();
    setIsBackButtonPressed(false);
  };

  return {
    isFormModalOpen,
    isUnsavedChangesModalOpen,
    handleCloseAllModals,
    handleCloseUnsavedChangesModal,
    handleOpenFormModal,
    handleCloseFormModal,
    handleOpenUnsavedChangesModal,
  };
};
