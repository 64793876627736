import React, { FC, useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import { TextField } from 'formik-material-ui';
import clsx from 'clsx';
import { useQuery } from 'react-query';
import CircularProgress from '@material-ui/core/CircularProgress';
import RadioGroup from '@material-ui/core/RadioGroup';
import * as Yup from 'yup';

import { CustomDatePicker, CustomTimePicker, CustomSlider, LoadingButton, Autocomplete } from 'components';
import { DropdownOption, InspectionFormValues, GridApiResponse } from 'types';
import { components } from 'generated/types';
import { api, apiRoutes } from 'api';
import { emptyTableData } from 'consts';
import { getFullName, toDateWithTimeZone } from 'utils';

import styles from './InspectionForm.module.scss';
import { useFormChanges } from 'contexts/FormChanges';

type Project = components['schemas']['Project'];
type User = components['schemas']['User'];

interface InspectionFormProps {
  onClose: () => void;
  onSubmit: (values: InspectionFormValues) => void | Promise<void>;
  submitButtonTitle: string;
  name?: string;
  reportDueDate?: string;
  dateRequested?: string;
  previouslyRequested?: string;
  lastInspection?: string;
  projectCompletionDate?: string;
  dateOfInspection?: string;
  timeOfInspection?: string;
  percentageCompleted?: number;
  estimatedPercentageCompleted?: number;
  retainageReleased?: string;
  project?: Project;
  owner?: User;
}

const emptyState = {} as DropdownOption;

export const InspectionForm: FC<InspectionFormProps> = ({
  onClose,
  onSubmit,
  submitButtonTitle,
  name,
  reportDueDate,
  dateRequested,
  previouslyRequested,
  lastInspection,
  projectCompletionDate,
  dateOfInspection,
  percentageCompleted,
  estimatedPercentageCompleted,
  retainageReleased,
  project,
  owner,
}) => {
  const { checkFormChanges } = useFormChanges();
  const projectsQuery = () =>
    api
      .get<DropdownOption[]>(apiRoutes.projects, {
        params: {
          'filter[_dropdown]': true,
          'sort[name]': 'ASC',
        },
      })
      .then((res) => res.data);
  const { isLoading, data: projects } = useQuery('projectsQuery', () => projectsQuery());

  const usersInfoQuery = () =>
    api
      .get<GridApiResponse<User>>(apiRoutes.users, {
        params: {
          'sort[firstName]': 'ASC',
          'sort[lastName]': 'ASC',
        },
      })
      .then((res) => res.data);
  const { isLoading: usersLoading, data: users } = useQuery('usersQuery', () => usersInfoQuery());

  const getOptionName = ({ name }: DropdownOption) => name;

  const filteredProjects = useMemo(() => (projects?.length ? projects : []), [projects]);

  const filteredUsers = useMemo(() => (users?.items?.length ? users : emptyTableData), [users]);

  const { items: userItems } = filteredUsers;

  const validationSchema = useMemo(
    () =>
      Yup.object().shape(
        {
          name: Yup.string().required('Inspection name is a required field'),
          projectId: Yup.object()
            .shape({
              id: Yup.string().required('Project is a required field'),
              name: Yup.string().required('Project is a required field'),
            })
            .typeError('Project is a required field'),
          timeOfInspection: Yup.string()
            .when(['dateOfInspection'], {
              is: (dateOfInspection: string) => {
                return !!dateOfInspection;
              },
              then: Yup.string()
                .typeError('Time of inspection is a required field')
                .required('Time of inspection is a required field'),
            })
            .nullable(),
          dateOfInspection: Yup.string()
            .when(['timeOfInspection'], {
              is: (timeOfInspection: string) => {
                return !!timeOfInspection;
              },
              then: Yup.string()
                .typeError('Date of inspection is a required field')
                .required('Date of inspection is a required field'),
            })
            .nullable(),
          owner: Yup.object()
            .shape({
              id: Yup.number(),
              name: Yup.string(),
            })
            .nullable(),
          projectCompletionDate: Yup.string().nullable(),
        },
        [['timeOfInspection', 'dateOfInspection']],
      ),
    [],
  );

  if (isLoading || usersLoading) return <CircularProgress size={64} />;

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={{
        name: name ?? '',
        reportDueDate: reportDueDate ?? null,
        dateRequested: dateRequested ?? null,
        previouslyRequested: previouslyRequested ?? null,
        lastInspection: lastInspection ?? null,
        projectCompletionDate: toDateWithTimeZone(projectCompletionDate) ?? null,
        dateOfInspection: toDateWithTimeZone(dateOfInspection as string) ?? null,
        timeOfInspection: toDateWithTimeZone(dateOfInspection as string) ?? null,
        percentageCompleted: percentageCompleted ?? 0,
        estimatedPercentageCompleted: estimatedPercentageCompleted ?? 0,
        retainageReleased: retainageReleased ?? 'false',
        owner: ({ ...owner, name: getFullName(owner) } ?? {}) as DropdownOption,
        projectId: project ? ({ id: project.id, name: project.name } as DropdownOption) : emptyState,
      }}
    >
      {({ errors, initialValues, values }) => {
        checkFormChanges(initialValues, values);

        return (
          <Form>
            <div className="flex flex-wrap">
              <>
                {console.log('errors,', errors)}
                <div className="col-12 col-md-6 mb-24 mb-md-36 order-md-1">
                  <Field component={TextField} name="name" label="Inspection name" variant="outlined" type="text" />
                </div>
              </>
              <div className="col-12 col-md-6 mb-24 mb-md-36 order-md-4">
                <Field
                  component={CustomDatePicker}
                  maxDate={new Date()}
                  name="dateOfInspection"
                  label="Date of inspection"
                />
              </div>

              <div className="col-12 col-md-6 mb-24 mb-md-36 order-md-2">
                <Field
                  component={Autocomplete}
                  name="projectId"
                  placeholder="Project"
                  type="text"
                  options={filteredProjects}
                  getOptionLabel={getOptionName}
                />
              </div>

              <div className="col-12 col-md-6 mb-24 mb-md-36 order-md-5">
                <Field
                  component={CustomTimePicker}
                  name="timeOfInspection"
                  label="Time of inspection"
                  className={styles.clock}
                />
              </div>

              <div className="col-12 col-md-6 mb-24 mb-md-36 order-md-3">
                <Field
                  component={Autocomplete}
                  name="owner"
                  placeholder="Inspector"
                  type="text"
                  options={(userItems as User[]).map((user) => ({ id: user.id, name: getFullName(user) }))}
                  getOptionLabel={getOptionName}
                />
              </div>

              <div className="col-12 col-md-6 mb-24 mb-md-36 order-md-6">
                <Field component={CustomDatePicker} name="projectCompletionDate" label="Projected completion date" />
              </div>

              <div className={clsx(styles.sliderContainer, 'col-12 col-md-6 mb-24 mb-md-36 order-md-10')}>
                <FormLabel className={styles.formLabel}>Percentage completed</FormLabel>
                <Field component={CustomSlider} name="percentageCompleted" />
              </div>

              <div role="group" className={clsx(styles.sliderContainer, 'col-12 col-md-6 mb-24 mb-md-36 order-md-9')}>
                <FormLabel className={styles.formLabel}>Retainage Released</FormLabel>

                <Field
                  name="retainageReleased"
                  // eslint-disable-next-line
                  render={({ field: { name }, form: { setFieldValue, values } }: any) => (
                    <RadioGroup value={values[name]} onChange={(event) => setFieldValue(name, event.target.value)}>
                      <div className="flex">
                        <FormControlLabel value={'true'} control={<Radio />} label="Yes" />
                        <FormControlLabel value={'false'} control={<Radio />} label="No" />
                      </div>
                    </RadioGroup>
                  )}
                />
              </div>

              <div className={clsx(styles.sliderContainer, 'col-12 col-md-6 mb-24 mb-md-36 order-md-11')}>
                <FormLabel className={clsx(styles.formLabel)}>Estimated percentage completed</FormLabel>
                <Field component={CustomSlider} name="estimatedPercentageCompleted" />
              </div>
            </div>

            <div className="flex pt-24 justify-content-end px-16">
              <LoadingButton variant="contained" type="submit">
                {submitButtonTitle}
              </LoadingButton>
              <Button variant="text" type="reset" onClick={onClose} className="ml-12">
                Cancel
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
