import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const ProjectInspectionsRoute = Route(
  {
    id: Route.params.number.optional,
    search: Route.query.string,
    page: Route.query.number,
  },
  ({ id }) => `/project/${id}/inspections`,
);

export const ProjectInspections = ProjectInspectionsRoute(lazy(() => import('./Inspections')));
