import React, { FC, useMemo, useRef } from 'react';
import { Form, Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import { Editor } from '@tinymce/tinymce-react';
import { useQuery } from 'react-query';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Autocomplete, LoadingButton } from 'components';
import { getFullName } from 'utils';
import { api, apiRoutes } from 'api';
import { DropdownOption, SendRemindersFormValues } from 'types';
import { components } from 'generated/types';

type Contact = components['schemas']['Contact'];
type User = components['schemas']['User'];

interface SendReminderResponse {
  text: string;
  subject: string;
}

interface SendRemindersProps {
  onSubmit: (values: SendRemindersFormValues) => void | Promise<void>;
  onClose?: () => void;
  text?: string;
  projectId: number;
  bankContactIds?: DropdownOption[];
  userIds?: DropdownOption[];
}

export const SendReminderForm: FC<SendRemindersProps> = ({
  onClose,
  projectId,
  bankContactIds,
  text,
  userIds,
  onSubmit,
}) => {
  // eslint-disable-next-line
  const editorRef = useRef<any>(null);

  const contactInfoQuery = () =>
    api
      .get<DropdownOption[]>(apiRoutes.contacts, {
        params: {
          'filter[_dropdown]': true,
          'sort[firstName]': 'ASC',
          'sort[lastName]': 'ASC',
        },
      })
      .then((res) => res.data);
  const { isLoading: contactsLoading, data: contacts } = useQuery('contactQuery', () => contactInfoQuery());

  const bankContactsQuery = () =>
    api.get<Contact[]>(`${apiRoutes.projects}/${projectId}/bank-contact`).then((res) => res.data);
  const { isLoading: bankContactsLoading, data: bankContacts } = useQuery('bankContactsQuery', () =>
    bankContactsQuery(),
  );

  const reminderTextQuery = () =>
    api
      .get<SendReminderResponse>(apiRoutes.reminderText, { params: { 'filter[_project]': projectId } })
      .then((res) => res.data);
  const {
    data: sendReminderData,
    isLoading: reminderTextLoading,
    isFetching: reminderTextFetching,
  } = useQuery('reminderTextQuery', () => reminderTextQuery());

  const getBankContacts = (bankContact: DropdownOption[]) => {
    return bankContacts
      ?.filter(({ id }) => !bankContact.map(({ id }) => id).includes(id as number))
      .map((user) => ({ id: user.id, name: getFullName(user) }));
  };

  const filteredContacts = useMemo(() => (contacts?.length ? contacts : contacts), [contacts]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        bankContactIds: Yup.array()
          .of(
            Yup.object()
              .shape({
                id: Yup.number().required('Bank contact is a required field'),
                name: Yup.string().required('Bank contact is a required field'),
              })
              .typeError('Bank contact is a required field')
              .required('Bank contact is a required field'),
          )
          .min(1, 'Bank contact is a required field'),
        userIds: Yup.array()
          .of(
            Yup.object()
              .shape({
                id: Yup.number().required('Other recipient is a required field'),
                name: Yup.string().required('Other recipient is a required field '),
              })
              .typeError('Other recipient is a required field')
              .required('Other recipient is a required field'),
          )
          .min(0),
        subject: Yup.string().required('Subject is  a required field'),
      }),
    [],
  );

  if (reminderTextLoading || contactsLoading || bankContactsLoading || reminderTextFetching)
    return <CircularProgress size={64} />;

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        bankContactIds: bankContactIds ?? [],
        text: text ?? '',
        userIds: userIds ?? [],
        subject: sendReminderData?.subject ?? '',
      }}
      onSubmit={(values) => {
        onSubmit({ ...values, text: editorRef.current.getContent() });
      }}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <div className="flex flex-wrap">
            <div className="col-12 col-md-6 mb-24 mb-md-36">
              <Field
                component={Autocomplete}
                name="bankContactIds"
                placeholder="Bank Contact"
                type="text"
                options={[...(getBankContacts(values.bankContactIds) as DropdownOption[])]}
                multiple
              />
            </div>
            <div className="col-12 col-md-6 mb-24 mb-md-36">
              <Field
                component={Autocomplete}
                name="userIds"
                placeholder="Other recipient"
                type="text"
                options={(filteredContacts as User[]).map(
                  (user) => ({ id: user.id, name: getFullName(user) } as DropdownOption),
                )}
                multiple
              />
            </div>
          </div>
          <div className="col-12 mb-24 mb-md-36">
            <Field component={TextField} name="subject" label="Subject" variant="outlined" type="text" />
          </div>

          <div className="mb-24 mb-md-36 px-16" style={{ minHeight: '300px' }}>
            <Editor
              apiKey="ymwha2a9hr4lhsfgy37tf8jkvdy9xmohruo05p874kuy4l5o"
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={sendReminderData?.text ?? ''}
              init={{
                width: '100%',
                menubar: true,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | image' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                image_title: true,
                /* enable automatic uploads of images represented by blob or data URIs*/
                automatic_uploads: true,
                /*
            URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
            images_upload_url: 'postAcceptor.php',
            here we add custom filepicker only to Image dialog
          */
                file_picker_types: 'image',
                /* and here's our custom image picker*/
              }}
            />
          </div>

          <div className="flex pt-24 justify-content-end px-16">
            <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
              Send
            </LoadingButton>

            <Button variant="text" type="reset" onClick={onClose} className="ml-12">
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
