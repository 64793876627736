import React from 'react';
import { FormHelperText, InputAdornment } from '@material-ui/core';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { TimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { FormikProps, FormikValues, useFormikContext } from 'formik';
import clsx from 'clsx';

import { CloseIcon, Time } from 'icons';

import styles from './CustomTimePicker.module.scss';

type CustomTimePickerProps = {
  label: string;
  field: {
    name: string;
  };
  form: FormikProps<FormikValues>;
  className: string;
  disabled?: boolean;
};

export const CustomTimePicker = ({
  label,
  field: { name },
  form: { setFieldValue, values },
  className,
  disabled,
}: CustomTimePickerProps) => {
  const formikContext = useFormikContext();
  const { error, touched } = formikContext.getFieldMeta(name);

  const handleChange = (date: MaterialUiPickersDate) => setFieldValue(name, date);
  const handleClear = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setFieldValue(name, null);
  };

  const Input = (props: JSX.IntrinsicAttributes & TextFieldProps) => (
    <TextField
      {...props}
      disabled={disabled}
      label={label}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Time className={styles.clock} />
          </InputAdornment>
        ),
        endAdornment: !disabled && (
          <InputAdornment position="start" onClick={handleClear}>
            <CloseIcon className={styles.clock} />
          </InputAdornment>
        ),
      }}
    />
  );

  return (
    <div
      className={clsx(
        'p-relative',
        !!(error && touched) && styles.error,
        styles.autocomplete,
        styles.fullWidth,
        className,
      )}
    >
      <TimePicker
        disabled={disabled}
        clearable
        ampm
        format="hh:mm a"
        onChange={handleChange}
        value={values[name] ? new Date(values[name]) : null}
        className={className}
        TextFieldComponent={Input}
        placeholder="HH:MM"
      />
      {error && touched && (
        <FormHelperText error className={clsx('weight-400', styles.errorMessage)}>
          {typeof error === 'object' ? (error as { name: string }).name : error}
        </FormHelperText>
      )}
    </div>
  );
};
