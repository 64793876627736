const getMonthsWithYearArray = (monthList: string[], divider = '-', numberOfMonthsToShow = 12) => {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  if (!monthList || monthList.length === 0) {
    const getFormattedMonth = (index: number): string => {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();
      const monthIndex = (currentMonth + index) % 12;
      const year = currentYear + Math.floor((currentMonth + index) / 12);
      return `${monthNames[monthIndex]}${divider}${String(year).slice(2)}`;
    };

    return Array.from({ length: numberOfMonthsToShow }, (_, index) => getFormattedMonth(index));
  }

  return monthList.map((monthYear) => {
    const [year, month] = monthYear.split('-');
    const shortYear = year.slice(2);
    const monthIndex = parseInt(month, 10) - 1;

    return `${monthNames[monthIndex]}${divider}${shortYear}`;
  });
};

export default getMonthsWithYearArray;
