import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import styles from './TabItem.module.scss';

export interface TabItemProps {
  route: string;
  activeClassName?: string;
  exact?: boolean;
  icon?: ReactNode;
  children: React.ReactNode;
  counter?: number;
}

export const TabItem: FC<TabItemProps> = ({ children, route, activeClassName, icon, exact = true, counter }) => {
  return (
    <NavLink
      exact={exact}
      to={route}
      className={clsx(styles.tab, 'py-12 px-md-32 px-16')}
      activeClassName={clsx(styles.active, activeClassName)}
    >
      <span className="d-none d-md-block p-relative">{icon}</span>
      {children}
      {!!counter && <div className={styles.counter}>{counter >= 100 ? '99+' : counter}</div>}
    </NavLink>
  );
};
