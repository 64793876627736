import { useMemo, FC } from 'react';
import { Form, Formik, FormikHelpers, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import InputAdornment from '@material-ui/core/InputAdornment';
import clsx from 'clsx';
import * as Yup from 'yup';
import { useQuery } from 'react-query';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { SignUpFormValues, DropdownOption } from 'types';
import { LoadingButton, PasswordField, InputPhoneMask, Autocomplete } from 'components';
import { PASSWORD, PHONE_NUMBER } from 'consts';
import { useFormChanges } from 'contexts/FormChanges';
import { api, apiRoutes } from 'api';

import styles from './SignUpForm.module.scss';

const emptyState = {} as DropdownOption;
interface SignUpProps {
  onSubmit: (userData: SignUpFormValues, formikHelpers: FormikHelpers<SignUpFormValues>) => void | Promise<void>;
  onClose: () => void;
  role?: DropdownOption;
}

export const SignUpForm: FC<SignUpProps> = ({ onSubmit, onClose, role }) => {
  const { checkFormChanges } = useFormChanges();
  const rolesQuery = () =>
    api
      .get<DropdownOption[]>(apiRoutes.roles, {
        params: {
          'sort[name]': 'ASC',
        },
      })
      .then((res) => res.data);
  const { isLoading, data: roles } = useQuery('rolesQuery', () => rolesQuery());

  const filteredRoles = useMemo(() => (roles?.length ? roles : []), [roles]);

  const getOptionName = ({ name }: DropdownOption) => name;

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required('First name is a required field'),
        lastName: Yup.string().required('This field is required'),
        personalNumber: Yup.string().matches(PHONE_NUMBER, 'Must be a valid phone number'),
        email: Yup.string().email('Invalid email address').required('Email is a required field'),
        workNumber: Yup.string().matches(PHONE_NUMBER, 'Must be a valid phone number'),
        role: Yup.object()
          .shape({
            id: Yup.number().required('Role is a required field'),
            name: Yup.string().required('Role is a required field'),
          })
          .typeError('Role is a required field'),
        password: Yup.string()
          .matches(PASSWORD, 'Use 8 or more characters with a mix of letters, numbers & symbols')
          .required('Password is a required field'),
        passwordConfirmation: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .required('Please confirm your password'),
      }),
    [],
  );

  if (isLoading) return <CircularProgress size={64} />;

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        personalNumber: '',
        workNumber: '',
        role: role ?? emptyState,
        password: '',
        passwordConfirmation: '',
      }}
    >
      {({ isSubmitting, initialValues, values }) => {
        checkFormChanges(initialValues, values);

        return (
          <Form>
            <div className="flex flex-wrap">
              <div className="col-md-6 col-sm-12 mb-24 col-12 mb-md-36">
                <Field component={TextField} name="firstName" label="First name" variant="outlined" type="text" />
              </div>
              <div className="col-md-6 col-sm-12 mb-24 col-12 mb-md-36">
                <Field component={TextField} name="lastName" label="Last name" variant="outlined" type="text" />
              </div>
              <div className="col-md-6 col-sm-12 mb-24 col-12 mb-md-36">
                <Field component={TextField} name="email" label="Email" variant="outlined" type="email" />
              </div>
              <div className="col-md-6 col-sm-12 mb-24 col-12 mb-md-36">
                <Field
                  component={InputPhoneMask}
                  name="personalNumber"
                  label="Personal number"
                  variant="outlined"
                  type="tel"
                />
              </div>
              <div className="flex col-md-6 col-sm-12 mb-24 col-12 mb-md-36">
                <Field
                  component={InputPhoneMask}
                  name="workNumber"
                  placeholder="+1 (770) 123-4567"
                  label="Work number"
                  variant="outlined"
                />
                <Field
                  component={TextField}
                  name="workNumberExtension"
                  variant="outlined"
                  placeholder="1234"
                  className={clsx(styles.workNumberExtension, 'ml-16')}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">#</InputAdornment>,
                  }}
                />
              </div>
              <div className="col-md-6 col-sm-12 mb-24 col-12 mb-md-36">
                <Field
                  component={Autocomplete}
                  name="role"
                  placeholder="Role"
                  type="text"
                  options={filteredRoles}
                  getOptionLabel={getOptionName}
                />
              </div>
              <div className="col-md-6 col-sm-12 mb-24 col-12 mb-md-40">
                <PasswordField name="password" label="Password" />
              </div>
              <div className="col-md-6 col-sm-12 mb-24 col-12 mb-md-40">
                <PasswordField name="passwordConfirmation" label="Confirm password" />
              </div>
            </div>
            <div className="flex pt-24 justify-content-end px-16">
              <Button variant="text" type="reset" onClick={onClose} className="ml-12">
                Cancel
              </Button>
              <LoadingButton type="submit" loading={isSubmitting}>
                Save
              </LoadingButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
