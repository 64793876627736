import { useEffect, useState } from 'react';
import { useProposalLocalStorage } from './useLocalStorage';

export const proposalPageId = 'proposal-page';

export const useScroll = () => {
  const { getScrollTopPositionFromLS } = useProposalLocalStorage();
  const [scrollTopPosition, setScrollTopPosition] = useState(getScrollTopPositionFromLS());
  const scrollDiv = document.getElementById(proposalPageId);

  useEffect(() => {
    scrollDiv?.addEventListener('scroll', handleScroll);
    return () => {
      scrollDiv?.removeEventListener('scroll', handleScroll);
    };
  }, [scrollDiv]);

  const handleScroll = () => {
    if (scrollDiv?.scrollTop !== 0) {
      setScrollTopPosition(Math.round(scrollDiv?.scrollTop ?? 0));
    }
  };

  const handleScrollTo = () => {
    if (scrollDiv && scrollTopPosition > 200) {
      scrollDiv.scrollTo({
        top: scrollTopPosition,
        behavior: 'smooth',
      });
    }
  };

  return { handleScrollTo, scrollTopPosition };
};
