import { useMemo, useCallback, useState, useEffect, useRef } from 'react';
import {
  useGetProjects,
  useGetCountries,
  useGetUsers,
  useGetCompanies,
  useGetContacts,
  useGetWorkOrderTypes,
  useGetProposalStatuses,
  useGetProposalEstimateList,
} from 'lib/react-query/queries';
import { CompanyTypesEnum, Country, DropdownOption, Proposal, ProposalCreateDTO, ProposalFormValues } from 'types';
import { getDateWithoutTimeZone, getFullName } from 'utils';
import { getMappedStates } from 'utils/common';
import { ProposalStatusEnum } from 'types/proposal';

export const useProposalRequestForm = (values?: Proposal) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>();
  const [selectedServices, setSelectedServices] = useState<NonNullable<Proposal['services']>>([]);
  const [dynamicLtv, setDynamicLtv] = useState<number>(values?.ltv ?? 0);

  const { data: projects, isLoading: projectsLoading } = useGetProjects({ params: { 'filter[_dropdown]': true } });
  const { data: countries } = useGetCountries();
  const { data: users, isLoading: usersLoading } = useGetUsers({ params: { 'filter[_dropdown]': true } });
  const { data: companies, isLoading: companiesLoading } = useGetCompanies({
    params: { 'filter[typeId]': CompanyTypesEnum.Bank, 'filter[_dropdown]': true },
  });
  const { data: contacts, isLoading: contactsLoading } = useGetContacts({
    params: { 'filter[companyType]': CompanyTypesEnum.Bank },
  });
  const { data: workOrderTypes, isLoading: workOrderTypesLoading } = useGetWorkOrderTypes();
  const { data: proposalStatuses, isLoading: proposalStatusesLoading } = useGetProposalStatuses();
  const { data: estimateList, isLoading: estimateListLoading } = useGetProposalEstimateList();

  const filteredUsers = useMemo(() => (users ? users : []), [users]);
  const filteredProjects = useMemo(
    () => (projects ? projects.map((project) => ({ ...project, name: `ID: ${project.id}, ${project.name}` })) : []),
    [projects],
  );
  const mappedStates = useMemo(() => getMappedStates(countries), [countries]);

  const isQueriesLoading =
    usersLoading ||
    contactsLoading ||
    companiesLoading ||
    projectsLoading ||
    workOrderTypesLoading ||
    proposalStatusesLoading ||
    estimateListLoading;

  const {
    name,
    status,
    state,
    assignee,
    reviewer,
    project,
    bank,
    ltv,
    bankContacts,
    drawPeriod,
    receivedDate,
    sentDate,
    awardDate,
    expectedDateOfFirstInspection,
    expectedDateOfLastInspection,
    notes,
  } = values || {};

  const initialValues = useMemo(() => {
    return {
      name: name ?? '',
      status: (status
        ? proposalStatuses?.find(({ id }) => id === status)
        : proposalStatuses?.find(({ id }) => id === ProposalStatusEnum.ToDo)) as DropdownOption,
      assignee: ({ id: assignee?.id, name: getFullName(assignee) } ?? {}) as DropdownOption,
      reviewer: (reviewer ? { id: reviewer?.id, name: getFullName(reviewer) } : {}) as DropdownOption,
      projectId: (project ? { id: project.id, name: `ID: ${project.id}, ${project.name}` } : {}) as DropdownOption,
      bank: (bank ?? {}) as DropdownOption,
      drawPeriod: (drawPeriod ? estimateList?.find(({ id }) => id === drawPeriod) : {}) as DropdownOption,
      state: (state && mappedStates.length
        ? {
            id: mappedStates.find(({ name }: Country) => name === state)?.id,
            name: state,
          }
        : {}) as Country,
      bankContacts: bankContacts
        ? bankContacts.map((contact) => {
            return { id: contact.id, name: getFullName(contact) };
          })
        : [],
      receivedDate: receivedDate ?? '',
      sentDate: sentDate ?? '',
      awardDate: awardDate ?? '',
      expectedDateOfFirstInspection: expectedDateOfFirstInspection ?? '',
      expectedDateOfLastInspection: expectedDateOfLastInspection ?? '',
      requestedServices: selectedServices,
      ltv: ltv ?? '',
      //@ts-ignore
      noteText: !!(notes?.length > 0) ? notes[0].text : '',
    };
  }, [values, mappedStates, proposalStatuses, estimateList]);

  useEffect(() => {
    const ltv = selectedServices.reduce((acc, cur) => {
      const amount = cur?.quantity && cur?.price ? +cur?.price * cur?.quantity : 0;
      return (acc += amount);
    }, 0);
    setDynamicLtv(ltv);
  }, [selectedServices]);

  useEffect(() => {
    formRef?.current?.setFieldValue('ltv', dynamicLtv);
  }, [dynamicLtv]);

  useEffect(() => {
    setSelectedServices(values?.services ?? []);
  }, [values?.services]);

  const getBankContacts = useCallback(
    (bankId?: number | string) => {
      return bankId
        ? contacts
            ?.filter(({ organization }) => organization?.id === bankId)
            ?.map((item) => ({ id: item.id, name: getFullName(item) })) ?? []
        : [];
    },
    [contacts],
  );

  const transformSubmitValues = (values: ProposalFormValues): ProposalCreateDTO => {
    return {
      state: values?.state?.name,
      status: values?.status?.id as ProposalCreateDTO['status'],
      drawPeriod: values.drawPeriod?.id as ProposalCreateDTO['drawPeriod'],
      requestedServices: selectedServices
        ?.filter((service) => service && service.name && service.quantity && service.price)
        ?.map((service) => ({
          price: Number(service.price),
          quantity: Number(service.quantity),
          name: service.name,
          id: service.id,
        })),
      assignee: Number(values.assignee?.id),
      name: values?.name?.toString() as string,
      reviewer: Number(values.reviewer?.id),
      projectId: values.projectId?.id?.toString() as string,
      expectedDateOfLastInspection:
        values.expectedDateOfLastInspection && getDateWithoutTimeZone(values.expectedDateOfLastInspection),
      expectedDateOfFirstInspection:
        values.expectedDateOfFirstInspection && getDateWithoutTimeZone(values.expectedDateOfFirstInspection),
      awardDate: values.awardDate && getDateWithoutTimeZone(values.awardDate),
      receivedDate: values.receivedDate,
      sentDate: values.sentDate && getDateWithoutTimeZone(values.sentDate),
      bankId: values.bank?.id?.toString(),
      bankContactIds: values.bankContacts?.map((contact) => contact.id as number),
      noteText: values?.noteText,
      //@ts-ignore
      ltv: dynamicLtv,
    };
  };

  const handleServices = (serviceId: string, value: string, fieldName?: string, isCheckbox?: boolean) => {
    if (isCheckbox) {
      const updatedArray = value
        ? ([...selectedServices, { name: serviceId }] as NonNullable<Proposal['services']>)
        : selectedServices.filter(({ name }) => name !== serviceId);
      return setSelectedServices(updatedArray);
    }

    const isSelectedService = selectedServices.find(({ name }) => name === serviceId);

    if (isSelectedService) {
      const updatedArray = selectedServices.map((obj) =>
        obj.name === serviceId ? { ...obj, [`${fieldName}`]: value } : obj,
      );
      return setSelectedServices(updatedArray);
    } else {
      const updatedArray = [...selectedServices, { name: serviceId, [`${fieldName}`]: value }];
      return setSelectedServices(updatedArray);
    }
  };

  const handleServicesKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const disallowedSymbols = ['-', ',', '.'];
    if (disallowedSymbols.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleServicesPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    const clipboardData = e.clipboardData;
    const pastedText = clipboardData.getData('text');
    const sanitizedText = pastedText.replace(/[-,.]/g, '');
    document.execCommand('insertText', false, sanitizedText);
  };

  return {
    filteredUsers,
    filteredProjects,
    contacts,
    projects,
    mappedStates,
    workOrderTypes,
    proposalStatuses,
    companies,
    estimateList,

    isQueriesLoading,

    getBankContacts,
    transformSubmitValues,

    selectedServices,
    handleServices,
    handleServicesKeyPress,
    handleServicesPaste,

    initialValues,

    formRef,
  };
};
