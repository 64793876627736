import { IconProps } from 'types';

export const SortIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333336 0.833415C0.333336 0.465225 0.631813 0.166748 1 0.166748H8.66667C9.03486 0.166748 9.33334 0.465225 9.33334 0.833415C9.33334 1.2016 9.03486 1.50008 8.66667 1.50008H1C0.631813 1.50008 0.333336 1.2016 0.333336 0.833415Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333336 5.16675C0.333336 4.79856 0.631813 4.50008 1 4.50008H8.66667C9.03486 4.50008 9.33334 4.79856 9.33334 5.16675C9.33334 5.53494 9.03486 5.83341 8.66667 5.83341H1C0.631813 5.83341 0.333336 5.53494 0.333336 5.16675Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0.166748C11.3682 0.166748 11.6667 0.465225 11.6667 0.833415V7.69791L12.4938 6.73289C12.7334 6.45334 13.1543 6.42096 13.4339 6.66058C13.7134 6.90019 13.7458 7.32106 13.5062 7.60061L11.5062 9.93394C11.3246 10.1458 11.0304 10.2221 10.7687 10.1253C10.507 10.0285 10.3333 9.77907 10.3333 9.50008V0.833415C10.3333 0.465225 10.6318 0.166748 11 0.166748Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333336 9.50008C0.333336 9.13189 0.631813 8.83341 1 8.83341H8.66667C9.03486 8.83341 9.33334 9.13189 9.33334 9.50008C9.33334 9.86827 9.03486 10.1667 8.66667 10.1667H1C0.631813 10.1667 0.333336 9.86827 0.333336 9.50008Z"
        fill="black"
      />
    </svg>
  );
};
