import { TextField } from 'formik-material-ui';
import { Field } from 'formik';
import { CustomDatePicker, Autocomplete } from 'components/common';
import { amountFieldProp } from 'consts';

import { defaultFieldProps, YesNoNaOptions } from '../const';

export const HardCostBudgetFields = () => (
  <>
    <Field component={TextField} name="contractor" label="Contractor" {...defaultFieldProps} />
    <Field component={CustomDatePicker} name="date" label="Date" removeTimezone {...defaultFieldProps} />
    <Field
      component={TextField}
      name="constructionCost"
      label="Total Construction Cost"
      {...amountFieldProp}
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      name="sovMatchSum"
      placeholder="Does the SOV match the contract sum?"
      options={YesNoNaOptions}
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      name="sovContingency"
      placeholder="Does the SOV include a Contingency line item? "
      options={YesNoNaOptions}
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      name="generalConditions"
      placeholder="Are General Conditions, Overhead, and Fees shown on seperate line items in the SOV? "
      options={YesNoNaOptions}
      {...defaultFieldProps}
    />
  </>
);
