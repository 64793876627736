import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const ProjectLayoutRoute = Route(
  {
    id: Route.params.number.optional,
    search: Route.query.string,
    page: Route.query.number,
    isTableView: Route.query.boolean,
    status: Route.query.string,
  },
  ({ id }) => `/project/${id}`,
);

export const ProjectOverview = ProjectLayoutRoute(lazy(() => import('./ProjectOverview')));
