import { IconProps } from 'types';

export const DocManagementFileIcon = ({ className, color }: IconProps) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.25 17.3333C16.6307 17.3333 17.75 16.214 17.75 14.8333V3.16663C17.75 1.78591 16.6307 0.666626 15.25 0.666626H2.75C1.36929 0.666626 0.25 1.78591 0.25 3.16663V14.8333C0.25 16.214 1.36929 17.3333 2.75 17.3333H15.25ZM16.0833 11.0154V3.16663C16.0833 2.70639 15.7102 2.33329 15.25 2.33329H2.75C2.28976 2.33329 1.91667 2.70639 1.91667 3.16663V14.8333C1.91667 15.2935 2.28976 15.6666 2.75 15.6666H11.714L16.0833 11.0154ZM14.0007 15.6666H15.25C15.7102 15.6666 16.0833 15.2935 16.0833 14.8333V13.4496L14.0007 15.6666Z"
        fill={color || 'black'}
      />
    </svg>
  );
};
