import { QuestionType, Questions } from 'pages/ReportWriting/schemas/types';
import { getParsedAnswers } from './getParsedAnswers';
import { validRadioQuestionTypes } from 'pages/ReportWriting/const';

export const getQuestionsWithoutAnswers = (arr: Partial<Questions>[], questionsState: Questions[]) => {
  const questionWithoutAnswers = arr.filter(
    ({ type, answer }) =>
      validRadioQuestionTypes.includes(type as QuestionType) && !getParsedAnswers(questionsState, answer),
  );

  const questionsWithAnswers = arr.filter(
    ({ type, answer }) =>
      validRadioQuestionTypes.includes(type as QuestionType) && getParsedAnswers(questionsState, answer),
  );

  questionsWithAnswers.forEach(({ answer, options }) => {
    options?.[getParsedAnswers(questionsState, answer)] &&
      questionWithoutAnswers.push(
        ...getQuestionsWithoutAnswers(
          options?.[getParsedAnswers(questionsState, answer)] as Partial<Questions>[],
          questionsState,
        ),
      );
  });

  return questionWithoutAnswers;
};
