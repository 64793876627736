import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const WorkOrderNotesRoute = Route(
  {
    id: Route.params.number.optional,
    noteId: Route.query.number,
    search: Route.query.string,
    page: Route.query.number,
    status: Route.query.string,
  },
  ({ id }) => `/work-order/${id}/notes`,
);

export const WorkOrderNotes = WorkOrderNotesRoute(lazy(() => import('./Notes')));
