import { Suspense, lazy } from 'react';
import { CustomDialog } from 'components';
import { DeleteIcon, EditUserIcon, PlusIcon } from 'icons';
import UnsavedChangesModal from 'components/common/UnsavedChangesModal';
import { useProposalRequests } from 'pages/ProposalRequests/context';
import { SelectedProposalRequestsDialogEnum } from 'pages/ProposalRequests/types';
const ProposalCreateForm = lazy(() => import('../ProposalCreateForm'));
const ProposalEditForm = lazy(() => import('../ProposalEditForm'));
const ConfirmationModal = lazy(() => import('components/common/ConfirmationModal'));

export const ProposalRequestsModals = () => {
  const {
    isFormModalOpen,
    isUnsavedChangesModalOpen,
    selectedOpenDialog,
    selectedProposalId,
    handleCloseAllModals,
    handleCloseUnsavedChangesModal,
    handleCloseFormModal,
    handleCloseSelectedDialog,
    handleDeleteProposal,
    isDeleteProposalRequestLoading,
  } = useProposalRequests();

  return (
    <>
      <CustomDialog
        open={isFormModalOpen === SelectedProposalRequestsDialogEnum.ProjectRequestCreate}
        icon={<PlusIcon />}
        header="Add new Proposal Request"
        onClose={(_, reason) => handleCloseFormModal(reason)}
      >
        <Suspense fallback={<span>Loading...</span>}>
          <ProposalCreateForm onClose={handleCloseFormModal} />
          <UnsavedChangesModal
            open={isUnsavedChangesModalOpen as boolean}
            onClose={handleCloseUnsavedChangesModal}
            closeAll={handleCloseAllModals}
          />
        </Suspense>
      </CustomDialog>

      <CustomDialog
        open={isFormModalOpen === SelectedProposalRequestsDialogEnum.ProjectRequestEdit}
        icon={<EditUserIcon />}
        header="Edit Proposal Request"
        onClose={(_, reason) => handleCloseFormModal(reason)}
      >
        <Suspense fallback={<span>Loading...</span>}>
          <ProposalEditForm id={selectedProposalId?.current as number} onClose={handleCloseFormModal} />
          <UnsavedChangesModal
            open={isUnsavedChangesModalOpen as boolean}
            onClose={handleCloseUnsavedChangesModal}
            closeAll={handleCloseAllModals}
          />
        </Suspense>
      </CustomDialog>

      <CustomDialog
        open={selectedOpenDialog === SelectedProposalRequestsDialogEnum.ProjectRequestDelete}
        icon={<DeleteIcon />}
        header="Delete this proposal request?"
        onClose={handleCloseSelectedDialog}
      >
        <Suspense fallback={<span>Loading...</span>}>
          <ConfirmationModal
            text="You won’t be able to restore it"
            onClose={handleCloseSelectedDialog}
            onSubmit={handleDeleteProposal}
            isLoading={isDeleteProposalRequestLoading}
          />
        </Suspense>
      </CustomDialog>
    </>
  );
};
