import { FC } from 'react';
import { CustomDialog } from '../Dialog';

import Button from '@material-ui/core/Button/Button';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';

type UnsavedChangesModalProps = {
  open: boolean;
  onClose: () => void;
  closeAll: () => void;
  variant?: UnsavedChangesModalVariants;
};

export enum UnsavedChangesModalVariants {
  default = 'default',
  reportGenerating = 'reportGenerating',
}

export const UnsavedChangesModal: FC<UnsavedChangesModalProps> = ({
  open,
  onClose,
  closeAll,
  variant = UnsavedChangesModalVariants.default,
}) => {
  const messages = {
    default: 'You can discard unsaved changes and leave, or continue editing.',
    reportGenerating: 'You can generate report without these changes, or return to editing',
  };

  const actionBtnTexts = {
    default: 'Discard & Leave',
    reportGenerating: 'Generate Report',
  };

  return (
    <CustomDialog header="You have unsaved changes" {...{ open, onClose }} maxWidth="sm">
      <DialogContent>
        <p className="mb-20">{messages[variant]}</p>
      </DialogContent>
      <DialogActions className="justify-content-end">
        <Button variant="outlined" onClick={closeAll}>
          {actionBtnTexts[variant]}
        </Button>
        <Button variant="contained" onClick={onClose}>
          Continue Editing
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};
